import { useEffect, useState, useRef } from 'react';
import api from '@services/client/api';
import { Notification } from '@utils/types';

interface User {
  type_permission: string;
}

interface NotificationHookProps {
  user: User;
  token: string;
}

const useNotificationHook = ({ user, token }: NotificationHookProps) => {
  const previousNotificationCountRef = useRef<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [userInteracted, setUserInteracted] = useState<boolean>(false);
  const audio = new Audio('https://app-elomania-v2-uploads.s3.amazonaws.com/notification.mp3');

  useEffect(() => {
    const retrieveNotifications = async () => {
      try {
        const response = await api.get(`/notifications/${user.type_permission.toLowerCase()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data } = response;
        setNotifications(data);

        const newNotifications = data.filter((notification: Notification) => !notification.isRead);
        const newNotificationCount = newNotifications.length;
        const previousNotificationCount = previousNotificationCountRef.current;

        if (newNotificationCount > previousNotificationCount && isLoaded && userInteracted) {
          audio.play();
        }

        previousNotificationCountRef.current = newNotificationCount;

        if (!isLoaded) {
          setIsLoaded(true);
        }
      } catch (err) {
        console.error('API Error:', err);
      }
    };

    const intervalId = setInterval(retrieveNotifications, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [user.type_permission, token, isLoaded, userInteracted]);

  // Adicione um evento de clique para indicar que o usuário interagiu
  useEffect(() => {
    const handleClick = () => {
      setUserInteracted(true);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return { notifications, isLoaded };
};

export default useNotificationHook;