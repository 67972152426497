import StatisticsByGame from "@components/Dashboard/mainDashboard/Adm/result/statisticsByGame";
import StatisticsByProfit from "@components/Dashboard/mainDashboard/Adm/result/statisticsByProfit";
import StatisticsByYear from "@components/Dashboard/mainDashboard/Adm/result/statisticsByService";
import StatisticsSmurf from "@components/Dashboard/mainDashboard/Adm/result/statisticsBySmurf";

type Props = {};

const ResultAdm = (props: Props) => {
  return (
    <>
      <StatisticsByGame />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-4">
        <StatisticsSmurf />
        <StatisticsByProfit />
      </div>
      <StatisticsByYear />
    </>
  );
};

export default ResultAdm;
