import React, { useState, useEffect, useMemo } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import Discord from '@assets/Topbar/Discord.svg';
import Toggle from '@assets/Topbar/Toggle.svg';
import Notification from '@assets/Topbar/Notification.svg';
import Profile from '@assets/Topbar/Profile.svg';
import PopupNotification from '@components/Notification';
import PopupSettings from '@components/Settings';
import '@styles/dashboard.css';
import '@styles/sidebarrr.css';

import Navigation from './Sidebar';
import { useTheme } from '@hooks/Theme';
import { useAuth } from '@context/AuthContext';

import Resumo from '@assets/SideMenu/resumo.svg';
import Clientes from '@assets/SideMenu/clientes.svg';
import Equipe from '@assets/SideMenu/equipe.svg';
import Smurfs from '@assets/SideMenu/smurfs.svg';
import Entretenimento from '@assets/SideMenu/entretenimento.svg';
import Ferramentas from '@assets/SideMenu/ferramentas.svg';
import Resultados from '@assets/SideMenu/resultados.svg';
import Loja from '@assets/SideMenu/Loja.svg';
import Vip from '@assets/SideMenu/Vip.svg';
import History from '@assets/SideMenu/History.svg';

import SubmenuClient from '@assets/SideMenu/IconSubmenuClient.svg';
import SubmenuFeedbacks from '@assets/SideMenu/IconSubmenuFeedbacks.svg';
import SubmenuBooster from '@assets/SideMenu/IconSubmenuBooster.svg';
import SubmenuCand from '@assets/SideMenu/IconSubmenuCand.svg';
import SubmenuAdmins from '@assets/SideMenu/IconSubmenuAdmins.svg';
import SubmenuStreaming from '@assets/SideMenu/IconSubmenuStreaming.svg';
import SubmenuRoleta from '@assets/SideMenu/IconSubmenuRoleta.svg';
import SubmenuCupon from '@assets/SideMenu/IconSubmenuCupon.svg';
import SubmenuPrice from '@assets/SideMenu/IconSubmenuPrice.svg';
import SubmenuDuoBoost from '@assets/SideMenu/IconSubmenuDuoBoost.svg';
import SubmenuValorant from '@assets/SideMenu/SubmenuValorant.svg';
import SubmenuLol from '@assets/SideMenu/SubmenuLol.svg';
import SubmenuTFT from '@assets/SideMenu/SubmenuTFT.svg';
import SubmenuWR from '@assets/SideMenu/SubmenuWR.svg';
import SubmenuCsGo from '@assets/SideMenu/SubmenuCsGo.svg';

import IconDark from '@assets/SideMenu/IconDark.svg';
import IconLight from '@assets/SideMenu/IconLight.svg';
import i18n from '@i18n/index';
import { useTranslation } from 'react-i18next';
import CarrinhoModal from '@components/(check)';
import CheckoutCart from '@components/(checkout)/(cart)';

type Props = {
  userType: 'ADMIN' | 'CLIENT' | 'BOOSTER';
};

interface Menu {
  name: string;
  icon: string;
  submenus?: Menu[];
  url?: string;
  isPaid?: boolean;
}

interface MenusByUserType {
  [key: string]: Menu[];
}

const menusByUserType: MenusByUserType = {
  ADMIN: [
    {
      name: 'Resumo',
      icon: Resumo,
      url: '/dashboard',
    },
    {
      name: 'Clientes',
      icon: Clientes,
      submenus: [
        {
          name: 'Clientes',
          icon: SubmenuClient,
          url: '/dashboard/client',
        },
        {
          name: 'Feedbacks',
          icon: SubmenuFeedbacks,
          url: '/dashboard/feedback',
        },
      ],
    },
    {
      name: 'Equipe',
      icon: Equipe,
      url: '/dashboard/boosters',
      submenus: [
        {
          name: 'Boosters',
          icon: SubmenuBooster,
          url: '/dashboard/boosters',
        },
        {
          name: 'Candidatos',
          icon: SubmenuCand,
          url: '/dashboard/candidates',
        },
        {
          name: 'Admins',
          icon: SubmenuAdmins,
          url: '/dashboard/admins',
        },
      ],
    },
    {
      name: 'Smurfs',
      icon: Smurfs,
      url: '/dashboard/smurf',
    },
    {
      name: 'Entretenimento',
      icon: Entretenimento,
      submenus: [
        // {
        //   name: 'Streaming',
        //   icon: SubmenuStreaming,
        //   url: '/dashboard/streaming',
        // },
        {
          name: 'Roleta',
          icon: SubmenuRoleta,
          url: '/dashboard/roulette',
        },
        {
          name: 'Gerenciar',
          icon: SubmenuRoleta,
          url: '/dashboard/manage',
        },
      ],
    },
    {
      name: 'Ferramentas',
      icon: Ferramentas,
      submenus: [
        {
          name: 'Preços',
          icon: SubmenuPrice,
          url: '/dashboard/price',
        },
        {
          name: 'Cupons',
          icon: SubmenuCupon,
          url: '/dashboard/coupons',
        },
        {
          name: 'Duo Boost',
          icon: SubmenuDuoBoost,
          url: '/dashboard/duoboost',
        },
      ],
    },
    {
      name: 'Resultados',
      icon: Resultados,
      url: '/dashboard/result',
    },
  ],
  CLIENT: [
    {
      name: 'Resumo',
      icon: Resumo,
      url: '/dashboard',
      isPaid: false,
    },
    {
      name: 'Loja',
      icon: Loja,
      url: '/dashboard/lol',
      isPaid: false,
      submenus: [
        {
          name: 'Lol',
          icon: SubmenuLol,
          url: '/dashboard/lol',
        },
        {
          name: 'Wild Rift',
          icon: SubmenuWR,
          url: '/dashboard/wildrift',
        },
        {
          name: 'Valorant',
          icon: SubmenuValorant,
          url: '/dashboard/valorant',
        },
        {
          name: 'TFT',
          icon: SubmenuTFT,
          url: '/dashboard/teamfight',
        },
        {
          name: 'CS2',
          icon: SubmenuCsGo,
          url: '/dashboard/csgo',
        },
      ],
    },
    {
      name: 'Smurfs',
      icon: Smurfs,
      url: '/dashboard/smurfs',
      isPaid: true,
    },
    // {
    //   name: 'Entretenimento',
    //   icon: Entretenimento,
    //   isPaid: true,
    //   submenus: [
    //     {
    //       name: 'Streaming',
    //       icon: SubmenuStreaming,
    //       url: '/dashboard/streaming',
    //     },
    //   ],
    // },
    // Menu Mobile
    // {
    //   name: 'Roleta',
    //   icon: SubmenuRoleta,
    //   url: '/dashboard/roulette',
    //   isPaid: true,
    // },
    // {
    //   name: 'VIP',
    //   icon: Vip,
    //   url: '/dashboard/rewards',
    //   isPaid: true,
    // },
  ],
  BOOSTER: [
    {
      name: 'Resumo',
      icon: Resumo,
      url: '/dashboard',
    },
    {
      name: 'Contas',
      icon: Smurfs,
      url: '/dashboard/accounts',
    },
    {
      name: 'Histórico',
      icon: History,
      url: '/dashboard/historic',
    },
  ],
};

interface NavigationProps {
  userType: string;
}

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean>(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handleChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
};

const Topbar: React.FC<Props> = ({ userType }) => {
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const { t } = useTranslation();
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)');
  const [isMenuToggled, setIsMenuToggled] = useState<boolean>(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState<Menu | null>(null);
  const [activeMenu, setMenuActive] = useState(false);
  const [isLightTheme, setIsLightTheme] = useState(false);
  const { toggleTheme } = useTheme();
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleNotificationButtonClick = () => {
    setShowNotificationPopup((prevState) => !prevState);
    setShowSettingsPopup(false);
  };

  const handleSettingsButtonClick = () => {
    setShowSettingsPopup((prevState) => !prevState);
    setShowNotificationPopup(false);
  };

  const renderUserSpecificContent = () => {
    if (userType === 'ADMIN' || userType === 'CLIENT' || userType === 'BOOSTER') {
      return (
        <>
          <button className="p-0 sm:p-3" onClick={handleSettingsButtonClick}>
            <img src={Profile} alt="Profile" />
          </button>
        </>
      );
    }
    return null;
  };

  const handleLinkClick = () => {
    setIsMenuToggled(false);
  };

  const handleSubMenuClick = (menuIndex: number) => {
    const selectedMenu = menusByUserType[userType][menuIndex];

    if (selectedMenu.submenus && selectedMenu.submenus.length > 0) {
      setSelectedSubMenu(selectedMenu);
    } else {
      setSelectedSubMenu(null);
      setIsMenuToggled(false);
    }
  };

  const isSubmenusEmpty = (menu: Menu | null) => {
    return !menu || !menu.submenus || menu.submenus.length === 0;
  };

  const currentMenus = menusByUserType[userType];

  const filteredMenus = currentMenus.filter((menu) => {
    if (menu.isPaid !== undefined) {
      if (user && user.isPaid === false) {
        // return menu.isPaid === false;
        return true;
      } else {
        return true;
      }
    }
    return true;
  });

  useEffect(() => {
    // Determine the active menu and submenu based on the current route
    const currentMenus = menusByUserType[userType];
    const activeMenu = currentMenus.find((menu) => menu.url === location.pathname);

    if (activeMenu) {
      setSelectedSubMenu(activeMenu);
    } else {
      // Check if the current route matches any submenu
      for (const menu of currentMenus) {
        const activeSubmenu = menu.submenus?.find((submenu) => submenu.url === location.pathname);
        if (activeSubmenu) {
          setSelectedSubMenu(menu);
          break;
        }
      }
    }
  }, [location.pathname, userType]);

  const [activePage, setActivePage] = useState(''); // Adicione o estado para a página ativa

  useEffect(() => {
    const activeMenu = currentMenus.find((menu) => menu.url === location.pathname);
    if (activeMenu) {
      setActivePage(activeMenu.url || '');
    } else {
      for (const menu of currentMenus) {
        const activeSubmenu = menu.submenus?.find((submenu) => submenu.url === location.pathname);
        if (activeSubmenu) {
          setActivePage(menu.url || '');
          break;
        }
      }
    }
  }, [location.pathname, userType, currentMenus]);

  const getFullAvatarUrl = (avatar: string | null) => {
    if (avatar && (avatar.startsWith('http://') || avatar.startsWith('https://'))) {
      return avatar;
    }
    return avatar ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}` : '';
  };

  const handleChangeLanguage = (event: any) => {
    i18n.changeLanguage(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('lang', event.target.value);
    localStorage.setItem('lang', event.target.value);
    const search = queryParams.toString();
    const newUrl = `${window.location.pathname}?${search}${window.location.hash}`;
    navigate(newUrl, { replace: true });
  };

  return (
    <>
      <div className="elementHidden bg-white-20 dark:bg-blue-50"></div>
      <div
        className={`topbar bg-blue-50 w-full flex justify-between items-center mb-[18px] mx-auto p-4`}
      >
        <div className="toggle flex">
          {isAboveMediumScreens ? (
            <div className="hidden cursor-pointer">
              <img src={Toggle} alt="Toggle" />
            </div>
          ) : (
            <button
              className="flex cursor-pointer"
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              <img src={Toggle} alt="Toggle" />
            </button>
          )}
        </div>

        <div className="flex items-center gap-6 sm:gap-4">
          <div className="hidden sm:flex">
            <select
              name="selectLanguage"
              id="selectLanguage"
              className="bg-blue-20 p-3 border border-secondary-500 rounded-lg cursor-pointer outline-none text-sm"
              onChange={handleChangeLanguage}
              defaultValue={localStorage.getItem('lang') ? localStorage.getItem('lang')! : 'pt-br'}
            >
              <option value="pt-br">PT-BR</option>
              <option value="en-us">EN-US</option>
              <option value="es-es">ES-ES</option>
            </select>
          </div>
          <a
            href="https://discord.gg/elomania"
            target="_blank"
            rel="noopener noreferrer"
            className="p-0 sm:p-3"
          >
            <img src={Discord} alt="Discord" />
          </a>
          <button className="p-0 sm:p-3" onClick={handleNotificationButtonClick}>
            <img src={Notification} alt="Notification" />
          </button>
          {renderUserSpecificContent()}
          {showNotificationPopup && <PopupNotification />}
          {showSettingsPopup && <PopupSettings />}
          <CheckoutCart />
        </div>
      </div>
      {!isAboveMediumScreens && isMenuToggled && (
        <div className="fixed right-0 bottom-0 z-50 h-full w-full bg-blue-50 drop-shadow-xl overflow-y-auto">
          <div
            className={`navigation-container-mobile ${
              !isSubmenusEmpty(selectedSubMenu) ? 'active' : ''
            }`}
          >
            <div
              className={`navigation-mobile ${
                activeMenu || !isSubmenusEmpty(selectedSubMenu) ? 'active' : ''
              }`}
            >
              <ul>
                <li>
                  <a className="sidebar-logo-container"></a>
                </li>

                <div className="view-profile absolute z-50 top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <Link
                    to="/dashboard/profile"
                    className="flex flex-col items-center justify-center text-center py-4 gap-8"
                  >
                    <img
                      src={getFullAvatarUrl(user && user.avatar)}
                      alt="IconProfile"
                      className="w-20 h-20 rounded-lg"
                    />
                    <p className="text-gray-500 text-base font-semibold">
                      Bem-vindo, {user && user.name ? user && user.name.split(' ')[0] : ''}!
                    </p>
                  </Link>
                </div>

                {filteredMenus.map((menu, index) => (
                  <li
                    key={index}
                    className={`li-item ${activePage === menu.url ? 'hovered' : ''}`}
                    style={{ '--order': index } as React.CSSProperties}
                    onClick={() => {
                      handleSubMenuClick(index);
                    }}
                  >
                    <NavLink to={menu.url ?? '/dashboard'} onClick={handleLinkClick}>
                      <React.Fragment>
                        <div className="icon">
                          <img src={menu.icon} alt={menu.name} />
                        </div>
                        <span className={`title ${activePage === menu.url ? 'active-text' : ''}`}>
                          {menu.name}
                        </span>
                      </React.Fragment>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`submenu ${
                activeMenu || isSubmenusEmpty(selectedSubMenu) ? 'hidden' : ''
              }`}
            >
              {selectedSubMenu?.submenus && selectedSubMenu.submenus.length > 0 ? (
                <ul>
                  {selectedSubMenu.submenus.map((submenu, index) => (
                    <li key={index}>
                      <NavLink to={submenu.url ?? '/dashboard'} onClick={handleLinkClick}>
                        {({ isActive, isPending }) => (
                          <React.Fragment>
                            <div className={`icon ${isActive ? 'purple' : ''}`}>
                              <img src={submenu.icon} alt={submenu.name} />
                            </div>
                            <span className="title">{submenu.name}</span>
                          </React.Fragment>
                        )}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>

            <div className="absolute z-50 bottom-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-slate-50/10 border-2 border-blue-500 rounded-lg flex items-center">
              <button
                className={`p-2 rounded-2xl ${isLightTheme ? '' : 'dark:bg-blue-20'}`}
                onClick={toggleTheme}
              >
                <img src={isLightTheme ? IconLight : IconDark} alt="Theme Icon" />
              </button>
              <button
                className={`p-2  rounded-2xl ${isLightTheme ? 'dark:bg-blue-20' : ''}`}
                onClick={toggleTheme}
              >
                <img src={isLightTheme ? IconDark : IconLight} alt="Theme Icon" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Topbar;