import React, { useEffect, useState } from 'react';
import Close from '@assets/Buy/Close.svg';
import IconNotification from '@assets/IconNotification.svg';
import '@assets/Coockie.png';
import api from '@services/client/api';
import { useAuth } from '@context/AuthContext';
import Spin from '@assets/Spin.svg';

type Props = {};

interface Notification {
  content: string;
  description: string;
  link: string;
}

interface NotifyLink {
  avatar: string;
  booster?: string;
  cliente?: string;
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const PopupNotification = (props: Props) => {
  const { user, token } = useAuth();

  const [already, setAlready] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const retrieveNotifications = async () => {
      try {
        const response = await api.get(
          `/notifications/${user && user.type_permission.toLowerCase()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const { data } = response;
        setNotifications(data);
        setAlready(true);
      } catch (err) {
        console.log(err);
      }
    };
    retrieveNotifications();
  }, []);

  if (already === false) {
    return (
      <>
        {showPopup && (
          <div className="absolute top-20 right-0 sm:right-12">
            <div className={`z-[1000]`}>
              <div className="bg-blue-20 w-[450px] border border-secondary-500 rounded-2xl">
                <div className="title bg-blue-50 w-full flex justify-between items-center p-4 mb-2 rounded-2xl">
                  <div className="text-left gap-2">
                    <h2 className="mr-2 text-gray-500 text-sm font-bold">Notificações</h2>
                  </div>
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
                <div className="flex justify-center items-center bg-blue-20 p-4 max-h-[400px] overflow-y-auto rounded-2xl">
                  <img src={Spin} alt={Spin} className="animate-spin select-none" />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {showPopup && (
        <div className="absolute top-20 right-12">
          <div className={`z-[1000]`}>
            <div className="bg-blue-20 w-[450px] border border-secondary-500 rounded-2xl">
              <div className="title bg-blue-50 w-full flex justify-between items-center p-4 rounded-2xl">
                <div className="text-left gap-2">
                  <h2 className="mr-2 text-gray-500 text-sm font-bold">Notificações</h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="bg-blue-20 p-4 max-h-[400px] overflow-y-auto rounded-2xl">
                {notifications.length > 0 ? (
                  <div>
                    {notifications.map((notification) => {
                      const link: NotifyLink = isJsonString(notification.link)
                        ? JSON.parse(notification.link)
                        : ``;

                      return (
                        <div
                          key={notifications.indexOf(notification)}
                          className="flex items-start py-4 border-b border-b-gray-100 gap-4"
                        >
                          <img src={IconNotification} alt="IconNotification" />
                          {isJsonString(notification.link) ? (
                            <p className="text-gray-500 text-sm font-normal">
                              {notification.content}
                            </p>
                          ) : (
                            <p className="text-gray-500 text-sm font-normal">
                              {notification.content}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="mt-2">
                    <p className="text-gray-100 text-sm font-semibold">
                      {' '}
                      Ainda não há notificações. 😭{' '}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNotification;