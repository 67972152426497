import TableDuoBoost from "@components/Dashboard/mainDashboard/Adm/tools/duoBoost"

type Props = {}

const DuoBoostAdm = (props: Props) => {
  return (
    <>
      <TableDuoBoost />
    </>
  )
}

export default DuoBoostAdm