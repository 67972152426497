import ResumeAdm from "@components/Dashboard/mainDashboard/Adm/resumeAdm";
import RequestProgressAdm from "@components/Dashboard/mainDashboard/Adm/requestProgress";
import RequestFinishedAdm from "@components/Dashboard/mainDashboard/Adm/requestFinished";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { useEffect, useState } from "react";

const Admin = () => {
  const { user, token } = useAuth();

  if(!token) return null;

  return (
    <>
       {user && token && <ResumeAdm user={user} token={token} />} 
      <RequestFinishedAdm token={token} />
    </>
  );
};

export default Admin;
