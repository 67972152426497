import React, { useState, useEffect } from 'react';
import { Typography } from "@material-tailwind/react";

interface CurrencyInfo {
  prefix: string;
  locale: string;
  value: string;
  exchange: boolean;
  decimalSeparator: string;
  multiplier: number;
}

interface Props {
  price: number;
  reference: keyof typeof currencies;
  destination: keyof typeof currencies;
  convert?: boolean;
}

interface RawCurrency {
  value: string;
}

type CurrencyType = "BRL" | "USD" | "EUR";

const currencies: Record<CurrencyType, CurrencyInfo> = {
  BRL: { prefix: "R$", locale: "pt-BR", value: "BRL", exchange: false, decimalSeparator: ",", multiplier: 0 },
  USD: { prefix: "$", locale: "en-US", value: "USD", exchange: true, decimalSeparator: ".", multiplier: 0 },
  EUR: { prefix: "€", locale: "en-EU", value: "EUR", exchange: true, decimalSeparator: ".", multiplier: 0 },
};

const rawCurrency = (price: number, destination: keyof typeof currencies): RawCurrency => {
  const currencyInfo = currencies[destination];

  if (!currencyInfo) {
    throw new Error(`Invalid region code: ${destination}`);
  }

  const formattedPrice = price.toLocaleString(currencyInfo.locale, {
    style: "currency",
    currency: currencyInfo.value,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return {
    value: formattedPrice,
  };
};

const exchangeCurrency = async (value: number, reference: keyof typeof currencies, destination: keyof typeof currencies, convert?: boolean): Promise<number> => {
  const currencyReferenceInfo = currencies[reference];
  const currencyDestinationInfo = currencies[destination];
  const exchange = currencyDestinationInfo.exchange;

  if (!exchange || convert === false)  {
    return value;
  }

  const response = await fetch(`https://elomania.com/api/exchange/${currencyReferenceInfo.value}-${currencyDestinationInfo.value}`);
  const exchangeRate = await response.json();
  const convertedValue = value * exchangeRate[currencyReferenceInfo.value + currencyDestinationInfo.value].bid;
  return convertedValue + (convertedValue * currencyDestinationInfo.multiplier);
};

const CurrencyDisplay: React.FC<Props> = ({ price, reference, destination, convert = true}) => {
  const [isLoading, setLoading] = useState(true);
  const [exchangeRate, setExchangeRate] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const rate = await exchangeCurrency(price, reference, destination, convert);
        setExchangeRate(rate);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [price, reference, destination]);

  if (isLoading && convert) return (
    <div className="max-w-full flex items-center justify-center animate-pulse">
      <Typography
        as="div"
        variant="h1"
        className="h-6 w-20 rounded-full bg-blue-20"
      >
        &nbsp;
      </Typography>
    </div>
  );

  return <>{rawCurrency(exchangeRate || 0, destination).value}</>;
};

export default CurrencyDisplay;
export { currencies, exchangeCurrency, rawCurrency };













