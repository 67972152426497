import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import modifyStyles from './style';
import arrowDrop from '@assets/settings/arrow-drop.svg';

export type CustomSelectProps = {
    label: string;
    hasImage: boolean;
    isDisabled?: boolean;
    isCenter?: boolean;
    isSearchable?: boolean;
    redirectTo?: string;
    options: Option[];
    type?: string;
    value: Option | string | null;
    color?: string;
    onChange: (selectedOption: Option | null) => void;
};

export type Option = {
    value: string;
    label: string;
    image?: string;
    isDefault?: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
    label,
    hasImage,
    isDisabled = false,
    isSearchable = false,
    isCenter = true,
    options,
    type = '',
    value,
    onChange,
    color
}) => {
    const [selectedValue, setSelectedValue] = useState<Option | null>(null);

    useEffect(() => {
        // Função para definir a opção padrão
        const findDefaultOption = (val: Option | string | null): Option | null => {
            if (typeof val === 'string') {
                return options.find(option => option.value === val) || null;
            }
            return val as Option;
        };

        // Atualizar o estado quando value mudar
        setSelectedValue(findDefaultOption(value));
    }, [value, options]);

    const handleChange = useCallback((selectedOption: Option | null) => {
        setSelectedValue(selectedOption);
        onChange(selectedOption);
    }, [onChange]);

    const handleArrowClick = (direction: 'up' | 'down') => {
        const currentIndex = options.findIndex(option => option.value === selectedValue?.value);
        let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        
        if (newIndex < 0) newIndex = options.length - 1;
        if (newIndex >= options.length) newIndex = 0;

        handleChange(options[newIndex]);
    };

    const Control = (props: any) => (
        <div className={`flex w-full rounded-md hover:bg-[#2a2d36] transition-all duration-500 py-0.5 pr-5 pl-2 flex-row items-center gap-2 border-[2px] border-[#232630] ${color ? 'bg-[' + color + ']' : 'bg-blue-500'} select-none cursor-pointer`}>
            <div className='flex w-full'>
                <components.Control {...props} />
            </div>
            <div className='flex flex-col items-center justify-center cursor-pointer'>
                <img src={arrowDrop} alt="" className='w-3 h-3' onClick={() => handleArrowClick('up')} />
                <img src={arrowDrop} alt="" className='w-3 h-3 transform rotate-180' onClick={() => handleArrowClick('down')} />
            </div>
        </div>
    );

    return (
        <Select
            components={{ Control }}
            placeholder={"Carregando..."}
            isSearchable={false}
            value={selectedValue}
            options={options}
            formatOptionLabel={(option: Option) => (
                <div className={`flex w-full items-center gap-3`}>
                    {hasImage && option.image && (
                        <img src={option.image} alt={option.label} width={20} height={20} />
                    )}
                    <span>{option.label}</span>
                </div>
            )}
            inputId={selectedValue?.value}
            styles={modifyStyles(color, false)}
            onChange={handleChange}
        />
    );
};

export default CustomSelect;
