import React, { useState, useEffect } from "react";
import Client from "@assets/Table/Client.svg";
import MiniBag from "@assets/Table/MiniBag.svg";
import MiniGames from "@assets/Table/MiniGames.svg";
import MiniStar from "@assets/Table/MiniStar.svg";
import api from "@services/client/api";
import Loading from "@pages/loading";
import { useAuth } from "@context/AuthContext";
import { useTranslation } from "react-i18next";

type Booster = {
  image: string;
  name: string;
  boosterLevel: string;
  boosterNextLevel: string;
  games: string;
  servicesFinished: number;
  servicesRemain: number;
  level_remain: number;
  rating: string;
};

const TableTopBoosters = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [boostersData, setBoostersData] = useState<Booster[]>([]);
  const { token } = useAuth();

  useEffect(() => {
    const fetchTopBoosters = async () => {
      try {
        const response = await api.get("/boosters/top-boosters", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data: Booster[] = response.data;
        setBoostersData(data);
      } catch (error) {
        console.error("Error fetching top boosters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopBoosters();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Client} alt={Client} />
          Top Boosters
        </h2>
      </div>
      <div className="transition-colors duration-1000 bg-transparent rounded-2xl mt-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {boostersData.slice(0, 3).map((booster, index) => (
            <div
              key={index}
              className={`bg-blue-20 rounded-2xl p-4 border border-secondary-500`}
            >
              <div className="flex items-center gap-2">
                <div className="bg-blue-50 w-14 h-14 border border-blue-100 rounded-full">
                  <img
                    src={booster.image}
                    alt={booster.name}
                    className="w-full h-full rounded-full"
                  />
                </div>
                <h4 className="text-white font-light text-base">
                  {booster.name}
                </h4>
              </div>
              <div className="mt-4">
                <div className="flex items-center justify-between mt-8">
                  <p className="text-sm text-gray-500">
                    {booster.servicesFinished}
                  </p>
                  <p className="text-sm text-gray-500">
                    {booster.level_remain}
                  </p>
                </div>
                <div className="h-2 bg-gray-300 rounded-full relative">
                  <div
                    className="h-full bg-blue-100 rounded-full absolute"
                    style={{
                      width: `${
                        (booster.servicesFinished /
                          booster.level_remain) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <p className="text-gray-100 font-semibold text-xs">
                    {t('client.current')}:{" "}
                    <span className="text-gray-500">
                      {booster.boosterLevel}
                    </span>
                  </p>
                  <p className="text-gray-100 font-semibold text-xs">
                  {t('client.next')}:{" "}
                    <span className="text-gray-500">
                      {booster.boosterNextLevel}
                    </span>
                  </p>
                </div>
              </div>
              <ul className="mt-4">
                <li className="flex items-center gap-2 py-2">
                  <img src={MiniGames} alt={MiniGames} />
                  <h4 className="text-white font-light text-xs">
                    {booster.games}
                  </h4>
                </li>
                <li className="flex items-center gap-2 py-2">
                  <img src={MiniBag} alt={MiniBag} />
                  <h4 className="text-white font-light text-xs">
                    {booster.servicesFinished} {t('table.serviceTitle')}
                  </h4>
                </li>
                <li className="flex items-center gap-2 py-2">
                  <img src={MiniStar} alt={MiniStar} />
                  <h4 className="text-white font-light text-xs">
                    {booster.rating} {t('admin.starbooster')} 
                  </h4>
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableTopBoosters;
