import React, { useCallback } from 'react';
import Close from '@assets/Buy/Close.svg';
import { useRouletteContext } from '../../Context/RouletteProvider';
import { toast } from 'react-toastify';

interface ClaimPopupProps {
  coupon: string;
}

const ClaimPopup: React.FC<ClaimPopupProps> = React.memo(({ coupon }) => {
  const { claimPopup, setClaimPopup } = useRouletteContext();

  const handleClosePopup = useCallback(() => {
    setClaimPopup({ open: false, coupon: '' });
  }, [setClaimPopup]);

  const handleCopy = useCallback(() => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(coupon)
        .then(() => {
          toast.success("Cupom copiado com sucesso!");
          handleClosePopup();
        })
        .catch(err => {
          console.error('Falha ao copiar o cupom:', err);
        });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = coupon;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        toast.success("Cupom copiado com sucesso!");
      } catch (err) {
        console.error('Falha ao copiar o cupom:', err);
      }
      document.body.removeChild(textarea);
      handleClosePopup();
    }
  }, [coupon, handleClosePopup]);

  if (!claimPopup.open) {
    return null;
  }

  return (
    <div className="bg-black bg-opacity-70 fixed z-[99999] inset-0 flex justify-center items-center backdrop-blur-sm">
      <div className="flex w-full justify-center items-center">
        <div className="bg-blue-50 w-3/5 mx-auto border border-secondary-500 rounded-2xl py-4 px-4">
          <div className="bg-blue-20 flex flex-col gap-2 w-full p-4 rounded-2xl">
            <div className="title w-full flex justify-between items-center">
              <div className="text-left mb-2">
                <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                  Resgate a sua premiação:
                </h2>
              </div>
              <button className="p-2" onClick={handleClosePopup}>
                <img src={Close} alt="Close" />
              </button>
            </div>
            {coupon ? (
              <div className="mt-4">
                <label htmlFor="cupom" className="text-gray-500 text-sm mb-1 mt-6">
                  Código do Cupom:
                </label>
                <input
                  type="text"
                  id="cupom"
                  disabled
                  value={coupon}
                  className="w-full bg-blue-500/10 outline-none rounded-md px-3 py-2"
                />
              </div>
            ) : (
              <div className="flex flex-col gap-12 rounded-2xl">
                {/* Conteúdo alternativo se não houver cupom */}
              </div>
            )}

            <div className="mt-4 flex items-center justify-center w-full">
              <button
                onClick={handleCopy}
                className="w-full rounded-md font-semibold bg-secondary-500 px-10 py-3 border border-secondary-500 hover:bg-primary-500 hover:text-white transition duration-100 text-sm"
              >
                {coupon ? 'Copiar' : 'Resgatar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ClaimPopup;
