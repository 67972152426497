import React, { useState, useEffect } from "react";
import Client from "@assets/Table/Client.svg";
import Add from "@assets/Table/Add.svg";
import Delete from "@assets/Table/Delete.svg";
import PopupNewDuoBooster from "./PopupNewDuoBoost";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import Close from "@assets/Buy/Close.svg";
import IconDelete from "@assets/Table/IconDelete.png";
import { toast } from "react-toastify";

import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

type Props = {};

interface TableData {
  id: string;
  user: string;
  password: string;
  nick: string;
  current_level: number;
  is_available: boolean;
  campeoes: string[];
}

const TableDuoBoost = (props: Props) => {
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [accountID, setAccountID] = useState<string>("");
  const [tableDataCoupons, setTableDataCoupons] = useState<TableData[]>([]);
  const { token } = useAuth();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const handleButtonClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  useEffect(() => {
    async function loadAccounts() {
      try {
        const response = await api.get("/accountDuoboost/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setTableDataCoupons(response.data);
      } catch (error: any) {
        console.error(error);
      }
    }
    loadAccounts();
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const deleteAccount = async (id: string) => {
    try {
      await api.delete(`/accountDuoboost/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Conta excluída com sucesso!", { autoClose: 3000 });
      setShowPopupDelete(false);
    } catch (error) {
      toast.error("Erro ao excluir a conta. Tente novamente.", {
        autoClose: 3000,
      });
    }
  };

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastBooster = currentPage * perPage;

  return (
    <>
      <Card className="h-full w-full p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">
              {t("table.duoBoost")}
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
              {t("table.textduoboost")}
              </Typography>
            </div>
            <div className="flex flex-row w-full shrink-0 gap-2 md:w-max">
              <Button
                className="flex items-center gap-3 bg-primary-500"
                size="md"
                onClick={handleButtonClick}
              >
                <img src={Add} alt={Add} />{t("admin.new")} &nbsp; {t("table.duoBoost")}
              </Button>
            </div>
          </div>
        </CardHeader>

        <CardBody className="px-0 overflow-auto bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.user")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.password")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    Nick
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.level")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.available")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.action")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableDataCoupons &&
                tableDataCoupons.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-s-2xl">
                      {data.user}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                      {data.password}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                      {data.nick}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                      {data.current_level}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                      <div
                        className={`w-4 h-4 flex items-center rounded-full ${
                          data.is_available
                            ? "bg-[#56BD79] transition-all duration-300"
                            : "bg-red-500"
                        }`}
                      ></div>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-e-2xl">
                      <button
                        className="p-2"
                        onClick={() => {
                          setAccountID(data.id);
                          setShowPopupDelete(true);
                        }}
                      >
                        <img src={Delete} alt={Delete} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
          <Typography variant="small" color="white" className="font-normal text-blue-50 dark:text-gray-500">
            Página {currentPage} de{" "}
            {Math.ceil(tableDataCoupons.length / perPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-gray-600 fonte-semibold rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-primary-500 rounded-lg"
              onClick={handleNextPage}
              disabled={indexOfLastBooster >= tableDataCoupons.length}
            >
              Seguinte
            </Button>
          </div>
        </CardFooter>
      </Card>
      {showPopup && <PopupNewDuoBooster />}
      {showPopupDelete && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-0 flex justify-center items-center mt-24`}
          >
            <div className="bg-blue-50 w-3/5 mx-auto border border-secondary-500 rounded-3xl py-4 px-4">
              <div className="bg-blue-20 w-full p-4 rounded-3xl">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 flex items-center gap-2"></div>
                  <button
                    className="p-2"
                    onClick={(e) => setShowPopupDelete(false)}
                  >
                    <img src={Close} alt="Close" />
                  </button>
                </div>
                <div className="rounded-2xl p-4">
                  <div className="grid place-items-center gap-4">
                    <div className="w-full flex items-center justify-center">
                      <img src={IconDelete} alt="IconDelete" />
                    </div>
                    <h2 className="text-center font-semibold text-lg text-gray-500">
                      {" "}
                      Deseja deletar a conta selecionada?{" "}
                    </h2>
                    <p className="text-center font-semibold text-base text-gray-100">
                      {" "}
                      Cuidado! Ao deletar a conta, ela será removida
                      imediatamente do banco de dados.{" "}
                    </p>
                  </div>
                  <div className="p-2 flex items-center justify-center gap-2 mt-2">
                    <button
                      className="bg-gray-100 rounded-xl px-4 py-2"
                      onClick={(e) => setShowPopupDelete(false)}
                    >
                      Fechar
                    </button>
                    <button
                      className="bg-red-500 rounded-xl px-4 py-2"
                      onClick={() => deleteAccount(accountID)}
                    >
                      Excluir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableDuoBoost;
