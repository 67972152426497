// Simple.tsx

import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

interface PaginationProps {
  active: number;
  totalPages: number;
  onNext: () => void;
  onPrev: () => void;
}

export function Pagination({
  active,
  totalPages,
  onNext,
  onPrev,
}: PaginationProps) {
  return (
    <div className="flex items-center gap-8">
      <IconButton
        size="sm"
        color="blue"
        onClick={onPrev}
        disabled={active === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
      <Typography color="purple" className="font-normal text-blue-50 dark:text-gray-500">
        Página <span className="text-primary-500">{active}</span> de{" "}
        <span className="text-primary-500">{totalPages}</span>
      </Typography>
      <IconButton
        size="sm"
        color="blue"
        onClick={onNext}
        disabled={active === totalPages}
      >
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
    </div>
  );
}
