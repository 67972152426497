import TableSmurfClient from '@components/Dashboard/Client/smurf'

type Props = {}

const SmurfClient = (props: Props) => {
  return (
    <>
      <TableSmurfClient />
    </>
  )
}

export default SmurfClient