import React, { useState, useEffect } from 'react';

interface FileUploadProps {
    index: number;
    name: string;
    accept: string;
    maxSize: number;
    onFileChange: (index: number, name: string, value: any | File | null, state: boolean) => void;
    defaultImage?: string;
    value?: string; 
}
interface CustomFile extends File {
    index?: number;
  }

const FileUpload: React.FC<FileUploadProps> = ({ index, name, accept, maxSize, onFileChange, defaultImage, value }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(defaultImage || value || null);

    useEffect(() => {
        if (defaultImage) {
            setImageUrl(defaultImage);
        }
    }, [defaultImage]);

    useEffect(() => {
        if (value && value.length > 0) {
            setImageUrl(value);
        }
    }, [value]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const maxSizeInBytes = maxSize * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                alert(`O arquivo excede o tamanho máximo de ${maxSize}MB`);
                onFileChange(index, name, null, false);
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const imageUrl = reader.result as string;
                const customFile = file as CustomFile;
                customFile.index = index;
                setImageUrl(imageUrl);
                onFileChange(index, name, customFile, true);
            };
        }
    };

    return (
        <label className="relative bg-[#16191D] w-full h-[10rem] border-2 border-dashed border-[#232630] rounded-md cursor-pointer">
            {imageUrl ? (
                <img src={imageUrl} alt="Uploaded Image" className="cursor-pointer p-2 object-contain w-full h-full rounded-[10px]" />
            ) : (
                <div className='flex flex-col items-center justify-center w-full h-full px-2'>
                    <span className="text-gray-200 text-sm text-center font-semibold">Arraste e solte aqui</span>
                    <span className="text-gray-200 text-opacity-70 text-center text-xs">(ou clique para selecionar)</span>
                    <span className="text-gray-200 text-opacity-30 text-xs mt-2">{accept}</span>
                    <span className="text-gray-200 text-opacity-30 text-xs">Tamanho máximo: {maxSize}MB</span>
                </div>
            )}
            <input 
                type="file" 
                accept={accept} 
                className="absolute inset-0 opacity-0 cursor-pointer" 
                id="upload" 
                onChange={handleFileChange} 
            />
        </label>
    );
};

export default FileUpload;
