import React from 'react'
import Spin from '@assets/Spin.svg'

type Props = {}

const Loading = (props: Props) => {
  return (
    <div className="bg-transparent flex justify-center items-center h-screen">
        <img src={Spin} alt={Spin} className="animate-spin select-none" />
    </div>
  )
}

//Teste

export default Loading