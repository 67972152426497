import Construction from "@components/Construction"
import RouletteGames from "@components/(Roulette)/roulette"
import { RouletteProvider } from "@components/(Roulette)/roulette/Context/RouletteProvider"

type Props = {}

const Roulette = (props: Props) => {
  return (
        <RouletteGames />
  )
}

export default Roulette