import React, { ReactNode } from 'react';
import "@styles/dashboard.css"

type Props = {
  children: ReactNode;
};

const Content: React.FC<Props> = ({ children }) => {
  return <div className="outlet w-[98%] my-[14px] mx-auto rounded-lg gap-4">{children}</div>;
};

export default Content;