import { useAlertContext } from "@hooks/AlertContext";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-tailwind/react";
import { ClaimModal } from "./awards/claim-modal";
import { RoulettePrizes } from "./awards/claim-prizes";
import { useRouletteContext } from "./Context/RouletteProvider";
import Roulette from "./roulette";
import ClaimPopup from "./awards/claim-popup";

export interface TableData {
  id: string;
  name: string;
  description: string;
  enable: boolean;
  probability: number;
  image: string;
}

export interface RouletteData {
  remainingRoulettes: number;
  lastRouletteDate: number;
  nextRouletteDate: number;
}

const RouletteGames = () => {
  const { t } = useTranslation();
  const { claimPopup, rouletteUserData, roulettePrizes, onSpinningEnd, onSpinningStart } = useRouletteContext()

  return (
    <div className="flex flex-col gap-4">
      <div className="roulette-game-container">
        <Roulette
          cardSize={140}
          spinTime={10}
          rolls={70}
          awaitTime={0.5}
          items={roulettePrizes}
          onSpinningStart={onSpinningStart}
          onSpinningEnd={onSpinningEnd}
          enable={Boolean(rouletteUserData && rouletteUserData.canSpin)}
        />
        <div className="rewards-container">
          <Typography variant="h5" className="text-primary-500">
            {t("roulette.awards")}
          </Typography>
          <div className="rewards-divisor mt-2"></div>
          <RoulettePrizes /> 
        </div>

         {claimPopup.open ? (
          <ClaimPopup coupon={claimPopup.coupon}  
          />
        ) : null} 
      </div>
    </div>
  );
};

export default RouletteGames;
