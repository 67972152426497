import React, { useEffect, useState } from "react";
import Smurf from "@assets/Table/Smurfmy.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { ISmurfAccount } from "@utils/types";
import PopupInfoSmurf from "@components/Dashboard/mainDashboard/Adm/(Smurf)/(modals)/info";
import { useTranslation } from "react-i18next";
import Arrow from "@assets/Table/Arrow.svg";

type Props = {};

const TableSmurfClient = (props: Props) => {
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [accounts, setAccounts] = useState<ISmurfAccount[]>([]);
  const { token } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    async function loadAccounts() {
      try {
        const response = await api.get("/account-smurfs/smurf-by-client", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedAccounts = response.data.map((account: ISmurfAccount) => {
          return {
            ...account,
            skins: account.skins.split(","),
          };
        });

        setAccounts(updatedAccounts);
      } catch (error: any) {}
    }
    loadAccounts();
  }, [token]);

  const [idInfoAccount, setIdInfoAccount] = useState("");

  const handleInfoModal = (id: string) => {
    setIdInfoAccount(id);
    setShowPopupInfo(true);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Smurf} alt="Smurf" />
          {t("table.smurfs")}
        </h2>
      </div>
      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto min-h-[75vh] max-h-[80vh]">
          {accounts.length === 0 ? (
            <div className="w-full py-6 flex flex-col items-center justify-center gap-4">
              <p className="text-gray-100 font-semibold text-base text-center">
                Você ainda não possui nenhuma smurf.
              </p>
              <a href="https://www.elomania.com/smurfs" className="text-blue-1001 font-semibold text-base w-full text-center flex justify-center items-center gap-3"> Comprar smurf agora <img src={Arrow} alt={Arrow} /> </a>
            </div>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.server")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Nickname
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.be")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Skins
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      {data.server}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.nickname || "N/A"}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.blueessence}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.skins.toString().replace(/-/g, " | ")}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <button
                        className="p-2"
                        onClick={() => handleInfoModal(String(data.id))}
                      >
                        <img src={FileSearch} alt="FileSearch" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {showPopupInfo && <PopupInfoSmurf id={idInfoAccount} />}
    </div>
  );
};

export default TableSmurfClient;
