import React, { useState, useEffect } from "react";
import Close from "@assets/Buy/Close.svg";
import Coupon from "@assets/Table/Coupon.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import { toast } from "react-toastify";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { useTranslation } from "react-i18next";

type Props = {};

type GameOption = {
  name: string;
  value: string;
};

const PopupNewCoupon = (props: Props) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [showPopup, setShowPopup] = useState(true);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    percentage: "",
    services: "all_site",
    is_visible: true,
  });

  const [gameOptions, setGameOptions] = useState<GameOption[]>([
    { name: "Todo o Site", value: "all_site" },
    { name: "Todos os Jogos", value: "all_games" },
    { name: "Smurfs", value: "smurfs" },
  ]);

  const fetchGameOptions = async () => {
    try {
      const response = await api.get("/services/games/");
      const gamesFromBackend = response.data;
      const combinedGameOptions = gameOptions.concat(gamesFromBackend);
      setGameOptions(combinedGameOptions);
    } catch (error) {
      console.error("Erro ao buscar os jogos:", error);
    }
  };

  useEffect(() => {
    fetchGameOptions();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let { id, value } = e.target;

    if (id === "percentage" && value !== "") {
      value = parseFloat(value).toFixed(2);
    }

    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    let isValid =
      typeof formValues.name === "string" &&
      formValues.name.trim() !== "" &&
      typeof formValues.description === "string" &&
      formValues.description.trim() !== "" &&
      !isNaN(Number(formValues.percentage)) &&
      Number(formValues.percentage) >= 0 &&
      typeof formValues.services === "string" &&
      formValues.services.trim() !== "" &&
      typeof formValues.is_visible === "boolean";

    if (!isValid) {
      toast.info(
        "Verifique os tipos de dados inseridos. Alguns valores estão incorretos."
      );
      return;
    }

    try {
      const response = await api.post("/coupons", formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Cupom criado com sucesso!");
      handleClosePopup();
    } catch (error: any) {
      toast.error(error?.response.data.message);
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
        <div
          className={`fixed z-10 inset-6 flex justify-center items-center`}
        >
          <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Coupon} alt={Coupon} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("admin.new")}{" "}
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <div className="w-[49%]">
                  <h2> {t("client.code")}: </h2>
                  <input
                    type="text"
                    id="name"
                    placeholder="Digite o código do cupom"
                    defaultValue={formValues.name}
                    className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-[49%]">
                  <h2> Porcentagem: </h2>
                  <input
                    type="text"
                    id="percentage"
                    placeholder="Digite a porcentagem do cupom"
                    value={
                      !isNaN(parseFloat(formValues.percentage))
                        ? parseFloat(formValues.percentage) === 0
                          ? "0"
                          : parseFloat(formValues.percentage).toString()
                        : "0"
                    }
                    className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    onFocus={(e) => {
                      e.currentTarget.value =
                        !isNaN(parseFloat(formValues.percentage)) &&
                        parseFloat(formValues.percentage) !== 0
                          ? parseFloat(formValues.percentage).toString()
                          : "";
                    }}
                    onBlur={(e) => {
                      e.currentTarget.value =
                        !isNaN(parseFloat(e.currentTarget.value)) &&
                        parseFloat(e.currentTarget.value) !== 0
                          ? parseFloat(e.currentTarget.value).toString()
                          : "0";
                    }}
                    onInput={(e) => {
                      let value = parseFloat(e.currentTarget.value);
                      if (!isNaN(value) && value >= 0 && value <= 100) {
                        setFormValues({
                          ...formValues,
                          percentage: value.toString(),
                        });
                      }
                    }}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <div className="w-[49%]">
                  <h2> {t("table.description")}: </h2>
                  <input
                    type="text"
                    id="description"
                    placeholder="Digite a descrição do cupom"
                    defaultValue={formValues.description}
                    className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-[49%]">
                  <h2> {t("table.game")}: </h2>
                  <select
                    name="selectNewBoosterGames"
                    id="services"
                    defaultValue={formValues.services}
                    className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    onChange={handleChange}
                  >
                    {gameOptions.map((option) => (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex items-center justify-end gap-2 mt-4">
                <button
                  className="bg-gray-100 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={handleClosePopup}
                >
                  {t("tools.close")}
                </button>
                <button
                  className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={handleSubmit}
                >
                  {t("tools.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNewCoupon;
