import React from 'react'
import Routing from "@routes/routes";
import { AuthProvider } from "@context/AuthContext";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@hooks/Theme';
import { Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { WebSocketProvider } from '@hooks/WebSocketContext';
import { AlertProvider } from '@hooks/AlertContext';
import { CheckoutProvider } from '@context/CheckoutContextProvider';
import { RouletteProvider } from '@components/(Roulette)/roulette/Context/RouletteProvider';

const queryClient = new QueryClient();


export default function App() {
  const googleId = process.env.REACT_APP_GOOGLE_ID;

  if (!googleId) {
    throw new Error('GOOGLE ID is not defined');
  }

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={googleId}>
        <AlertProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            limit={2}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />

          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <CheckoutProvider>
                <WebSocketProvider>
                  <ThemeProvider>
                  <RouletteProvider>
                    <Routing />
                  </RouletteProvider>
                  </ThemeProvider>
                </WebSocketProvider>
              </CheckoutProvider>
            </QueryClientProvider>
          </AuthProvider>
        </AlertProvider>
      </GoogleOAuthProvider>
    </div>
  );
}