import TableDuoBoostBooster from "@components/Dashboard/Booster/accounts/duoBoost"
import TableMySmurfBooster from "@components/Dashboard/Booster/accounts/mySmurfs"

type Props = {}

const AccountsBooster = (props: Props) => {
  return (
    <>
      {/* <TableDuoBoostBooster /> */}
      <TableMySmurfBooster />
    </>
  )
}

export default AccountsBooster