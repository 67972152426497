import React from 'react';
import Client from "@assets/Table/Client.svg";
import { maskPhone } from '@utils/mask/telephone';
import { IRequestDTO } from '@components/Chat/types/IRequest';
import { useTranslation } from 'react-i18next';

interface ClientInfoProps {
    user: { type_permission: string } | null;
    request: IRequestDTO;
}

const ClientInfo = ({ user, request }: ClientInfoProps) => {
    const { t } = useTranslation();

    if (!user || user.type_permission !== "ADMIN") return null;

    return (
        <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4 flex flex-col w-full">
            <h2 className="text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
                <img src={Client} alt="Client Icon" />
                {t("chat.clientInfo")}
            </h2>
            <div className="bg-white-100 dark:bg-blue-20 rounded-2xl p-4 grid grid-cols-3 md:grid-cols-4 gap-6 mt-4">
                <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {t("chat.clientName")}: <br />
                    <span className="font-normal text-blue-50 dark:text-gray-500">
                        {request.client?.name || ""}
                    </span>
                </h2>
                <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {t("chat.purchaseDate")}: <br />
                    <span className="font-normal text-blue-50 dark:text-gray-500">
                        {request.created_at ?
                            new Date(request.created_at).toLocaleDateString("pt-BR") :
                            t("chat.dateUnavailable")}
                    </span>
                </h2>
                <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {t("chat.clientPhone")}: <br />
                    <span className="font-normal text-blue-50 dark:text-gray-500">
                        {request.client && request.client.telephone || `Não informado`}
                    </span>
                </h2>
                <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {t("chat.clientDiscord")}: <br />
                    <span className="font-normal text-blue-50 dark:text-gray-500">
                        {request.client && request.client.discord  || `Não informado`}
                    </span>
                </h2>
            </div>
        </div>
    );
};

export default ClientInfo;
