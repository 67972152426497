import React, { useState, useEffect } from "react";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
} from "@material-tailwind/react";
import api from "@services/client/api";
import { useTranslation } from "react-i18next";

type Game = {
  id: string;
  name: string;
};

type Service = {
  id: string;
  gameId: string;
  label: string;
};

type Elo = {
  id: string;
  gameId: string;
  label: string;
  elo: string;
  value: string;
  image: string;
  currency: string;
  price: string;
  additionalSelect: string;
  created_at: string;
  updated_at: string;
};

type ExpandedSections = {
  [key: string]: boolean;
};

const TablePriceAdm = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [expandedSections, setExpandedSections] = useState<ExpandedSections>({});
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [gamesData, setGamesData] = useState<Game[]>([]);
  const [selectedGameServices, setSelectedGameServices] = useState<Service[]>([]);
  const [selectedElos, setSelectedElos] = useState<Elo[]>([]);
  const [editedElos, setEditedElos] = useState<Record<string, string>>({});
  const { token } = useAuth();

  const fetchGameServices = async (gameId: string) => {
    try {
      const response = await api.get(`/services/selects/gameId/${gameId}`, { headers: { Authorization: `Bearer ${token}` }});
      setSelectedGameServices(response.data);
    } catch (error) {
      console.error(`Error fetching services for Game ID ${gameId}:`, error);
    }
  };

  const fetchGames = async () => {
    try {
      const response = await api.get("/services/games", { headers: { Authorization: `Bearer ${token}` }});
      setGamesData(response.data);
    } catch (error) {
      console.error("Error fetching Games:", error);
    }
  };

  const fetchElosByServiceId = async (serviceId: string) => {
    try {
      const response = await api.get(`/prices-services/${serviceId}`, { headers: { Authorization: `Bearer ${token}` }});
      setSelectedElos(response.data);
    } catch (error) {
      console.error(`Error fetching elos for Service ID ${serviceId}:`, error);
    }
  };

  const handleAlterarClick = async (serviceId: string) => {
    setExpandedSections((prevState) => ({ ...prevState, [serviceId]: !prevState[serviceId] }));
    setEditedElos({});
    await fetchElosByServiceId(serviceId);
  };


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const selectedGame = gamesData.find((game) => game.id === selectedValue);
    if (selectedGame) fetchGameServices(selectedGame.id);
    setExpandedSections({});
  };

  const saveEditedElos = async () => {
    try {
      const updatedElos = selectedElos.map((elo) => ({
        ...elo,
        price: editedElos[elo.id] || elo.price,
      }));

      const response = await api.put("/prices-services/update", updatedElos, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      toast.success("Alterações salvas com sucesso!");
      setEditedElos({});
      await fetchElosByServiceId(selectedOption);
    } catch (error) {
      console.error("Erro ao salvar elos editados:", error);
      toast.error("Não foi possível salvar as alterações.");
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  return (
    <>
      <Card className="h-full w-full p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader floated={false} shadow={false} className="rounded-none bg-transparent">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">{t("tools.titleprice")}</Typography>
              <Typography className="mt-1 font-normal text-gray-100"> {t("tools.price")}</Typography>
            </div>
          </div>
        </CardHeader>

        <CardBody className="px-0">
          <div className="flex w-full">
            <select
              name="selectPriceGames"
              id="selectPriceGames"
              className="bg-blue-20 p-3 border border-secondary-500 rounded-lg cursor-pointer outline-none text-gray-500 text-sm font-bold"
              onChange={handleSelectChange}
            >
              <option value="update-price">{t("tools.select")}</option>
              {(gamesData || []).map((option) => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))}
            </select>
          </div>
          {selectedGameServices.map((service) => (
            <div key={service.id} className="bg-blue-20 p-3 border border-secondary-500 rounded-xl mt-4">
              <div className="flex items-center justify-between">
                <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">{service.label}</h2>
                <button className="bg-blue-1001 hover:bg-blue-1001/20 px-4 py-3 border border-secondary-500 rounded-md text-gray-500 font-semibold text-sm" onClick={() => handleAlterarClick(service.id)}>{t("tools.alter")}</button>
              </div>

              {expandedSections[service.id] && (
                <div className="mt-4">
                  <div className="text-gray-100 text-sm font-semibold mb-4 text-center">
                    <h2>ATENÇÃO: Ao alterar e salvar os novos preços, os valores serão atualizados imediatamente no site. Então, tome cuidado! Para alterar o preço, primeiro informe a porcentagem desejada, depois selecione se deseja aumentar ou diminuir essa porcentagem e, por fim, clique em salvar.</h2>
                  </div>

                  <div className="bg-blue-50 rounded-xl p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10">
                    {selectedElos.map((elo) => (
                      <div key={elo.id} className="flex flex-col justify-center items-center gap-3 w-full">
                        <div className="w-full flex items-center justify-between gap-4">
                          <div className={`${editedElos[elo.id] ? 'bg-blue-20 border-2 border-primary-500' : 'bg-secondary-500'} w-[130px] h-[100px] rounded-xl flex items-center overflow-hidden justify-center`}>
                            <img src={elo.image} alt={elo.elo} className="w-full h-full mb-4" />
                          </div>
                          <div className="flex flex-col w-full justify-start items-center gap-3">
                          <h3 className={`w-full text-base font-semibold ${editedElos[elo.id] ? 'text-primary-500' : 'text-gray-100'}`}>{elo.label}</h3>
                         
                          <input
                            type="text"
                            className={`text-gray-500 ${editedElos[elo.id] ? 'border-primary-500' : 'border-gray-500/10'} text-right font-normal text-sm bg-transparent border-2 rounded-xl outline-none p-3 w-full`}
                            defaultValue={elo.price}
                            onChange={(e) => setEditedElos((prev) => ({ ...prev, [elo.id]: e.target.value }))}
                          />
                        
                        </div>
                        </div>
                       
                      </div>
                    ))}
                  </div>
                  <button className="bg-primary-500 mt-4 w-full text-gray-500 font-semibold text-sm rounded-md py-2 px-5 text-center" onClick={saveEditedElos}>Salvar</button>
                </div>
              )}
            </div>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default TablePriceAdm;
