import React, { useEffect, useState } from "react";
import Admin from "@assets/Table/Client.svg";
import Delete from "@assets/Table/Delete.svg";
import Add from "@assets/Table/Add.svg";
import PopupNewAdmin from "./PopupNewAdmin";
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import Loading from "@pages/loading";
import PopupDeleteAdmin from "./PopupDeleteAdmin";

import {
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

type Props = {};

interface AdminData {
  id: number | string;
  user: {
    name: string;
    discord: string;
    telephone: string;
    avatar: string | null;
  };
}

const TableAdmins = (props: Props) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [idInfoAccountDelete, setIdInfoAccountDelete] = useState("");
  const [admins, setAdmins] = useState<AdminData[]>([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  useEffect(() => {
    async function loadAdmins() {
      try {
        const response = await api.get("/admins", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdmins(response.data);
        setLoading(false);
      } catch {
        setAdmins([]);
      }
    }
    loadAdmins();
  }, [token]);

  const handleButtonClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  const handleButtonDelete = (id: string) => {
    setIdInfoAccountDelete(id);
    setShowPopupDelete(true);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastClient = currentPage * perPage;

  return (
    <>
      <Card className="h-full w-full p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex-row sm:flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" className="text-primary-500">
                {t('admin.titleadm')} 
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
              {t('admin.adm')} 
              </Typography>
            </div>
            <Button
              className="flex items-center gap-3 bg-primary-500"
              size="md"
              onClick={handleButtonClick}
            >
              <img src={Add} alt={Add} /> {t('admin.new')}  Admin
            </Button>
          </div>
        </CardHeader>

        <CardBody className="px-0 bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
          <table className="mt-4 w-full h-[70vh] overflow-auto text-left">
            <thead>
              <tr>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    Admins
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    Discord
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t('table.phone')} 
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    Ação
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    <Loading />
                  </td>
                </tr>
              ) : (
                admins.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${isHovered ? 'hovered-row' : ''
                      }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-s-2xl">
                      <div className="flex items-center just gap-2">
                        {data.user.name}
                      </div>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                      {data.user.discord}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                      {data.user.telephone}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-e-2xl">
                      <button
                        className="p-2"
                        onClick={() => {
                          handleButtonDelete(data.id.toString());
                        }}
                      >
                        <img src={Delete} alt="Delete" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
          <Typography variant="small" color="white" className="font-normal text-blue-50 dark:text-gray-500">
            Página {currentPage} de {Math.ceil(admins.length / perPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-gray-600 fonte-semibold rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-primary-500 rounded-lg"
              onClick={handleNextPage}
              disabled={indexOfLastClient >= admins.length}
            >
              Seguinte
            </Button>
          </div>
        </CardFooter>
      </Card>
      {showPopup && <PopupNewAdmin />}
      {showPopupDelete && (
        <PopupDeleteAdmin
          admIdDelete={idInfoAccountDelete}
          showPopupDelete={showPopupDelete}
          setShowPopupDelete={setShowPopupDelete}
        />
      )}
    </>
  );
};

export default TableAdmins;
