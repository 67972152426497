import { useRouletteConfigContext } from "@components/(Roulette)/manage/Context/RouletteConfigContext";
import RouletteGames from "@components/(Roulette)/roulette";
import Construction from "@components/Construction";
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import { useEffect, useState } from "react";

type Props = {};

const RouletteClient = (props: Props) => {
  const [rouletteStatus, setRouletteStatus] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useAuth()

  useEffect(() => {
    const fetchRouletteStatus = async () => {
      setLoading(true);
      try {
        const response = await api.get('/roulette/config', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setRouletteStatus(response.data);
      } catch (err) {
        setError('Erro ao buscar status da roleta.');
      } finally {
        setLoading(false);
      }
    };

    fetchRouletteStatus();
  }, []);

  
  if (error) return <div className="text-red-500">{error}</div>;
  
  return rouletteStatus ? <RouletteGames/> : <Construction />;
};

export default RouletteClient;
