import CardVipClient from '@components/Dashboard/Client/vip'

import React from 'react';
import Vip1 from '@assets/Vip/Vip1.png';
import Vip2 from '@assets/Vip/VIP2.png';
import Vip3 from '@assets/Vip/VIP3.png';
import Vip4 from '@assets/Vip/VIP4.png';
import Vip5 from '@assets/Vip/VIP5.png';
import Vip6 from '@assets/Vip/VIP6.png';
import CardVip from '@components/VIP/cardVip';
import { useAuth } from '@context/AuthContext';
import Construction from '@components/Construction';

const VipClient = () => {

  type VipCard = {
    image: string;
    title: string;
    description: string;
  };

  const vipCards: VipCard[] = [
    {
      image: Vip1,
      title: 'XP em dobro',
      description: 'XP em dobro durante toda a semana.',
    },
    {
      image: Vip2,
      title: 'R$30 em crédito no site todo',
      description: 'Resgate R$30 em crédito para usar no site.',
    },
    {
      image: Vip3,
      title: 'Insígnia de destaque no site',
      description: 'Receba uma insígnia de destaque no site.',
    },
    {
      image: Vip4,
      title: '1 Smurf de lol grátis (Skin aleatória)',
      description: 'Receba uma Smurf de LoL grátis com uma skin aleatória.',
    },
    {
      image: Vip5,
      title: '1 Coach grátis (não cumulativo)',
      description: 'Resgate uma sessão de coaching gratuita.',
    },
    {
      image: Vip6,
      title: 'Cargo diferenciado no discord',
      description: 'Obtenha um cargo especial no servidor do Discord.',
    },
  ];


  return (
    <>
        <Construction />
      {/* <div className="bg-blue-50 p-12 rounded-xl">
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 '>
          {vipCards.map((card, index) => (
            <div
              key={index}
              className="bg-[#CCA263]/5 hover:shadow-md p-4 rounded-2xl border border-secondary-500"
            >
              <div className="w-full">
                <img src={card.image} alt={`Vip${index + 1}`} className="w-full" />
              </div>
              <h2 className="text-gray-500 text-center text-sm font-semibold mt-2">
                {card.title}
              </h2>
              <button className="bg-gray-500 text-primary-100 w-full rounded-full text-center text-sm font-semibold outline-none p-2 mt-4">
                Resgatar
              </button>
            </div>
          ))}
        </div>
      </div> */}
    </>
  )
}

export default VipClient