// WebSocketContext.tsx
import { useAuth } from '@context/AuthContext';
import { useCheckout } from '@context/CheckoutContextProvider';
import React, { createContext, useContext, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { io, Socket } from 'socket.io-client';


interface WebSocketContextProps {
  websocket: WebSocketProps;
}

interface BoosterInfo {
  id: string;
  role: string;
  name: string;
}

interface UserConnectedInfo {
  valorant: number;
  home: number;
  dashboard: number;
}


interface ConnectedUser {
  id: string
  auth: {
    token: string
    id: string
    email: string
    name: string
    role: string
  }
  roomId: string
  online: boolean
  status: string
  lastSeen: string;
}

export interface WebSocketProps {
  mensagens: string[];
  enviarMensagem: Dispatch<SetStateAction<string>>;
  usersCount: UserConnectedInfo | undefined;
  isConnected: boolean;
  socket: Socket;
  boosters: BoosterInfo[]; 
  roomInfo: ConnectedUser[] | null;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

const useWebSocket = (): WebSocketProps => {
  const { user, token } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [mensagens, setMensagens] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [boosters, setBoosters] = useState<BoosterInfo[]>([]);
  const [usersCount, setUsersCount] = useState<UserConnectedInfo>();
  const [roomInfo, setRoomInfo] = useState<ConnectedUser[] | null>(null);
  const { setCheckout, setOpenCheckout, checkout, openCheckout } = useCheckout()

  useEffect(() => {
    if (!user) {
      return;
    }

    const newSocket = io(`${process.env.REACT_APP_API_URL}`, {
      auth: {
        token: token,
        id: user.id,
        email: user.email,
        name: user.name,
        role: user.type_permission,
      },
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao servidor WebSocket');
      setIsConnected(true);
    });

    newSocket.on('disconnect', () => {
      console.log('Desconectado do servidor WebSocket');
      setIsConnected(false);
    });

    newSocket.on('message', (data: string) => {
      setMensagens((mensagensAntigas) => [...mensagensAntigas, data]);
    });

    newSocket.on('message-checkout', (data: { type: string, message: string, item: any }) => {
      switch (data.type) {
        case 'checkout_item_add':
            let doc = document.getElementById("tidio-chat-iframe");

            setCheckout((prevCheckout: any) => ({
                ...prevCheckout!,
                checkoutItems: [...prevCheckout!.checkoutItems, data.item],
            }));

            if (doc && !openCheckout === false) {
                doc.style.transform = 'translateX(0%)';
            }

            setOpenCheckout(true);
            break;

        case 'checkout_item_remove':
            setCheckout((prevCheckout: any) => ({
                ...prevCheckout!,
                checkoutItems: prevCheckout!.checkoutItems.filter((item: { id: any; }) => item.id !== data.item.id),
            }));
            break;
        default:
            break;
    }
    });

    newSocket.on('getBoosters', (data: BoosterInfo[]) => {
      setBoosters(data);
    });

    newSocket.on('getUsers', (data: UserConnectedInfo) => {
      setUsersCount(data)
    });

    newSocket.on('roomUpdate', (data: any) => {
      console.log(data)
      setRoomInfo(data);
    });

    newSocket.on('error', (error: any) => {
      console.error('Erro de conexão WebSocket:', error);
    });

    newSocket.on('connect_error', (error: any) => {
      console.error('Erro de conexão WebSocket:', error);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [user, token]);

  const enviarMensagem: WebSocketProps['enviarMensagem'] = (mensagem) => {
    if (socket) {
      socket.emit("message", mensagem);
    }
  };

  const safeSocket = socket as Socket;

  return { usersCount, mensagens, enviarMensagem, isConnected, socket: safeSocket, boosters, roomInfo };
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const websocket = useWebSocket();

  return (
    <WebSocketContext.Provider value={{ websocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error('useWebSocketContext deve ser usado dentro de WebSocketProvider');
  }

  return context;
};
