import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import IconDelete from "@assets/Table/IconDelete.png";
import "@assets/Coockie.png";
import "@styles/popup.css";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { ISmurfAccount } from "@utils/types";

type Props = {
  id: string;
};

const PopupDeleteSmurf = ({
  id
}: Props) => {
  const { token } = useAuth();
  const [smurf, setSmurf] = useState<ISmurfAccount>();
  const [isLoading, setIsLoading] = useState(false); 
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const deleteAccount = async (id: string | null) => {
    if (id !== null) {
      setIsLoading(true);
      try {
        const response = await api.post(
          "/account-smurfs/delete",
          { id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowPopup(false); 
        window.location.reload(); 
      } catch (error) {
        console.error("Error deleting account:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("accountId is null");
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-0 flex justify-center items-center mt-24`}
          >
            <div className="bg-blue-50 w-3/5 mx-auto border border-secondary-500 rounded-3xl py-4 px-4">
              <div className="bg-blue-20 w-full p-4 rounded-3xl">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 flex items-center gap-2"></div>
                  {/* <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button> */}
                </div>
                <div className="rounded-2xl p-4">
                  <div className="grid place-items-center gap-4">
                    <div className="w-full flex items-center justify-center">
                      <img src={IconDelete} alt="IconDelete" />
                    </div>
                    <h2 className="text-center font-semibold text-lg text-gray-500">
                      {" "}
                      Deseja deletar a smurf selecionada?{" "}
                    </h2>
                    <p className="text-center font-semibold text-base text-gray-100">
                      {" "}
                      Cuidado! Ao deletar a smurf, ela será excluída
                      imediatamente do banco de dados.{" "}
                    </p>
                  </div>
                  <div className="p-2 flex items-center justify-center gap-2 mt-2">
                    <button
                      className="bg-gray-100 rounded-xl px-4 py-2"
                      onClick={handleClosePopup}
                    >
                      Fechar
                    </button> *
                    <button
                      className="bg-red-500 rounded-xl px-4 py-2"
                      onClick={() => deleteAccount(id)} 
                      disabled={isLoading}
                    >
                      {isLoading ? "Carregando..." : "Excluir"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupDeleteSmurf;