import { SortableTable } from "@components/Dashboard/mainDashboard/Adm/client";

type Props = {};

const ClientAdm = (props: Props) => {
  return (
    <>
      <SortableTable />
    </>
  );
};

export default ClientAdm;
