import { IUser } from "@utils/types";
import React from "react";
import './style.css'

const DateTimeFormatter = (date: Date) => {
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return formatter.format(date);
};

const ChatMessage = ({
  message,
  isSelf,
  senderName,
  time,
  user,
}: {
  message: string;
  isSelf: boolean;
  senderName: string;
  time: string;
  user: IUser;
}) => {
  if (!user) return null;

  const formattedTime = DateTimeFormatter(new Date(time));

  const sender_name = (() => {
    switch (senderName) {
      case "BOOSTER":
        return "Booster";
      case "ADMIN":
        return "Admin";
      default:
        return "Cliente";
    }
  })();

  const messageBgClass = (() => {
    if (isSelf && senderName !== "ADMIN") return "bg-blue-500 border border-secondary-500";

    switch (senderName) {
      case "BOOSTER":
        return "bg-primary-500 border border-secondary-500";
      case "ADMIN":
        return "bg-orange-500 text-white";
      case "CLIENT":
        return "bg-blue-50 border border-secondary-500";
      default:
        return "bg-primary-500 text-white";
    }
  })();

  const borderRadiusClass = isSelf ? "rounded-t-2xl rounded-es-2xl" : "rounded-t-2xl rounded-ee-2xl";

  return (
    <div className={`grid ${isSelf ? "justify-end" : "justify-start"}`}>
      <div className={`flex flex-col mb-4 ${isSelf ? "items-end" : "items-start"}`}>
        <div className={`p-4 max-w-[400px] break-words bubble-effect ${messageBgClass} ${borderRadiusClass}`}>
          <p className="text-sm font-semibold">{!isSelf ? sender_name : ""}</p>
          <p className="text-sm font-normal">{message}</p>
        </div>
        <p className={`text-sm mt-2 text-gray-100 font-light ${isSelf ? "text-right" : "text-left"}`}>
          {formattedTime}
        </p>
      </div>
    </div>
  );
};

export default ChatMessage;
