import { useCheckout } from '@context/CheckoutContextProvider';
import Loading from '@pages/loading';
import React, { useState } from 'react';

interface GameIframeProps {
    game: string;
}

const GameIframe: React.FC<GameIframeProps> = ({ game }) => {
    const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
    const { enable } = useCheckout()

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    return (
        <div className="w-full min-h-screen">
            <iframe
                title={'Selects'}
                src={`https://elomania.com/en-us/dashboard/${game}`}
                className={`w-full h-screen ${iframeLoaded ? '' : 'hidden'}`}
                onLoad={handleIframeLoad}
                loading="eager"
            ></iframe>
        </div>
    );
};

export default GameIframe;