import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, Plus, X, AlertCircle, Loader } from 'react-feather';
import { useAuth } from '@context/AuthContext';
import { useRouletteConfigContext } from '../Context/RouletteConfigContext';
import ScrollIndicator from './scroll';
import CardComponent from './card';
import { Tooltip } from '@material-tailwind/react';

interface CreateRouletteItemProps {
    handleClose: () => void;
}

const CreateRouletteItem: React.FC<CreateRouletteItemProps> = ({ handleClose }) => {
    const { saveLoading, deletePrize, prizeData, setPrizeData, setDataValidate, dataValidate, addPrize, handleSave } = useRouletteConfigContext();
    const [totalProbability, setTotalProbability] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [validForm, setValidForm] = useState(false);

    useEffect(() => {
        const total = prizeData.reduce((sum, prize) => sum + Number(prize.probability), 0);
        setTotalProbability(total);
        const valid = dataValidate.every(obj => Object.values(obj).every(Boolean));
        setValidForm(valid)
    }, [prizeData]);

    const handlePropertyChange = (index: number, name: string, value: any | File, state: boolean) => {
        setPrizeData((prevPrizeData) => {
            const newPrizeData = [...prevPrizeData];
            newPrizeData[index] = {
                ...newPrizeData[index],
                [name]: value,
            };
            return newPrizeData;
        });

        setDataValidate((prevState) => {
            const newState = [...prevState];
            newState[index] = {
                ...newState[index],
                [name]: state,
            };
            return newState;
        });
    };

    const handleRemoveCard = async (item: any, index: number) => {
        if (item.id) {
            await deletePrize(item.id, index);
        }
        else {
            setPrizeData((prevPrizeData) => prevPrizeData.filter((_, i) => i !== index));
            setDataValidate((prevDataValidate) => prevDataValidate.filter((_, i) => i !== index));
        }
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % (prizeData.length + 1));
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + prizeData.length + 1) % (prizeData.length + 1));
    };

    const handleSaveAndClose = async () => {
        await handleSave();
        handleClose();
    };

    const getInvalidItems = () => {
        return prizeData
            .map((item, index) => ({
                name: item.name,
                isValid: Object.values(dataValidate[index]).every(Boolean),
            }))
            .filter(item => !item.isValid)
            .map(item => item.name)
            .join(', ');
    };

    const invalidItems = getInvalidItems();

    return (
        <div className="relative w-full h-screen bg-black bg-opacity-60 backdrop-blur-md flex justify-center items-center overflow-hidden">
            <ScrollIndicator activeIndex={activeIndex} setActiveIndex={setActiveIndex} onAddItem={addPrize} />
            <div
                className="flex transition-transform duration-700"
                style={{
                    transform: `translateX(-${activeIndex * 100}%)`,
                    width: `${(prizeData.length + 1) * 100}%`,
                    display: 'flex',
                }}
            >
                {prizeData.map((item, index) => (
                    <div
                        key={index}
                        className="w-full h-screen flex justify-center items-center text-white text-xl p-4 box-border"
                        style={{ flex: '0 0 100%' }}
                    >
                        <CardComponent
                            item={item}
                            index={index}
                            valid={Object.values(dataValidate[index]).every(Boolean)}
                            handleRemoveCard={handleRemoveCard}
                            handlePropertyChange={handlePropertyChange}
                            handlePrev={handlePrev}
                            handleNext={handleNext}
                        />
                    </div>
                ))}
                <div
                    className="w-full h-screen flex justify-center items-center text-white text-xl p-4 box-border"
                    style={{ flex: '0 0 100%' }}
                >
                    <div className="w-[900px] h-full flex items-center justify-center border-2 border-gray-700 border-dashed bg-blue-500/10 p-10 rounded-md">
                        <button onClick={addPrize} className="text-gray-600 p-3 rounded-md flex flex-col items-center justify-center gap-2">
                            <Plus size={48} className="text-gray-700 bg-slate-500 p-4 rounded-full w-24 h-24" />
                            Adicionar Novo Item
                        </button>
                    </div>
                </div>
            </div>
            <div className="absolute top-4 right-4">
                {validForm ? (
                    totalProbability !== 100 ? (
                        <span className='text-sm opacity-50'>{totalProbability.toFixed(2)}%</span>
                    ) : (
                        saveLoading ? (
                            <Loader
                                size={24}
                                className='animate-spin text-gray-600 cursor-pointer transition-transform duration-300 transform hover:rotate-180 opacity-70'
                            />
                        ) : (
                            <X
                                size={24}
                                className='cursor-pointer transition-transform duration-300 transform hover:rotate-180 opacity-70'
                                onClick={handleSaveAndClose}
                            />
                        )
                    )
                ) : (
                    <Tooltip position={'bottom-start'} content={`Os seguintes itens apresentam erros e precisam ser verificado(s): ${invalidItems || 'Nome indefinido'}`} className=" bg-blue-500 w-[25%] p-2 rounded-md border border-gray-700">
                        <AlertCircle
                            size={24}
                            className='text-red-600 cursor-pointer transition-transform duration-300 transform hover:scale-110'
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default CreateRouletteItem;
