import AboutPage from '@assets/SobreNos.webp'

const Construction = () => {
    return (
        <div>
            <div className="w-full grid place-items-center my-4">
                <h1 className="text-gray-500 text-5xl font-semibold uppercase text-center"> Em <br /> Construção </h1>
                <h1 className="text-gray-500/70 text-base font-medium uppercase text-center tracking-[10px] mt-4"> O SERVIÇO ESTÁ QUASE PRONTO! </h1>
                <div className="w-[50%]">
                    <div className="mt-4">
                        <div className="h-2 bg-gray-300 rounded-sm">
                            <div className="h-full bg-primary-500 rounde-sm" style={{ width: '80%' }}></div>
                        </div>
                        <div className="flex items-center justify-between mt-2">
                            <p className="text-gray-100 font-semibold text-xs">80%</p>
                            <p className="text-gray-100 font-semibold text-xs">100%</p>
                        </div>
                    </div>
                </div>
                <div className="w-[40%]">
                    <img src={AboutPage} alt="AboutPage" className="w-full h-full" loading="lazy" />
                </div>
            </div>
        </div>
    )
}

export default Construction