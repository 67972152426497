import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { useAuth } from "@context/AuthContext";
import moment from "moment";
import mc from "@assets/maniacoin.png";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import api from "@services/client/api";
import { ILevelData, maniacoin } from "@utils/types";
import { useEffect, useState } from "react";
import PopupMC from "./ManiaCoins";
import { useTranslation } from "react-i18next";

interface TableData {
  id: string;
  name: string;
  mc: string;
  email: string;
  discord: string;
  telephone: string;
  levelData: ILevelData;
  avatar: string;
  maniacoin: maniacoin;
  created_at: string;
}

const maskPhone = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value.substring(0, 15);
};

export function SortableTable() {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [clients, setClients] = useState<TableData[]>([]);
  const [filteredClients, setFilteredClients] = useState<TableData[]>([]);
  const { token } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const [showPopup, setShowPopup] = useState(false);

  const TABLE_HEAD = [
    t("admin.name"),
    "Maniacoins",
    "Status",
    "Discord",
    t("table.phone"),
    t("admin.class"),
    t("admin.create"),
  ];

  useEffect(() => {
    async function loadClients() {
      try {
        const response = await api.get("/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClients(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    loadClients();
  }, [token]);

  const handleDownload = async () => {
    try {
      let file = await api.get("/newsletter/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let fileResponse = await api.get(`/newsletter/${file.data}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.data);
      document.body.appendChild(link);
      link.click();
      console.log("Download realizado com sucesso!");
    } catch (error) {
      console.error("Erro ao fazer o download:", error);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const filtered = clients.filter((client) => {
      const query = searchQuery.toLowerCase().trim();
      return (
        client.name.toLowerCase().includes(query) ||
        client.email.toLowerCase().includes(query)
      );
    });
    setFilteredClients(filtered);
  };

  const indexOfLastClient = currentPage * perPage;
  const indexOfFirstClient = indexOfLastClient - perPage;
  const currentClients = searchQuery
    ? filteredClients.slice(indexOfFirstClient, indexOfLastClient)
    : clients.slice(indexOfFirstClient, indexOfLastClient);

  const handleButtonClick = (clientId: string) => {
    setSelectedClientId(clientId);
    setShowPopup((prevState) => !prevState);
  };
  
  return (
    <>
      <Card className="h-full w-full p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">
                {t("sidebar.clients")}
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
                {t("admin.titlemembers")}
              </Typography>
            </div>
            <div className="flex flex-row w-full shrink-0 gap-2 md:w-max">
              <div className="relative bg-blue-20 rounded-md">
                <input
                  type="text"
                  className="bg-blue-20 rounded-full py-3 px-10 outline-none text-gray-500 text-sm font-semibold ml-2"
                  placeholder={t("admin.search")}
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                />

                <MagnifyingGlassIcon className="h-5 w-5 absolute top-3 left-4 select-none" />
              </div>
              <Button
                className="flex items-center gap-3 bg-primary-500"
                size="md"
                onClick={handleDownload}
              >
                <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" />{" "}
                Download
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="px-0 overflow-auto bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="white"
                      className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          className="h-4 w-4"
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentClients.map(
                (
                  {
                    id,
                    avatar,
                    name,
                    email,
                    maniacoin,
                    discord,
                    telephone,
                    levelData,
                    created_at,
                  },
                  index
                ) => {
                  const isLast = index === clients.length - 1;
                  const classes = isLast ? "p-4" : "p-4 ";

                  return (
                    <tr
                      key={id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className={`rounded-s-2xl ${classes}`}>
                        <div className="flex items-center gap-3">
                          <img
                            src={`https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`}
                            alt={name}
                            className="inline-block relative object-cover object-center w-9 h-9 rounded-md"
                            onError={(e) => {
                              e.currentTarget.src =
                                "https://www.adbensimoveis.com.br/wp-content/themes/netimoveis/public/img/no-avatar.png";
                            }}
                          />
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="white"
                              className="font-normal text-blue-50 dark:text-gray-500"
                            >
                              {name}
                            </Typography>
                            <Typography
                              variant="small"
                              className="font-normal text-blue-50 dark:text-gray-500"
                            >
                              {email}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td
                        className="flex flex-row gap-2 justify-start items-center mt-6"
                        onClick={() => handleButtonClick(id)}
                      >
                        <img src={mc} alt="Maniacoin" className="w-6 h-6" />
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal text-blue-50 dark:text-gray-500"
                        >
                          {(maniacoin &&
                            maniacoin.totalAmount &&
                            maniacoin.totalAmount.toLocaleString("pt-BR")) ||
                            0}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={"offline"}
                            className="text-white bg-red-500 font-semibold"
                          />
                        </div>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal text-blue-50 dark:text-gray-500"
                        >
                          {discord ? discord : "Não informado"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal text-blue-50 dark:text-gray-500"
                        >
                          {telephone ? maskPhone(telephone) : "Não informado"}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal text-blue-50 dark:text-gray-500"
                        >
                          {levelData.class}
                        </Typography>
                      </td>
                      <td className={`rounded-e-2xl ${classes}`}>
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal text-blue-50 dark:text-gray-500"
                        >
                          {moment(created_at).format("DD/MM/YYYY")}
                        </Typography>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
          <Typography
            variant="small"
            className="font-normal text-blue-50 dark:text-gray-500"
          >
            Página {currentPage} de {Math.ceil(clients.length / perPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-gray-600 fonte-semibold rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-primary-500 rounded-lg"
              onClick={handleNextPage}
              disabled={indexOfLastClient >= clients.length}
            >
              Seguinte
            </Button>
          </div>
        </CardFooter>
      </Card>
      {showPopup && <PopupMC userId={selectedClientId} />}
    </>
  );
}
