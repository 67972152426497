import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Edit } from 'react-feather';
import { RouletteConfigProvider } from '@components/(Roulette)/manage/Context/RouletteConfigContext';
import ManiacoinsCard from '@components/(Roulette)/manage/config/maniacoins';
import WebhookCard from '@components/(Roulette)/manage/config/webhook';
import CreateRouletteItem from '@components/(Roulette)/manage/create';
import StatusCard from '@components/(Roulette)/manage/config/status';

const ManageAdm = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  return (
    <RouletteConfigProvider>
      <div className='flex w-full h-screen flex-row items-center justify-center bg-[#16191D] rounded-md overflow-hidden'>
      {/* <SimulationProvider>
            <SimulationDisplay />
          </SimulationProvider>  */}
       
       <div className='flex flex-row w-full flex-shrink-0 h-full justify-center items-center gap-2 px-2'>
       <StatusCard />
          <ManiacoinsCard />
          <WebhookCard />
          
        </div> 

        {!isPopupOpen ? (
          <div className="absolute top-4 right-4 z-20">
            <button onClick={togglePopup} className="p-2 rounded-md bg-indigo-600">
              <Edit size={20} />
            </button>
          </div>
        ) : null}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isPopupOpen ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className='w-full h-screen absolute'
          style={{ display: isPopupOpen ? 'block' : 'none' }}
        >
          <CreateRouletteItem handleClose={togglePopup} />
        </motion.div>
      </div>
    </RouletteConfigProvider>
  );
};

export default ManageAdm;
