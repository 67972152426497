import React from 'react';
import { ChevronLeft, ChevronRight, Trash } from 'react-feather';
import CustomCheck from '@components/custom/checkbox';
import CustomInput from '@components/custom/input';
import CustomSelect from '@components/custom/select';
import Slider from "react-slider";
import FileUpload from '@components/custom/file';

interface Item {
    name: string;
    description: string;
    image?: string;
    level_required: string;
    probability: number;
    delivery_method: string;
    delivery_count: string;
    enabled: boolean;
    show_popup: boolean;
    send_to_discord: boolean;
    visible: boolean;
    autoredeem: boolean;
    admin_confirmation: boolean;
}

interface Props {
    item: Item;
    index: number;
    valid: boolean;
    handleRemoveCard: (item: Item, index: number) => void;
    handlePropertyChange: (index: number, name: string, value: any | File, state: boolean) => void;
    handlePrev: () => void;
    handleNext: () => void;
}

const CardComponent: React.FC<Props> = ({ item, index, valid, handleRemoveCard, handlePropertyChange, handlePrev, handleNext }) => {
    const getAdditionalInfoPlaceholder = (deliveryMethod: string) => {
        switch (deliveryMethod) {
            case 'maniacoins':
                return 'Ex: 100';
            case 'service':
                return 'Ex: Nome do Serviço';
            case 'smurf':
                return 'Ex: Conta Smurf';
            case 'steam_key':
                return '';
            case 'subscription':
                return '';
            case 'coupon':
                return 'Ex: Porcentagem do desconto';
            case 'reroll':
                return '';
            case 'nothing':
                return '';
            case 'custom':
                return '';
            default:
                return 'Ex: Informação Adicional';
        }
    };

    const shouldRenderAdditionalInfo = (deliveryMethod: string) => {
        const excludedMethods = ['nothing', 'subscription', 'steam_key', 'custom', 'reroll'];
        return !excludedMethods.includes(deliveryMethod);
    };

    return (
        <div key={index} className={`flex flex-shrink-0 flex-row w-[900px] gap-3 h-full items-start justify-between bg-blue-500 rounded-md backdrop-blur-xl p-4`}>
            <div className='w-2/3  h-full flex flex-col justify-between'>
                <div className='w-full h-full flex flex-col'>
                    <div className="flex flex-row w-full justify-between">
                        <button
                            className="absolute right-5 -translate-y-2 z-10 flex bg-blue-500 backdrop-blur-sm border border-white border-opacity-25 rounded-md transition-all duration-500 p-2 text-white hover:bg-black/50"
                            onClick={() => handleRemoveCard(item, index)}
                        >
                            <Trash size={18} />
                        </button>
                        <div className='flex flex-col w-full'>
                            <div className="mb-4 text-sm">
                                <CustomInput
                                    index={index}
                                    label="Nome"
                                    name="name"
                                    placeholder="Ex: Cupom de 25%"
                                    value={item.name}
                                    onChange={handlePropertyChange}
                                />
                            </div>
                            <div className="mb-4 text-sm">
                                <CustomInput
                                    index={index}
                                    label="Descrição"
                                    name="description"
                                    placeholder="Ex: Utilize-o em suas compras!"
                                    value={item.description}
                                    onChange={handlePropertyChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <span className="block text-gray-200 text-sm font-bold mb-2">
                            Nível:
                        </span>
                        <CustomSelect
                            label="Nível Requerido"
                            value={item.level_required}
                            hasImage={false}
                            onChange={(e: any) => handlePropertyChange(index, 'level_required', e?.value, true)}
                            options={[
                                { value: 'Knight', label: 'Knight', isDefault: true },
                                { value: 'Juggernaut', label: 'Juggernaut' },
                                { value: 'Titan', label: 'Titan' },
                                { value: 'Immortal', label: 'Immortal' },
                                { value: 'Eternal', label: 'Eternal' }
                            ]}
                        />
                    </div>
                    <div className='flex flex-col items-start justify-center mb-6 gap-2'>
                        <span className="block text-gray-200 text-sm font-bold mb-2">
                            Probabilidade:
                        </span>
                        <div className='w-full flex items-center justify-center rounded-md'>
                            <Slider
                                value={item.probability}
                                onChange={(value: number) =>
                                    handlePropertyChange(index, 'probability', parseFloat(value.toFixed(2)), true)
                                }
                                min={0.05}
                                max={100}
                                step={0.05}
                                className="w-full mt-2"
                                renderThumb={(props: any, state: any) => (
                                    <div className="relative">
                                        <div
                                            {...props}
                                            className={`bg-primary-500 rounded-full h-6 w-6 ${state.index === 0 ? "ml-0" : "-ml-3"
                                                } -mt-3`}
                                        />
                                        <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 text-sm text-gray-500">
                                            {state.valueNow.toFixed(2)}%
                                        </div>
                                    </div>
                                )}
                                renderTrack={(props: any, state: any) => (
                                    <div
                                        {...props}
                                        className={`h-1 ${state.index === 0 ? "rounded-r-full" : "rounded-l-full"
                                            } ${state.value > 0 ? "bg-primary-500" : "bg-gray-300"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col justify-between gap-3 items-center w-full mb-4'>
                        <div className='w-full'>
                            <span className="block text-gray-200 text-sm font-bold mb-2">
                                Tipo de entrega:
                            </span>
                            <CustomSelect
                                label="Método de Entrega"
                                hasImage={false}
                                value={item.delivery_method}
                                onChange={(e: any) => handlePropertyChange(index, 'delivery_method', e?.value, true)}
                                options={[
                                    { value: 'maniacoins', label: 'Maniacoins', isDefault: true },
                                    { value: 'service', label: 'Serviço' },
                                    { value: 'smurf', label: 'Smurf' },
                                    { value: 'steam_key', label: 'Steam Key' },
                                    { value: 'subscription', label: 'Assinaturas' },
                                    { value: 'coupon', label: 'Cupom' },
                                    { value: 'reroll', label: 'Reroll' },
                                    { value: 'nothing', label: 'Nada' },
                                    { value: 'custom', label: 'Customizado (Ex.: Camisas, Bonés, Eletrônicos, etc...)' },
                                ]}
                            />
                        </div>
                        {shouldRenderAdditionalInfo(item.delivery_method) && (
                            <div className="w-full text-sm">
                                <CustomInput
                                    index={index}
                                    label="Infos. Adicionais"
                                    name="delivery_count"
                                    placeholder={getAdditionalInfoPlaceholder(item.delivery_method)}
                                    value={item.delivery_count}
                                    onChange={handlePropertyChange}
                                    min={1}
                                    max={100}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='w-3/4 h-full flex flex-col gap-4 bg-blue-500 p-4 rounded-md'>
                <div className='w-full h-full flex flex-col gap-4'>
                    <FileUpload
                        index={index}
                        name={'image'}
                        accept="JPG, PNG, SVG"
                        maxSize={5}
                        value={item.image || ''}
                        onFileChange={handlePropertyChange}
                    />
                    <div className='flex flex-col gap-3'>
                        <span className="block text-gray-200 text-sm font-bold">
                            Configurações adicionais:
                        </span>
                        <div className='grid grid-cols-2 gap-4'>
                            <CustomCheck
                                index={index}
                                label="Ativar item"
                                name="enabled"
                                checked={item.enabled}
                                onChange={handlePropertyChange}
                            />
                            <CustomCheck
                                index={index}
                                label="Mostrar Popup"
                                name="show_popup"
                                checked={item.show_popup}
                                onChange={handlePropertyChange}
                            />
                            <CustomCheck
                                index={index}
                                label="Enviar p/ Discord"
                                name="send_to_discord"
                                checked={item.send_to_discord}
                                onChange={handlePropertyChange}
                            />
                            <CustomCheck
                                index={index}
                                label="Exibir na Roleta"
                                name="visible"
                                checked={item.visible}
                                onChange={handlePropertyChange}
                            />
                            <CustomCheck
                                index={index}
                                label="Resgate automático"
                                name="autoredeem"
                                checked={item.autoredeem}
                                onChange={handlePropertyChange}
                            />
                            <CustomCheck
                                index={index}
                                label="Confirmação Adm."
                                name="admin_confirmation"
                                checked={item.admin_confirmation}
                                onChange={handlePropertyChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-row gap-4'>
                    <button
                        className='w-full bg-gray-700 text-sm px-4 py-4 rounded-md hover:bg-gray-600 transition-colors duration-300'
                        onClick={handlePrev}
                    >
                        Anterior
                    </button>
                    <button
                        className={`w-full ${valid ? 'bg-primary-500 hover:bg-primary-500/50 cursor-pointer' : 'bg-gray-70 cursor-not-allowed'} text-sm px-4 py-4 rounded-md transition-colors duration-300`}
                        onClick={handleNext}
                        disabled={!valid}
                    >
                        Próximo
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CardComponent;
