import React, { useState, useEffect } from "react";
import Close from "@assets/Buy/Close.svg";
import Bag from "@assets/Table/MiniBag.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {};

interface Champion {
  name: string;
  value: string;
}

interface GameOption {
  label: string;
  value: string;
}

const PopupNewBooster = (props: Props) => {
  const { t } = useTranslation();

  const [gameOptions, setGameOptions] = useState<GameOption[]>([]);
  const [showPopup, setShowPopup] = useState(true);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  const [champions, setChampions] = useState<Champion[]>([]);
  const [formValues, setFormValues] = useState({
    name: "",
    cpf: "",
    email: "",
    discord: "",
    password: "",
    cep: "",
    address: "",
    city: "",
    telephone: "",
    state: "",
    payment_key: "",
    whatsapp: "",
    mainAccount: "",
    champion_name: "",
    game: "LOL",
  });

  useEffect(() => {
    api
      .get("/games/all")
      .then((response) => {
        const options: GameOption[] = response.data;
        setGameOptions(options);
      })
      .catch((error) => console.error("Error fetching game options:", error));
  }, []);

  useEffect(() => {
    api
      .get("/champions", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const championArray: Champion[] = response.data
          .filter((champion: { available: boolean }) => champion.available) 
          .map((champion: { name: string }) => ({
            name: champion.name,
            value: champion.name,
          }));
        setChampions(championArray);
      })
      .catch((error) => console.error("Erro ao buscar campeões:", error));
  }, []);

  const valorantChampions = [
    { name: "Astra", value: "Astra" },
    { name: "Breach", value: "Breach" },
    { name: "Brimstone", value: "Brimstone" },
    { name: "Chamber", value: "Chamber" },
    { name: "Cypher", value: "Cypher" },
    { name: "Jett", value: "Jett" },
    { name: "KAY/O", value: "KAY/O" },
    { name: "Killjoy", value: "Killjoy" },
    { name: "KJ", value: "KJ" },
    { name: "Omen", value: "Omen" },
    { name: "Phoenix", value: "Phoenix" },
    { name: "Raze", value: "Raze" },
    { name: "Reyna", value: "Reyna" },
    { name: "Sage", value: "Sage" },
    { name: "Skye", value: "Skye" },
    { name: "Sova", value: "Sova" },
    { name: "Viper", value: "Viper" },
    { name: "Yoru", value: "Yoru" },
  ];

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
  };

  const handleSaveBooster = async () => {
    setLoading(true);
    const newBoosterData = {
      ...formValues,
      gain_percentage: "60",
      booster_level: "Warrior",
    };

    try {
      await api.post("/boosters", newBoosterData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Booster criado com sucesso!");
      window.location.reload();
    } catch (error: any) {
      console.error(
        "Error creating new booster:",
        error?.response.data.message
      );
      toast.error(error?.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Bag} alt={Bag} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("admin.new")}
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="bg-blue-50 rounded-2xl p-4">
                <div className="flex justify-between items-center">
                  <div className="w-[49%]">
                    <h2> {t("admin.name")}: </h2>
                    <input
                      type="text"
                      id="name"
                      value={formValues.name}
                      onChange={handleChange}
                      placeholder="Nome do Booster"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[49%]">
                    <h2> CPF: </h2>
                    <input
                      type="text"
                      id="cpf"
                      value={formValues.cpf}
                      onChange={handleChange}
                      placeholder="Digite o CPF"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[49%]">
                    <h2> E-mail: </h2>
                    <input
                      type="text"
                      id="email"
                      value={formValues.email}
                      onChange={handleChange}
                      placeholder="Digite o e-mail"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[49%]">
                    <h2> Discord: </h2>
                    <input
                      type="text"
                      id="discord"
                      value={formValues.discord}
                      onChange={handleChange}
                      placeholder="Digite o Discord"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[49%]">
                    <h2> {t("table.password")}: </h2>
                    <input
                      type="password"
                      id="password"
                      value={formValues.password}
                      onChange={handleChange}
                      placeholder="Digite a senha"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[49%]">
                    <h2> CEP: </h2>
                    <input
                      type="text"
                      id="cep"
                      value={formValues.cep}
                      onChange={handleChange}
                      placeholder="Digite o CEP"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[49%]">
                    <h2> {t("client.address")}: </h2>
                    <input
                      type="text"
                      id="address"
                      value={formValues.address}
                      onChange={handleChange}
                      placeholder="Digite o endereço"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[49%]">
                    <h2> {t("client.city")}: </h2>
                    <input
                      type="text"
                      id="city"
                      value={formValues.city}
                      onChange={handleChange}
                      placeholder="Digite a cidade"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[49%]">
                    <h2> {t("client.state")}: </h2>
                    <input
                      type="text"
                      id="state"
                      value={formValues.state}
                      onChange={handleChange}
                      placeholder="Digite o estado"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[49%]">
                    <h2> PIX: </h2>
                    <input
                      type="text"
                      id="payment_key"
                      value={formValues.payment_key}
                      onChange={handleChange}
                      placeholder="Digite o PIX"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[49%]">
                    <h2> Whatsapp: </h2>
                    <input
                      type="text"
                      id="telephone"
                      value={formValues.telephone}
                      onChange={handleChange}
                      placeholder="Digite o número de Whatsapp"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[49%]">
                    <h2> Nick: </h2>
                    <input
                      type="text"
                      id="mainAccount"
                      value={formValues.mainAccount}
                      onChange={handleChange}
                      placeholder="Digite o Nick da conta Main"
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[49%]">
                    {formValues.game === "CS2" ? (
                      <>
                        <h2> Nome na steam: </h2>
                        <input
                          type="text"
                          id="champion_name"
                          value={formValues.champion_name}
                          onChange={handleChange}
                          placeholder="Nome na steam"
                          className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                        />
                      </>
                    ) : (
                      <>
                        <h2> {t("table.champions")}: </h2>
                        <select
                          id="champion_name"
                          value={formValues.champion_name}
                          onChange={handleChange}
                          className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                        >
                          {(formValues.game === "VALORANT"
                            ? valorantChampions
                            : champions
                          ).map((champion) => (
                            <option key={champion.value} value={champion.value}>
                              {champion.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>

                  <div className="w-[49%]">
                    <h2> {t("table.game")}: </h2>
                    <select
                      name="selectNewBoosterGames"
                      id="game"
                      defaultValue={"LOL"}
                      value={formValues.game}
                      onChange={handleChange}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    >
                      {gameOptions.map((option) => (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="p-2 flex items-center justify-end gap-2 mt-2">
                  <button
                    className="bg-gray-100 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    onClick={handleClosePopup}
                  >
                    {t("tools.close")}
                  </button>
                  <button
                    className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    onClick={handleSaveBooster}
                  >
                    {loading ? "Salvando..." : t("tools.save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNewBooster;
