import React, { useState, useEffect } from 'react';
import api from '@services/client/api';
import CustomCheck from '@components/custom/checkbox';
import { useAuth } from '@context/AuthContext';

const StatusCard: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<boolean>(false);
    const { token } = useAuth();

    useEffect(() => {
        const fetchConfig = async () => {
            setLoading(true);
            try {
                const response = await api.get('/roulette/config', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setStatus(response.data); 
            } catch (err) {
                setError('Erro ao buscar status da roleta.');
            } finally {
                setLoading(false);
            }
        };

        fetchConfig();
    }, [token]);

    const handleUpdate = async (newStatus: boolean) => {
        try {
           let res = await api.patch('/roulette/config', 
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setStatus(newStatus); 
        } catch (err) {
            console.error('Erro ao atualizar configurações da roleta:', err);
        }
    };

    if (loading) return <div className="text-gray-400">Carregando...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="px-4 py-8 w-1/3 h-2/4 bg-blue-500 flex items-center justify-center rounded-md shadow-md">
            <CustomCheck
                index={0}
                label="Habilitar/Desabilitar Roleta"
                name="enable"
                checked={status}
                onChange={(index, name, value, state) => handleUpdate(value)}
            />
        </div>
    );
};

export default StatusCard;
