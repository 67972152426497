import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

interface ServiceData {
  quantity: number;
  totalAmount: string;
  parcials: {
    booster: string;
    elomania: string;
  };
}

interface GameData {
  serviceQuantity: number;
  totalRevenue: string;
}

interface LegendData {
  [key: string]: GameData;
}

let GAMES: string[] = [];

const COLORS: { [key: string]: string } = {};

const StatisticsSmurf: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [chartData, setChartData] = useState<{
    series: number[];
    legendData: LegendData;
  }>({ series: [], legendData: {} });
  const [data, setData] = useState<ServiceData>();

  const fetchData = async () => {
    try {
      const response = await api.get("/results/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { games } = response.data.services;
      setData(response.data.services);

      GAMES = Object.keys(games);

      GAMES.forEach((game, index) => {
        COLORS[game] = generateColor(index);
      });

      const series = GAMES.map((game) => games[game]?.serviceQuantity || 0);

      const legendData: LegendData = GAMES.reduce((acc, game) => {
        acc[game] = games[game] || { serviceQuantity: 0, totalRevenue: "0" };
        return acc;
      }, {} as LegendData);

      setChartData({ series, legendData });
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Error fetching data";
      toast.warn(errorMessage);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options: ApexOptions = {
    chart: {
      width: "100%",
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (
          value: number,
          { seriesIndex }: { seriesIndex: number }
        ): string {
          const gameName = GAMES[seriesIndex];
          return `${gameName} - ${value} serviços`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
    stroke: {
      show: false,
      width: 2,
      lineCap: "round",
    },
  };

  const generateColor = (index: number): string => {
    const colors = ["#00F2FE", "#26E67A", "#FFF845", "#FF4493", "#845EC2"];
    return colors[index % colors.length];
  };

  return (
    <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 py-6 border border-secondary-500 gap-4">
      <div className="flex items-center justify-between gap-2">
        <Tooltip
          content={
        
            <div className="flex flex-col p-4">
            <span>
                <span className="text-yellow-400">Boosters:</span> {data?.parcials.booster}
            </span>
            <span>
                <span className="text-primary-500">Elomania:</span> {data?.parcials.elomania}
            </span>
        </div>
        
          }
        >
          <div className="flex flex-col gap-2">
            <h2 className="text-gray-100 font-medium text-base">{t("results.services")}</h2>
            <h1 className="text-blue-50 dark:text-gray-500 font-semibold text-2xl">
              {data?.totalAmount || "R$ 0"}
            </h1>
          </div>
        </Tooltip>
        <div className="flex items-center gap-4">
          <div className="bg-blue-20 p-3 px-6 border border-secondary-500 rounded-lg cursor-pointer outline-none text-base font-semibold">
          {t("results.30days")}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8">
        <div
          id="donut-chart"
          className="flex justify-center col-span-1 transition-colors duration-1000 bg-white-100 dark:bg-blue-50 w-full pl-6"
        >
          <Chart
            options={options}
            series={chartData.series}
            type="donut"
            className="min-w-[240px]"
          />
        </div>
        <div className="col-span-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6">
          {GAMES.map((game) => (
            <div
              key={game}
              className={`flex items-start gap-4 result-${game
                .toLowerCase()
                .replace(/\s+/g, "-")}`}
            >
              <div
                className={`bg-${COLORS[game]} py-1 px-4 rounded-lg mt-2 flex items-center justify-center`}
                style={{ minWidth: "30px" }}
              >
                <div
                  className="min-w-[16px] min-h-[16px] rounded-lg"
                  style={{ backgroundColor: COLORS[game] }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <h2 className="text-gray-100 font-medium text-sm">{game}</h2>
                <h1 className="text-blue-50 dark:text-gray-500 font-semibold text-sm">
                {t("results.services")}:{" "}
                  {game in chartData.legendData
                    ? chartData.legendData[game].serviceQuantity
                    : 0}
                </h1>
                <h1 className="text-blue-50 dark:text-gray-500 font-semibold text-sm">
                {t("table.value")}:{" "}
                  {game in chartData.legendData
                    ? chartData.legendData[game].totalRevenue
                    : "0"}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatisticsSmurf;