import { useState } from 'react';
import Search from '@assets/Table/Search.svg';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
    onSearch: (option: string, query: string) => void;
}
  
  const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchOption, setSearchOption] = useState('skins');
    const [isFocused, setIsFocused] = useState(false);
    const [placeholderText, setPlaceholderText] = useState(t('admin.search'));
  
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchQuery(value);
      onSearch(searchOption, value);
    };
  
    const handleOptionChange = (option: string, placeholder: string) => {
      setSearchOption(option);
      setPlaceholderText(placeholder);
      setSearchQuery('');
      onSearch(option, ''); 
    };
  
  return (
    <div className="relative">
      <input
        type="text"
        className="bg-blue-20 rounded-lg py-3 px-10 outline-none text-gray-500 text-sm font-semibold"
        placeholder={placeholderText}
        value={searchQuery}
        onChange={handleSearchChange}
        onFocus={() => setIsFocused(!isFocused)}
      />
      <img
        src={Search}
        alt="Search"
        className="absolute top-3 left-4 select-none cursor-pointer"
      />

      {isFocused && (
        <div className="bg-blue-20 absolute top-12 z-[99999] left-0 mt-2 w-full rounded-lg overflow-hidden shadow-md" onMouseLeave={() => setIsFocused(!isFocused)}>
          <div
            className="cursor-pointer p-2 hover:bg-primary-500"
            onClick={() => handleOptionChange('ea', 'Ex.: 45000')}
          >
            EA
          </div>
          <div
            className="cursor-pointer p-2 hover:bg-primary-500"
            onClick={() => handleOptionChange('skins', 'Ex.: Storm Dragon')}
          >
            Skins
          </div>
          <div
            className="cursor-pointer p-2 hover:bg-primary-500"
            onClick={() => handleOptionChange('client', 'Ex.: Pedro H')}
          >
            Cliente
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
