import React from "react";
import { useCheckout } from "@context/CheckoutContextProvider";
import { Link } from "react-router-dom";

const ButtonHold = () => {
  const { checkout, setOpenCheckout, openCheckout } = useCheckout();

  const isDisabled =
    !checkout ||
    !checkout.checkoutItems ||
    checkout.checkoutItems.length === 0 ||
    checkout.checkoutItems.some((item) => item && item.status !== 'available');

  return (
    <Link to={"https://checkout.elomania.com"}>
      <button
        className={`w-full rounded-md p-4 font-poppins font-bold text-white text-xs flex items-center justify-center
    ${
      isDisabled
        ? "bg-slate-100/50 cursor-not-allowed"
        : "bg-[#564FFF] cursor-pointer"
    }`}
        disabled={isDisabled}
      >
        Ir para o checkout
      </button>
    </Link>
  );
};

export default ButtonHold;
