import TableBoosters from "@components/Dashboard/mainDashboard/Adm/team/boosters"
import TableTopBoosters from "@components/Dashboard/mainDashboard/Adm/team/boosters/topBoosters"

type Props = {}

const BoostersAdm = (props: Props) => {

  return (
    <>
      <TableTopBoosters />
      <TableBoosters />
    </>
  )
}

export default BoostersAdm