import React, { useState, useEffect } from "react";
import News1 from "@assets/News/News1.png";
import News2 from "@assets/News/News2.png";
import News3 from "@assets/News/News3.png";
import News4 from "@assets/News/News4.png";
import News5 from "@assets/News/News5.png";

const images = [
  { src: News1, url: "https://discord.com/invite/elomania" },
  { src: News2, url: "" },
  { src: News3, url: "https://www.elomania.com/lol/md5" },
  { src: News4, url: "https://www.elomania.com/" },
  { src: News5, url: "https://www.elomania.com/smurfs" }
];

const News = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = images[currentIndex].src;
    img.onload = () => setIsLoading(false);
  }, [currentIndex]);

  const handleButtonClick = (index: React.SetStateAction<number>) => {
    if (index !== currentIndex) {
      setIsLoading(true);
      setCurrentIndex(index);
    }
  };

  const currentImage = images[currentIndex];

  return (
    <div className="relative flex items-center w-full h-[400px] justify-center transition-colors duration-1000 bg-gradient-to-b from-[#02061B] via-[#101230] to-[#101230] rounded-3xl border border-secondary-500">
      <a
        href={currentImage.url ? currentImage.url : "#"}
        target={currentImage.url ? "_blank" : "_self"}
        rel="noopener noreferrer"
        className="absolute inset-0 flex items-center justify-center transition-colors duration-1000 bg-gradient-to-b from-[#02061B] via-[#101230] to-[#101230] rounded-3xl"
        style={{
          backgroundImage: `url(${currentImage.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </a>
      <div className="absolute right-2 rounded-md p-2 py-6 bg-gradient-to-l from-[#02061B/80] via-[#101230/50] to-[#101230/30] backdrop-blur flex flex-col items-center justify-center border border-secondary-500 gap-6">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className={`rounded-full ${
              index === currentIndex ? 'bg-[#FFF72D] shadow-2xl min-w-[14px] max-w-[14px] min-h-[14px] max-h-[14px]' : 'bg-gray-500 min-w-[10px] h-[10px]'
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default News;
