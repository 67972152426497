import TableCoupons from "@components/Dashboard/mainDashboard/Adm/tools/coupons"

type Props = {}

const CouponsAdm = (props: Props) => {
  return (
    <>
      <TableCoupons />
    </>
  )
}

export default CouponsAdm