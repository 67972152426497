import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import Bag from "@assets/Table/MiniBag.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

type Props = {};

const PopupNewAdmin = (props: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const { token } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    address: "XXXXX",
    city: "XX",
    state: "XX",
    cep: "XXXXXXX",
    telephone: "",
    discord: "",
    cpf: Math.random().toString(),
  });

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const maskPhone = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value.substring(0, 15);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Ativa o indicador de carregamento
      let data = await api.post("/admins/create", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.info("Admin cadastrado com sucesso!");
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error?.response?.data?.message);
        return;
      }
      toast.error(
        "Ocorreu um erro ao cadastrar o admin. Por favor, tente novamente."
      );
      console.error("Erro ao cadastrar admin:", error);
    } finally {
      setIsLoading(false); // Desativa o indicador de carregamento
      handleClosePopup();
      window.location.reload();
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Bag} alt={Bag} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("admin.new")}
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="mt-4">
                <h2>{t("admin.name")}:</h2>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Digite o nome"
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
              </div>
              <div className="mt-4">
                <h2>E-mail:</h2>
                <input
                  type="text"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Digite o e-mail"
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
              </div>
              <div className="mt-4">
                <h2>{t("table.password")}:</h2>
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Digite a senha"
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
              </div>
              <div className="mt-4">
                <h2>{t("table.phone")}:</h2>
                <input
                  type="text"
                  id="telephone"
                  value={maskPhone(formData.telephone)}
                  onChange={handleChange}
                  placeholder="Digite o telefone"
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
              </div>
              <div className="mt-4">
                <h2>Discord:</h2>
                <input
                  type="text"
                  id="discord"
                  value={formData.discord}
                  onChange={handleChange}
                  placeholder="Digite o Discord"
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
              </div>
              <div className="p-2 flex items-center justify-end gap-2 mt-2">
                <button
                  className="bg-gray-100 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={handleClosePopup}
                >
                  {t("tools.close")}
                </button>
                <button
                  className={`bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2 ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "Salvando..." : t("tools.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNewAdmin;
