import Construction from '@components/Construction'
import StreamGames from '@components/Dashboard/mainDashboard/Adm/streaming'

type Props = {}

const StreamClient = (props: Props) => {
  return (
    <>
      <Construction />
    </>
  )
}

export default StreamClient