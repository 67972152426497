import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const Main: React.FC<Props> = ({ children }) => {
  return <div className="main bg-[#F7F7F7] dark:bg-blue-50 transition-colors duration-300">{children}</div>;
};

export default Main;