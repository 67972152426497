import Close from "@assets/Buy/Close.svg";
import Bag from "@assets/Table/MiniBag.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

function PopupNewSmurf() {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [showPopup, setShowPopup] = useState(true);
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".txt"],
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const humanFileSize = (size: number) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };

  const handleSave = async () => {
    if (files.length > 0) {
      setLoading(true);
      const data = new FormData();
      files.forEach((file) => data.append("file", file));

      try {
        await api.post(`/account-smurfs`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleClosePopup();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
    }
  };
  return (
    <>
      {showPopup && (
        <div className="bg-overlay my-20">
          <div className={`fixed inset-0 flex justify-center items-center`}>
            <div className="bg-blue-50 w-5/6 mx-auto border border-secondary-500 rounded-2xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Bag} alt={Bag} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("tools.txt")}:
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="bg-blue-50 rounded-2xl p-4 max-h-[450px] overflow-y-auto">
                <div
                  {...getRootProps({
                    className:
                      "relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer",
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-col items-center justify-center py-10 text-center">
                    <p className="m-0">
                      Arraste o arquivo aqui ou clique nesta área.
                    </p>
                  </div>
                </div>
                {files.length > 0 && (
                  <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-6">
                    {files.map((file, index) => (
                      <div className="relative flex flex-col items-center overflow-hidden border-2 border-gray-100/20 text-center rounded-xl cursor-move select-none group">
                        {file.type === "text/plain" ? (
                          <div className="bg-gray-70 rounded-2xl shadow-lg p-12 items-center justify-center text-gray-700">
                            <span className="text-md">
                              Visualização indisponível
                            </span>
                          </div>
                        ) : (
                          <div>Unknown file type</div>
                        )}
                        <button
                          className="flex items-center justify-center absolute bg-blue-20 top-2 right-2 z-50 hover:bg-primary-500 transition duration-200 ease-in-out rounded-md"
                          type="button"
                          onClick={() => removeFile(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            viewBox="0 0 20 25"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414L11.414 13l2.293 2.293a1 1 0 01-1.414 1.414L10 14.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 13 6.293 10.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        <div className="absolute bottom-0 left-0 right-0 flex flex-col p-2 text-xs bg-blue-20 bg-opacity-50">
                          <span className="w-full font-bold text-white truncate">
                            {file.name}
                          </span>
                          <span className="text-xs text-white">
                            {humanFileSize(file.size)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="p-2 flex items-center justify-end gap-2 mt-2">
                <button
                  className="bg-gray-100 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={handleClosePopup}
                >
                  {t("tools.close")}
                </button>
                <button
                  className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={handleSave}
                  disabled={loading}
                >
                  {loading ? "Enviando arquivo..." : t("tools.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopupNewSmurf;
