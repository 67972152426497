import { Alert, Button } from '@material-tailwind/react';
import { useState, useEffect } from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6 mr-3"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function AlertCustomStyles({
  status,
  message,
  closeTime,
}: {
  status: string;
  message: string;
  closeTime: number;
}) {
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    const closeAlert = () => {
      setOpen(false);
    };

    const timeoutId = setTimeout(closeAlert, closeTime);

    return () => clearTimeout(timeoutId);
  }, [closeTime]);

  return null;
  // return (
  //   <Alert
  //     icon={<Icon />}
  //     open={open}
  //     className={`whitespace-nowrap rounded-md border-l-4 ${status === 'success' ? 'border-[#2ec946] fill-[#2ec946]' : 'border-red-500'} bg-black/30 font-medium text-${status === 'success' ? '[#2ec946]' : 'red-500'} w-full`}
  //   >
  //     {message}
  //   </Alert>
  // );
}