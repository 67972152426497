import { NavLink } from "react-router-dom";
import { ReactNode } from "react";

const ClientID = process.env.REACT_APP_DISCORD_CLIENT_ID || 'default_client_id';
const redirectUrl = process.env.REACT_APP_DISCORD_REDIRECT || 'default_client_id';

const DiscordProvider = ({ children }: { children: ReactNode }) => {

    const discordLoginUrl = `https://discord.com/api/oauth2/authorize?client_id=${ClientID}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=identify%20email`;

    return (
        <NavLink to={discordLoginUrl}>
            <>{children}</>
        </NavLink>
    );
}

export default DiscordProvider;
