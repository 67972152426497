import { useAuth } from '@context/AuthContext'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '@services/client/api'
import Loading from '@pages/loading'
import RequestDetailsPage from '@components/Chat'

const ChatClient = () => {
  const { user, token } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [request, setRequest] = useState<any>(null);

  useEffect(() => {
    if(!token && !id) return;
    const loadRequest = async () => {
      try {
        const response = await api.get(`/order/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setRequest(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    loadRequest();
  }, [id]);

  if (!request) return <Loading />;

  return <RequestDetailsPage request={request} user={user} token={token || ""} />;
}

export default ChatClient;
