import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "@services/client/api";
import DataTable from "@components/Dashboard/mainDashboard/Adm/requestFinished/generic-table";
import Bag from "@assets/Table/Check.svg";
import { IRequestDTO } from "@components/Chat/types/IRequest";
import Play from "@assets/Table/Play.svg";
import ReCAPTCHA from "react-google-recaptcha";

interface AvailableBoosterProps {
    token: string;
}

const TABLE_HEADS = [
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Server", type: "text" as const, key: "server.value" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Preferences", type: "preference" as const, key: "preferences" },
    { label: "Optionals", type: "optional" as const, key: "optionals" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Actions", type: "action" as const },
];

const AvailableBooster: React.FC<AvailableBoosterProps> = ({ token }) => {
    const { t } = useTranslation();
    const [data, setData] = useState<IRequestDTO[]>([]);
    const [showCaptchaForRow, setShowCaptchaForRow] = useState<number | null>(null);

    const fetchData = async () => {
        try {
            const response = await api.get(`/order/booster/available`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setData(response.data);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const handleActionClick = (row: { [key: string]: any }) => {
        setShowCaptchaForRow(row.id);
    };

    const acceptRequest = async (order_id: number | null) => {
        if (!order_id) {
            console.error("Serviço inválido.");
            return;
        }

        try {
            await api.post(
                `/order/accept-service`, 
                { order_id },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setShowCaptchaForRow(null); 
            window.location.href = `/dashboard/chat/${order_id}`
        } catch (error) {
            console.error("Erro ao aceitar o pedido:", error);
        }
    };

    return (
        <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
            <div className="flex items-center justify-between">
                <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
                    <img src={Bag} alt={Bag} />
                    {t("table.availableTitle")}
                </h2>
            </div>

            <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
                <DataTable
                    head={TABLE_HEADS}
                    rows={data}
                    recordsLimit={5}
                    actions={(row: any) => (
                        <button
                            className=" bg-orange-400 rounded-full w-8 h-8 p-2"
                            onClick={() => handleActionClick(row)}
                            aria-label="Ação"
                        >
                            <img src={Play} alt={Play} className="w-full h-full" />
                        </button>
                    )}
                />
            </div>

            {showCaptchaForRow !== null && (
                <div className="absolute inset-0 backdrop-blur-sm bg-blue-50 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[350px]">
                        <ReCAPTCHA
                            sitekey="6LcryA0oAAAAAAs7VIqQK3s47FAG3Zzoe6HmK-nR"
                            onChange={() => acceptRequest(showCaptchaForRow)} 
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AvailableBooster;
