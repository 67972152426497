import React, { useEffect, useState } from "react";
import Close from "@assets/Buy/Close.svg";
import Client from "@assets/Table/Client.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import { ISmurfAccount } from "@utils/types";
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
};

const PopupInfoSmurf = ({ id }: Props) => {
  const { token } = useAuth();
  const [smurf, setSmurf] = useState<ISmurfAccount>();
  const [showPopup, setShowPopup] = useState(true);
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  
  const getSmurf = async () => {
    try {
      const response = await api.get(`/account-smurfs/ytyrtq/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSmurf(response.data);
    } catch (error) {
      console.error("Error fetching for smurf:", error);
    }
  };

  useEffect(() => {
    getSmurf();
  }, []);

  return (
    <>
      {showPopup && smurf && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-0 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-5/6 mx-auto border border-secondary-500 rounded-3xl py-4 px-4">
              <div className="w-full rounded-3xl">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 grid sm:flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <img src={Client} alt={Client} />
                      <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                        Smurf:
                      </h2>
                    </div>
                    <div className="bg-blue-50 rounded-2xl p-2 text-center">
                      <h2 className="mr-2 text-gray-500 text-sm font-bold">
                        {" "}
                        #{smurf.id}{" "}
                      </h2>
                    </div>
                  </div>
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
                <div className="bg-blue-50 rounded-2xl p-4 max-h-[700px] overflow-y-auto">
                  <div className="grid md:flex justiy-center items-start justify-between mt-2">
                    <div className="w-[48%]">
                      <div className="flex items-center gap-2 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          {t('table.server')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.server}
                        </h2>
                      </div>
                      <div className="flex items-center gap-2 mt-4 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          Login:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.login}
                        </h2>
                      </div>
                      <div className="flex items-center gap-2 mt-4 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          {t('table.password')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.password}
                        </h2>
                      </div>
                      <div className="flex items-center pb-4 gap-2 mt-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          Email:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.email}
                        </h2>
                      </div>
                      <div className="flex items-center mt-4 pb-4 gap-2 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 font-bold mt-4">
                          {t('table.birthday')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.birthday}
                        </h2>
                      </div>
                      <div className="flex items-center mt-4 gap-2 pb-4">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          {t('table.date')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {/* R${infoService.price} */}
                          {smurf.creation_date}
                        </h2>
                      </div>
                    </div>
                    {/* Informações Serviço */}
                    <div className="w-[48%]">
                      <div className="flex items-center gap-2 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          {t('table.summoner')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.nickname || "N/A"}
                        </h2>
                      </div>
                      <div className="flex items-center gap-2 mt-4 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          {t('table.level')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.level}
                        </h2>
                      </div>
                      <div className="flex items-center gap-2 mt-4 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          {t('table.be')} :
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.blueessence}
                        </h2>
                      </div>
                      <div className="flex items-center mt-4 gap-2 pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          ISP:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.isp}
                        </h2>
                      </div>
                      <div className="flex items-start mt-4 gap-2 pb-4">
                        <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                          Skins:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-lg font-semibold mt-4">
                          {smurf.skins}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupInfoSmurf;
