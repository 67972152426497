import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import "./style.scss";
import { useRouletteContext } from "../../Context/RouletteProvider";

type ClaimModalProps = {
  onClose: (e: null) => void;
};

export const ClaimModal: React.FC<ClaimModalProps> = ({ onClose }) => {
  const anim1Ref = useRef<HTMLDivElement>(null);
  const { raffledItem } = useRouletteContext();

  useEffect(() => {
    if (!raffledItem) return;

    const anim1 = lottie.loadAnimation({
      container: anim1Ref.current ?? document.createElement("div"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: "https://assets5.lottiefiles.com/private_files/lf30_brfwfvq7.json",
    });

    const openModal = () => {
      const modal = document.querySelector(".modal-roulette");
      if (modal) {
        modal.classList.add("is-in");
        anim1.goToAndPlay(100 % anim1.totalFrames);
        anim1.play();
      }
    };

    openModal();

    return () => {
      anim1.destroy();
    };
  }, [raffledItem]);

  const closeModal = () => {
    const modal = document.querySelector(".modal-roulette");
    if (modal) {
      modal.classList.remove("is-in");
      onClose(null);
    }
  };

  const rewardModal = () => {
    const modal = document.querySelector(".modal-roulette");
    if (modal) {
      modal.classList.remove("is-in");
      onClose(null);
    }
  };

  if (!raffledItem) return null; 

  return (
    <div className="modal-roulette h-full">
      <div className="modal-content h-full" style={{zoom: 0.9}}>
        <div className="lottie-container">
          <div id="anim1" ref={anim1Ref} className="lottie-glow"></div>
        </div>
        <div className="rewards">
          <div className="rewards-container-modal">
            <div className="card-rewards">
              <div className="flex items-center justify-center gap-4">
                <img
                  src={raffledItem.item.image || ""}
                  alt={raffledItem.item.name || ""}
                  className="animate-float w-[14rem] h-[14rem]"
                />
                <div className="reward-text">{raffledItem.item.name || ""}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col items-center justify-center">
              <h2 className="textCustom">Parabéns</h2>
              <p className="opacity-[0.5] leading-[1.4] w-[85%]">
                Você acaba de ganhar um prêmio. Aproveite agora mesmo!
              </p>
            </div>
            <div className="flex items-center flex-col justify-center p-4 gap-4">
              <button onClick={rewardModal} className="🚀button-claim">
                <span>Reivindicar prêmio</span>
              </button>
              <button onClick={closeModal} className="opacity-40">
                <span>Resgatar mais tarde</span>
              </button>
            </div>
          </div>
        </div>
        <div className="glow glow-1"></div>
        <div className="glow glow-2"></div>
      </div>
    </div>
  );
};
