import { toast } from 'react-toastify';

const handleCopy = (text: string) => {
    if (!navigator.clipboard) {
        toast.error("Clipboard API não suportada no seu navegador.");
        return;
    }

    if (text) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast.success("Copiado para a área de transferência!");
            })
            .catch((err) => {
                console.error("Erro ao copiar para a área de transferência", err);
                toast.error("Erro ao copiar para a área de transferência.");
            });
    } else {
        toast.error("Texto vazio, nada para copiar.");
    }
};

export default handleCopy;
