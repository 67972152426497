import React, { useState, useEffect } from "react";
import Close from "@assets/Buy/Close.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import IconChoose from "@assets/Settings_tuor/IconChoose.png";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

type Props = {};

const PopupImgProfile = (props: Props) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(true);
  const [avatar, setAvatar] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { user, token, updateUser, signOut } = useAuth();
  const [profilePictures, setProfilePictures] = useState<any[]>([]);
  const [selectedUniqueChampion, setSelectedUniqueChampion] =
    useState<string>("");
  const [file, setFile] = useState<File>();

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAvatarTemplate = async (photo: string) => {
    try {
      setIsLoading(true); // Ativar loading
      const response = await api.patch(
        `/users/avatar/by-templates/${photo}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      updateUser(response.data);
      handleClosePopup();
      toast.success("Imagem de avatar alterada com sucesso!"); // Notificação de sucesso
    } catch (error: any) {
      toast.warn(error?.response?.data?.message); // Notificação de erro específico
    } finally {
      setIsLoading(false); // Desativar loading
    }
  };

  const handleSendClicked = async () => {
    if (!file) {
      toast.warn("Nenhum arquivo selecionado."); // Notificação de aviso
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data = new FormData();
      data.append("file", file);
      setIsLoading(true);
      await api.patch(`/users/avatar/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Imagem de avatar atualizada com sucesso.");
      handleClosePopup();
      window.location.reload();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        toast.error(`Error: ${error?.response?.data?.message}`); // Notificação de erro específico
      }
      toast.error("O arquivo de imagem não pode ser enviado. Tente novamente."); // Notificação de erro geral
      console.log(error);
    } finally {
      setIsLoading(false); // Desativar loading
    }
  };

  useEffect(() => {
    async function loadChampions() {
      try {
        const response = await api.get("/photo-avatar", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfilePictures(response.data);
      } catch (error) {
        console.error("Failed to load champions:", error);
      } finally {
        setIsLoading(false);
      }
    }

    const avatarRequest = user && user.avatar;
    setAvatar(avatarRequest || "");
    loadChampions();
    window.scrollTo({ top: 0 });
  }, [user]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      setFile(file);
    }
  };

  const handleUploadOrSave = () => {
    if (file) {
      handleSendClicked(); // Caso um arquivo tenha sido selecionado
    } else if (selectedUniqueChampion) {
      handleAvatarTemplate(selectedUniqueChampion); // Caso contrário, use o campeão único selecionado
    } else {
      toast.warn("Nenhuma imagem selecionada."); // Caso contrário, mostre um aviso
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay-low">
          <div
            className={`fixed z-10 inset-0 flex justify-center items-center`}
          >
            <div className="bg-transparent w-5/6 mx-auto border border-secondary-500 rounded-3xl">
              <div className="bg-blue-50 w-full p-4 rounded-3xl">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2">
                    <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                      Imagem de Perfil:
                    </h2>
                  </div>
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Uploaded Profile Image"
                    className="w-full h-full rounded-xl bg-cover bg-center"
                  />
                )}
                <div className="grid grid-cols-4 sm:grid-cols-8 md:grid-cols-12 gap-6 mt-4">
                  {!selectedImage &&
                    profilePictures.map((item) => (
                      <>
                        <button
                          key={item.id}
                          className="border border-primary-500 rounded-xl focus:ring w-14 h-14"
                          onClick={() => {
                            setSelectedUniqueChampion(item.photo);
                          }}
                        >
                          <img
                            src={
                              selectedImage
                                ? selectedImage
                                : `https://app-elomania-v2-uploads.s3.amazonaws.com/${item.photo}`
                            }
                            alt="Imagem de Perfil"
                            className="w-full h-full rounded-xl bg-cover bg-center"
                          />
                        </button>
                      </>
                    ))}
                </div>

                <div className="w-full flex items-end justify-end mt-4 gap-6">
                  <label
                    htmlFor="fileInput"
                    className="bg-blue-1001/20 cursor-pointer rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/50 px-7 py-3.5 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  >
                    <img src={IconChoose} alt="IconChoose" />
                    <input
                      type="file"
                      id="fileInput"
                      className="hidden"
                      onChange={handleImageChange}
                    />
                  </label>
                  <button
                    className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    onClick={handleUploadOrSave}
                    disabled={isLoading}
                  >
                    {isLoading ? "Carregando..." : "Salvar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupImgProfile;
