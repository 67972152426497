import api from "@services/client/api";
import { IUser } from "@utils/types";
import { useEffect, useState } from "react";
import Profile from "@assets/Topbar/Profile.svg";
import Cover from "@assets/Background/edit-profile.png";
import { useTranslation } from "react-i18next";

type Props = {
  user: IUser;
  token: string;
};

type Booster = {
  avatar: string;
  name: string;
  booster_level: string;
  booster_next_level: string;
  services_quantity: number;
  services_remain: number;
  level_remain: number;
  champion_name: string;
  address: string;
  discord: string;
  phone: string;
  email: string;
  pix: string;
  telephone: string;
  accumulated_amount_received: string | number;
};

interface Notification {
  content: string;
  description: string;
  link: string;
}

interface NotifyLink {
  avatar: string;
  booster?: string;
  cliente?: string;
}

interface NotificationProps {
  index: string | number;
  avatar: string;
  name: string | undefined;
  type: string;
  content: string;
  description: string;
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const NotificationComponent = ({
  index,
  avatar,
  name,
  type,
  content,
  description,
}: NotificationProps) => {
  return (
    <div
      key={index}
      className="w-full flex items-center justify-between py-6 border-b border-b-gray-100/10"
    >
      <div className="flex items-center gap-4">
        <div className="border border-gray-100/60 transition-colors duration-1000 bg-white-100 dark:bg-secondary-500 min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full overflow-hidden">
          <img
            src={avatar}
            alt="Perfil"
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="grid gap-1">
          <div className="flex items-center gap-2">
            <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-sm">
              {" "}
              {name}{" "}
            </h2>
            <h3 className="transition-colors duration-1000 bg-blue-50 text-gray-500 text-sm font-medium py-1 px-2 rounded-3xl">
              {" "}
              {type}{" "}
            </h3>
          </div>
          <p className="text-blue-1001 font-semibold text-sm"> {content} </p>
        </div>
      </div>
      <p className="text-gray-100 font-semibold text-sm"> {description} </p>
    </div>
  );
};

const ResumeBooster = ({ user, token }: Props) => {
  const { t } = useTranslation();
  const [booster, setBooster] = useState<Booster>();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const retrieveNotifications = async () => {
      try {
        const response = await api.get(`/notifications/booster`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { data } = response;

        setNotifications(data);
      } catch (err) {
        console.log(err);
      }
    };

    const retrieveBoosterDetails = async () => {
      try {
        const response = await api.get(`/boosters/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { data } = response;
        setBooster(data);
      } catch (err) {
        console.log(err);
      }
    };

    retrieveNotifications();
    retrieveBoosterDetails();
  }, [token]);

  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  const handleMouseEnter = (mensagem: string) => {
    setPopupMessage(mensagem);
  };

  const handleMouseLeave = () => {
    setPopupMessage(null);
  };

  return (
    <div className="w-full">
      <div className="bg-transparent grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/* Capa e Perfil */}
        <div className="bg-blue-50 rounded-2xl w-full h-[350px] relative">
          <div className="capa w-full h-full rounded-2xl">
            <img
              src={
                user.banner
                  ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                  : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
              }
              alt="Capa"
              className="object-cover w-full h-full rounded-2xl"
            />
          </div>
          <div className="absolute top-4 items-center justify-center w-full">
            <div className="flex items-center justify-between px-4 mb-4">
              <div className="bg-gray-500/20 rounded-3xl w-20 py-2 text-center">
                <p className="text-gray-500 font-normal text-xs">Booster</p>
              </div>
            </div>
            <div className="w-full grid place-items-center mt-8 gap-2">
              <div className="bg-secondary-500 w-20 h-20 rounded-2xl">
                <img
                  src={`${user.avatar}`}
                  alt="Perfil"
                  className="w-full h-full rounded-2xl"
                />
              </div>
              <h3 className="transition-colors duration-100 text-gray-500 font-semibold text-lg">
                {booster?.champion_name}
              </h3>
            </div>
            <div
              className="mt-4 px-4 cursor-pointer"
              onMouseEnter={() =>
                handleMouseEnter(
                  "Aumente seu nível e ganhe mais! 🚀 Você ganha XP quando finaliza um serviço."
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              {booster && (
                <>
                  <div className="flex items-center justify-between mt-24">
                    <p className="text-sm text-gray-500">
                      {booster.services_quantity}
                    </p>
                    <p className="text-sm text-gray-100">
                      {booster.level_remain}
                    </p>
                  </div>
                  <div className="h-2 bg-gray-500 rounded-full">
                    <div
                      className="h-full bg-gradient-to-l from-[#FF8730] to-[#FFE62E]  shadow-[2px_2px_16px_#FF8730] rounded-full"
                      style={{
                        width: `${
                          (booster.services_quantity / booster.level_remain) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <p className="text-gray-100 font-semibold text-xs">
                      {t("client.current")}:{" "}
                      <span className="text-gray-500">
                        {booster.booster_level}
                      </span>
                    </p>
                    <p className="text-gray-100 font-semibold text-xs">
                      {t("client.next")}:{" "}
                      <span className="text-gray-500">
                        {booster.booster_next_level}
                      </span>
                    </p>
                  </div>
                </>
              )}

              {popupMessage && popupMessage && (
                <div
                  className="absolute left-4 bg-blue-50 w-[450px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 mt-2 shadow transition duration-300"
                  onMouseEnter={() =>
                    handleMouseEnter(
                      "Aumente seu nível e ganhe mais! 🚀 Você ganha XP quando finaliza um serviço."
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  {popupMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Valor a Receber */}
        <div className="w-full flex flex-col h-full">
          <div className="flex items-center justify-center select-none gap-4 transition-colors duration-1000 bg-gradient-to-b py-10 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl p-4 border border-gray-100 dark:border-secondary-500">
            <span className="text-blue-50 dark:text-gray-500 font-semibold text-base transition-colors duration-1000">
              Valor a receber
            </span>
            <div className="px-5 py-1 min-w-[55px] font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-50 dark:bg-blue-1001/10 shadow-2xl">
              {" "}
              R${" "}
              {booster?.accumulated_amount_received
                ? Number(booster.accumulated_amount_received).toFixed(2)
                : "0.00"}
            </div>
          </div>
          <div className="flex flex-col text-center justify-center items-center gap-4 h-full transition-colors duration-1000 bg-gradient-to-b p-2 pt-6 pb-4 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl border border-gray-100 dark:border-secondary-500 mt-4">
            <h3 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-lg">
              Aumente seu nível e ganhe mais!
            </h3>
            <span className=" text-center text-gray-100 text-sm font-medium w-full">
              Você ganha XP quando finaliza um serviço. Suba de nível e aumente
              a sua porcentagem de ganhos.
            </span>
          </div>
        </div>
        {/* Atividades */}
        <div className="transition-colors h-[350px] overflow-hidden duration-1000 bg-gradient-to-b py-6 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl p-4 border border-gray-100 dark:border-secondary-500">
          <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">
            {t("table.activities")}
          </h2>
          <div className="flex flex-col gap-5">
            {notifications.length > 0 ? (
              <div>
                {notifications.slice(0, 3).map((notification, index) => {
                  if (!isJsonString(notification.link)) {
                    return (
                      <NotificationComponent
                        index={index}
                        avatar={Profile}
                        name={"Old"}
                        type={"Notification"}
                        content={notification.content}
                        description={notification.description}
                      />
                    );
                  }
                  const link: NotifyLink = JSON.parse(notification.link);
                  return (
                    <div>
                      <NotificationComponent
                        index={index}
                        avatar={link.avatar}
                        name={!link.booster ? link.cliente : link.booster}
                        type={!link.booster ? "Cliente" : "Booster"}
                        content={notification.content}
                        description={notification.description}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-4">
                <p className="text-gray-100 text-sm font-semibold">
                  {t("table.noneactivities")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeBooster;
