import React, { useEffect, useState, useCallback, useMemo } from "react";
import $ from "jquery";
import "./style.css";
import ProgressTimer from "./remaining";
import { useAlertContext } from "@hooks/AlertContext";
import { useTranslation } from "react-i18next";
import { IResponseRoulettePrizes, useRouletteContext } from "../Context/RouletteProvider";
import moment from "moment-timezone";
import RouletteHeader from "./roulette-header";

const shuffleArray = <T,>(array: T[], times: number = 3): T[] => {
  const shuffle = (arr: T[]): T[] => {
    let currentIndex = arr.length;
    let randomIndex: number;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
    }

    return arr;
  };

  for (let i = 0; i < times; i++) {
    array = shuffle([...array]); 
  }

  return array;
};


interface RouletteAppProps {
  items: IResponseRoulettePrizes[] | [];
  spinTime: number;
  onSpinningStart?: () => void;
  onSpinningEnd?: () => void;
  enable: boolean;
  cardSize: number;
  awaitTime: number;
  rolls: number;
}

const Roulette: React.FC<RouletteAppProps> = ({
  items,
  spinTime,
  cardSize,
  onSpinningStart,
  onSpinningEnd,
  enable,
  awaitTime,
  rolls
}) => {
  const { t } = useTranslation();
  const { addAlert } = useAlertContext();
  const { drawPrize, isSpinning, setSpinning, setSpinStart, spinStart, rouletteUserData } = useRouletteContext()
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  useEffect(() => {
    initWheel(rolls);
  }, [items]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isSpinning) {
      setRemainingSeconds(spinTime + 1);
      intervalId = setInterval(() => {
        setRemainingSeconds(prev => {
          if (prev <= 1) {
            clearInterval(intervalId as NodeJS.Timeout);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isSpinning, spinTime]);

  const initWheel = useCallback((rows: number) => {
    const $wheel = $(".roulette-wrapper .wheel");

    $wheel.empty();

    const shuffledItems = shuffleArray([...items]);

    for (let i = 0; i < rows; i++) {
      const $row = $("<div>", { class: "row" });
      shuffledItems.forEach((item) => {
        const itemName =
          item.name.length > 15
            ? `${item.name.substring(0, 15)}...`
            : item.name;

        const divTemplate = `
          <div class="card" data-id=${item.id}>
            <img class="card-image" src="${item.image}"/>
            <span class="card-title">${itemName}</span>
          </div>`;

        $row.append(divTemplate);
      });

      $wheel.append($row);
    }
  }, [items]);

  const spinWheel = (selectedPrize: number) => {
    if (isSpinning) {
      return;
    }

    setRemainingSeconds(spinTime + 1);
    setSpinning(true);

    const $wheel = $(".roulette-wrapper .wheel");

    const randomize = Math.floor(Math.random() * cardSize) - cardSize / 2;

    let whell = $wheel.children()[rolls * 0.5];
    let row = $(whell).children()[selectedPrize];
    let mano = 0;

    var posElemento1 = $(".roulette-indicator").offset();
    var posElemento2 = $(row).offset();

    if (posElemento1 && posElemento2) {
      var distanciaX = posElemento2.left - posElemento1.left;
      var distanciaY = posElemento2.top - posElemento1.top;

      var distanciaTotal = Math.sqrt(
        distanciaX * distanciaX + distanciaY * distanciaY
      );

      var distanciaArredondada = Math.round(distanciaTotal);
      mano = distanciaArredondada + cardSize / 2;
    }

    if (onSpinningStart) {
      onSpinningStart();
    }

    // $wheel.css({
    //   "transition-timing-function": "cubic-bezier(0.4, 0, 0.2, 1)",
    //   "transition-duration": `${spinTime}s`,
    //   transform: `translate3d(-${mano + randomize}px, 0px, 0px)`,
    // });

    $wheel.css({
      "transition-timing-function": "cubic-bezier(0, 0.7, 0.3, 1)",
      "transition-duration": `${spinTime}s`,
      transform: `translate3d(-${mano + randomize}px, 0px, 0px)`,
    });

    setTimeout(() => {
      setTimeout(() => {
        $wheel.css({
          "transition-timing-function": "",
          "transition-duration": `1s`,
        });

        $wheel.css("transform", `translate3d(0px, 0px, 0px)`);
        setSpinning(false);
      }, 1000 * awaitTime);

      if (onSpinningEnd) {
        onSpinningEnd();
        setSpinStart(!spinStart)
      }
    }, spinTime * 1100);
  };

  const handleButtonClick = async () => {
    let raffledItem = await drawPrize();

    if (raffledItem) {
      const $wheel = $(".roulette-wrapper .wheel");
      let whell = $wheel.children()[rolls * 0.5];
      let foundElement = whell.querySelector(`[data-id="${raffledItem.item.id}"]`);
      let prizeIndex = foundElement
        ? $(whell).children().index(foundElement)
        : -1;

      spinWheel(prizeIndex);
    } else {
      addAlert("error", "Nenhum prêmio foi sorteado", 3000);
    }
  };

  return (
    <>
      <div className="roulette-wrapper">
        <div className="roulette-container transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500">
          <RouletteHeader />
          <div className="roulette-wheel-wrapper">
            <div className="roulette-wheel-linear"></div>
            <div className="roulette-indicator"></div>
            <div className="wheel"></div>
            {!enable && !isSpinning ? <div className="roulette-wheel-filter transition-colors duration-1000 bg-gray-500 dark:bg-blue-50"></div> : null}
          </div>
          <button
            className="roulette-button"
            onClick={handleButtonClick}
            disabled={isSpinning || !enable}
          >
            {isSpinning ? `Aguarde ${remainingSeconds}s` : rouletteUserData?.canSpin ? t("roulette.click") : <ProgressTimer/>}
          </button>
        </div>
      </div>
    </>
  );
};

export default Roulette;
