import React, { useEffect, useState } from "react";
import Client from "@assets/Table/Client.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import { useAuth } from "@context/AuthContext";
import { ServiceRequests } from "@utils/types";
import api from "@services/client/api";
import PopupFinalized from "@components/Dashboard/mainDashboard/Adm/requestFinished/PopupFinalized";
import { useTranslation } from "react-i18next";

type Props = {
  finalizedRequests: ServiceRequests[];
};

interface TableData {
  id: string;
  idServico: string;
  servico: string;
  descricao: string;
  comissão: number;
  valor: number;
  jogos: string[];
  status: string;
}
const TableMySalesBooster = ({ finalizedRequests }: Props) => {
  const { t } = useTranslation();
  const { token } = useAuth();

  const [selected, setSelected] = useState("");
  const [showPopupFinalized, setShowPopupFinalized] = useState(false);

  const handleButtonClickFinalized = (id: string) => {
    setSelected(id);
    setShowPopupFinalized((prevState) => !prevState);
  };

  const tableDataMyService: TableData[] = finalizedRequests.map((data) => ({
    id: data.id,
    idServico: data.id,
    servico:
    data.preferences_md_or_duo_boost === "duo" ? "Duo Boost" : data.type_service,
    descricao: data.desired_level,
    comissão: data.booster.gain_percentage,
    valor: typeof data.price === "string" ? parseFloat(data.price) : data.price,
    jogos: [data.game_service],
    status: data.request_status,
  }));
  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };
  const renderStatus = (status: string) => {
    if (status === "Aprovado") {
      return <span className="text-gray-500 font-semibold">{t("table.approved")}</span>;
    } else if (status === "Pendente") {
      return <span className="text-gray-500 font-semibold">Pendente</span>;
    } else if (status === "FINALIZADO") {
      return <span className="text-gray-500 font-semibold">{t("table.finalizedstatus")}</span>;
    }
    return null;
  };

  const handleCopyOnClick = (props: any) => {
    navigator.clipboard.writeText(props.target.innerHTML);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Client} alt={Client} />
          {t("table.mine")}
        </h2>
      </div>
      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
          <div className="overflow-auto max-h-[550px]">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    ID
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.game")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.commission")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.value")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableDataMyService.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <div className="flex flex-row justify-center items-center">
                        <button
                          className="overflow-hidden whitespace-nowrap max-w-[5em]"
                          onClick={handleCopyOnClick}
                        >
                          {data.idServico}
                        </button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                          />
                        </svg>
                      </div>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.jogos.map((jogo) => (
                        <span
                          key={jogo}
                          className="flex items-center justify-center"
                        >
                          {getGameIcon(jogo)}
                        </span>
                      ))}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.comissão}%
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.valor
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ",")}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <div className="bg-red-500 p-2 px-4 rounded-full">
                        {renderStatus(data.status)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default TableMySalesBooster;
