import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import "@styles/popup.css";
import IconChoose from "@assets/Settings_tuor/IconChoose.png";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";

const CAPAS = Array.from(
  { length: 21 },
  (_, i) => `https://app-elomania-v2-uploads.s3.amazonaws.com/Capa${i + 1}.jpg`
);

const PopupImgCover = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [file, setFile] = useState<File>();
  const [selectedCapa, setSelectedCapa] = useState<string>(""); // Novo estado para armazenar a capa selecionada
  const { token, updateUser } = useAuth();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); // Novo estado para URL de preview
  const [showCapas, setShowCapas] = useState(true); // Novo estado para controlar a visibilidade da lista
  const [isLoading, setIsLoading] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setPreviewUrl(url); // Atualiza o estado com a URL de preview
      setFile(selectedFile);
      setShowCapas(false); // Esconde a lista de capas
    }
  };

  const updateBanner = async () => {
    if (!file && !selectedCapa) return;

    setIsLoading(true);

    try {
      if (file) {
        const data = new FormData();
        data.append("file", file);
        const response = await api.patch(`/users/banner/`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        updateUser(response.data);
      }

      if (selectedCapa) {
        const response = await api.patch(
          `/users/banner-template`,
          {
            banner: `${selectedCapa}.jpg`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        updateUser(response.data);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      toast.info("Imagem de capa atualizada com sucesso!");
      handleClosePopup();
    }
  };

  const handleImageClick = async (capaName: string) => {
    try {
      const response = await api.patch(
        `/users/banner-template`,
        {
          banner: `${capaName}.jpg`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedCapa(capaName);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center mb-4">
                <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                  Imagem de Capa:
                </h2>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="block max-h-[60vh] overflow-y-auto">
                {showCapas ? (
                  <div className="block">
                    {CAPAS.map((capa, index) => (
                      <button
                        key={index}
                        className="border border-primary-500 rounded-xl focus:ring w-full h-[320px]"
                      >
                        <img
                          src={capa}
                          alt={`Capa${index + 1}`}
                          className="w-full h-full rounded-xl bg-cover bg-center object-cover"
                          onClick={() => {
                            const capaName = `Capa${index + 1}`;
                            handleImageClick(capaName);
                          }}
                        />
                      </button>
                    ))}
                  </div>
                ) : (
                  <div className="preview-container">
                    <img
                      src={previewUrl || ""}
                      alt="Preview"
                      className="w-full h-[200px] rounded-xl"
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex items-end justify-end mt-4 gap-5">
                <label
                  htmlFor="fileInput"
                  className="bg-blue-1001/20 cursor-pointer rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/50 px-7 py-3.5 flex items-center text-gray-500 text-sm font-semibold gap-2"
                >
                  <img src={IconChoose} alt="IconChoose" />
                  <input
                    type="file"
                    id="fileInput"
                    className="hidden"
                    onChange={handleImageChange}
                  />
                </label>
                <button
                  className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={updateBanner}
                  disabled={isLoading} // Desativar o botão durante o carregamento
                >
                  {isLoading ? "Salvando..." : "Salvar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupImgCover;
