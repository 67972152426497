import React, { useEffect, useState } from 'react';
import EditProfile from '@components/Profile';
import { useAuth } from '@context/AuthContext';
import api from '@services/client/api';
import { IBooster } from '@utils/types';

type Props = {};

const Profile = (props: Props) => {
  const { user, updateUser, token } = useAuth();
  const [boosterInfo, setBoosterInfo] = useState<IBooster | null>(null);

  useEffect(() => {
    if (user && user.type_permission === 'BOOSTER') {
      api
        .get('/boosters/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setBoosterInfo(response.data);
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
    }
  }, [user, token]);

  return (
    <div>
      {user && token && (
        <EditProfile user={user} updateUser={updateUser} token={token} boosterInfo={boosterInfo} />
      )}
    </div>
  );
};

export default Profile;