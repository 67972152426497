import "./style.scss";
import { useTranslation } from "react-i18next";
import { IResponseRouletteAward, useRouletteContext } from "../../Context/RouletteProvider";

export const RoulettePrizes = () => {
  const { t } = useTranslation();
  const { rouletteAwards, handleClaimPrize } = useRouletteContext();

  const PrizeCard = ({ claimedStatus }: { claimedStatus: string | null }) => {
    switch (claimedStatus) {
      case "claimed":
        return (
          <div
            className={`text-zinc-50 text-xs font-semibold whitespace-nowrap items-center justify-center px-3 py-1 rounded w-18 bg-green-400`}
          >
            {t("roulette.recued")}
          </div>
        );
      case "pending":
        return (
          <div
            className={`text-zinc-50 text-xs font-semibold whitespace-nowrap items-center justify-center px-3 py-1 rounded w-18 bg-yellow-500`}
          >
            {t("roulette.await")}
          </div>
        );
      case "claim":
        return (
          <div
            className={`text-zinc-50 text-xs font-semibold whitespace-nowrap items-center justify-center px-3 py-1 rounded w-18 bg-green-400`}
          >
            {t("roulette.active")}
          </div>
        );
      default:
        return;
    }
  };

  return (
    <>
      <div className="transition-colors duration-1000 bg-white-100 dark:bg-[#02061B] rounded-2xl p-4 mt-4">
        <div className="mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {rouletteAwards.map((award: IResponseRouletteAward) => (
              <div
                className="w-full transition-all duration-300 transform hover:translate-y-[-8px] drop-shadow-md bg-blue-20 border-gray-20 hover:border-[#6C5FFB] hover:bg-[radial-gradient(131.63%_131.63%_at_49.89%_-31.63%,#4237BF_0%,#1B1E37_73.47%)] p-3 rounded-md border-2 border-solid"
                key={award.id}
              >
                <div className="flex items-center gap-4 mb-6">
                  <div className="text-zinc-50 text-xs font-semibold whitespace-nowrap bg-indigo-500 items-center justify-center px-3 py-1 rounded w-18">
                    {award.prize.level_required}
                  </div>
                  {PrizeCard({
                    claimedStatus: award.claim_status,
                  })}
                </div>
                <div className="w-full flex flex-col">
                  <div className="justify-center items-center bg-indigo-300 bg-opacity-10 flex grow flex-col w-full px-4 py-4 rounded-xl h-full">
                    <div className="w-full glowingasset token is-sm">
                      <div className="w-full flex items-center justify-center">
                        <img
                          src={award.prize.image || ''}
                          alt="Prêmio"
                          className="object-contain object-center w-1/2 h-auto overflow-hidden"
                          loading="lazy"
                        />
                      </div>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                    </div>
                  </div>
                  <div className="flex-col gap-4 mt-4">
                    <div className="text-zinc-50 text-base whitespace-normal font-semibold overflow-hidden">
                      {award.prize.name}
                    </div>
                    <div className="text-slate-500 mt-4 text-sm">
                      {award.prize.description}
                    </div>
                  </div>
                </div>
                <div>
                  {award.claim_status === "claimed" ? (
                    <p className="text-green-500 font-semibold mt-2">
                      {t("roulette.redeemed")}
                    </p>
                  ) : (
                    <button
                      onClick={() => handleClaimPrize(award)}
                      className={`w-full button-claim is-glowing mt-4 outline-none cursor-pointer ${
                        award.claim_status === "claim" ? "" : "disabled"
                      }`}
                      disabled={award.claim_status === "claimed"}
                    >
                      {(() => {
                        switch (award.claim_status) {
                          case "pending":
                            return t("roulette.claim");
                          case "claim":
                            return t("roulette.progress");
                          case "claimed":
                            return t("roulette.redeemed");
                          default:
                            return t("roulette.state");
                        }
                      })()}
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                      <i></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
