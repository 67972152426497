// MessageInput.tsx
import React from "react";
import ArrowDown from "@assets/Chat/ArrowDown.svg";

interface MessageInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSend: () => void;
    onEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ value, onChange, onSend, onEnterPress }) => (
    <div className="bg-blue-50 relative p-2 rounded-3xl border border-secondary-500 mt-4">
        <input
            type="text"
            value={value}
            onChange={onChange}
            onKeyUp={onEnterPress}
            className="bg-gray-500 rounded-full py-3 px-10 outline-none text-gray-20 text-sm font-semibold w-full"
            placeholder="Mensagem"
        />
        <button className="absolute top-6 right-6" onClick={onSend}>
            <img src={ArrowDown} alt="Send" />
        </button>
    </div>
);

export default MessageInput;
