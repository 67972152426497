import { useState, useEffect } from "react";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import Logo from "@assets/LogoActive.svg";
import "@styles/input.css";
import api from "@services/client/api";
import { toast } from "react-toastify";

type Props = {};

const ForgotPassword = (props: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { signIn, signOut } = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const fetchToken = async () => {
      try {
        if (code) {
          setLoading(true);
          await signIn({
            authtype: "discord",
            code: code,
          });
          setLoading(false);
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Erro ao obter o token do Discord:", error);
      }
    };

    fetchToken();
  }, [signIn, navigate]);

  const [userId, setUserId] = useState<string | null>(null);
  const [recoveryCode, setRecoveryCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [codeValidated, setCodeValidated] = useState(false); // Estado para verificar se o código foi validado

  const [step, setStep] = useState(0);

  const handleSendRecoveryCode = async () => {
    setLoading(true);
    try {
      const response = await sendRecoveryCode(email);
      if (response && response.userId) {
        setUserId(response.userId);
        setStep(1); // Mova para a próxima etapa
      } else {
        console.error("Resposta inesperada:", response);
      }
      setLoading(false);
    } catch (error: any) {
      console.error("Erro ao enviar o código de recuperação:", error);
      toast.error(error?.response.data.message);
      setLoading(false);
    }
  };

  const sendRecoveryCode = async (email: string) => {
    console.log("Enviando e-mail:", email); // Adicionado para depuração
    try {
      const response = await api.post("/sessions/send-recovery-code", {
        email,
      });
      return response.data;
    } catch (error: any) {
      console.error("Erro na chamada da API:", error);
      toast.error(error?.response.data.message);
    }
  };

  // Função para validar o código inserido pelo usuário
  const handleValidateCode = async () => {
    setLoading(true);
    try {
      if (userId) {
        const isValidCode = await validateCode(userId, recoveryCode);
        if (isValidCode) {
          setCodeValidated(true);
          setStep(2); // Atualiza para a etapa 2
        }
      } else {
        console.error("UserID não encontrado");
      }
    } catch (error: any) {
      console.error(error?.response.data.message);
      toast.error(error?.response.data.message);
    }
    setLoading(false);
  };

  // Função que chama a API para validar o código (ajuste conforme suas necessidades)
  const validateCode = async (userId: string, code: string) => {
    try {
      const response = await api.post("/sessions/validate-code", {
        userId,
        code,
      });


      localStorage.setItem("recoveryToken", response.data.token);

      return response.data;
    } catch (error) {
      console.error("Erro na chamada da API:", error);
      throw error;
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("recoveryToken");

      const response = await api.post("/sessions/change-password-with-token", {
        token,
        newPassword,
      });
      toast.success("Sua senha foi alterada com sucesso!");
      setTimeout(() => {
        navigate("/auth/sign-in");
      }, 2000);
    } catch (error: any) {
      console.error("Erro ao redefinir a senha:", error);
      toast.error(error?.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-blue-20 flex justify-center items-center min-h-screen relative">
      <div className="absolute inset-0 flex justify-center items-center">
        <video
          src="https://app-elomania-v2-uploads.s3.amazonaws.com/scenes/scenes-5.mp4"
          autoPlay
          muted
          loop
          className="object-cover w-full h-full z-0"
        ></video>
      </div>
      <div className="box z-10 bg-blue-20/20 relative border border-secondary-500 w-[380px] h-[480px] rounded-lg overflow-hidden">
        <div className="form bg-blue-50/10 backdrop-blur-md shadow-inner absolute flex flex-col p-10 rounded-lg inset-[2px] z-10">
          <div className="mx-auto">
            <img src={Logo} alt={Logo} />
          </div>
          <div className="w-full flex items-center justify-center gap-2 text-center text-gray-100 text-base font-semibold mt-6">
            <p> Lembrou a senha? </p>
            <a
              href="/auth/sign-in"
              className="text-center text-blue-100 text-base font-semibold"
            >
              Entre aqui
            </a>
          </div>
          <div className="mt-12">
            <h1 className="text-gray-500 font-bold text-2xl">
              {" "}
              Esqueceu sua Senha?{" "}
            </h1>
          </div>
          <div className="mt-4">
            <h2 className="text-gray-100 text-base">
              {" "}
              Enviaremos um e-mail com instruções de como redefinir sua senha.{" "}
            </h2>
          </div>
          <div className={step === 0 ? "send-email" : "hidden"}>
            <div className="inputBox relative mt-8 w-[300px]">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                name="mail"
                id="mail"
                required
                className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
              />
              <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                E-mail
              </span>
              <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
            </div>
            <div className="mt-4">
              <button
                className="bg-blue-1001 text-gray-500 w-full py-2 rounded-lg cursor-pointer outline-none border-none hover:ring focus:ring"
                onClick={handleSendRecoveryCode}
              >
                {loading ? "Loading..." : "Enviar"}
              </button>
            </div>
          </div>
          <div className={step === 1 ? "verification-code" : "hidden"}>
            {userId && (
              <>
                <div className="inputBox relative mt-8 w-[300px]">
                  <input
                    value={recoveryCode}
                    onChange={(e) => setRecoveryCode(e.target.value)}
                    type="text"
                    name="code"
                    id="code"
                    required
                    className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                  />
                  <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                    Código de Recuperação
                  </span>
                  <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
                </div>
                <div className="mt-4">
                  <button
                    className="bg-blue-1001 text-gray-500 w-full py-2 rounded-lg cursor-pointer outline-none border-none hover:ring focus:ring"
                    onClick={handleValidateCode}
                  >
                    {loading ? "Loading..." : "Validar Código"}
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={step === 2 ? "password-code" : "hidden"}>
            {codeValidated && (
              <>
                <div className="inputBox relative mt-8 w-[300px]">
                  <input
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    required
                    className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                  />
                  <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                    Nova Senha
                  </span>
                  <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
                </div>
                <div className="mt-8">
                  <button
                    className="bg-blue-1001 text-gray-500 w-full py-2 rounded-lg cursor-pointer outline-none border-none hover:ring focus:ring"
                    onClick={handleResetPassword}
                  >
                    {loading ? "Loading..." : "Redefinir Senha"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
