import React, { useState, useEffect } from "react";
import Price from "@assets/Table/Price.svg";
import EA from "@assets/Table/ea.webp";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import { CreatePolice } from "./create-police";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

type Props = {};

export interface Smurf {
  id: string;
  bluesessence: string;
  server: string;
  isRandom: boolean;
  random: number;
  epic: number;
  legendary: number;
  ultimate: number;
  mythic: number;
  created_at: string;
  updated_at: string;
}

const SmurfPrice = (props: Props) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [smurfsData, setSmurfsData] = useState<Smurf>();
  const [priceSmurf, setPriceSmurf] = useState<Smurf[]>([]);
  const [isExpandedEditar, setIsExpandedEditar] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedEdit, setIsExpandedEdit] = useState(false);

  const fetchPrices = async () => {
    try {
      const response = await api.get(`/prices-smurfs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPriceSmurf(response.data);
      if (response.data.length > 0) {
        setPriceSmurf(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlterarClickEditar = async () => {
    setIsExpandedEditar(!isExpandedEditar);
    if (!isExpandedEditar) {
      try {
        await fetchPrices();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAlterarClick = async () => {
    setIsExpanded(!isExpanded);
  };

  const handleEditPolice = async (data: Smurf) => {
    setIsExpandedEdit(!isExpandedEdit);
    setSmurfsData(data);
  };

  return (
    <>
      <Card className="h-full w-full mt-4 p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">
                Smurfs
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
              {t("tools.smurf")}
              </Typography>
            </div>
          </div>
        </CardHeader>

        <CardBody className="px-0">
          <div className="bg-blue-20 p-3 border border-secondary-500 rounded-lg mt-4">
            <div className="flex items-center justify-between">
              <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">
                {t("tools.createpolicy")}
              </h2>
              <button
                className="bg-blue-1001 hover:bg-blue-1001/20 px-4 py-3 border border-secondary-500 rounded-md text-gray-500 font-semibold text-sm"
                onClick={handleAlterarClick}
              >
                {t("tools.alter")}
              </button>
            </div>

            {isExpanded && <CreatePolice operation={"create"} />}
          </div>

          <div className="bg-blue-20 p-3 border border-secondary-500 rounded-lg mt-4">
            <div className="flex items-center justify-between">
              <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">
                {t("tools.editpolicy")}
              </h2>
              <button
                className="bg-blue-1001 hover:bg-blue-1001/20 px-4 py-3 border border-secondary-500 rounded-md text-gray-500 font-semibold text-sm"
                onClick={handleAlterarClickEditar}
              >
                {t("tools.alter")}
              </button>
            </div>

            {isExpandedEditar && (
              <div className="mt-4">
                {isExpandedEdit && (
                  <CreatePolice operation="edit" smurf={smurfsData} />
                )}

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {priceSmurf.map((policy, index) => (
                    <div
                      key={index}
                      className="card-price bg-blue-50 p-4 rounded-lg"
                    >
                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Bluesessence:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>{policy.bluesessence}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Servidor:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>{policy.server}</span>
                        </div>
                      </div>

                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Aleatória:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>R$ {policy.random}</span>
                        </div>
                      </div>

                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Épica:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>R$ {policy.epic}</span>
                        </div>
                      </div>

                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Lendária:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>R$ {policy.legendary}</span>
                        </div>
                      </div>

                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Mítica:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>R$ {policy.mythic}</span>
                        </div>
                      </div>

                      <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                          <h3 className="text-gray-100 text-base font-semibold mb-2">
                            Ultimate:
                          </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <span>R$ {policy.ultimate}</span>
                        </div>
                      </div>

                      <button
                        className="bg-blue-1001 hover:bg-blue-1001/20 px-4 py-3 border border-secondary-500 rounded-md text-gray-500 font-semibold text-sm"
                        onClick={() => handleEditPolice(policy)}
                      >
                        Editar
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SmurfPrice;
