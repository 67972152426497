import React, { useEffect, useState } from "react";
import api from "@services/client/api";
import BR from "@assets/Table/BR.png";
import EUW from "@assets/Table/EUW.png";
import NA from "@assets/Table/NA.png";
import { Tooltip } from "@material-tailwind/react";

interface StockProps {
  onServerClick: (server: string) => void;
}


export type RarityCount = {
  [key: string]: number;
};

export type BlueEssenceRange = {
  total: number;
  rarityCount: RarityCount;
};

export type BlueEssenceRanges = {
  '40-69k': BlueEssenceRange;
  '70-99k': BlueEssenceRange;
  '100-149k': BlueEssenceRange;
  '150-230k': BlueEssenceRange;
};

export type StockItem = {
  name: string;
  quantity: number;
  bluessence: BlueEssenceRanges;
};

const images: { [key: string]: string } = {
  BR: BR,
  EUW: EUW,
  LAS: BR,
  NA: NA,
};

export const Stock: React.FC<StockProps> = ({ onServerClick }) => {
  const [stock, setStock] = useState<StockItem[]>([]);
  const [selectedServer, setSelectedServer] = useState<string | null>('BR');

  const handleServerClick = (server: string) => {
    setSelectedServer(server);
  };

  const getRarityColor = (rarity: string): string => {
    switch (rarity.toLowerCase()) {
      case 'épica':
        return 'text-primary-500';
      case 'lendária':
        return 'text-amber-500';
      case 'mítica':
          return 'text-rose-600'; 
      case 'ultimate':
            return 'text-emerald-400';
      case 'padrão':
          return 'text-slate-400'; 
      case 'desconhecida':
          return 'text-gray-500';
        default:
          return 'text-gray-500'; 
      }
  };

  useEffect(() => {
    const loadStock = async () => {
      const response = await api.get<StockItem[]>(`/account-smurfs/stock-admin`);
      setStock(response.data);
    };

    loadStock();
  }, []);


  return (
    <div className="mt-4 sm:mt-0 grid xs:flex items-center gap-4">
      {stock.map((item) => (
       <Tooltip
       key={item.name}
       content={
         <div className="min-w-xs p-8">
           <div className="flex justify-between">
             {Object.entries(item.bluessence).slice(0, 2).map(([range, data]) => (
               <div key={range} className="mr-4">
                 <strong>{range} - ({data.total}):</strong>
                 <ul>
                   {Object.entries(data.rarityCount).map(([rarity, count]) => (
                     <li key={rarity} className={`${getRarityColor(rarity)}`}>
                       {rarity}: {count}
                     </li>
                   ))}
                 </ul>
               </div>
             ))}
           </div>
           <div className="flex justify-between mt-4">
             {Object.entries(item.bluessence).slice(2).map(([range, data]) => (
               <div key={range} className="mr-4">
                 <strong>{range} - ({data.total}):</strong>
                 <ul>
                   {Object.entries(data.rarityCount).map(([rarity, count]) => (
                     <li key={rarity} className={`${getRarityColor(rarity)}`}>
                       {rarity}: {count}
                     </li>
                   ))}
                 </ul>
               </div>
             ))}
           </div>
         </div>
       }
     >
         <div
            key={item.name}
            className={`bg-blue-20 flex items-center gap-2 text-white text-sm font-semibold border-2 ${
              selectedServer === item.name ? 'border-primary-500' : 'border-secondary-500'
            } px-3 py-1 rounded-full cursor-pointer`}
            onClick={() => {
              handleServerClick(item.name);
              onServerClick(item.name); 
            }}
          >
            <img src={images[item.name]} alt={item.name} />
            {item.name}: {item.quantity}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
