import React, { useState, useEffect, useMemo } from "react";
import Client from "@assets/Table/Client.svg";
import Add from "@assets/Table/Add.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import Search from "@assets/Table/Search.svg";
import Delete from "@assets/Table/Delete.svg";
import PopupNewBooster from "./PopupNewBooster";
import api from "@services/client/api";
import Loading from "@pages/loading";
import { useAuth } from "@context/AuthContext";

import PopupInfoBooster from "./PopupInfoBooster";

import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import PopupDeleteBooster from "./PopupDeleteBooster";
import { useWebSocketContext } from "@hooks/WebSocketContext";

import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

type Props = {};

interface TableData {
  id: string
  user_id: string
  champion_id: string
  champion_name: string
  cpf: string
  accumulated_amount_received: string
  gain_percentage: string
  booster_level: string
  payment_key: string
  games: string
  services_quantity: number
  created_at: string
  updated_at: string
  champion: {
    id: string
    name: string
    nameAvatar: string
    game: string
    image: string
    available: boolean
    created_at: string
    updated_at: string
  }
  user: {
    id: string
    name: string
    email: string
    nick_user: any
    address: string
    city: string
    state: string
    cep: string
    telephone: string
    discord: string
    type_permission: string
    avatar: string
    banner: any
    is_historic: boolean
    created_at: string
    updated_at: string
    subscribed: boolean
    subscription_id: any
    remaining_roulettes: any
    last_roulette_date: any
    isPaid: boolean
  }
  online: boolean
}

const TableBoosters = (props: Props) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableDataBoosters, setTableDataBoosters] = useState<TableData[]>([]);
  const [filteredBoosters, setFilteredBoosters] = useState<TableData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { token } = useAuth();
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [selectedBooster, setSelectedBooster] = useState("");
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [idInfoAccountDelete, setIdInfoAccountDelete] = useState("");
  const { websocket } = useWebSocketContext();

  const [isHovered, setIsHovered] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const handleButtonClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchBoostersData = async () => {
      try {
        const response = await api.get("/boosters", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data: TableData[] = response.data;
        setTableDataBoosters(data);
      } catch (error) {
        console.error("Error fetching boosters data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBoostersData().then(() => {
    });
  }, []);

  useEffect(() => {
    const updatedTableData = tableDataBoosters.map((booster) => {
      const isOnline = websocket.boosters.some(
        (socketBooster) => socketBooster.id === booster.user_id
      );

      return {
        ...booster,
        online: isOnline,
      };
    });

    setTableDataBoosters(updatedTableData);
  }, [websocket.boosters]);

  const filteredData = useMemo(() => {
    return tableDataBoosters.filter((data) => {
      const id = data.id ? data.id.toLowerCase() : "";
      const name = data.user.name ? data.user.name.toLowerCase() : "";
      const paymentKey = data.payment_key ? data.payment_key.toLowerCase() : "";

      return (
        id.includes(searchQuery.toLowerCase()) ||
        name.includes(searchQuery.toLowerCase()) ||
        paymentKey.includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, tableDataBoosters]);

  useEffect(() => {
    setFilteredBoosters(filteredData);
  }, [filteredData]);

  if (loading) {
    return <Loading />;
  }

  const handleButtonClickInfo = (booster_id: string) => {
    setSelectedBooster(booster_id);
    setShowPopupInfo((prevState) => !prevState);
  };

  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const handleButtonDelete = (id: string) => {
    setIdInfoAccountDelete(id);
    setShowPopupDelete(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };


  const indexOfLastBooster = currentPage * perPage;
  const indexOfFirstBooster = indexOfLastBooster - perPage;
  const currentBoosterData = searchQuery
    ? filteredBoosters.slice(indexOfFirstBooster, indexOfLastBooster)
    : tableDataBoosters.slice(indexOfFirstBooster, indexOfLastBooster);


  return (
    <>
      <Card className="h-full w-full mt-4 p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">
                Boosters
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
                {t('admin.titleboosters')} 
              </Typography>
            </div>
            <div className="flex flex-row w-full shrink-0 gap-2 md:w-max">
              <div className="relative bg-blue-20 rounded-md">
                <input
                  type="text"
                  className="bg-blue-20 rounded-full py-3 px-10 outline-none text-gray-500 text-sm font-semibold ml-2"
                  placeholder={t('admin.search')} 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />


                <MagnifyingGlassIcon className="h-5 w-5 absolute top-3 left-4 select-none" />
              </div>
              <Button
                className="flex items-center gap-3 bg-primary-500"
                size="md"
                onClick={handleButtonClick}
              >
                <img src={Add} alt={Add} /> {t('admin.new')}  Booster
              </Button>
            </div>
          </div>
        </CardHeader>

        <CardBody className="px-0 overflow-auto bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    Booster
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    Status
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t('table.game')} 
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t('table.commission')} 
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    PIX
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t('table.accumulated')} 
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t('table.serviceTitle')} 
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t('table.action')} 
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentBoosterData.map((data) => (
                <tr
                  key={data.id}
                  className={`cursor-pointer transition-colors duration-1000 bg-transparent ${isHovered ? "hovered-row" : ""
                    }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-s-2xl">
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-3">
                        <img
                          src={data.user.avatar}
                          alt={data.user.name}
                          className="inline-block relative object-cover object-center w-9 h-9 rounded-md"
                          onError={(e) => {
                            e.currentTarget.src =
                              "https://www.adbensimoveis.com.br/wp-content/themes/netimoveis/public/img/no-avatar.png";
                          }}
                        />
                      </div>
                      {
                        data.user.name
                          .split(" ")
                          .filter((word) => word !== "")[0]
                      }{" "}
                      {data.user.name.split("").filter((word) => word !== "")
                        .length > 0 && "..."}
                    </div>
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    <div
                      className={`w-4 h-4 flex items-center rounded-full ${data.online
                        ? "bg-[#56BD79] transition-all duration-300"
                        : "bg-red-500"
                        }`}
                    ></div>
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    <div className="flex items-center gap-4">
                      {data.games
                        .split(",")
                        .map((game) => getGameIcon(game.trim()))}
                    </div>
                  </td>

                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.gain_percentage}%
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.payment_key}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.accumulated_amount_received !== undefined
                      ? parseFloat(data.accumulated_amount_received)
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ",")
                      : "N/A"}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.services_quantity}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-e-2xl">
                    <button
                      className="p-2"
                      onClick={() => handleButtonClickInfo(data.user.id)}
                    >
                      <img src={FileSearch} alt={FileSearch} />
                    </button>
                    <button
                      className="p-2"
                      onClick={() => {
                        handleButtonDelete(data.id);
                      }}
                    >
                      <img src={Delete} alt="Delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
          <Typography variant="small" color="white" className="font-normal text-blue-50 dark:text-gray-500">
            Página {currentPage} de {Math.ceil(tableDataBoosters.length / perPage)}

          </Typography>
          <div className="flex gap-2">
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-gray-600 fonte-semibold rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-primary-500 rounded-lg"
              onClick={handleNextPage}
              disabled={indexOfLastBooster >= tableDataBoosters.length}
            >
              Seguinte
            </Button>
          </div>
        </CardFooter>


      </Card>

      {showPopup && <PopupNewBooster />}
      {showPopupInfo && <PopupInfoBooster booster_id={selectedBooster} />}
      {showPopupDelete && (
        <PopupDeleteBooster
          boosterIdDelete={idInfoAccountDelete}
          showPopupDelete={showPopupDelete}
          setShowPopupDelete={setShowPopupDelete}
        />
      )}
    </>
  );
};

export default TableBoosters;
