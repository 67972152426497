import TableSmurfAdm from '@components/Dashboard/mainDashboard/Adm/(Smurf)'

type Props = {}

const SmurfAdm = (props: Props) => {
  return (
    <>
      <TableSmurfAdm />
    </>
  )
}

export default SmurfAdm