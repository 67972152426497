import React from 'react';
import { useTranslation } from "react-i18next";

interface Tab {
    title: string;
    status: string;
}

interface TabSelectorProps {
    tabs: Tab[]; 
    currentTab: number;
    handleTabChange: (index: number) => void;
}

const TabSelector: React.FC<TabSelectorProps> = ({ tabs, currentTab, handleTabChange }) => {
    return (
        <div className="flex items-center justify-between py-4">
            <div className="w-full grid sm:flex items-center gap-8">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={`tab-button ${currentTab === index
                            ? "bg-blue-1001 text-white"
                            : "bg-blue-20/40 text-white"
                            } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
                        onClick={() => handleTabChange(index)}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TabSelector;
