import React, { useEffect, useRef, useState } from "react";
import { Collapse, Card, CardBody, Typography, Tooltip } from "@material-tailwind/react";
import { useCheckout } from "@context/CheckoutContextProvider";
import trash from '@assets/checkout/Trash.svg'
import discount from '@assets/checkout/discount.svg'
import CurrencyDisplay, { currencies } from "@hooks/Currency";
import { Clock } from 'react-feather';
import moment from "moment";
import 'moment/locale/pt-br';
import { useAuth } from "@context/AuthContext";

const OrderDetails: React.FC = () => {
  const { checkout, setCheckout, removeCheckoutItem } = useCheckout();
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth()

  const toggleOpen = (index: number) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const maxContentHeight = screenHeight * 0.5;
    setMaxHeight(maxContentHeight);
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      contentRef.current.style.overflowY =
        contentHeight > maxHeight ? "auto" : "hidden";
    }
  }, [maxHeight]);


  useEffect(() => {
    if (!checkout?.id) return;
    if (!user) return;

    const url = `${process.env.REACT_APP_API_URL}/checkout-item/${checkout.id}/${user?.id}`;
    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data) {
          setCheckout(prevCheckout => ({
            ...prevCheckout!,
            checkoutItems: data
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };

    eventSource.onerror = (err) => {
      eventSource.close();
      console.error('Erro de conexão:', err);
    };

    return () => {
      eventSource.close();
    };
  }, [checkout?.id]);

  const getColorByExpiration = (expiresAt: Date, status: string) => {
    const now = moment();
    const expiration = moment(expiresAt);
    const duration = moment.duration(expiration.diff(now));
    const relativeTime = moment(expiresAt).locale('pt-br').fromNow();

    if (status === 'expired') {
      return { color: 'rgb(220 38 38)', text: `Item expirado.` };
    } else if (duration.asMinutes() <= 2) {
      return { color: 'rgb(245 158 11)', text: `O item expira em breve.` };
    }     
    else if (duration.asHours() <= 2) {
      return { color: 'rgb(245 158 11)', text: `Expira ${relativeTime}` };
    } else if (duration.asHours() <= 3) {
      return { color: 'rgb(79 70 229)', text: `Expira ${relativeTime}` };
    } else {
      return { color: 'gray', text: `Expira ${relativeTime}` };
    }
  };


  return (
    <div className="flex flex-col w-full gap-3 mt-2 px-4">
      {checkout?.checkoutItems.map((item, index) => (
        <div
          key={index}
          className={`bg-blue-20 md:p-4 rounded-md flex flex-col gap-2 sm:py-6`}
        >
        <div className="w-full inline-block">
            {item && item.status === "expired" ? (
              <span className="bg-red-600 text-white py-1 px-2 text-sm font-poppins font-semibold rounded-xl">
                Item expirado
              </span>
            ) : null}
            {item && item.status === "unavailable" ? (
              <span className="bg-blue-600 text-white py-1 px-2 text-sm font-poppins font-semibold rounded-xl">
                Item indisponível
              </span>
            ) : null}
          </div>

          <div
            className={`flex gap-5 justify-between w-full ${item && item.status !== "available" ? "opacity-40" : "opacity-100"
              }`}
          >
            <div className="flex items-center gap-6">
              {item && item.image ? (
                <div className="flex items-center justify-center rounded-md w-[80px] h-[80px] bg-[#1E2242]">
                  <img
                    src={item.image}
                    alt="Imagem do jogo"
                    width={40}
                    height={40}
                    className="rounded-md object-cover object-center"
                  />
                </div>
              ) : null}
              <div className="flex flex-col justify-center">
                <div className="text-base font-bold tracking-wider text-indigo-600">
                  {item &&
                    item.tags &&
                    item.tags[0] &&
                    item.tags[0].charAt(0).toUpperCase() +
                      item.tags[0].slice(1)}
                </div>
                <div className="mt-2.5 flex items-center justify-center flex-row w-full gap-1.5">
                  <div className="text-xl font-semibold text-gray-500 whitespace-nowrap">
                    {item && item.name}
                  </div>
                  {item && item.expires_at && (
                    <div className="flex items-center gap-1">
                      <Tooltip
                        content={getColorByExpiration(item.expires_at, item.status).text || ''}
                        placement="top"
                        className={` bg-blue-50 bg-opacity-20 backdrop-blur-sm border-[2px] px-2 py-1.5 border-indigo-600`}
                      >
                        <Clock
                          size={18}
                          strokeWidth={2.5}
                          color={getColorByExpiration(item.expires_at, item.status).color || ''}
                          className="cursor-pointer"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="text-base text-gray-500 text-opacity-50">
                  {item.subtitle}
                </div>
              </div>
            </div>
            <div className="flex gap-4 items-start text-xs font-medium text-gray-500 text-opacity-50">
              <div className="font-poppins font-semibold text-xs leading-4 text-gray-500 text-opacity-50 flex-none order-0 flex-grow-0">
                <CurrencyDisplay price={Number(item.price)} reference={'BRL'} destination={checkout?.currency as keyof typeof currencies || 'BRL'} convert={false} />
              </div>
              <svg
                className="cursor-pointer"
                onClick={() => toggleOpen(index)}
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: openItems.includes(index)
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              >
                <path
                  d="M3.75 7.24561L8.29289 11.7885C8.68342 12.179 9.31658 12.179 9.70711 11.7885L14.25 7.2456"
                  stroke="#D5D8DC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <button
                className="select-none"
                onClick={() => removeCheckoutItem(item && item)}
              >
                <img
                  src={trash}
                  alt={"Trash"}
                  width={15}
                  height={15}
                />
              </button>
            </div>
          </div>
          <Collapse open={openItems.includes(index)}>
            <Card className="bg-tranparent flex justify-start w-full">
              <CardBody className="m-0 p-0 text-gray-500">
                <div className="text-gray-100 flex-none order-1 text-md self-stretch flex-grow-0">
                  {item.description}
                </div>
              </CardBody>
            </Card>
          </Collapse>
          {item.coupon ? (
            <div className="flex items-center justify-start gap-2">
              <img
                src={discount}
                alt={"Desconto"}
                width={18}
                height={18}
              />
              <span className="text-sm text-[#19E783] font-poppins">
                Desconto aplicado
              </span>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default OrderDetails;
