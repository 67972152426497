import React, { useState, useEffect } from "react";
import IconPedido from "@assets/Table/totalpedido.svg";
import IconCupom from "@assets/Table/totalcupom.svg";
import IconVendido from "@assets/Table/totalvendido.svg";
import IconClient from "@assets/Table/totalcliente.svg";
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Info = {
  icon: string;
  title: string;
  data: number;
  grow: number;
  month: string;
  totalFaturado: number;
};

type Total = {
  orders: { quantity: number; growthPercentage: number };
  smurfs: { quantity: number; growthPercentage: number };
  coupons: { quantity: number; growthPercentage: number };
  sellings: { quantity: number; growthPercentage: number };
  clients: { quantity: number; growthPercentage: number };
};

const StatisticsByGame = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [total, setTotal] = useState<Total | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const loadResults = async () => {
    try {
      const response = await api.get("/results/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotal(response.data.total);
    } catch (error: any) {
      toast.warn(error.response?.data?.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadResults();
  }, []);

  const infos: Info[] = [
    {
      icon: IconClient,
      title: t("results.clienttotal"),
      data: total?.clients.quantity || 0,
      grow: total?.clients.growthPercentage || 0,
      month: "Junho",
      totalFaturado: 0,
    },
    {
      icon: IconCupom,
      title: t("results.coupontotal"),
      data: total?.coupons.quantity || 0,
      grow: total?.coupons.growthPercentage || 0,
      month: "Junho",
      totalFaturado: 0,
    },
    {
      icon: IconPedido,
      title: t("results.totalorders"),
      data: total?.orders.quantity || 0,
      grow: total?.orders.growthPercentage || 0,
      month: "Junho",
      totalFaturado: 0,
    },
    {
      icon: IconVendido,
      title: t("results.totalsold"),
      data: total?.sellings.quantity || 0,
      grow: total?.sellings.growthPercentage || 0,
      month: "Junho",
      totalFaturado: 0,
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {infos.map((info) => (
          <div
            key={info.title}
            className="transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 py-6 border border-secondary-500"
          >
            <div className="flex items-center justify-between gap-2">
              <h2 className="text-gray-100 text-sm font-semibold">
                {info.title}
              </h2>
              <img src={info.icon} alt={info.title} />
            </div>
            <div className="flex justify-between items-center gap-4 mt-4">
              <h2 className="text-gray-100 dark:text-gray-500 text-xl font-semibold">
                {info.data}
              </h2>


            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticsByGame;