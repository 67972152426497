import api from '@services/client/api';
import ResumeBooster from '@components/Dashboard/Booster/resumeBooster';
import { useAuth } from '@context/AuthContext';
import AvailableBooster from '@components/Dashboard/Booster/(tables)/available';
import InProgressBooster from '@components/Dashboard/Booster/(tables)/in-progress';
import FinalizedBooster from '@components/Dashboard/Booster/(tables)/finalized';

const Booster = () => {
  const { user, token, signOut } = useAuth();

  return (
    <>
      {user && token && <ResumeBooster user={user} token={token} />}
      {user && token && <AvailableBooster token={token}/>}
      {user && token && <InProgressBooster token={token}/>}
       {user && token && <FinalizedBooster token={token}/>}
    </>
  );
};

export default Booster;