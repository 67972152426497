import React, { useState, useEffect } from 'react';
import api from '@services/client/api';
import { useAuth } from '@context/AuthContext';
import CustomInput from '@components/custom/input';
import { Send } from 'react-feather';

interface WebhookConfig {
    url_awards: string;
    url_notification_adm: string;
}

const WebhookCard: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [webhookConfig, setWebhookConfig] = useState<WebhookConfig | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const { token } = useAuth();

    useEffect(() => {
        const fetchConfig = async () => {
            setLoading(true);
            try {
                const response = await api.get('/roulette/config/props/webhook', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setWebhookConfig(response.data.webhook);
            } catch (err) {
                setError('Erro ao buscar status da roleta.');
            } finally {
                setLoading(false);
            }
        };

        fetchConfig();
    }, [token]);

    const handleUpdate = async () => {
        if (!webhookConfig) return;

        setIsUpdating(true);
        try {
            await api.post('/roulette/config/props/webhook',
                {
                    value: webhookConfig,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSuccessMessage('Atualizado com sucesso!');
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
        } catch (err) {
            console.error('Erro ao atualizar configurações do webhook:', err);
        } finally {
            setIsUpdating(false);
        }
    };


    const handleSend = async () => {
        if (!webhookConfig) return;

        try {
            await api.put(`/roulette/awards/fake/d55c5044a47ef441ebf57d52afff6c99355eb5de38c25f0ba9c944ef669e7f3d`);
            setSuccessMessage('Webhook enviado com sucesso!');
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
        } catch (err) {
            console.error('Erro ao enviar webhook:', err);
        }
    };


    if (loading) return <div className="text-gray-400">Carregando...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    const handleInputChange = (name: keyof WebhookConfig, value: string) => {
        setWebhookConfig(prev => prev ? { ...prev, [name]: value } : null);
    };

    return (
        <div className="px-6 py-8 w-1/3 h-2/4 bg-blue-500 rounded-md shadow-md space-y-4">
            <h2 className="w-full text-xl font-semibold text-white">Configurações de Webhooks</h2>
            <div className="flex flex-col space-y-4 w-full">
            <div className="flex items-end justify-center w-full gap-2">
                   <div className='w-full '>
                   <CustomInput
                        index={0}
                        label="Premiações"
                        name="url_awards"
                        type="text"
                        placeholder="https://webhook.com"
                        value={webhookConfig?.url_awards ?? ''}
                        onChange={(index, name, value, state) => handleInputChange('url_awards', value)}
                        formatValue={(value) => value}
                        formatType="onChange"
                    />
                   </div>
                    <button
                        onClick={handleSend}
                        className="bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition-colors duration-300"
                        aria-label="Enviar"
                    >
                        <Send className="w-6 h-6" />
                    </button>
                </div>

                <div>
                    <CustomInput
                        index={1}
                        label="Notificações internas"
                        name="url_notification_adm"
                        type="text"
                        placeholder="https://webhook.com"
                        value={webhookConfig?.url_notification_adm ?? ''}
                        onChange={(index, name, value, state) => handleInputChange('url_notification_adm', value)}
                        formatValue={(value) => value}
                        formatType="onChange"
                    />
                </div>
            </div>
            <button
                onClick={handleUpdate}
                disabled={isUpdating}
                className="bg-indigo-600 text-white p-2 w-full rounded hover:bg-indigo-700 disabled:opacity-50 transition-colors duration-300"
            >
                {isUpdating ? 'Atualizando...' : successMessage || 'Atualizar'}
            </button>
        </div>
    );
};

export default WebhookCard;
