import React, { useEffect, useState, useTransition } from "react";
import Feedback from "@assets/Table/Feedback.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import CopyId from "@assets/Table/Copy.svg";
import Delete from "@assets/Table/Delete.svg";
import Star from "@assets/Table/Star.svg";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import Spin from "@assets/Spin.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import PopupInfoFeedback from "./PopupInfoFeedback";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { ServiceRequests } from "@utils/types";
import Reload from "@assets/Table/Reload.svg";
import Loading from "@pages/loading";
import { useTranslation } from "react-i18next";

type Props = {};

interface TableData {
  id: string;
  jogos: string[];
  cliente: string;
  booster: string;
  avaliacao: number;
}

const TableFeedback = (props: Props) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [orderId, setOrderId] = useState("");

  const [loading, setLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState<ServiceRequests[]>([]);

  const getFeedbacks = async () => {
    try {
      const response = await api.patch(
        `/requests/feedback/1e2t61i751b65e4b319y2afeb2039018`,
        {
          toSecure: "66dthyt6grgrtfdsfsf",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data } = response;
      setFeedbacks(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err?.response?.data?.message);
        return;
      }

      toast.error(
        `Houve uma falha na recuperação dos dados do banco de dados. Agradecemos sua paciência enquanto resolvemos o problema.`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  const handleButtonClick = (orderId: string) => {
    setOrderId(orderId);
    setShowPopup((prevState) => !prevState);
  };

  const handleReload = () => {
    setLoading(true);
    getFeedbacks();
  };

  const handleCopyOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const image = event.target as HTMLImageElement;
    const dataId = image.getAttribute("data-id");
    if (!dataId) return;

    navigator.clipboard.writeText(dataId);
    toast.info(`Copiado para a área de transferência.`);
  };

  const tableDataFeedback: TableData[] = feedbacks.map((data) => ({
    id: data.id,
    jogos: [data.game_service],
    cliente: data.client.name,
    booster: data.booster ? data.booster.champion_name : "", // Check if booster is defined
    avaliacao: data.feedback_points ?? 1,
  }));

  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const renderAvaliacao = (avaliacao: number | undefined) => {
    const estrelas = [];
    if (!avaliacao) return;

    for (let i = 0; i < avaliacao; i++) {
      estrelas.push(
        <img key={i} src={Star} alt="Estrela Ativa" className="w-4 h-4" />
      );
    }
    return (
      <div className="flex justify-center items-center gap-1">{estrelas}</div>
    );
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500">
      <div className="grid sm:flex items-center justify-between gap-4 sm:gap-0">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Feedback} alt={Feedback} />
          Feedbacks
          <div className="bg-blue-20 rounded-full p-3 min-w-[50px] text-center">
            <p className="text-gray-500 font-semibold text-base">
              {tableDataFeedback.length}
            </p>
          </div>
        </h2>
        <h3 className="text-gray-500 font-semibold text-sm">
          {t("admin.feedbacks")}
          <span className="text-gray-100">
            {tableDataFeedback.filter((item) => item.avaliacao === 5).length}
          </span>
        </h3>
        <button
          className="bg-blue-50 flex items-center justify-center rounded-full p-3"
          onClick={handleReload}
        >
          <img
            className={`w-[24px] h-[24px] ${
              loading === true ? "animate-spin" : ""
            }`}
            src={loading === true ? Spin : Reload}
            alt=""
          />
        </button>
      </div>
      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto h-screen">
          {loading === true ? (
            <Loading />
          ) : (
            <>
              {tableDataFeedback.length === 0 ? (
                <div className="w-full min-h-[550px] flex justify-center items-center">
                  <h1 className="font-bold text-xl">
                    Não há nenhum feedback {":("}
                  </h1>
                </div>
              ) : (
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                        ID
                      </th>
                      <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                        {t("table.game")}
                      </th>
                      <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                        {t("table.client")}
                      </th>
                      <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                        Booster
                      </th>
                      <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                        {t("table.star")}
                      </th>
                      <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                        {t("table.action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableDataFeedback.map((data) => (
                      <tr
                        key={data.id}
                        className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                          isHovered ? "hovered-row" : ""
                        }`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                          <div className="flex items-center justify-center ">
                            {data.id.substring(0, 8)}
                          </div>
                        </td>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                          {data.jogos.map((jogo) => (
                            <span
                              key={jogo}
                              className="flex items-center justify-center"
                            >
                              {getGameIcon(jogo)}
                            </span>
                          ))}
                        </td>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                          {data.cliente}
                        </td>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                          {data.booster}
                        </td>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                          {renderAvaliacao(data.avaliacao)}
                        </td>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                          <div className="flex items-center justify-center">
                            <button className="p-2" onClick={handleCopyOnClick}>
                              <img
                                src={CopyId}
                                alt={CopyId}
                                data-id={data.id}
                              />
                            </button>
                            <button
                              className="p-2"
                              onClick={() => handleButtonClick(data.id)}
                            >
                              <img src={FileSearch} alt={FileSearch} />
                            </button>
                            <button className="p-2">
                              <img src={Delete} alt={Delete} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
      {showPopup && <PopupInfoFeedback orderId={orderId} />}
    </div>
  );
};

export default TableFeedback;
