import { useCheckout } from '@context/CheckoutContextProvider';
import cart from '@assets/checkout/cart.svg'
import { useEffect } from 'react';
import { useAuth } from '@context/AuthContext';

const CheckoutCart = () => {
    const { checkout, openCheckout, setOpenCheckout } = useCheckout();
    let doc = document.getElementById("tidio-chat-iframe");
    const { user } = useAuth()
         
    const handleCloseModal: () => void = () => {
        if (doc && !openCheckout === false){
          doc.style.transform = 'translateX(0%)';
        }

        setOpenCheckout(!openCheckout);
    };


    return (
        <div className="relative flex items-center gap-4">
            <button onClick={() => handleCloseModal()} type="button" className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white  rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <img src={cart} alt="Cart" width={20} height={20} className="h-5 w-5" />
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">{checkout && checkout.checkoutItems ? checkout.checkoutItems.length : '0'}</div>
            </button>
        </div>
    )
}

export default CheckoutCart;