import ResumeClient from '@components/Dashboard/Client/resumeClient';
import PopupTuor from '@components/Tuor';
import { useAuth } from '@context/AuthContext';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import AvailableClient from '@components/Dashboard/Client/(tables)/current';
import FinalizedClient from '@components/Dashboard/Client/(tables)/finalized';

type Props = {};

type Payment = {
  id: string;
  client_id: string;
  checkout_id: string;
  payment_status: string;
  payment_method: string;
  service_id?: string;
  product_id?: string;
  created_at: Date;
};

const Client = (props: Props) => {
  const { user, token, signOut } = useAuth();
  const [showPopupTour, setShowPopupTour] = useState(false);

  useEffect(() => {
    const tutorialCookie = Cookies.get('tutorial');
    if (!tutorialCookie) {
      setShowPopupTour(true);
      Cookies.set('tutorial', 'true', { expires: 365 });
    }
  }, []);

  return (
    <>
      {user && token && <ResumeClient user={user} token={token} />}
      {user && token && <AvailableClient token={token}/>}
      {user && token && <FinalizedClient token={token}/>}
      {showPopupTour && user && token && <PopupTuor user={user} token={token} />}
    </>
  );
};

export default Client;