import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { AlertCustomStyles } from "@components/Alert";

type AlertStatus = "success" | "error";

type Alert = {
  id: number;
  status: AlertStatus;
  message: string;
  closeTime: number;
};

type AlertContextType = {
  currentAlert: Alert | null;
  addAlert: (status: AlertStatus, message: string, closeTime: number) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentAlert, setCurrentAlert] = useState<Alert | null>(null);
  const [idCounter, setIdCounter] = useState<number>(1);

  const addAlert = (status: AlertStatus, message: string, closeTime: number) => {
    const newAlert: Alert = {
      id: idCounter,
      status,
      message,
      closeTime,
    };

    setCurrentAlert(newAlert);
    setIdCounter(idCounter + 1);

    setTimeout(() => {
      setCurrentAlert(null);
    }, closeTime);
  };

  const contextValue: AlertContextType = {
    currentAlert,
    addAlert,
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {currentAlert && (
        <div className={`alert-container fixed top-[87%] left-[43%] z-[999999] flex flex-col gap-2 justify-center items-center`}>
          <AlertCustomStyles
            key={currentAlert.id}
            status={currentAlert.status}
            message={currentAlert.message}
            closeTime={currentAlert.closeTime}
          />
        </div>
      )}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error("useAlertContext deve ser usado dentro de um AlertProvider");
  }

  return context;
};
