import React, { useEffect, useState } from "react";
import Request from "@assets/Table/Check.svg";
import TabSelector from "./table-selector";
import DataTable from "./generic-table";
import Going from "@assets/Table/Going.svg";
import PopupInfoFinished from "./PopupInfoFinished";
import PopupPendingPayment from "./PopupPendingPayment";
import PopupFinalized from "./PopupFinalized";
import { useTranslation } from "react-i18next";
import { IRequestResponse } from "types/services/IRequestResponse";
import api from "@services/client/api";

interface RequestFinishedAdmProps {
  token: string;
}

const TABLE_HEADS = {
  available: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Server", type: "text" as const, key: "server.value" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  booster_in_progress: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  booster_finished: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  approved: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  completed: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  total_refund: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Phone", type: "text" as const, key: "client.telephone" },
    { label: "Discord", type: "text" as const, key: "client.discord" },
    { label: "Actions", type: "action" as const },
  ],
};

type TableHeadsKey = keyof typeof TABLE_HEADS;

const RequestFinishedAdm: React.FC<RequestFinishedAdmProps> = ({ token }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const [data, setData] = useState<IRequestResponse[]>([]);
  const [cache, setCache] = useState<Record<string, IRequestResponse[]>>({});

  const tabs = [
    { title: t("admin.inqueue"), status: "available", head: TABLE_HEADS.available },
    { title: t("table.currentTitle"), status: "booster_in_progress", head: TABLE_HEADS.booster_in_progress },
    { title: t("admin.titleapproval"), status: "booster_finished", head: TABLE_HEADS.booster_finished },
    { title: t("admin.titlepayment"), status: "approved", head: TABLE_HEADS.approved },
    { title: t("table.finalized"), status: "completed", head: TABLE_HEADS.completed },
    { title: t("admin.titlerefunded"), status: "total_refund", head: TABLE_HEADS.total_refund },
  ];

  const fetchData = async (status: string) => {
    if (cache[status]) {
      setData(cache[status]);
      return;
    }

    try {
      const response = await api.get(`/order/status/${status}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setCache((prev) => ({ ...prev, [status]: response.data }));
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    fetchData(tabs[currentTab].status);
  }, [currentTab, token]);

  const handleTabChange = (index: number) => {
    setCurrentTab(index);
  };

  const handleActionClick = (row: { [key: string]: any }) => {
    console.log("Action clicked for:", row);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
        <img src={Request} alt={Request} />
        {t("admin.requests")}
      </h2>

      <div className="hidden sm:grid">
        <TabSelector
          tabs={tabs}
          currentTab={currentTab}
          handleTabChange={handleTabChange}
        />

        <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
          <DataTable
            head={tabs[currentTab].head}
            rows={data}
            recordsLimit={5}
            actions={(row) => (
              <>
                <button
                  className="p-2 bg-blue-700 rounded-md"
                  onClick={() => window.location.href = `/dashboard/chat/${row.id}`}
                  aria-label="Ir para o chat"
                >
                  <img src={Going} alt="Chat" width={18} />
                </button>
              </>
            )}
          />
        </div>
      </div>

      {/* Popups comentados, você pode descomentar se necessário */}
      {/* {showPopupInfo && (
        <PopupInfoFinished
          token={token}
          request_id={actionRequest}
          showPopup={showPopupInfo}
          setShowPopup={setShowPopupInfo}
        />
      )} */}
      {/* {showPopupPendingPayment && (
        <PopupPendingPayment
          token={token}
          infoService={getOrder(getPendingPaymentSelected)}
        />
      )} */}
      {/* {showPopupFinalized && (
        <PopupFinalized
          token={token}
          infoService={getOrder(getPendingPaymentSelected)}
        />
      )} */}
    </div>
  );
};

export default RequestFinishedAdm;
