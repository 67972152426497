import React, { useState, ReactNode } from 'react';
import { Pagination } from '../pagination';
import { IResponsePreferenceDTO } from '@components/Chat/types/IRequest';
import IconAndamento from "@assets/Table/IconAndamento.svg";
import { useTranslation } from 'react-i18next';


interface TableHead {
    label: string;
    type: 'text' | 'image' | 'booster' | 'money' | 'action' | 'optional' | 'preference' | 'status' | 'custom-id' | 'payment';
    key?: string;
}

interface TableProps {
    head: TableHead[];
    rows: {
        [key: string]: any;
    }[];
    actions?: (row: { [key: string]: any }) => ReactNode;
    onRowClick?: (row: { [key: string]: any }) => void;
    recordsLimit?: number;
}

const getValueFromKey = (obj: { [key: string]: any }, key: string) => {
    return key.split('.').reduce((o, k) => (o || {})[k], obj);
};

const DataTable: React.FC<TableProps> = ({ head, rows, actions, onRowClick, recordsLimit = 10 }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(rows.length / recordsLimit);
    const startIndex = (currentPage - 1) * recordsLimit;
    const endIndex = startIndex + recordsLimit;
    const currentRows = rows.slice(startIndex, endIndex);
    const [hoveredId, setHoveredId] = useState<string | null>(null);
    const [hoveredIdPreference, setHoveredIdPreference] = useState<string | null>(null);

    const { t } = useTranslation();


    const handleRowClick = (row: { [key: string]: any }) => {
        if (onRowClick) {
            onRowClick(row);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleMouseEnter = (id: string) => setHoveredId(id);
    const handleMouseLeave = () => setHoveredId(null);

    const handleMouseEnterPref = (id: string) => setHoveredIdPreference(id);
    const handleMouseLeavePref = () => setHoveredIdPreference(null);

    interface Champion {
        image: string;
        name: string;
    }

    const formatCellValue = (
        type: 'text' | 'image' | 'booster' | 'money' | 'action' | 'preference' | 'optional' | 'status' | 'custom-id' | 'payment',
        value: any,
        row: { [key: string]: any },
        actions?: (row: { [key: string]: any }) => ReactNode
    ) => {
        if (value == null && type != "action") {
            return "Desconhecido"; 
          }

        switch (type) {
            case 'text':
                return <span className='whitespace-nowrap'>{typeof value === 'string' ? value : JSON.stringify(value)}</span>;
            case 'text':
                if (value) {
                    return (
                      <div className="bg-[#56BD79] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.1253 17.6996C10.0653 17.6996 9.25531 16.7796 9.14531 15.5996H6.94531C7.06531 17.7896 8.70531 19.0196 10.6253 19.4296V21.5996H13.6253V19.4496C15.5753 19.0796 17.1253 17.9496 17.1253 15.8996C17.1253 13.0596 14.6953 12.0896 12.4253 11.4996C10.1553 10.9096 9.42531 10.2996 9.42531 9.34961C9.42531 8.25961 10.4353 7.49961 12.1253 7.49961C13.9053 7.49961 14.5653 8.34961 14.6253 9.59961H16.8353C16.7653 7.87961 15.7153 6.29961 13.6253 5.78961V3.59961H10.6253V5.75961C8.68531 6.17961 7.12531 7.43961 7.12531 9.36961C7.12531 11.6796 9.03531 12.8296 11.8253 13.4996C14.3253 14.0996 14.8253 14.9796 14.8253 15.9096C14.8253 16.5996 14.3353 17.6996 12.1253 17.6996Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                        <span className="text-gray-500 font-semibold">
                          {t("table.received")}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div className="bg-[#ffb13c] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.6255 7.49961C14.4055 7.49961 15.0655 8.34961 15.1255 9.59961H17.3355C17.2655 7.87961 16.2155 6.29961 14.1255 5.78961V3.59961H11.1255V5.75961C10.7355 5.83961 10.3755 5.96961 10.0255 6.11961L11.5355 7.62961C11.8555 7.54961 12.2255 7.49961 12.6255 7.49961ZM5.59555 4.51961L4.18555 5.92961L7.62555 9.36961C7.62555 11.4496 9.18555 12.5896 11.5355 13.2796L15.0455 16.7896C14.7055 17.2796 13.9955 17.6996 12.6255 17.6996C10.5655 17.6996 9.75555 16.7796 9.64555 15.5996H7.44555C7.56555 17.7896 9.20555 19.0196 11.1255 19.4296V21.5996H14.1255V19.4496C15.0855 19.2696 15.9555 18.8996 16.5855 18.3296L18.8055 20.5496L20.2155 19.1396L5.59555 4.51961Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                        <span className="text-gray-500 font-semibold">Pendente</span>
                      </div>
                    );
                  }
            case 'custom-id':
                return <span className="bg-blue-1001 text-gray-500 font-semibold rounded-full px-6 py-2 inline-flex flex-row justify-center items-center">
                    #{value}
                </span>
            case 'money':
                return (
                    <span className="text-gray-100 dark:text-gray-500 text-md">
                        <span className="text-primary-200 font-semibold">R$</span>
                        {Number(value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }).slice(2)}
                    </span>
                );
            case 'status':
                if (value === "booster_in_progress") {
                    return (
                        <div className="bg-blue-1001 rounded-full w-auto inline-flex text-gray-500 font-semibold justify-center items-center px-4 py-2 gap-2">
                            Em andamento
                            <img src={IconAndamento} alt="Andamento" />
                        </div>
                    );
                } else if (value === "booster_finished") {
                    return <span className="text-gray-500 font-semibold">Pendente</span>;
                }
                break;
            case 'booster':
                return (
                    <span className='transition-colors duration-1000 p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full'>
                        {typeof value === 'string' ? value : JSON.stringify(value)}
                    </span>
                );
            case 'image':
                return typeof value === 'string' ? (
                    <div className='flex items-center justify-center w-full'>
                        <img src={value} alt="table-image" className="bg-[#161A2D] rounded-md h-10 w-10 p-2 object-contain border border-gray-700" />
                    </div>
                ) : null;
            case 'preference':
                const remainingPreferences = value.length - 1;
                const ignoredTypes = ["lp_additional_required", "current_required", "desired_required"];

                const validPreferences = value.filter((preference: IResponsePreferenceDTO) =>
                    !ignoredTypes.includes(preference.type)
                );

                return (
                    <div className="flex flex-col items-center justify-center space-y-1">
                        <div
                            className="flex flex-col items-center justify-center space-y-1"
                            onMouseEnter={() => value && value.length > 0 && handleMouseEnterPref(row.id)}
                            onMouseLeave={handleMouseLeavePref}
                        >
                            {validPreferences.length > 0 ? (
                                <>
                                    <span className="font-semibold text-gray-100 dark:text-gray-500">{validPreferences[0].label}</span>
                                    <span className="text-gray-500 dark:text-gray-400">{validPreferences[0].option.label}</span>
                                </>
                            ) : (
                                <span className="text-gray-500 dark:text-gray-400">Nenhuma preferência disponível</span>
                            )}
                        </div>

                        {remainingPreferences > 0 && (
                            <span className="text-primary-200 text-sm mt-2 cursor-pointer">
                                +{remainingPreferences} remain...
                            </span>
                        )}

                        {validPreferences.length > 0 && hoveredIdPreference === row.id && (
                            <div
                                className="absolute z-[99999999] bg-blue-50 bg-opacity-80 backdrop-blur-md w-[200px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow-lg transition duration-300"
                                onMouseEnter={() => handleMouseEnterPref(row.id)}
                                onMouseLeave={handleMouseLeavePref}
                            >
                                {validPreferences.slice(1)
                                    .map((preference: IResponsePreferenceDTO, index: any) => {
                                        if (preference.type === 'selected_champions_required') {
                                            return (
                                                <div key={index} className="mt-2 flex flex-col items-center gap-2 justify-center">
                                                    <span className="font-semibold text-primary-200">{preference.label}</span>
                                                    <div className="grid grid-cols-4 gap-2 mt-1">
                                                        {Array.isArray(preference.option.value) && preference.option.value.map((champion: Champion, index: number) => (
                                                            <div key={index} className="w-8 h-8">
                                                                <img
                                                                    src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${champion.image}`}
                                                                    alt={champion.name}
                                                                    className="w-8 h-8 rounded-full object-cover"
                                                                />
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={index} className="mt-2 flex flex-col items-center gap-2 justify-center">
                                                <span className="font-semibold text-primary-200">{preference.label}</span>
                                                <span>{preference.option.label}</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                );

            case 'optional':
                return (
                    <div className="text-center flex items-center justify-center">
                        <div
                            className="flex items-center justify-center w-10 h-10 border-2 border-primary-500 rounded-full p-2 cursor-pointer "
                            onMouseEnter={() => value && value.length > 0 && handleMouseEnter(row.id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {value && value.length > 0 ? value.length : "0"}
                        </div>
                        {value && value.length > 0 && hoveredId === row.id && (
                            <div
                                className="absolute z-[9999] top-16 bg-blue-50 backdrop-blur-md opacity-1 w-[200px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                                onMouseEnter={() => handleMouseEnter(row.id)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {value.map((text: any, index: number) => (
                                    <h3
                                        key={index}
                                        className="text-gray-500 text-xs py-2 px-4 rounded-full"
                                    >
                                        {text.label.trim()}
                                    </h3>
                                ))}
                            </div>
                        )}
                    </div>
                );
            case 'action':
                return actions ? actions(row) : null;
            default:
                return null;
        }
    };

    return (
        <div className='flex w-full h-full flex-col gap-2 items-center justify-center'>
            <div className="flex w-full overflow-auto max-h-[570px]">
                <table className="table-auto w-full">
                    <thead>
                        <tr>
                            {head.map((column, index) => (
                                <th
                                    key={index}
                                    className="whitespace-nowrap transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2"
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.length === 0 ? (
                            <tr>
                                <td colSpan={head.length} className="text-center text-gray-500 py-4">
                                    Nenhum registro encontrado.
                                </td>
                            </tr>
                        ) : (
                            currentRows.map((data, index) => (
                                <tr
                                    key={index}
                                    className="cursor-pointer transition-transform duration-100 ease-in-out transform hover:-translate-y-1 hover:z-10"
                                    onClick={() => handleRowClick(data)}
                                >
                                    {head.map((column, i) => (
                                        <td
                                            key={i}
                                            className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4"
                                        >
                                            {formatCellValue(
                                                column.type,
                                                getValueFromKey(data, column.key || column.label.toLowerCase()),
                                                data,
                                                actions
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <>
                    <hr className="my-4 w-full border-gray-600 dark:border-gray-800" />
                    <Pagination
                        active={currentPage}
                        totalPages={totalPages}
                        onNext={handleNext}
                        onPrev={handlePrev}
                    />
                </>
            )}
        </div>
    );
};

export default DataTable;
