import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const QueryParam = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');

    let lang = storedLang ? storedLang : navigator.language.split('-')[0];
    i18n.changeLanguage(lang);
  
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('lang', storedLang ? storedLang : navigator.language.toLowerCase());
    const search = queryParams.toString();
    const newUrl = `${window.location.pathname}?${search}${window.location.hash}`;
    navigate(newUrl, { replace: true });
  }, [i18n, navigate]);

  return null;
};

export default QueryParam;
