import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import api from "@services/client/api";
import { toast } from "react-toastify";
import { useAuth } from "@context/AuthContext";
import moment from "moment";
import "@styles/result.css";

interface ApexChartProps {}

interface ApexChartState {
  series: { name: string; data: number[] }[];
  options: ApexOptions;
}

type Historic = {
  [month: string]: {
    orders: {
      quantity: number;
      amount: string;
    };
    smurfs: {
      quantity: number;
      amount: string;
    };
  };
};

const filterOptions = ["2021", "2022", "2023", "2024"];  // Adicione esta linha

const orderedMonths = [
  "janeiro", "fevereiro", "março", "abril", "maio", "junho",
  "julho", "agosto", "setembro", "outubro", "novembro", "dezembro",
];

const ApexChart: React.FC<ApexChartProps> = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [historic, setHistoric] = useState<Historic | null>(null);
  const [selectedYearOption, setSelectedYearOption] = useState<number>(
    moment().year()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(
          "/results/historic",
          { interval: selectedYearOption },
          { 
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        setHistoric(response.data.historicChart);
        // console.log(response.data.historicChart)
      } catch (error: any) {
        console.error("Error fetching data:", error);
        console.log("Request configuration:", error.config);
        toast.warn(error.response?.data?.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token, selectedYearOption]);
  
  useEffect(() => {
    if (historic) {
      const servicesData = orderedMonths.map(
        (month) => historic[month]?.orders?.quantity || 0
      );
      const smurfsData = orderedMonths.map(
        (month) => historic[month]?.smurfs?.quantity || 0
      );

      setChartState((prevState) => ({
        ...prevState,
        series: [
          { name: "Serviços", data: servicesData },
          { name: "Smurfs", data: smurfsData },
        ],
      }));
    }
  }, [historic]);

  const [chartState, setChartState] = useState<ApexChartState>({
    series: [
      {
        name: "Serviços",
        data: [],
      },
      {
        name: "Smurfs",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 450,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        },
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ["#000", "#000"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: orderedMonths,
      },
      yaxis: {
        title: {
          text: "Quantidade",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        y: {
          formatter: (val) => `$ ${val} milhares`,
        },
        x: {
          show: false, // hide default x-axis tooltip
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const month = orderedMonths[dataPointIndex];
          const servicesQuantity = series[0][dataPointIndex];
          const smurfsQuantity = series[1][dataPointIndex];

          // Adicione as linhas abaixo para obter os valores arrecadados
          const servicesAmount = historic?.[month]?.orders?.amount || 'R$ 0,00';
          const smurfsAmount = historic?.[month]?.smurfs?.amount || 'R$ 0,00';

          let tooltipContent = `
            <div class="tooltip-container">
              <div class="tooltip-header">${month.charAt(0).toUpperCase() + month.slice(1)}</div>
            `;

          // Exibir informações separadas para serviços e Smurfs
          if (seriesIndex === 0) { // Se for a coluna de Serviços
            tooltipContent += `
              <div class="tooltip-item">
                <span class="tooltip-label">Serviços: &nbsp; </span>
                <span class="tooltip-value">${servicesQuantity}</span>
              </div>
            `;
          } else { // Se for a coluna de Smurfs
            tooltipContent += `
              <div class="tooltip-item">
                <span class="tooltip-label">Smurfs: &nbsp; </span>
                <span class="tooltip-value">${smurfsQuantity}</span>
              </div>
            `;
          }

          tooltipContent += `</div>`;

          return tooltipContent;
        },

      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: 0,
        markers: {
          radius: 12,
          fillColors: ["#00FCCE", "#F2C94C"],
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        formatter: function (seriesName, opts) {
          return seriesName;
        },
      },
      colors: ["#00FCCE", "#F2C94C"],
      title: {
        style: {
          color: "#333",
        },
      },
    },
  });

  return (
    <div className="relative transition-colors duration-1000 bg-white-100 dark:bg-blue-50 w-full rounded-2xl border border-secondary-500 p-4 my-4">
      <Chart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height={450}
      />
      <div className="absolute top-3 left-8">
        <select
          name="selectDate"
          id="selectDate"
          className="bg-blue-20 p-2 px-6 border border-secondary-500 rounded-lg cursor-pointer outline-none text-sm font-semibold"
          onChange={(e) => setSelectedYearOption(Number(e.target.value))}
          value={selectedYearOption}
        >
          {filterOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ApexChart;