import React, { useEffect, useState } from "react";
import Close from "@assets/Buy/Close.svg";
import Bag from "@assets/Table/MiniBag.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import FileSearch from "@assets/Table/FileSearch.svg";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import MiniBag from "@assets/Table/MiniBag.svg";
import { ServiceRequests } from "@utils/types";
import { toast } from "react-toastify";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import Spin from "@assets/Spin.svg";
import { useTranslation } from "react-i18next";

type Props = {
  booster_id: string;
};

interface TableData {
  id: string;
  jogos: string[];
  idServico: string;
  servico: string;
  comissao: number;
  valor: string | number;
  status: string;
}

type Booster = {
  avatar: string;
  name: string;
  currentElo: string;
  nextElo: string;
  address: string;
  discord: string;
  phone: string;
  email: string;
  pix: string;
  telephone: string;
  booster_level: string;
  booster_next_level: string;
  services_quantity: number;
  services_remain: number;
  level_remain: number;
  accumulated_amount_received: string | number;
};

const PopupInfoBooster = ({ booster_id }: Props) => {
  const [selectedBooster, setSelectedBooster] = useState<Booster | null>(null);
  const { t } = useTranslation();
  const { token } = useAuth();

  const [showPopup, setShowPopup] = useState(true);
  const [loading, setLoading] = useState(true);
  const [booster, setBooster] = useState<Booster>();
  const [finalizedRequests, setFinalizedRequests] = useState<ServiceRequests[]>(
    []
  );

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getBoosterFinalizedRequests = async () => {
    try {
      const response = await api.get(
        `/requests/booster/${booster_id}/finalized`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBooster(response.data.userInfo);
      setFinalizedRequests(response.data.finalizedRequests);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBoosterFinalizedRequests();
  }, []);

  const tableDataMySalesBooster: TableData[] = finalizedRequests.map(
    (item) => ({
      id: item.ecommerce_id,
      jogos: [item.game_service],
      servico: `${item.type_service} - ${item.game_service}`,
      idServico: item.id,
      comissao: item.booster.gain_percentage,
      valor: item.price,
      status: "Finalizado",
    })
  );

  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status: string) => {
    if (status === "Finalizado") {
      return "text-gray-500 font-semibold";
    } else if (status === "Em andamento") {
      return "text-gray-500 font-semibold";
    }
    return "";
  };

  if (loading) {
    return (
      <>
        
      </>
    );
  }

  return (
    <>
      {showPopup && booster && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-0 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-[95%] mx-auto grid grid-cols-3 border border-secondary-500 rounded-2xl py-4 px-4 gap-4">
              <div className="col-span-2 bg-transparent">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 flex items-center gap-2">
                    <img src={Bag} alt={Bag} />
                    <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                      {t("sidebar.historic")}
                    </h2>
                  </div>
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
                <div className=" bg-blue-50 rounded-2xl p-4 max-h-[650px]">
                  <div className="tab-content mt-4">
                    <div className="overflow-auto h-[580px] max-h-[590px]">
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              ID
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                            {t("table.game")}
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                            {t("table.service")}
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                            {t("table.commission")} (%)
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                            {t("table.value")}
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              Status
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                            {t("table.action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableDataMySalesBooster.map((data) => (
                            <tr
                              key={data.id}
                              className="transition-colors duration-1000 bg-transparent "
                            >
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                                {data.id}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                {data.jogos.map((jogo) => (
                                  <span
                                    key={jogo}
                                    className="flex items-center justify-center"
                                  >
                                    {getGameIcon(jogo)}
                                  </span>
                                ))}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                {data.servico}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                {data.comissao}%
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                R$
                                {data.valor
                                  .toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                  .replace(".", ",")}
                              </td>
                              <td
                                className={`text-sm font-light text-center px-4 py-4 ${getStatusColor(
                                  data.status
                                )}`}
                              >
                                <div className="bg-[#56BD79] p-2 px-4 rounded-full">
                                  {data.status}
                                </div>
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                                <button
                                  className="p-2"
                                  onClick={() =>
                                    (window.location.href = `/dashboard/chat/${data.idServico}`)
                                  }
                                >
                                  <img src={FileSearch} alt={FileSearch} />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-resume-adm bg-center bg-cover rounded-2xl p-4 mb-4">
                  <div className="flex items-center justify-between">
                    <div className="bg-gray-500/20 rounded-2xl py-2 px-4 text-center">
                      <p className="text-gray-500 text-xs font-semibold">
                        Booster
                      </p>
                    </div>
                    <div className="flex justify-center items-center bg-gray-500 py-2 px-4 text-center rounded-full">
                      <p className="text-primary-100 font-bold text-base">
                        R${" "}
                        {booster?.accumulated_amount_received
                          ? Number(booster.accumulated_amount_received).toFixed(
                              2
                            )
                          : "0.00"}
                      </p>
                    </div>
                  </div>
                  <div className="w-full grid place-items-center gap-2">
                    <div className="bg-secondary-500 w-24 h-24 rounded-2xl">
                      <img
                        src={booster.avatar}
                        alt="Perfil"
                        className="w-full h-full rounded-2xl"
                      />
                    </div>
                    <h3 className="text-gray-500 font-normal text-base">
                      {" "}
                      {booster.name}
                    </h3>
                  </div>
                  <div className="mt-4">
                      {booster && (
                        <>
                          <div className="flex items-center justify-between mt-8">
                            <p className="text-sm text-gray-500">
                              {booster.services_quantity}
                            </p>
                            <p className="text-sm text-gray-500">
                              {booster.level_remain}
                            </p>
                          </div>
                          <div className="h-2 bg-gray-300 rounded-full">
                            <div
                              className="h-full bg-primary-500 rounded-full"
                              style={{
                                width: `${
                                  (booster.services_quantity /
                                    booster.level_remain) *
                                  100
                                }%`,
                              }}
                            ></div>
                          </div>
                          <div className="flex items-center justify-between mt-2">
                            <p className="text-gray-100 font-semibold text-xs">
                              {t("client.current")}:{" "}
                              <span className="text-gray-500">
                                {booster.booster_level}
                              </span>
                            </p>
                            <p className="text-gray-100 font-semibold text-xs">
                              {t("client.next")}:{" "}
                              <span className="text-gray-500">
                                {booster.booster_next_level}
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                  </div>
                </div>
                <div className="mt-4">
                  <div
                    className={`bg-blue-20 rounded-2xl p-4 border border-secondary-500`}
                    onClick={() => setSelectedBooster(booster)}
                  >     
                    <ul>
                      <li className="flex items-start gap-2">
                        <img src={MiniBag} alt={MiniBag} className="mt-1" />
                        <div className="flex flex-col gap-1">
                          <h3 className="text-gray-100 font-medium text-base"> {t("client.address")}: </h3>
                          <h4 className="text-white font-medium text-sm">
                            {booster.address || "Não Informado"}
                          </h4>
                        </div>
                      </li>

                      <li className="flex items-start gap-2 mt-4">
                        <img src={MiniBag} alt={MiniBag} className="mt-1" />
                        <div className="flex flex-col gap-1">
                          <h3 className="text-gray-100 font-medium text-base"> Discord: </h3>
                          <h4 className="text-white font-medium text-sm">
                            {booster.discord || "Não Informado"}
                          </h4>
                        </div>
                      </li>

                      <li className="flex items-start gap-2 mt-4">
                        <img src={MiniBag} alt={MiniBag} className="mt-1" />
                        <div className="flex flex-col gap-1">
                          <h3 className="text-gray-100 font-medium text-base"> {t("table.phone")}: </h3>
                          <h4 className="text-white font-medium text-sm">
                            {booster.telephone || booster.phone || "Não Informado" }
                          </h4>
                        </div>
                      </li>
                      
                      <li className="flex items-start gap-2 mt-4">
                        <img src={MiniBag} alt={MiniBag} className="mt-1" />
                        <div className="flex flex-col gap-1">
                          <h3 className="text-gray-100 font-medium text-base"> E-mail: </h3>
                          <h4 className="text-white font-medium text-sm">
                            {booster.email || "Não Informado"}
                          </h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupInfoBooster;
