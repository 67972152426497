import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useAuth } from "@context/AuthContext";
import { TableData } from "..";
import { useAlertContext } from "@hooks/AlertContext";
import api from "@services/client/api";
import { toast } from "react-toastify";

type RouletteContextType = {
  rouletteUserData: RouletteUserData | null;
  roulettePrizes: IResponseRoulettePrizes[] | any[] | [];
  rouletteAwards: IResponseRouletteAward[] | [];

  raffledItem: IResponseDraw | null;
  setRaffledItem: React.Dispatch<React.SetStateAction<IResponseDraw | null>>;

  isSpinning: boolean;
  setSpinning: React.Dispatch<React.SetStateAction<boolean>>;

  drawPrize: () => Promise<IResponseDraw | undefined>;
  onSpinningEnd: () => Promise<any>;
  onSpinningStart: () => Promise<any>;
  handleConvertMania: () => Promise<any>;

  spinStart: boolean;
  setSpinStart: React.Dispatch<React.SetStateAction<boolean>>;

  claimModal: boolean;
  setClaimModal: React.Dispatch<React.SetStateAction<boolean>>;

  updateItems: boolean;
  setUpdateItems: React.Dispatch<React.SetStateAction<boolean>>;

  handleClaimPrize: (prizeToClaim: IResponseRouletteAward) => Promise<void>;
  claimPopup: { open: boolean; coupon: string };
  setClaimPopup: React.Dispatch<React.SetStateAction<{ open: boolean; coupon: string }>>;

  canSpinAgain: boolean;
  setSpinAgain: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface RouletteUserData {
  remainingRoulettes: number;
  lastRouletteDate: string;
  nextRouletteDate: string;
  timeDifference: string;
  canSpin: boolean;
}

export interface IResponseRoulettePrizes {
  id: string;
  image: string | null;
  name: string;
  description: string;
  showPopup: boolean;
  level_required: string;
  delivery_method: string;
}

export interface IResponseRouletteAward {
  id: string;
  item_id: string;
  client_id: string;
  given: boolean;
  number: number;
  hash: string;
  claim_status: string;
  claimed_date?: Date;
  created_at: Date;
  prize: IResponseRoulettePrizes;
}

export interface IResponseDraw {
  item: IResponseRoulettePrizes;
  award: IResponseRouletteAward;
}

const RouletteContext = createContext<RouletteContextType | undefined>(undefined);

export const RouletteProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { addAlert } = useAlertContext();
  const { token, user } = useAuth();
  const [rouletteUserData, setRouletteUserData] = useState<RouletteUserData | null>(null);
  const [roulettePrizes, setRoulettePrizes] = useState<IResponseRoulettePrizes[] | any[] | []>([]);
  const [rouletteAwards, setRouletteAwards] = useState<IResponseRouletteAward[] | []>([]);
  const [raffledItem, setRaffledItem] = useState<IResponseDraw | null>(null);
  const [canSpinAgain, setSpinAgain] = useState<boolean>(false)

  const audioRef = useRef(new Audio('https://app-elomania-v2-uploads.s3.amazonaws.com/roulette/audio.mp3'));
  const [updateItems, setUpdateItems] = useState(false);

  const [isSpinning, setSpinning] = useState(false);
  const [spinStart, setSpinStart] = useState(false);

  const [claimModal, setClaimModal] = useState(false);

  const [claimPopup, setClaimPopup] = useState({open: false, coupon: ''});

  const fetchPrizesData = async () => {
    try {
      const response = await api.get("/roulette/prizes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setRoulettePrizes(data);
    } catch (error: any) {
      addAlert("error", error.response?.data?.message, 3000);
    }
  };

  const fetchRouletteData = async () => {
    try {
      const response = await api.get("/roulette/awards/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = response.data;
      setRouletteUserData(responseData);
    } catch (error: any) {
      addAlert("error", error.response?.data?.message, 3000);
    }
  };

  const fetchAwardsData = async () => {
    try {
      const response = await api.get(`/roulette/awards/${user && user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setRouletteAwards(data);
    } catch (error: any) {
      addAlert("error", error.response?.data?.message, 3000);
    }
  };

  useEffect(() => {
    fetchPrizesData();
  }, [token, updateItems]);

  useEffect(() => {
    fetchRouletteData();
    setSpinAgain(false);
  }, [token, raffledItem, canSpinAgain]);

  useEffect(() => {
    fetchAwardsData();
  }, [token, spinStart]);

  const onSpinningStart = async (): Promise<any> => {
    setClaimModal(false);
    audioRef.current.play();
  };

  const onSpinningEnd = async (): Promise<any> => {
    console.log("Parou de girar");
    setClaimModal(true);
    audioRef.current.pause();
    audioRef.current.currentTime = 0; 
    // setRaffledItem(null);
  };

  const drawPrize = async (): Promise<IResponseDraw | undefined> => {
    setSpinning(true);
    try {
      const response = await api.post("/roulette/awards/draw", null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const selectedPrize = response.data;
      setRaffledItem(selectedPrize);
      return selectedPrize;
    } catch (error: any) {
      addAlert("error", error.response?.data?.message, 3000);
    }
  };

  const handleConvertMania = async () => {
    if(!user) return;
    try {
      await api.patch(`/roulette/awards/convert/${user.id}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      fetchRouletteData();
      toast.success("Você trocou seus maniacoins por giros!")
    } catch (err: any) {
      toast.error(err.response.data.message)
    }
  };

  const handleClaimPrize = async (prizeToClaim: IResponseRouletteAward): Promise<void> => {
      try {
        const response = await api.patch(`/roulette/awards/claim/${prizeToClaim.id}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response)
        if(response.data.message){
          if (prizeToClaim.prize.delivery_method === 'coupon') {
            setClaimPopup({open: true, coupon: response.data.message})
          }
          else {
            toast.success(response.data.message)
          }
        }
        await fetchAwardsData(); 
      } catch (error: any) {
        toast.error(error?.response.data.message)
      }
  };

  const contextValue = {
    roulettePrizes,
    rouletteAwards,
    rouletteUserData,
    raffledItem,

    setRaffledItem,
    isSpinning,
    setSpinning,

    drawPrize,

    spinStart,
    setSpinStart,

    onSpinningEnd,
    onSpinningStart,

    claimModal,
    setClaimModal,

    updateItems,
    setUpdateItems,

    handleClaimPrize,
    handleConvertMania,

    setClaimPopup,
    claimPopup,

    canSpinAgain,
    setSpinAgain
  };

  return (
    <RouletteContext.Provider value={contextValue}>
      {children}
    </RouletteContext.Provider>
  );
};

export const useRouletteContext = () => {
  const context = useContext(RouletteContext);

  if (!context) {
    throw new Error(
      "useRouletteContext deve ser usado dentro de um RouletteProvider"
    );
  }

  return context;
};
