import React, { useState } from "react";
import Send from "@assets/Chat/Send.svg";
import coin from "./coin.webp";
import "./teste.css";

import { useTranslation } from "react-i18next";
import { IRequestDTO } from "../types/IRequest";

interface ServiceInfoProps {
  request: IRequestDTO;
}

const ServiceInfo: React.FC<ServiceInfoProps> = ({ request }) => {
  const { t } = useTranslation();
  const renderServiceInfo = () => {

    switch (request.service?.type) {
      case "arena":
      case "rating":
      case "eloboost":
        return (
          <div className="flex flex-col gap-5">
            <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
              <div className="elo w-[150px] flex items-center justify-between">
                <img
                  src={(request.current && request.current.item.image) || ""}
                  alt="Entry Level"
                  className="w-full h-full object-fit"
                />
              </div>
              <div className="gap-2">
                <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-base whitespace-nowrap">
                  {(request.current && request.current.item.label) || ""} {(request.current && request.current.description) || ""}
                </h2>
                <p className="text-gray-100 font-semibold text-sm">
                  {(request.current && request.current.label) || ""}
                  <br />
                </p>
              </div>
            </div>
            <div className="w-full flex justify-center items-center mt-4">
              <div className="center-con">
                <div className="round">
                  <div id="cta">
                    <span className="arrow primera">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                          fill="#4E5160"
                        />
                      </svg>
                    </span>
                    <span className="arrow segunda next">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                          fill="#4E5160"
                        />
                      </svg>
                    </span>
                    <span className="arrow terceira next">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                          fill="#4E5160"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
              <div className="elo w-[150px] flex items-center justify-between">
                <img
                  src={(request.desired && request.desired.item.image) || ""}
                  alt="Entry Level"
                  className="w-full h-full object-fit"
                />
              </div>
              <div className="gap-2">
                <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-base whitespace-nowrap">
                  {(request.desired && request.desired.item.label) || ""} {(request.desired && request.desired.description) || ""}{" "}
                  {(() => {
                    const lpPreference = request.preferences?.find((preference) => preference.type === "lp_additional_required");
                    return lpPreference ? `- ${lpPreference.label}: ${lpPreference.option.value}` : "";
                  })()}
                </h2>
                <p className="text-gray-100 font-semibold text-sm">
                  {(request.desired && request.desired.label) || ""}
                  <br />
                </p>
              </div>
            </div>
          </div>
        );
      case "coach":
        return (
          <>
            <div className="flex flex-col gap-5">
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 flex-col backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
                <div className="elo w-[100px] flex items-center justify-between">
                  <img
                    src={coin}
                    alt="Entry Level"
                    className="w-full h-full object-fit select-none"
                  />
                </div>
                <div className="flex justify-start items-start flex-col">
                  <div className="gap-2">
                    <p className="text-gray-100 font-semibold text-base">
                      Nível do Curso{" "}
                    </p>
                    <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-center font-semibold text-lg">
                      {(request.current && request.current.item.label) || ""} {(request.current && request.current.description) || ""}
                    </h2>
                  </div>
                  <div className="flex items-center justify-center flex-col gap-2 mt-8 w-full">
                    <p className="text-gray-100 font-semibold text-base">
                      {" "}
                      Qtn. Aulas{" "}
                    </p>
                    <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-lg">
                      {" "}
                      {(request.desired && request.desired.item.label) || ""}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "md10":
      case "md5":
        return (
          <>
            <div className="flex flex-col gap-5">
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
                <div className="elo w-[150px] flex items-center justify-between">
                  <img
                    src={(request.current && request.current.item.image) || ""}
                    alt="Entry Level"
                    className="w-full h-full object-fit"
                  />
                </div>
                <div className="gap-2">
                  <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-base whitespace-nowrap">
                    {(request.current && request.current.item.label) || ""} {(request.current && request.current.description) || ""}
                  </h2>
                  <p className="text-gray-100 font-semibold text-sm">
                    {t("chat.initialElo")}{" "}
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-center items-center mt-4">
                <div className="center-con">
                  <div className="round">
                    <div id="cta">
                      <span className="arrow primera">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                            fill="#4E5160"
                          />
                        </svg>
                      </span>
                      <span className="arrow segunda next ">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                            fill="#4E5160"
                          />
                        </svg>
                      </span>
                      <span className="arrow terceira next ">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                            fill="#4E5160"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center px-8 gap-8 rounded-lg">
                <div className="elo w-[120px] flex items-center justify-between">
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/512/5984/5984288.png"
                    }
                    alt="Entry Level"
                    className="w-full h-full object-fit"
                  />
                </div>
                <div className="gap-2">
                  <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-base whitespace-nowrap">
                    {(request.desired && request.desired.item.value) || ""}
                  </h2>
                  <p className="text-gray-100 font-semibold text-sm">
                    Partida(s)
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "wins":
      case "classification":
        return (
          <>
            <div className="flex flex-col gap-5">
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
                <div className="elo w-[150px] flex items-center justify-between">
                  <img
                    src={(request.current && request.current.item.image) || ""}
                    alt="Entry Level"
                    className="w-full h-full object-fit"
                  />
                </div>
                <div className="gap-2">
                  <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-base whitespace-nowrap">
                    {(request.current && request.current.item.label) || ""}
                  </h2>
                  <p className="text-gray-100 font-semibold text-sm">
                    {t("chat.initialElo")}{" "}
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-center items-center mt-4">
                <div className="center-con">
                  <div className="round">
                    <div id="cta">
                      <span className="arrow primera">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                            fill="#4E5160"
                          />
                        </svg>
                      </span>
                      <span className="arrow segunda next ">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                            fill="#4E5160"
                          />
                        </svg>
                      </span>
                      <span className="arrow terceira next ">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.41406 10.2664L12.4714 7.20709C12.5862 7.08015 12.6477 6.91394 12.6433 6.74286C12.6389 6.57179 12.5689 6.40895 12.4478 6.28807C12.3267 6.16719 12.1637 6.09752 11.9926 6.09349C11.8215 6.08946 11.6554 6.15138 11.5287 6.26642L8.47139 9.32376C8.34637 9.44874 8.17683 9.51895 8.00006 9.51895C7.82328 9.51895 7.65374 9.44874 7.52872 9.32376L4.47139 6.26642C4.3463 6.14142 4.17667 6.07123 3.99982 6.07129C3.82298 6.07135 3.6534 6.14166 3.52839 6.26676C3.40338 6.39185 3.33319 6.56148 3.33326 6.73833C3.33332 6.91517 3.40363 7.08475 3.52872 7.20976L6.58606 10.2664C6.96111 10.6414 7.46973 10.852 8.00006 10.852C8.53039 10.852 9.039 10.6414 9.41406 10.2664V10.2664Z"
                            fill="#4E5160"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center px-8 gap-8 rounded-lg">
                <div className="elo w-[120px] flex items-center justify-between">
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/512/5984/5984288.png"
                    }
                    alt="Entry Level"
                    className="w-full h-full object-fit"
                  />
                </div>
                <div className="gap-2">
                  <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-semibold text-base whitespace-nowrap">
                  {(request.desired && request.desired.item.value) || ""}
                  </h2>
                  <p className="text-gray-100 font-semibold text-sm">
                    Classificatória
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "pass":
        return (
          <>
            <div className="flex flex-col gap-5">
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 flex-col backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
                <div className="elo w-[100px] flex items-center justify-between">
                  <img
                    src={(request.current && request.current.item.image) || ""}
                    alt="Entry Level"
                    className="w-full h-full object-fit select-none"
                  />
                </div>
                <div className="flex justify-start gap-6 items-start flex-col">
                  <div className="gap-2 flex flex-col items-center justify-center">
                    <p className="text-gray-100 font-semibold text-base">
                      Passe{" "}
                    </p>
                    <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-center font-semibold text-lg">
                      {(request.current && request.current.label) || ""}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "maintenance":
        return (
          <>
            <div className="flex flex-col gap-5">
              <div className="bg-white-100 dark:bg-blue-500 bg-opacity-30 flex-col backdrop-blur-md border-t border-secondary-500 p-4 mt-4 grid xs:flex items-center gap-4 rounded-lg">
                <div className="elo w-[100px] flex items-center justify-between">
                  <img
                    src={(request.current && request.current.item.image) || ""}
                    alt="Entry Level"
                    className="w-full h-full object-fit select-none"
                  />
                </div>
                <div className="flex justify-start gap-6 items-start flex-col">
                  <div className="gap-2">
                    <p className="text-gray-100 font-semibold text-sm">
                      Elo Atual{" "}
                    </p>
                    <h2 className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-center font-semibold text-base">
                      {(request.current && request.current.label) || ""}
                    </h2>
                  </div>
                  <div className="gap-2">
                    <p className="text-gray-100 font-semibold text-sm">
                      {" "}
                      Duração{" "}
                    </p>
                    <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">
                      {" "}
                      {(request.desired && request.desired.item.value) || ""}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <p>Tipo de serviço desconhecido</p>;
    }
  };

  return <>{renderServiceInfo()}</>;
};

export default ServiceInfo;
