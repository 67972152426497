import React, { useState } from 'react';
import Close from '@assets/Buy/Close.svg';
import IconDiscord from '@assets/Settings_tuor/IconDiscord.svg';
import IconLogout from '@assets/Settings_tuor/IconLogout.svg';
import '@assets/Coockie.png';
import { Link } from 'react-router-dom';
import { useAuth } from '@context/AuthContext';

type Props = {};

const PopupSettings = (props: Props) => {
  const [showPopup, setShowPopup] = useState(true);
  const { user, signOut } = useAuth();

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getFullAvatarUrl = (avatar: string | null) => {
    if (avatar && (avatar.startsWith('http://') || avatar.startsWith('https://'))) {
      return avatar;
    }
    return avatar ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}` : '';
  };

  return (
    <>
      {showPopup && (
        <div className="absolute top-20 right-0">
          <div className={`z-[10000px]`}>
            <div className="bg-blue-20 w-72 border border-secondary-500 rounded-2xl">
              <div className="title bg-blue-50 w-full flex justify-between items-center p-4 mb-2 rounded-2xl">
                <div className="text-left gap-2">
                  <h2 className="mr-2 text-gray-500 text-sm font-bold">{user && user.name}</h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="bg-blue-20 p-4 max-h-[250px] overflow-y-auto rounded-2xl">
                <Link
                  to="/dashboard/profile"
                  className="flex items-center py-4 border-b border-b-gray-100 gap-4"
                >
                  <img
                    src={getFullAvatarUrl(user && user.avatar)}
                    alt="IconProfile"
                    className="w-8 h-8 rounded-2xl"
                  />
                  <p className="text-gray-500 text-sm font-normal"> Meu perfil </p>
                </Link>

                <Link
                  to="https://discord.gg/elomania"
                  target="blank"
                  className="flex items-start py-4 border-b border-b-gray-100 gap-4"
                >
                  <img src={IconDiscord} alt="IconDiscord" />
                  <p className="text-gray-500 text-sm font-normal"> Discord </p>
                </Link>
                <Link to={'/auth/sign-in'}>
                  <div onClick={signOut} className="flex items-start py-4 gap-4">
                    <img src={IconLogout} alt="IconLogout" />
                    <p className="text-gray-500 text-sm font-normal"> Sair da conta </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupSettings;