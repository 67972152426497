import TablePriceAdm from "@components/Dashboard/mainDashboard/Adm/tools/price"
import SmurfPrice from "@components/Dashboard/mainDashboard/Adm/tools/price/SmurfPrice"

type Props = {}

const PriceAdm = (props: Props) => {
  return (
    <>
      <TablePriceAdm />
      <SmurfPrice />
    </>
  )
}

export default PriceAdm