import mc from "@assets/maniacoin.png";

import Passo0 from "@assets/Tuor/Passo0.png";
import Passo1 from "@assets/Tuor/Passo1.png";
import Passo2 from "@assets/Tuor/Passo2.png";
import Passo3 from "@assets/Tuor/Passo3.png";
import Passo4 from "@assets/Tuor/Passo4.png";
import Passo5 from "@assets/Tuor/Passo5.png";
import Passo6 from "@assets/Tuor/Passo6.png";
import Passo7 from "@assets/Tuor/Passo7.png";

import Close from "@assets/Buy/Close.svg";
import IconDiscord from "@assets/Settings_tuor/IconDiscord.svg";
import IconLogout from "@assets/Settings_tuor/IconLogout.svg";

import api from "@services/client/api";
import { IUser } from "@utils/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import moment from "moment";
import News from "@components/Dashboard/Client/news";

type Props = {
  user: IUser;
  token: string;
};

const PopupTuor = ({ user, token }: Props) => {
  const [popupMessageProgress, setPopupMessageProgress] = useState<
    string | null
  >(null);
  const [apiData, setApiData] = useState<IUser | null>(null);
  const { t } = useTranslation();
  const [expireDateInfo, setExpireDateInfo] = useState({
    formatted: "",
    relative: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setApiData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleMouseEnterProgress = (mensagem: string) => {
    setPopupMessageProgress(mensagem);
  };

  const handleMouseLeaveProgress = () => {
    setPopupMessageProgress(null);
  };

  useEffect(() => {
    const calculateExpireDate = () => {
      if (apiData && apiData.maniacoin && apiData.maniacoin.expireDate) {
        const expireDate = new Date(apiData.maniacoin.expireDate);
        const diffMilliseconds = expireDate.getTime() - Date.now();
        const diffDuration = moment.duration(diffMilliseconds);
        const formattedDate = `${Math.floor(
          diffDuration.asDays()
        )}d ${diffDuration.hours()}h ${diffDuration.minutes()}min`;
        const relativeDate = moment(expireDate).fromNow();
        setExpireDateInfo({ formatted: formattedDate, relative: relativeDate });
      }
    };

    calculateExpireDate();

    const interval = setInterval(calculateExpireDate, 60000);
    return () => clearInterval(interval);
  }, [apiData]);

  const getFullAvatarUrl = (avatar: string | null) => {
    if (
      avatar &&
      (avatar.startsWith("http://") || avatar.startsWith("https://"))
    ) {
      return avatar;
    }
    return avatar
      ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`
      : "";
  };

  const [showPopup, setShowPopup] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleContinue = () => {
    setCurrentStep(1);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`w-full h-full absolute backdrop-blur-3xl left-0 top-0`}
          >
            {currentStep === 0 && (
              <div className="w-full h-full flex items-center justify-center">
                <div className="bg-blue-50 relative w-[800px] max-w-[1600px] max-h-[98vh] transition-colors duration-1000 bg-gradient-to-b p-10 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl border border-gray-100 dark:border-secondary-500">
                  <div className="flex flex-col text-center justify-between items-center gap-4 h-full">
                    <img src={Passo0} alt="Passo0" className="w-28 h-28" />
                    <h3 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-lg">
                      Bem-vindo,{" "}
                      {user && user.name ? user.name.split(" ")[0] : ""}!
                    </h3>
                    <span className="text-center text-gray-100 text-lg font-medium w-full">
                      Esta é sua nova plataforma e faremos uma breve
                      apresentação.
                    </span>
                    <span className="text-center text-gray-100 text-base font-medium w-full">
                      Mas não se preocupe, levará menos de 1 minuto.
                    </span>
                    <div className="w-full flex items-center gap-4">
                      <button
                        onClick={handleContinue}
                        className="bg-[#FF8730] w-full p-2 rounded-full text-center"
                      >
                        Continuar
                      </button>
                      <button
                        onClick={handleClosePopup}
                        className="bg-[#2D313D] w-full p-2 rounded-full text-center"
                      >
                        Pular
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 1 && (
              <div className="bg-transparent grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                {/* Capa e Perfil */}
                <div className="bg-blue-20 relative rounded-3xl w-full h-[400px] border-2 border-primary-100">
                  <div className="capa w-full h-full rounded-3xl overflow-hidden">
                    {/* Sobreposição escura */}
                    <img
                      src={
                        user.banner
                          ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                          : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
                      }
                      className="object-cover w-full h-full rounded-3xl"
                    />
                  </div>
                  <div className="absolute z-10 rounded-3xl py-3 top-0 flex flex-col justify-between items-center w-full h-full">
                    <div className="flex items-center justify-between px-4 w-full">
                      <div className="flex items-center gap-4">
                        {user.subscribed && (
                          <div className="bg-blue-100 rounded-3xl w-14 py-2 text-center mb-4">
                            <p className="text-gray-500 font-normal text-xs">
                              Elomania Premium
                            </p>
                          </div>
                        )}
                        <div className="bg-gray-500/10 rounded-3xl w-16 py-2 flex items-center justify-center mb-4">
                          <p className="text-gray-500 font-normal text-xs">
                            {t("client.name")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="view-profile w-full h-full flex justify-center items-center">
                      <Link
                        to="/dashboard/profile"
                        className="flex flex-col items-center justify-center text-center py-4 gap-8"
                      >
                        <img
                          src={getFullAvatarUrl(user && user.avatar)}
                          alt="IconProfile"
                          className="w-20 h-20 rounded-lg"
                        />
                        <p className="text-gray-500 text-base font-semibold">
                          {t("sidebar.welcome")}{" "}
                          {user && user.name ? user.name.split(" ")[0] : ""}!
                        </p>
                      </Link>
                    </div>
                    <div
                      className="w-full mt-14 px-4 cursor-pointer"
                      onMouseEnter={() =>
                        handleMouseEnterProgress(t("client.hover"))
                      }
                      onMouseLeave={handleMouseLeaveProgress}
                    >
                      <div className="flex items-center justify-between mt-8">
                        <p className="text-sm text-gray-500">
                          {apiData?.levelData?.currentLevel}
                        </p>
                        <p className="text-sm text-gray-500">
                          {apiData?.levelData?.levels}
                        </p>
                      </div>
                      <div className="h-2 bg-gray-500 rounded-full">
                        <div
                          className="h-full bg-gradient-to-l from-[#FF8730] to-[#FFE62E]  shadow-[2px_2px_16px_#FF8730] rounded-full"
                          style={{
                            width: `${apiData?.levelData?.progress || 0}%`,
                          }}
                        ></div>
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <p className="text-gray-100 font-semibold text-base">
                          {t("client.current")}: &nbsp;
                          <span className="text-gray-500">
                            {apiData?.levelData?.class || "N/A"}
                          </span>
                        </p>
                        <p className="text-gray-100 font-semibold text-base">
                          {t("client.next")}: &nbsp;
                          <span className="text-gray-500">
                            {apiData?.levelData?.next_class || "N/A"}
                          </span>
                        </p>
                      </div>
                      {popupMessageProgress && (
                        <div
                          className="absolute left-2 z-20 mt-4 bg-blue-50 border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                          onMouseEnter={() =>
                            handleMouseEnterProgress(t("client.hover"))
                          }
                          onMouseLeave={handleMouseLeaveProgress}
                        >
                          {popupMessageProgress}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full h-full flex items-start justify-start">
                  <div className="w-full h-1/2 p-4 rounded-3xl border border-secondary-500 bg-gray-500/[0.02] backdrop-blur-3xl flex items-center justify-center gap-4">
                    <img src={Passo1} alt="Passo1" className="w-28 h-28" />
                    <div className="flex flex-col text-left items-center gap-4">
                      <span className="text-left text-gray-100 text-lg font-bold w-full">
                        Passo 1
                      </span>
                      <span className="text-left text-gray-500 text-sm font-semibold w-full">
                        Você tem uma capa e imagem de perfil personalizada e
                        pode alterá-las quando quiser (veremos como, nos
                        próximos passos).
                      </span>
                    </div>
                    <button
                      className="bg-transparent p-4"
                      onClick={handleNextStep}
                    >
                      <svg
                        width="50"
                        height="51"
                        viewBox="0 0 50 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.1353 42.1417C10.9955 42.2865 10.8286 42.4022 10.6441 42.4825C10.4596 42.5627 10.2611 42.6058 10.0599 42.6093C9.85875 42.6128 9.65886 42.5767 9.47166 42.5029C9.28446 42.4292 9.11362 42.3193 8.96889 42.1795C8.82416 42.0398 8.70838 41.8729 8.62815 41.6884C8.54792 41.5039 8.50482 41.3053 8.50131 41.1042C8.4978 40.903 8.53394 40.7031 8.60769 40.5159C8.68143 40.3287 8.79131 40.1579 8.93107 40.0132L22.9116 25.5378L8.43441 11.5592C8.14212 11.2769 7.97392 10.8901 7.96683 10.4838C7.95974 10.0776 8.11433 9.68511 8.3966 9.39282C8.67886 9.10052 9.06568 8.93233 9.47196 8.92524C9.87824 8.91815 10.2707 9.07274 10.563 9.355L26.1404 24.3979C26.2852 24.5376 26.4011 24.7045 26.4814 24.889C26.5617 25.0736 26.6048 25.2721 26.6083 25.4733C26.6118 25.6745 26.5757 25.8744 26.5019 26.0616C26.4281 26.2488 26.3181 26.4196 26.1782 26.5643L11.1353 42.1417ZM23.7446 11.292L38.2218 25.2706L24.2412 39.7459C24.1015 39.8906 23.9916 40.0615 23.9178 40.2487C23.8441 40.4359 23.808 40.6358 23.8115 40.8369C23.815 41.0381 23.8581 41.2366 23.9383 41.4211C24.0185 41.6056 24.1343 41.7725 24.2791 41.9123C24.4238 42.0521 24.5946 42.162 24.7818 42.2357C24.969 42.3094 25.1689 42.3456 25.3701 42.3421C25.5712 42.3386 25.7698 42.2955 25.9543 42.2152C26.1388 42.135 26.3057 42.0192 26.4454 41.8745L41.4884 26.2971C41.6282 26.1524 41.7382 25.9816 41.812 25.7944C41.8858 25.6072 41.922 25.4072 41.9185 25.206C41.915 25.0049 41.8718 24.8063 41.7916 24.6218C41.7113 24.4373 41.5954 24.2704 41.4506 24.1307L25.8732 9.08777C25.5809 8.8055 25.1884 8.65091 24.7821 8.658C24.3758 8.66509 23.989 8.83328 23.7068 9.12558C23.4245 9.41788 23.2699 9.81033 23.277 10.2166C23.2841 10.6229 23.4523 11.0097 23.7446 11.292Z"
                          fill="#FFF72D"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="w-full"></div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="bg-transparent grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                {/* Capa e Perfil */}
                <div className="bg-blue-20 relative rounded-3xl w-full h-[400px] border-2 border-primary-100">
                  <div className="capa w-full h-full rounded-3xl overflow-hidden">
                    {/* Sobreposição escura */}
                    <img
                      src={
                        user.banner
                          ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                          : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
                      }
                      className="object-cover w-full h-full rounded-3xl"
                    />
                  </div>
                  <div className="absolute z-10 rounded-3xl py-3 top-0 flex flex-col justify-between items-center w-full h-full">
                    <div className="flex items-center justify-between px-4 w-full">
                      <div className="flex items-center gap-4">
                        {user.subscribed && (
                          <div className="bg-blue-100 rounded-3xl w-14 py-2 text-center mb-4">
                            <p className="text-gray-500 font-normal text-xs">
                              Elomania Premium
                            </p>
                          </div>
                        )}
                        <div className="bg-gray-500/10 rounded-3xl w-16 py-2 flex items-center justify-center mb-4">
                          <p className="text-gray-500 font-normal text-xs">
                            {t("client.name")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="view-profile w-full h-full flex justify-center items-center">
                      <Link
                        to="/dashboard/profile"
                        className="flex flex-col items-center justify-center text-center py-4 gap-8"
                      >
                        <img
                          src={getFullAvatarUrl(user && user.avatar)}
                          alt="IconProfile"
                          className="w-20 h-20 rounded-lg"
                        />
                        <p className="text-gray-500 text-base font-semibold">
                          {t("sidebar.welcome")}{" "}
                          {user && user.name ? user.name.split(" ")[0] : ""}!
                        </p>
                      </Link>
                    </div>
                    <div
                      className="w-full mt-14 px-4 cursor-pointer"
                      onMouseEnter={() =>
                        handleMouseEnterProgress(t("client.hover"))
                      }
                      onMouseLeave={handleMouseLeaveProgress}
                    >
                      <div className="flex items-center justify-between mt-8">
                        <p className="text-sm text-gray-500">
                          {apiData?.levelData?.currentLevel}
                        </p>
                        <p className="text-sm text-gray-500">
                          {apiData?.levelData?.levels}
                        </p>
                      </div>
                      <div className="h-2 bg-gray-500 rounded-full">
                        <div
                          className="h-full bg-gradient-to-l from-[#FF8730] to-[#FFE62E]  shadow-[2px_2px_16px_#FF8730] rounded-full"
                          style={{
                            width: `${apiData?.levelData?.progress || 0}%`,
                          }}
                        ></div>
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <p className="text-gray-100 font-semibold text-base">
                          {t("client.current")}: &nbsp;
                          <span className="text-gray-500">
                            {apiData?.levelData?.class || "N/A"}
                          </span>
                        </p>
                        <p className="text-gray-100 font-semibold text-base">
                          {t("client.next")}: &nbsp;
                          <span className="text-gray-500">
                            {apiData?.levelData?.next_class || "N/A"}
                          </span>
                        </p>
                      </div>
                      {popupMessageProgress && (
                        <div
                          className="absolute left-2 z-20 mt-4 bg-blue-50 border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                          onMouseEnter={() =>
                            handleMouseEnterProgress(t("client.hover"))
                          }
                          onMouseLeave={handleMouseLeaveProgress}
                        >
                          {popupMessageProgress}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full h-full flex items-end justify-end">
                  <div className="w-full h-1/2 p-4 rounded-3xl border border-secondary-500 bg-gray-500/[0.02] backdrop-blur-3xl flex items-center justify-center gap-4">
                    <img src={Passo2} alt="Passo2" className="w-28 h-28" />
                    <div className="flex flex-col text-left items-center gap-4">
                      <span className="text-left text-gray-100 text-lg font-bold w-full">
                        Passo 2
                      </span>
                      <span className="text-left text-gray-500 text-sm font-semibold w-full">
                        Esta é a barra de XP, compre produtos e serviços para
                        upar de nível. Você receberá ManiaCoins por cada compra
                        no site!
                      </span>
                    </div>
                    <button
                      className="bg-transparent p-4"
                      onClick={handleNextStep}
                    >
                      <svg
                        width="50"
                        height="51"
                        viewBox="0 0 50 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.1353 42.1417C10.9955 42.2865 10.8286 42.4022 10.6441 42.4825C10.4596 42.5627 10.2611 42.6058 10.0599 42.6093C9.85875 42.6128 9.65886 42.5767 9.47166 42.5029C9.28446 42.4292 9.11362 42.3193 8.96889 42.1795C8.82416 42.0398 8.70838 41.8729 8.62815 41.6884C8.54792 41.5039 8.50482 41.3053 8.50131 41.1042C8.4978 40.903 8.53394 40.7031 8.60769 40.5159C8.68143 40.3287 8.79131 40.1579 8.93107 40.0132L22.9116 25.5378L8.43441 11.5592C8.14212 11.2769 7.97392 10.8901 7.96683 10.4838C7.95974 10.0776 8.11433 9.68511 8.3966 9.39282C8.67886 9.10052 9.06568 8.93233 9.47196 8.92524C9.87824 8.91815 10.2707 9.07274 10.563 9.355L26.1404 24.3979C26.2852 24.5376 26.4011 24.7045 26.4814 24.889C26.5617 25.0736 26.6048 25.2721 26.6083 25.4733C26.6118 25.6745 26.5757 25.8744 26.5019 26.0616C26.4281 26.2488 26.3181 26.4196 26.1782 26.5643L11.1353 42.1417ZM23.7446 11.292L38.2218 25.2706L24.2412 39.7459C24.1015 39.8906 23.9916 40.0615 23.9178 40.2487C23.8441 40.4359 23.808 40.6358 23.8115 40.8369C23.815 41.0381 23.8581 41.2366 23.9383 41.4211C24.0185 41.6056 24.1343 41.7725 24.2791 41.9123C24.4238 42.0521 24.5946 42.162 24.7818 42.2357C24.969 42.3094 25.1689 42.3456 25.3701 42.3421C25.5712 42.3386 25.7698 42.2955 25.9543 42.2152C26.1388 42.135 26.3057 42.0192 26.4454 41.8745L41.4884 26.2971C41.6282 26.1524 41.7382 25.9816 41.812 25.7944C41.8858 25.6072 41.922 25.4072 41.9185 25.206C41.915 25.0049 41.8718 24.8063 41.7916 24.6218C41.7113 24.4373 41.5954 24.2704 41.4506 24.1307L25.8732 9.08777C25.5809 8.8055 25.1884 8.65091 24.7821 8.658C24.3758 8.66509 23.989 8.83328 23.7068 9.12558C23.4245 9.41788 23.2699 9.81033 23.277 10.2166C23.2841 10.6229 23.4523 11.0097 23.7446 11.292Z"
                          fill="#FFF72D"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="w-full"></div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="bg-transparent grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                {/* Capa e Perfil */}
                <div className="w-full"></div>
                <div className="w-full flex flex-col h-full">
                  <div className="flex items-center justify-center select-none gap-4 transition-colors duration-1000 bg-gradient-to-b py-10 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl p-4 border border-gray-100 dark:border-secondary-500">
                    <img src={mc} alt="Mania Coins" width={32} height={32} />
                    <span className="text-blue-50 dark:text-gray-500 font-semibold text-base transition-colors duration-1000">
                      ManiaCoins
                    </span>
                    <div className="px-5 py-1 min-w-[55px] font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-50 dark:bg-blue-1001/10 shadow-2xl">
                      {apiData &&
                      apiData.maniacoin &&
                      apiData.maniacoin.totalAmount
                        ? `${apiData.maniacoin.totalAmount.toLocaleString(
                            "pt-BR"
                          )}`
                        : "0"}
                    </div>
                  </div>
                  <div className="mt-4 w-full h-full p-4 rounded-3xl border border-secondary-500 bg-gray-500/[0.02] backdrop-blur-3xl flex items-center justify-center gap-4">
                    <img src={Passo3} alt="Passo3" className="w-28 h-28" />
                    <div className="flex flex-col text-left items-center gap-4">
                      <span className="text-left text-gray-100 text-lg font-bold w-full">
                        Passo 3
                      </span>
                      <span className="text-left text-gray-500 text-sm font-semibold w-full">
                        Estes são seus ManiaCoins, use-os em sua próxima compra
                        para ter ainda mais descontos!
                      </span>
                    </div>
                    <button
                      className="bg-transparent p-4"
                      onClick={handleNextStep}
                    >
                      <svg
                        width="50"
                        height="51"
                        viewBox="0 0 50 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.1353 42.1417C10.9955 42.2865 10.8286 42.4022 10.6441 42.4825C10.4596 42.5627 10.2611 42.6058 10.0599 42.6093C9.85875 42.6128 9.65886 42.5767 9.47166 42.5029C9.28446 42.4292 9.11362 42.3193 8.96889 42.1795C8.82416 42.0398 8.70838 41.8729 8.62815 41.6884C8.54792 41.5039 8.50482 41.3053 8.50131 41.1042C8.4978 40.903 8.53394 40.7031 8.60769 40.5159C8.68143 40.3287 8.79131 40.1579 8.93107 40.0132L22.9116 25.5378L8.43441 11.5592C8.14212 11.2769 7.97392 10.8901 7.96683 10.4838C7.95974 10.0776 8.11433 9.68511 8.3966 9.39282C8.67886 9.10052 9.06568 8.93233 9.47196 8.92524C9.87824 8.91815 10.2707 9.07274 10.563 9.355L26.1404 24.3979C26.2852 24.5376 26.4011 24.7045 26.4814 24.889C26.5617 25.0736 26.6048 25.2721 26.6083 25.4733C26.6118 25.6745 26.5757 25.8744 26.5019 26.0616C26.4281 26.2488 26.3181 26.4196 26.1782 26.5643L11.1353 42.1417ZM23.7446 11.292L38.2218 25.2706L24.2412 39.7459C24.1015 39.8906 23.9916 40.0615 23.9178 40.2487C23.8441 40.4359 23.808 40.6358 23.8115 40.8369C23.815 41.0381 23.8581 41.2366 23.9383 41.4211C24.0185 41.6056 24.1343 41.7725 24.2791 41.9123C24.4238 42.0521 24.5946 42.162 24.7818 42.2357C24.969 42.3094 25.1689 42.3456 25.3701 42.3421C25.5712 42.3386 25.7698 42.2955 25.9543 42.2152C26.1388 42.135 26.3057 42.0192 26.4454 41.8745L41.4884 26.2971C41.6282 26.1524 41.7382 25.9816 41.812 25.7944C41.8858 25.6072 41.922 25.4072 41.9185 25.206C41.915 25.0049 41.8718 24.8063 41.7916 24.6218C41.7113 24.4373 41.5954 24.2704 41.4506 24.1307L25.8732 9.08777C25.5809 8.8055 25.1884 8.65091 24.7821 8.658C24.3758 8.66509 23.989 8.83328 23.7068 9.12558C23.4245 9.41788 23.2699 9.81033 23.277 10.2166C23.2841 10.6229 23.4523 11.0097 23.7446 11.292Z"
                          fill="#FFF72D"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="w-full"></div>
              </div>
            )}
            {currentStep === 4 && (
              <div className="bg-transparent grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                {/* Capa e Perfil */}
                <div className=""></div>
                <div className="w-full h-full flex items-start justify-start">
                  <div className="w-full h-1/2 p-4 rounded-3xl border border-secondary-500 bg-gray-500/[0.02] backdrop-blur-3xl flex items-center justify-center gap-4">
                    <img src={Passo6} alt="Passo6" className="w-28 h-28" />
                    <div className="flex flex-col text-left items-center gap-4">
                      <span className="text-left text-gray-100 text-lg font-bold w-full">
                        Passo 4
                      </span>
                      <span className="text-left text-gray-500 text-sm font-semibold w-full">
                        Ao lado são nossos posts. Fique por dentro de novidades,
                        promoções e avisos. Cuidado, ao clicar vc pode ser
                        direcionado!
                      </span>
                    </div>
                    <button
                      className="bg-transparent p-4"
                      onClick={handleNextStep}
                    >
                      <svg
                        width="50"
                        height="51"
                        viewBox="0 0 50 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.1353 42.1417C10.9955 42.2865 10.8286 42.4022 10.6441 42.4825C10.4596 42.5627 10.2611 42.6058 10.0599 42.6093C9.85875 42.6128 9.65886 42.5767 9.47166 42.5029C9.28446 42.4292 9.11362 42.3193 8.96889 42.1795C8.82416 42.0398 8.70838 41.8729 8.62815 41.6884C8.54792 41.5039 8.50482 41.3053 8.50131 41.1042C8.4978 40.903 8.53394 40.7031 8.60769 40.5159C8.68143 40.3287 8.79131 40.1579 8.93107 40.0132L22.9116 25.5378L8.43441 11.5592C8.14212 11.2769 7.97392 10.8901 7.96683 10.4838C7.95974 10.0776 8.11433 9.68511 8.3966 9.39282C8.67886 9.10052 9.06568 8.93233 9.47196 8.92524C9.87824 8.91815 10.2707 9.07274 10.563 9.355L26.1404 24.3979C26.2852 24.5376 26.4011 24.7045 26.4814 24.889C26.5617 25.0736 26.6048 25.2721 26.6083 25.4733C26.6118 25.6745 26.5757 25.8744 26.5019 26.0616C26.4281 26.2488 26.3181 26.4196 26.1782 26.5643L11.1353 42.1417ZM23.7446 11.292L38.2218 25.2706L24.2412 39.7459C24.1015 39.8906 23.9916 40.0615 23.9178 40.2487C23.8441 40.4359 23.808 40.6358 23.8115 40.8369C23.815 41.0381 23.8581 41.2366 23.9383 41.4211C24.0185 41.6056 24.1343 41.7725 24.2791 41.9123C24.4238 42.0521 24.5946 42.162 24.7818 42.2357C24.969 42.3094 25.1689 42.3456 25.3701 42.3421C25.5712 42.3386 25.7698 42.2955 25.9543 42.2152C26.1388 42.135 26.3057 42.0192 26.4454 41.8745L41.4884 26.2971C41.6282 26.1524 41.7382 25.9816 41.812 25.7944C41.8858 25.6072 41.922 25.4072 41.9185 25.206C41.915 25.0049 41.8718 24.8063 41.7916 24.6218C41.7113 24.4373 41.5954 24.2704 41.4506 24.1307L25.8732 9.08777C25.5809 8.8055 25.1884 8.65091 24.7821 8.658C24.3758 8.66509 23.989 8.83328 23.7068 9.12558C23.4245 9.41788 23.2699 9.81033 23.277 10.2166C23.2841 10.6229 23.4523 11.0097 23.7446 11.292Z"
                          fill="#FFF72D"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <News />
              </div>
            )}
            {currentStep === 5 && (
              <div className="bg-transparent grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                {/* Capa e Perfil */}
                <div className="w-full"></div>
                <div className=""></div>
                <div className="w-full relative">
                  <div className="absolute top-0 right-0 bg-blue-20 w-72 border border-secondary-500 rounded-2xl">
                    <div className="title bg-blue-50 w-full flex justify-between items-center p-4 mb-2 rounded-2xl">
                      <div className="text-left gap-2">
                        <h2 className="mr-2 text-gray-500 text-sm font-bold">
                          {user && user.name}
                        </h2>
                      </div>
                      <button className="p-2">
                        <img src={Close} alt="Close" />
                      </button>
                    </div>
                    <div className="bg-blue-20 p-4 max-h-[250px] overflow-y-auto rounded-2xl">
                      <a className="flex items-center py-4 border-b border-b-gray-100 gap-4">
                        <img
                          src={getFullAvatarUrl(user && user.avatar)}
                          alt="IconProfile"
                          className="w-8 h-8 rounded-2xl"
                        />
                        <p className="text-gray-500 text-sm font-normal">
                          {" "}
                          Meu perfil{" "}
                        </p>
                      </a>

                      <a className="flex items-start py-4 border-b border-b-gray-100 gap-4">
                        <img src={IconDiscord} alt="IconDiscord" />
                        <p className="text-gray-500 text-sm font-normal">
                          {" "}
                          Discord{" "}
                        </p>
                      </a>
                      <a>
                        <div className="flex items-start py-4 gap-4">
                          <img src={IconLogout} alt="IconLogout" />
                          <p className="text-gray-500 text-sm font-normal">
                            {" "}
                            Sair da conta{" "}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="absolute top-0 right-80 w-full p-4 rounded-3xl border border-secondary-500 bg-gray-500/[0.02] backdrop-blur-3xl flex items-center justify-center gap-4">
                    <img
                      src={Passo4}
                      alt="Passo4"
                      className="w-28 h-28 flex-shrink-0"
                    />
                    <div className="flex flex-col text-left items-center gap-4">
                      <span className="text-left text-gray-100 text-lg font-bold w-full">
                        Passo 5
                      </span>
                      <span className="text-left text-gray-500 text-sm font-semibold w-full">
                        Clique no ícone "Configurações" acima para exibir este
                        menu ao lado. Seus dados, imagem de capa e perfil podem
                        ser alterados em "Meu perfil".
                      </span>
                    </div>
                    <button
                      className="bg-transparent p-4"
                      onClick={handleNextStep}
                    >
                      <svg
                        width="50"
                        height="51"
                        viewBox="0 0 50 51"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.1353 42.1417C10.9955 42.2865 10.8286 42.4022 10.6441 42.4825C10.4596 42.5627 10.2611 42.6058 10.0599 42.6093C9.85875 42.6128 9.65886 42.5767 9.47166 42.5029C9.28446 42.4292 9.11362 42.3193 8.96889 42.1795C8.82416 42.0398 8.70838 41.8729 8.62815 41.6884C8.54792 41.5039 8.50482 41.3053 8.50131 41.1042C8.4978 40.903 8.53394 40.7031 8.60769 40.5159C8.68143 40.3287 8.79131 40.1579 8.93107 40.0132L22.9116 25.5378L8.43441 11.5592C8.14212 11.2769 7.97392 10.8901 7.96683 10.4838C7.95974 10.0776 8.11433 9.68511 8.3966 9.39282C8.67886 9.10052 9.06568 8.93233 9.47196 8.92524C9.87824 8.91815 10.2707 9.07274 10.563 9.355L26.1404 24.3979C26.2852 24.5376 26.4011 24.7045 26.4814 24.889C26.5617 25.0736 26.6048 25.2721 26.6083 25.4733C26.6118 25.6745 26.5757 25.8744 26.5019 26.0616C26.4281 26.2488 26.3181 26.4196 26.1782 26.5643L11.1353 42.1417ZM23.7446 11.292L38.2218 25.2706L24.2412 39.7459C24.1015 39.8906 23.9916 40.0615 23.9178 40.2487C23.8441 40.4359 23.808 40.6358 23.8115 40.8369C23.815 41.0381 23.8581 41.2366 23.9383 41.4211C24.0185 41.6056 24.1343 41.7725 24.2791 41.9123C24.4238 42.0521 24.5946 42.162 24.7818 42.2357C24.969 42.3094 25.1689 42.3456 25.3701 42.3421C25.5712 42.3386 25.7698 42.2955 25.9543 42.2152C26.1388 42.135 26.3057 42.0192 26.4454 41.8745L41.4884 26.2971C41.6282 26.1524 41.7382 25.9816 41.812 25.7944C41.8858 25.6072 41.922 25.4072 41.9185 25.206C41.915 25.0049 41.8718 24.8063 41.7916 24.6218C41.7113 24.4373 41.5954 24.2704 41.4506 24.1307L25.8732 9.08777C25.5809 8.8055 25.1884 8.65091 24.7821 8.658C24.3758 8.66509 23.989 8.83328 23.7068 9.12558C23.4245 9.41788 23.2699 9.81033 23.277 10.2166C23.2841 10.6229 23.4523 11.0097 23.7446 11.292Z"
                          fill="#FFF72D"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}
            
            {currentStep === 6 && (
              <div className="bg-transparent w-full relative h-full gap-4">
                <div className="absolute bottom-1/4 left-0 w-1/3 p-4 rounded-3xl border border-secondary-500 bg-gray-500/[0.02] backdrop-blur-3xl flex items-center justify-center gap-4">
                  <img
                    src={Passo5}
                    alt="Passo5"
                    className="w-28 h-28 flex-shrink-0"
                  />
                  <div className="flex flex-col text-left items-center gap-4">
                    <span className="text-left text-gray-100 text-lg font-bold w-full">
                      Passo 6
                    </span>
                    <span className="text-left text-gray-500 text-sm font-semibold w-full">
                      Clique no ícone "Fantasma" ao lado esquerdo para
                      visualizar suas{" "}
                      <span className="text-blue-100 text-sm font-semibold">
                        {" "}
                        SMURFS{" "}
                      </span>
                      compradas no site da Elomania.
                    </span>
                  </div>
                  <button
                    className="bg-transparent p-4"
                    onClick={handleNextStep}
                  >
                    <svg
                      width="50"
                      height="51"
                      viewBox="0 0 50 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.1353 42.1417C10.9955 42.2865 10.8286 42.4022 10.6441 42.4825C10.4596 42.5627 10.2611 42.6058 10.0599 42.6093C9.85875 42.6128 9.65886 42.5767 9.47166 42.5029C9.28446 42.4292 9.11362 42.3193 8.96889 42.1795C8.82416 42.0398 8.70838 41.8729 8.62815 41.6884C8.54792 41.5039 8.50482 41.3053 8.50131 41.1042C8.4978 40.903 8.53394 40.7031 8.60769 40.5159C8.68143 40.3287 8.79131 40.1579 8.93107 40.0132L22.9116 25.5378L8.43441 11.5592C8.14212 11.2769 7.97392 10.8901 7.96683 10.4838C7.95974 10.0776 8.11433 9.68511 8.3966 9.39282C8.67886 9.10052 9.06568 8.93233 9.47196 8.92524C9.87824 8.91815 10.2707 9.07274 10.563 9.355L26.1404 24.3979C26.2852 24.5376 26.4011 24.7045 26.4814 24.889C26.5617 25.0736 26.6048 25.2721 26.6083 25.4733C26.6118 25.6745 26.5757 25.8744 26.5019 26.0616C26.4281 26.2488 26.3181 26.4196 26.1782 26.5643L11.1353 42.1417ZM23.7446 11.292L38.2218 25.2706L24.2412 39.7459C24.1015 39.8906 23.9916 40.0615 23.9178 40.2487C23.8441 40.4359 23.808 40.6358 23.8115 40.8369C23.815 41.0381 23.8581 41.2366 23.9383 41.4211C24.0185 41.6056 24.1343 41.7725 24.2791 41.9123C24.4238 42.0521 24.5946 42.162 24.7818 42.2357C24.969 42.3094 25.1689 42.3456 25.3701 42.3421C25.5712 42.3386 25.7698 42.2955 25.9543 42.2152C26.1388 42.135 26.3057 42.0192 26.4454 41.8745L41.4884 26.2971C41.6282 26.1524 41.7382 25.9816 41.812 25.7944C41.8858 25.6072 41.922 25.4072 41.9185 25.206C41.915 25.0049 41.8718 24.8063 41.7916 24.6218C41.7113 24.4373 41.5954 24.2704 41.4506 24.1307L25.8732 9.08777C25.5809 8.8055 25.1884 8.65091 24.7821 8.658C24.3758 8.66509 23.989 8.83328 23.7068 9.12558C23.4245 9.41788 23.2699 9.81033 23.277 10.2166C23.2841 10.6229 23.4523 11.0097 23.7446 11.292Z"
                        fill="#FFF72D"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
            
            {currentStep === 7 && (
              <div className="w-full h-full flex items-center justify-center">
                <div className="bg-blue-50 relative w-[800px] max-w-[1600px] max-h-[98vh] transition-colors duration-1000 bg-gradient-to-b p-10 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl border border-gray-100 dark:border-secondary-500">
                  <div className="flex flex-col text-center justify-between items-center gap-4 h-full">
                    <img src={Passo7} alt="Passo7" className="w-28 h-28" />
                    <h3 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-lg">
                      Oba, concluimos a apresentação!
                    </h3>
                    <span className="text-center text-gray-100 text-lg font-medium w-full">
                      Prepare-se para elevar a sua experiência com nossos serviços.
                    </span>
                    <div className="w-full flex items-center gap-4">
                      <button
                        onClick={handleClosePopup}
                        className="bg-[#2D313D] w-full p-2 rounded-full text-center"
                      >
                        Concluir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupTuor;
