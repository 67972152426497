import { useWebSocketContext } from "@hooks/WebSocketContext";
import api from "@services/client/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import { IRequestDTO } from "../types/IRequest";

interface ProfileImageProps {
  order: IRequestDTO;
  user: {
    type_permission: string;
  };
  token: string;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ order, user, token }) => {
  const { websocket } = useWebSocketContext();
  const [profile, setProfile] = useState({
    name: "",
    avatar: "",
    id: "",
    online: false,
    status: "",
    lastSeen: "",
  });
  const [isEditMode, setEditMode] = useState(false);
  const [editedNick, setEditedNick] = useState("");

  useEffect(() => {
    if (order?.id && websocket.socket) {
      websocket.socket.emit("join_room", order.id);
      return () => {
        websocket.socket.emit("leave_room", order.id);
      };
    }
  }, [order, websocket.socket]);

  useEffect(() => {
    updateProfileInfo();
  }, [websocket.roomInfo, profile]);

  const updateProfileInfo = () => {
    if (websocket.roomInfo) {
      const userInRoom = websocket.roomInfo.find(
        (user) => user.auth && user.auth.id === profile.id
      );

      if (userInRoom) {
        const { auth, online, lastSeen, status } = userInRoom;
        setProfile((prev) => ({
          ...prev,
          online: online || false,
          status,
          lastSeen: moment(lastSeen).fromNow(),
        }));
      }
    }
  };

  const getAvatarUrl = (avatar: string) =>
    avatar.startsWith("https")
      ? avatar
      : `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`;

  const initializeProfile = () => {
    switch (user.type_permission) {
      case "ADMIN":
        setProfile({
          name: "Cliente",
          avatar: getAvatarUrl(order.client?.avatar || ""),
          id: order.client_id,
          online: false,
          status: "",
          lastSeen: "",
        });
        break;
      case "CLIENT":
        setProfile({
          name: order.booster?.champion_name || "Aguarde por um booster!",
          avatar: order.booster?.champion_name
            ? `http://ddragon.leagueoflegends.com/cdn/11.24.1/img/champion/${order.booster.champion_name}.png`
            : "",
          id: order.booster?.user_id || "",
          online: false,
          status: "",
          lastSeen: "",
        });
        break;
      default:
        setProfile({
          name: "Cliente",
          avatar: getAvatarUrl(order.client?.avatar || ""),
          id: order.client_id,
          online: false,
          status: "",
          lastSeen: "",
        });
    }
  };

  useEffect(() => {
    initializeProfile();
  }, [order, user]);

  const handleEditClick = () => user.type_permission === "ADMIN" && setEditMode(true);

  const handleConfirmClick = () => {
    api
      .post(
        "/requests/update-nick-admin",
        { nick_game: editedNick, request_id: order.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Nome de usuário atualizado com sucesso!");
        setEditMode(false);
      })
      .catch((error) => toast.error(error.message));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleConfirmClick();
  };

  return (
    <div className="bg-transparent h-10 gap-2 rounded-full flex items-center">
      <img src={profile.avatar} alt={profile.name} className="w-12 h-12 rounded-full" />
      {isEditMode ? (
        <EditMode
          editedNick={editedNick}
          setEditedNick={setEditedNick}
          handleConfirmClick={handleConfirmClick}
          handleKeyDown={handleKeyDown}
        />
      ) : (
        <DisplayMode profile={profile} handleEditClick={handleEditClick} user={user} />
      )}
    </div>
  );
};

const EditMode = ({
  editedNick,
  setEditedNick,
  handleConfirmClick,
  handleKeyDown,
}: {
  editedNick: string;
  setEditedNick: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmClick: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) => (
  <div className="flex items-center gap-2">
    <input
      type="text"
      value={editedNick}
      onChange={(e) => setEditedNick(e.target.value)}
      onKeyDown={handleKeyDown}
      className="bg-blue-20 text-gray-500 font-semibold text-base ml-4 focus:outline-none rounded-full px-4 py-2"
    />
    <button onClick={handleConfirmClick}>
      <ConfirmIcon />
    </button>
  </div>
);

const DisplayMode = ({
  profile,
  handleEditClick,
  user,
}: {
  profile: any;
  handleEditClick: () => void;
  user: { type_permission: string };
}) => (
  <div className="flex items-center w-full gap-2">
    <div className="flex flex-col justify-start">
      <h1 className="text-gray-100 dark:text-gray-500 text-md font-bold">
        {profile.name}
      </h1>
      <StatusIndicator online={profile.online} lastSeen={profile.lastSeen} />
    </div>
    {/* {user.type_permission === "ADMIN" && (
      <button onClick={handleEditClick}>
        <EditIcon />
      </button>
    )} */}
  </div>
);

const StatusIndicator = ({ online, lastSeen }: { online: boolean; lastSeen: string }) => (
  <div className="flex flex-row items-center gap-0.5">
    <div className={`${online ? "bg-[#56BD79]" : "bg-gray-100"} rounded-xl w-2.5 h-2`}></div>
    <span className="text-xs">
      {online ? "Online" : `Offline - Última vez: ${lastSeen}`}
    </span>
  </div>
);

const ConfirmIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 13L10 16L17 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="12" cy="12" r="9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const EditIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3786 8.44975L8.96376 17.8648C8.68455 18.144 8.32895 18.3343 7.94177 18.4117L5.00003 19.0001L5.58838 16.0583C5.66582 15.6711 5.85612 15.3155 6.13532 15.0363L15.5502 5.62132M18.3786 8.44975L19.7929 7.03553C20.1834 6.64501 20.1834 6.01184 19.7929 5.62132L18.3786 4.20711C17.9881 3.81658 17.355 3.81658 16.9644 4.20711L15.5502 5.62132M18.3786 8.44975L15.5502 5.62132"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileImage;
