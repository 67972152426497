import React, { useEffect, useState, useRef } from "react";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { useTranslation } from "react-i18next";
import Loading from "@pages/loading";
import Graph from "@assets/Background/Graph.svg";

type Result = {
  openOrders: number;
  ongoingOrders: number;
  completedOrders: number;
  smurfsCount: number;
  servicesThisMonth: number;
  revenueByService: number;
  revenueBySmurf: number;
  currentMouth: string;
};

const InfoMonth = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [resultsData, setResultsData] = useState<Result | null>(null);
  const { token } = useAuth();
  const [hoverData, setHoverData] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const hoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await api.get("/results", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setResultsData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [token]);

  const handleMouseEnter = (e: React.MouseEvent) => {
    const rect = hoverRef.current?.getBoundingClientRect();
    setHoverData({
      top: e.clientY - (rect?.top ?? 0),
      left: e.clientX - (rect?.left ?? 0),
    });
  };

  const handleMouseLeave = () => {
    setHoverData(null);
  };

  if (loading) {
    return <Loading />;
  }

  if (!resultsData) return null;

  return (
    <div
    onMouseEnter={handleMouseEnter}
    onMouseMove={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    ref={hoverRef}
      className="w-full h-full relative flex flex-col items-center justify-center transition-colors overflow-hidden duration-1000 bg-gradient-to-b py-6 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl p-8 border border-gray-100 dark:border-secondary-500"
    >
      <div className="absolute w-full h-[80%] top-2 mb-[220px] transition-opacity duration-1000 select-none">
        <img src={Graph} alt="Graph" className="w-full h-full" />
      </div>

      <div className="w-full h-full mt-20 p-4 flex flex-col items-center border-gray-500/10 rounded-[9999px] justify-center text-center gap-2 mb-[100px]">
        <div className="px-5 py-1 min-w-[55px] font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 shadow-2xl">
          0%
        </div>
        <h3 className="transition-colors duration-1000 text-gray-100 text-xs sm:text-base">Total</h3>
        {resultsData && (
          <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 text-4xl font-semibold">
            {(
              resultsData.revenueByService + resultsData.revenueBySmurf
            ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
          </h2>
        )}
      </div>

      {hoverData && (
        <div
          className="absolute z-50 bg-blue-20/75 backdrop-blur-sm border border-blue-1001 p-4 text-white rounded-lg shadow-lg"
          style={{ top: hoverData.top, left: hoverData.left }}
        >
          <p className="mb-4 font-semibold text-lg flex items-center gap-3"> <div className="w-2 h-2 rounded-full bg-blue-1001"></div> Serviços: <span className="font-normal text-blue-50 dark:text-gray-500"> {resultsData?.servicesThisMonth} - R${resultsData?.revenueByService} </span> </p>
          <p className="mb-4 font-semibold text-lg flex items-center gap-3"> <div className="w-2 h-2 rounded-full bg-[#ED4C5C]"></div> EBV: <span className="font-normal text-blue-50 dark:text-gray-500"> 0 - R$0.00 </span> </p>
          <p className="font-semibold text-lg flex items-center gap-3"> <div className="w-2 h-2 rounded-full bg-blue-100"></div> Smurfs: <span className="font-normal text-blue-50 dark:text-gray-500"> {resultsData?.smurfsCount} - R${resultsData?.revenueBySmurf} </span> </p>
        </div>
      )}

      <div className="absolute bg-transparent w-full h-full z-20"></div>

      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-10">
        <div className="flex items-center gap-4">
          <div className="w-1 h-12 rounded-full bg-gradient-to-b from-[#2F1DD0] to-gray-500"></div>
          <div className="flex flex-col items-start justify-start text-left">
            <h3 className="text-gray-100 text-xs sm:text-lg">
              {t("table.serviceTitle")}
            </h3>
            {resultsData && (
              <h2 className="text-gray-100 dark:text-gray-500 text-xl font-bold">
                {resultsData.servicesThisMonth}
              </h2>
            )}
          </div> 
        </div>
        <div className="flex items-center gap-4">
          <div className="w-1 h-12 rounded-full bg-gradient-to-b from-[#ED4C5C] to-[#FFA7B0 90%] to-gray-500"></div>
          <div className="flex flex-col items-start justify-start text-left">
            <h3 className="text-gray-100 text-xs sm:text-lg">EBV</h3>
            <h2 className="text-gray-100 dark:text-gray-500 text-xl font-bold">
              0
            </h2>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-1 h-12 rounded-full bg-gradient-to-b from-[#2DC5E4] to-gray-500"></div>

          <div className="flex flex-col items-start justify-start text-left">
            <h3 className="text-gray-100 text-xs sm:text-lg">Smurfs</h3>
            {resultsData && (
              <h2 className="text-gray-100 dark:text-gray-500 text-lg font-bold">
                {resultsData.smurfsCount}
              </h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoMonth;
