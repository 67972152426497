import React, { useState, useEffect } from "react";
import Close from "@assets/Buy/Close.svg";
import moment from "moment";
import { toast } from "react-toastify";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";


interface PopupMCProps {
  userId: string | null;
}

interface ManiaCoinsHistory {
  totalAmount: number;
	expireDate: string;
  expireDateRelative: string;
  expireDateFormatted: string;
}

const PopupMC: React.FC<PopupMCProps> = ({ userId }) => {
  const { token } = useAuth()
  const [showPopup, setShowPopup] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [historyData, setHistoryData] = useState<ManiaCoinsHistory[]>([]);
  const [durationValue, setDurationValue] = useState<number>(30);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const fetchData = async () => {
    try {
        const response = await api.get(`/maniacoins/${userId ? userId : ''}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setHistoryData(response.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Erro ao obter o histórico de ManiaCoins");
    }
};


  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSave = async () => {
    try {
      const response = await api.post("/maniacoins", {
        client_id: `${userId}`,
        amount: Number(inputValue),
        duration: durationValue,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`ManiaCoins criados com sucesso!`);
      setInputValue("");
      fetchData();
    } catch (error) {
      console.error("Error creating ManiaCoins:", error);
      toast.error("Erro ao criar ManiaCoins");
    }
  };

  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-overlay-low">
          <div className="w-2/3 bg-blue-50 rounded-2xl border border-secondary-500 overflow-hidden">
            <div className="bg-blue-20 p-4 rounded-t-2xl mb-4">
              <div className="flex justify-between items-center">
                <div className="text-left">
                  <h2 className="text-gray-500 text-2xl font-bold">
                    Maniacoins do cliente: {historyData && historyData[0] && historyData[0].totalAmount.toLocaleString('pt-BR') || 0}
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
            </div>

            <div className="bg-blue-50 p-4 rounded-b-2xl">
              <div className="mb-4">
                <label htmlFor="valor" className="text-gray-500 text-base">
                  Definir Qtn.
                </label>
                <input
                  type="number"
                  id="valor"
                  min={0}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="mt-2 bg-blue-20 border border-secondary-500 rounded-xl px-4 py-2 w-full outline-none text-base text-gray-500 focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div className="mb-4">
              <label htmlFor="duration" className="text-gray-500 text-base">
  Duração (em dias):
</label>
<input
  type="number"
  id="duration"
  min={0}
  value={durationValue}
  onChange={(e) => setDurationValue(Number(e.target.value))}
  className="mt-2 bg-blue-20 border border-secondary-500 rounded-xl px-4 py-2 w-full outline-none text-base text-gray-500 focus:ring-2 focus:ring-primary-500"
/>

              </div>
              <div className="flex items-end justify-end mt-4">
                <button
                  onClick={handleSave}
                  className="w-full rounded-md font-semibold bg-secondary-500 px-8 py-3 border border-secondary-500 hover:bg-primary-500 hover:text-white transition duration-500 text-sm"
                >
                  Salvar
                </button>
              </div>
            </div>

            <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-500 mt-4">
              <div className="tab-content mt-4">
                <div className="overflow-auto max-h-[570px]">
                  <table className="table-auto w-full">
                    <thead>
                      <tr>
                        <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                          Qtn.:
                        </th>
                        <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                          Expira em:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {historyData.slice(0, 4).map((coin, index) => (
                        <tr
                          key={index}
                          className={`cursor-pointer transition-all duration-300 ease-in-out ${
                            index % 2 === 0
                              ? "bg-gray-200 dark:bg-blue-50"
                              : "bg-white dark:bg-blue-20"
                          } transform hover:translate-y-[-10px] z-100 `}
                        >
                          <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-2">
                            {coin && coin.totalAmount.toLocaleString('pt-BR')}
                          </td>
                          <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-2">
                          {coin.expireDateFormatted}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupMC;
