import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import Request from "@assets/Table/Calendar.svg";
import Matches from "@assets/Table/Matches.svg";
import api from "@services/client/api";
import Loading from "@pages/loading";
import { useAuth } from "@context/AuthContext";
import { useTranslation } from "react-i18next";
import "@styles/hover.css";
import Logo from "@assets/Logo.svg";
import EBV from "@assets/EBV.webp";
import { toast } from "react-toastify";
import { useWebSocketContext } from "@hooks/WebSocketContext";

type Result = {
  openOrders: number;
  ongoingOrders: number;
  completedOrders: number;
  smurfsCount: number;
  servicesThisMonth: number;
  revenueByService: number;
  revenueBySmurf: number;
  currentMouth: string;
};

type AnalyticsData = {
  profit: {
    amount: number;
    growthPercentage: number;
    parcials: {
      booster: string;
      elomania: string; // Adicione o campo para elomania dentro de parcials
    };
  };
  ticket: { amount: number; growthPercentage: number };
  orders: { amount: number; quantity: number };
  smurfs: { amount: number; quantity: number };
  clients: { quantity: number };
};

const GraphMonth = () => {
  const { t } = useTranslation();
  const pieChartRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [resultsData, setResultsData] = useState<Result | null>(null);
  const { token } = useAuth();
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const { websocket } = useWebSocketContext();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await api.get("/results", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setResultsData(response.data);
      } catch (error) {
        console.error("Error fetching results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const loadAnalytics = async () => {
    try {
      const response = await api.get("/results/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const analyticsData: AnalyticsData = response.data.analytics;

      setAnalytics({
        ...analyticsData,
        profit: {
          ...analyticsData.profit,
          amount: parseFloat(
            analyticsData.profit.parcials.elomania
              .replace("R$ ", "")
              .replace(".", "")
              .replace(",", ".")
          ),
        },
      });
    } catch (error: any) {
      toast.warn(error.response?.data?.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAnalytics();
  }, []);

  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 mt-4 gap-4">
      {/* Servicos */}

      <div className="transition-colors h-[400px] overflow-hidden duration-1000 bg-gradient-to-b p-4 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl pt-4 pb-16 border border-gray-100 dark:border-secondary-500">
        <h2 className="w-full transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Request} alt={Request} />
          {t("admin.salestitle")}
        </h2>
        <div className="relative w-full h-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8">
          <div className="absolute top-[-14px] left-1/2 w-[1px] h-1/2 border-r border-blue-1001/10"></div>
          <div className="absolute bottom-[-18px] left-1/2 w-[1px] h-1/2 border-r border-blue-1001/10"></div>

          <div className="w-full h-full flex items-center justify-center gap-6 text-left">
            <div className="flex flex-col gap-2">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-xl">
                Profit
              </h3>
              <h2 className="text-blue-50 dark:text-gray-500 text-2xl font-semibold">
                {analytics?.profit.parcials.elomania || 0}
              </h2>
            </div>

            {analytics?.profit.growthPercentage !== undefined ? (
              <>
                {analytics?.profit.growthPercentage < 0 ? (
                  <div className="px-5 py-1 min-w-[55px] text-blue-50 dark:text-gray-500 flex items-center gap-3 font-semibold text-lg rounded-full border-2 border-red-500 bg-red-500/10 shadow-2xl">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.7075 20.7075L22.7075 26.7075C22.6146 26.8005 22.5043 26.8742 22.3829 26.9246C22.2615 26.9749 22.1314 27.0008 22 27.0008C21.8686 27.0008 21.7385 26.9749 21.6171 26.9246C21.4957 26.8742 21.3854 26.8005 21.2925 26.7075L15.2925 20.7075C15.1049 20.5199 14.9994 20.2654 14.9994 20C14.9994 19.7346 15.1049 19.4801 15.2925 19.2925C15.4801 19.1049 15.7346 18.9994 16 18.9994C16.2654 18.9994 16.5199 19.1049 16.7075 19.2925L21 23.5863V9H4C3.73478 9 3.48043 8.89464 3.29289 8.70711C3.10536 8.51957 3 8.26522 3 8C3 7.73478 3.10536 7.48043 3.29289 7.29289C3.48043 7.10536 3.73478 7 4 7H22C22.2652 7 22.5196 7.10536 22.7071 7.29289C22.8946 7.48043 23 7.73478 23 8V23.5863L27.2925 19.2925C27.4801 19.1049 27.7346 18.9994 28 18.9994C28.2654 18.9994 28.5199 19.1049 28.7075 19.2925C28.8951 19.4801 29.0006 19.7346 29.0006 20C29.0006 20.2654 28.8951 20.5199 28.7075 20.7075Z"
                        fill="#FF5045"
                      />
                    </svg>
                    {Math.abs(Math.round(analytics?.profit.growthPercentage))}%
                  </div>
                ) : (
                  <div className="px-5 py-1 min-w-[55px] flex items-center gap-3 text-blue-50 dark:text-gray-500 font-semibold text-lg rounded-full border-2 border-green-500 bg-green-500/20 shadow-2xl">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 10V19C30 19.2652 29.8947 19.5196 29.7071 19.7071C29.5196 19.8946 29.2652 20 29 20C28.7348 20 28.4804 19.8946 28.2929 19.7071C28.1054 19.5196 28 19.2652 28 19V12.4137L15.7075 24.7075C15.6146 24.8005 15.5043 24.8742 15.3829 24.9246C15.2615 24.9749 15.1314 25.0008 15 25.0008C14.8686 25.0008 14.7385 24.9749 14.6171 24.9246C14.4957 24.8742 14.3854 24.8005 14.2925 24.7075L2.29251 12.7075C2.10487 12.5199 1.99945 12.2654 1.99945 12C1.99945 11.7346 2.10487 11.4801 2.29251 11.2925C2.48015 11.1049 2.73464 10.9994 3.00001 10.9994C3.26537 10.9994 3.51987 11.1049 3.70751 11.2925L15 22.5863L26.5863 11H20C19.7348 11 19.4804 10.8946 19.2929 10.7071C19.1054 10.5196 19 10.2652 19 10C19 9.73478 19.1054 9.48043 19.2929 9.29289C19.4804 9.10536 19.7348 9 20 9H29C29.2652 9 29.5196 9.10536 29.7071 9.29289C29.8947 9.48043 30 9.73478 30 10Z"
                        fill="#48FE91"
                      />
                    </svg>
                    {Math.round(analytics?.profit.growthPercentage)}%
                  </div>
                )}
              </>
            ) : null}
          </div>

          <div className="w-full h-full flex items-center justify-center gap-6 text-left">
            <div className="flex flex-col gap-2">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-xl">
                Ticket Médio
              </h3>
              <h2 className="text-blue-50 dark:text-gray-500 text-2xl font-semibold">
                {analytics?.ticket.amount || 0}
              </h2>
            </div>

            {analytics?.ticket.growthPercentage !== undefined ? (
              <>
                {analytics?.ticket.growthPercentage < 0 ? (
                  <div className="px-5 py-1 min-w-[55px] text-blue-50 dark:text-gray-500 flex items-center gap-3 font-semibold text-lg rounded-full border-2 border-red-500 bg-red-500/10 shadow-2xl">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.7075 20.7075L22.7075 26.7075C22.6146 26.8005 22.5043 26.8742 22.3829 26.9246C22.2615 26.9749 22.1314 27.0008 22 27.0008C21.8686 27.0008 21.7385 26.9749 21.6171 26.9246C21.4957 26.8742 21.3854 26.8005 21.2925 26.7075L15.2925 20.7075C15.1049 20.5199 14.9994 20.2654 14.9994 20C14.9994 19.7346 15.1049 19.4801 15.2925 19.2925C15.4801 19.1049 15.7346 18.9994 16 18.9994C16.2654 18.9994 16.5199 19.1049 16.7075 19.2925L21 23.5863V9H4C3.73478 9 3.48043 8.89464 3.29289 8.70711C3.10536 8.51957 3 8.26522 3 8C3 7.73478 3.10536 7.48043 3.29289 7.29289C3.48043 7.10536 3.73478 7 4 7H22C22.2652 7 22.5196 7.10536 22.7071 7.29289C22.8946 7.48043 23 7.73478 23 8V23.5863L27.2925 19.2925C27.4801 19.1049 27.7346 18.9994 28 18.9994C28.2654 18.9994 28.5199 19.1049 28.7075 19.2925C28.8951 19.4801 29.0006 19.7346 29.0006 20C29.0006 20.2654 28.8951 20.5199 28.7075 20.7075Z"
                        fill="#FF5045"
                      />
                    </svg>
                    {Math.abs(Math.round(analytics?.ticket.growthPercentage))}%
                  </div>
                ) : (
                  <div className="px-5 py-1 min-w-[55px] flex items-center gap-3 text-blue-50 dark:text-gray-500 font-semibold text-lg rounded-full border-2 border-green-500 bg-green-500/20 shadow-2xl">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 10V19C30 19.2652 29.8947 19.5196 29.7071 19.7071C29.5196 19.8946 29.2652 20 29 20C28.7348 20 28.4804 19.8946 28.2929 19.7071C28.1054 19.5196 28 19.2652 28 19V12.4137L15.7075 24.7075C15.6146 24.8005 15.5043 24.8742 15.3829 24.9246C15.2615 24.9749 15.1314 25.0008 15 25.0008C14.8686 25.0008 14.7385 24.9749 14.6171 24.9246C14.4957 24.8742 14.3854 24.8005 14.2925 24.7075L2.29251 12.7075C2.10487 12.5199 1.99945 12.2654 1.99945 12C1.99945 11.7346 2.10487 11.4801 2.29251 11.2925C2.48015 11.1049 2.73464 10.9994 3.00001 10.9994C3.26537 10.9994 3.51987 11.1049 3.70751 11.2925L15 22.5863L26.5863 11H20C19.7348 11 19.4804 10.8946 19.2929 10.7071C19.1054 10.5196 19 10.2652 19 10C19 9.73478 19.1054 9.48043 19.2929 9.29289C19.4804 9.10536 19.7348 9 20 9H29C29.2652 9 29.5196 9.10536 29.7071 9.29289C29.8947 9.48043 30 9.73478 30 10Z"
                        fill="#48FE91"
                      />
                    </svg>
                    {Math.round(analytics?.ticket.growthPercentage)}%
                  </div>
                )}
              </>
            ) : null}
          </div>

          <div className="w-full h-full flex items-center justify-center gap-6 text-left border-t  border-blue-1001/10">
            <div className="flex flex-col gap-2">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-xl">
                Visitantes
              </h3>
              <h2 className="text-blue-50 dark:text-gray-500 text-2xl font-semibold">0</h2>
            </div>

            <div className="px-5 py-1 min-w-[55px] flex items-center gap-3 text-blue-50 dark:text-gray-500 font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-1001/20 shadow-2xl">
              0%
            </div>
          </div>

          <div className="w-full h-full flex items-center justify-center gap-6 text-left border-t  border-blue-1001/10">
            <div className="flex flex-col gap-2">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-xl">
                Novos Clientes
              </h3>
              <h2 className="text-blue-50 dark:text-gray-500 text-2xl font-semibold">
                {analytics?.clients.quantity || "0"}
              </h2>
            </div>
            <div className="px-5 py-1 min-w-[55px] flex items-center gap-3 text-blue-50 dark:text-gray-500 font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-1001/20 shadow-2xl">
              0%
            </div>
          </div>
        </div>
      </div>

      {/* Vendas */}
      <div className="transition-colors duration-1000 relative h-[400px] flex flex-col items-center justify-between gap-4 overflow-hidden bg-gradient-to-b py-6 from-[#02061B] via-[#101230] to-[#101230] rounded-3xl border border-secondary-500">
        <div className="absolute inset-0 flex justify-center items-center">
          <video
            src="https://app-elomania-v2-uploads.s3.amazonaws.com/scenes/scenes-5.mp4"
            autoPlay
            muted
            loop
            className="object-cover w-full h-full"
          ></video>
          <div
            className="absolute left-0 top-0 rounded-3xl w-full h-full"
            style={{
              background:
                "linear-gradient(0.75deg, #02061B -3.4%, rgba(34, 16, 20, 0) 115.43%)",
            }}
          ></div>
        </div>

        <div className="absolute z-10 h-full w-full flex flex-col items-start justify-between px-4 pt-4 pb-[86px]">
          <div className="w-full flex items-center justify-center gap-4 ">
            <div className="text-gray-500 rounded-full flex items-center gap-4 font-semibold px-10 py-4 transition duration-500 text-sm">
              <div className="b-online rounded-full animate-pulse shadow-2xl"></div>
              <img src={Logo} alt="Logo" className="w-12" />
              <div className="px-5 py-1 min-w-[55px] font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-1001/20 shadow-2xl">
                {websocket.usersCount?.home || "0"}
              </div>
            </div>
            <div className="text-gray-500 rounded-full flex items-center gap-4 font-semibold px-10 py-4 transition duration-500 text-sm">
              <div className="b-online rounded-full animate-pulse shadow-2xl"></div>
              <img src={EBV} alt="Logo" className="w-12" />
              <div className="px-5 py-1 min-w-[55px] font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-1001/20 shadow-2xl">
                {websocket.usersCount?.valorant || "0"}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gradient-to-r w-full min-h-[80px] pl-5 flex flex-col items-start justify-center text-left from-[rgba(73,56,255,0.24)] to-[rgba(73,56,255,0)]  border-l-8 border-l-[#4938FF] rounded-[8px_19px_19px_8px]">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-lg">
                {t("admin.inqueue")}
              </h3>
              {resultsData && (
                <h2 className="text-gray-500 text-lg font-bold">
                  {resultsData.openOrders}
                </h2>
              )}
            </div>
            <div className="bg-gradient-to-r w-full min-h-[80px] pl-5 flex flex-col items-start justify-center text-left from-[rgba(73,56,255,0.24)] to-[rgba(73,56,255,0)] border-l-8 border-l-[#4938FF] rounded-[8px_19px_19px_8px]">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-lg">
                {t("table.currentTitle")}
              </h3>
              {resultsData && (
                <h2 className="text-gray-500 text-lg font-bold">
                  {resultsData.ongoingOrders}
                </h2>
              )}
            </div>
            <div className="bg-gradient-to-r w-full min-h-[80px] pl-5 flex flex-col items-start justify-center text-left from-[rgba(73,56,255,0.24)] to-[rgba(73,56,255,0)] border-l-8 border-l-[#4938FF] rounded-[8px_19px_19px_8px]">
              <h3 className="text-gray-100 font-semibold text-xs sm:text-lg">
                Finalizados
              </h3>
              {resultsData && (
                <h2 className="text-gray-500 text-lg font-bold">
                  {resultsData.completedOrders}
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphMonth;
