import React, { useEffect, useRef, useState } from "react";
import AutoMessage from "./message_auto";
import ChatMessage from "./message_bubble";
import { useWebSocketContext } from "@hooks/WebSocketContext";
import api from "@services/client/api";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { IRequestDTO } from "../types/IRequest";
import { IUser } from "@utils/types";
import MessageInput from "./message_input";

interface ICreateMessage {
    room_id: string;
    author_id: string;
    content: string;
}

interface IResponseMessage {
    id: string;
    room_id: string;
    author_type: string;
    content: string;
    created_at: string;
}

interface ChatContainerProps {
    user: IUser;
    token: string;
    order: IRequestDTO;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ user, token, order }) => {
    const chatDiv = useRef<HTMLDivElement>(null);
    const { websocket } = useWebSocketContext();
    const [messages, setMessages] = useState<IResponseMessage[]>([]);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (!user && !order) return;

        const loadMessages = async () => {
            try {
                const response = await api.get(`/messages/${user.id}/${order.id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setMessages(response.data);
                chatDiv.current?.scrollTo(0, chatDiv.current.scrollHeight);

                websocket.socket.emit("join_room", order.id);
            } catch (error: any) {
                if (error instanceof AxiosError) {
                    toast(`Error: ${error?.response?.data?.message}`);
                }
            }
        };
        loadMessages();
    }, []);

    const sendMessage = async () => {
        if (inputText.trim().length < 1) return;
        const message: ICreateMessage = { room_id: order.id, author_id: user.id, content: inputText };
        websocket.socket.emit("send_message", message);
        setInputText(""); 
    };

    useEffect(() => {
        const receiveMessage = () => {
            websocket.socket.on("receive_message", (packet: IResponseMessage) => {
                if (packet.room_id === order.id) {
                    setMessages((prevMessages) => [...prevMessages, packet]);
                    chatDiv.current?.scrollTo(0, chatDiv.current.scrollHeight);
                }
            });
            return () => websocket.socket.off("receive_message");
        };
        receiveMessage();
    }, []);

    return (
        <div className="transition-colors duration-1000 bg-transparent rounded-2xl mt-4">
            <div className="chat bg-white-100 dark:bg-blue-50 relative p-2 border-t border-secondary-500 min-h-[500px] max-h-[500px] overflow-y-auto" ref={chatDiv}>
                {user?.type_permission === "CLIENT" && (
                    <AutoMessage
                        name="Blitz Bot"
                        message="A Elomania agradece a preferência. Termine de preencher os dados da conta. Qualquer dúvida é só responder no chat que o Booster irá lhe ajudar. 🚀"
                    />
                )}
                {user?.type_permission === "BOOSTER" && (
                    <AutoMessage
                        name="Ryze Bot"
                        message="Saudações, booster! Não troque contatos externos com o cliente. Qualquer tentativa de desvio de clientes é proibido. Bons games! 🚀"
                    />
                )}
                {messages.length > 0 ? (
                    messages.map((item) => (
                        <ChatMessage
                            key={item.id}
                            message={item.content}
                            isSelf={item.author_type === user.type_permission}
                            senderName={item.author_type}
                            time={item.created_at}
                            user={user}
                        />
                    ))
                ) : (
                    <div className="w-full min-h-[450px] max-h-[450px] flex justify-center items-center">
                        Não há mensagens.
                    </div>
                )}
            </div>
            <MessageInput
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onSend={sendMessage}
                onEnterPress={(e) => e.key === "Enter" && sendMessage()}
            />
        </div>
    );
};

export default ChatContainer;
