const modifyStyles = (color: string | undefined, isDisabled: boolean) => {
    return {
        option: (provided: any, state: any) => ({
            ...provided,
            display: "flex",
            width: '100%',
            alignItems: "center",
            fontSize: "12px",
            color: "#b0b0b0",
            cursor: "pointer",
            borderRadius: "4px",
            marginTop: '4px',
            background: state.isSelected
                ? "#232630"
                : state.isFocused
                    ? "#232630"
                    : "transparent",
            "&:active": {
                background: "#232630",
            },
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            display: "inline-flex",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            boxShadow: "none",
            width: "100%",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "normal",
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            display: 'flex',
            backgroundColor: color ? color : '#16191D',
            borderRadius: "4px",
            zIndex: 9999,
            padding: '12px',
            width: '100%'
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            padding: 0,
            width: '100%',
        }),
        input: (provided: any) => ({
            ...provided,
        }),
    }
}

export default modifyStyles;
