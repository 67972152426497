import { useState, useEffect } from "react";
import { useAuth } from "@context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Logo from "@assets/LogoActive.svg";
import BtnGoogle from "@assets/Login/BtnGoogle.svg";
import BtnDiscord from "@assets/Login/BtnDiscord.svg";
import DiscordProvider from "@components/Providers/discord";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import "@styles/input.css";
import { toast } from "react-toastify";

const googleId = process.env.REACT_APP_GOOGLE_ID;

if (!googleId) {
  throw new Error("GOOGLE ID is not defined");
}

enum AuthType {
  Common = "common",
  Google = "google",
  Discord = "discord",
}

interface SignInCredentials {
  authtype: AuthType;
  email: string;
  password: string;
}

type Props = {};

const SignIn = (props: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [nick_name, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [discord, setDiscord] = useState("");
  const [loading, setLoading] = useState(false);
  const { loaded, token, signIn, signUp } = useAuth();

  const [showRegistration, setShowRegistration] = useState(true);

  if (loaded && token) {
    navigate("/dashboard");
  }

  const handleGoogleSignIn = async (credential?: string) => {
    setLoading(true);
    try {
      if (credential) {
        await signIn({
          authtype: "google",
          credentials: { credential },
        });
        setLoading(false);
        navigate("/dashboard");
      } else {
        throw new Error("Google credential is undefined");
      }
    } catch (error) {
      console.error("Erro ao realizar o login com o Google:", error);
      toast.error("Erro ao realizar o login com o Google");
      setLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const fetchToken = async () => {
      try {
        if (code) {
          setLoading(true);
          await signIn({
            authtype: "discord",
            code: code,
          });
          setLoading(false);
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Erro ao obter o token do Discord:", error);
        toast.error("Erro ao obter o token do Discord");
      }
    };

    fetchToken();
  }, [signIn, navigate]);

  const handleSignIn = async () => {
    setLoading(true);
    try {
      await signIn({ email, password, authtype: "common" });
      setLoading(false);
    } catch (error: any) {
      toast.error(error.toString());
      setLoading(false);
    }
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  useEffect(() => {
    const emailLocalStorage = localStorage.getItem("@EloMania:user");
    const parsedEmail = emailLocalStorage
      ? JSON.parse(emailLocalStorage).email
      : null;
    setEmail(parsedEmail);
  }, []);

  const handleRegisterClick = () => {
    setShowRegistration(!showRegistration);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const maskPhone = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value.substring(0, 15);
  };

  const handleDiscordChange = (e: any) => {
    setDiscord(e.target.value);
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleNickChange = (e: any) => {
    setNick(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleClick = async (e: any) => {
    try {
      setLoading(true);
      if (showRegistration) {
        const credentials: SignInCredentials = {
          authtype: AuthType.Common,
          email,
          password,
        };
        await signIn(credentials);
        toast.success("Login realizado com sucesso!");
      } else {
        const userData = {
          name,
          nick_name,
          email,
          password,
          telephone,
          discord,
        };

        await signUp(userData);
        toast.success("Cadastro realizado com sucesso!");
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className="bg-blue-20 flex justify-center items-center min-h-screen relative">
      <div className="absolute inset-0 flex justify-center items-center">
        <video
          src="https://app-elomania-v2-uploads.s3.amazonaws.com/scenes/scenes-5.mp4"
          autoPlay
          muted
          loop
          className="object-cover w-full h-full z-0"
        ></video>
      </div>
      <div className="box z-10 bg-blue-20/20 relative border border-secondary-500 w-full sm:w-[420px] h-[630px] rounded-lg overflow-hidden">
        <div className="form bg-blue-50/10 backdrop-blur-md shadow-inner absolute flex flex-col p-4 sm:p-10 rounded-lg inset-[2px] z-10">
          <div className="mx-auto">
            <img src={Logo} alt={Logo} />
          </div>
          {showRegistration ? (
            <div className="step-login">
              <div className="w-full flex items-center justify-center gap-2 text-center text-gray-100 text-base font-semibold mt-6">
                <p> Faça login ou </p>
                <button
                  onClick={handleRegisterClick}
                  className="text-center text-blue-100 text-base font-semibold"
                >
                  Cadastre-se
                </button>
              </div>
              <div className="inputBox relative mt-10 w-full sm:w-[340px]">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  name="mail"
                  id="mail"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  E-mail
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="inputBox relative mt-10 w-full sm:w-[340px]">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  name="password"
                  id="password"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                  onKeyUp={handleEnterKeyPress}
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  Senha
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="link flex justify-between items-center mt-4">
                <a href=""></a>
                <Link
                  to="/auth/forgot-password"
                  className="text-gray-100 text-sm hover:text-blue-1001"
                >
                  {" "}
                  Esqueci minha senha{" "}
                </Link>
              </div>
              <div className="mt-10">
                <button
                  className="bg-blue-1001 text-gray-500 w-full py-2 rounded-lg cursor-pointer outline-none border-none hover:ring focus:ring"
                  onClick={handleSignIn}
                >
                  {loading ? "Loading..." : "Entrar"}
                </button>
              </div>
              <div className="relative w-full my-10 flex justify-between items-center gap-4">
                <div className="flex-grow border-t border-gray-100"></div>
                <span className="px-4 text-gray-100 text-base tracking-wider pointer-events-none">
                  Ou
                </span>
                <div className="flex-grow border-t border-gray-100"></div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex p-1.5 items-start space-x-1 rounded-lg bg-white bg-opacity-10">
                  <GoogleOAuthProvider clientId={googleId}>
                    <GoogleLogin
                      type="icon"
                      size="large"
                      onSuccess={(credentialResponse) =>
                        handleGoogleSignIn(credentialResponse.credential)
                      }
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
                <button className="px-2">
                  <DiscordProvider>
                    <img src={BtnDiscord} alt={BtnDiscord} />
                  </DiscordProvider>
                </button>
              </div>
            </div>
          ) : (
            <div className="step-register">
              <div className="w-full flex items-start justify-center gap-2 text-center text-gray-100 text-base font-semibold mt-6">
                <p> Faça Cadastro ou </p>
                <button
                  onClick={handleRegisterClick}
                  className="text-center text-blue-100 text-base font-semibold"
                >
                  Login
                </button>
              </div>
              <div className="inputBox relative mt-6 w-full sm:w-[340px]">
                <input
                  value={name}
                  onChange={handleNameChange}
                  type="text"
                  name="name"
                  id="name"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  Nome
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="inputBox relative mt-6 w-full sm:w-[340px]">
                <input
                  value={email}
                  onChange={handleEmailChange}
                  type="text"
                  name="mail"
                  id="mail"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  E-mail
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="inputBox relative mt-6 w-full sm:w-[340px]">
                <input
                  value={password}
                  onChange={handlePasswordChange}
                  name="password"
                  type="password"
                  id="password"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  Senha
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="inputBox relative mt-6 w-full sm:w-[340px]">
                <input
                  value={telephone}
                  onChange={(e) => setTelephone(maskPhone(e.target.value))}
                  type="text"
                  name="telephone"
                  id="telephone"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  Telefone
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="inputBox relative mt-6 w-full sm:w-[340px]">
                <input
                  value={discord}
                  onChange={handleDiscordChange}
                  type="text"
                  name="discord"
                  id="discord"
                  required
                  className="bg-transparent relative w-full text-gray-100 text-base p-[10px] pt-5 tracking-wider outline-none border-none"
                />
                <span className="absolute text-gray-100 text-base px-0 pt-5 pb-[10px] left-0 tracking-wider pointer-events-none">
                  Discord
                </span>
                <i className="bg-blue-1001 absolute left-0 bottom-0 w-full h-[2px] rounded-md transition duration-150 pointer-events-none"></i>
              </div>
              <div className="mt-4">
                <button
                  className={`bg-blue-1001 text-gray-500 w-full py-2 rounded-lg cursor-pointer outline-none border-none hover:ring focus:ring ${
                    !isChecked ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleClick}
                  disabled={!isChecked}
                >
                  {loading ? "Loading..." : "Cadastrar"}
                </button>
              </div>
              <div className="w-full flex items-center gap-2 text-start text-gray-100 text-[10px] font-medium mt-4">
                <input
                  type="checkbox"
                  className="rounded-xl"
                  onChange={handleCheckboxChange}
                />
                <p>
                  Concordo com os
                  <a
                    href="https://www.elomania.com/pt-br/terms"
                    className="underline text-blue-100"
                    target="_blank"
                  >
                    {" "}
                    Termos e Condições{" "}
                  </a>{" "}
                  da Elomania.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
