import React, { useState, useEffect } from "react";
import Smurf from "@assets/Table/Client.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import Delete from "@assets/Table/Delete.svg";
import PopupInfoSmurf from "@components/Dashboard/mainDashboard/Adm/(Smurf)/(modals)/info";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { ISmurfAccount } from "@utils/types";
import { useTranslation } from "react-i18next";

type Props = {};

interface SmurfData {
  id: number;
  servidor: string;
  nivel: number;
  ea: number;
  skins: string[];
}

interface SoldSmurfData extends SmurfData {
  cliente: string;
}

const TableMySmurfBooster = (props: Props) => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState<ISmurfAccount[]>([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [idInfoAccount, setIdInfoAccount] = useState<string>("");
  const { token } = useAuth();

  const handleInfoModal = (id: string) => {
    setIdInfoAccount(id);
    setShowInfoModal(true);
  };

  const [availableSmurfs, setAvailableSmurfs] = useState([]);

  useEffect(() => {
    async function loadAccounts() {
      try {
        const response = await api.get("/account-smurfs/smurf-by-client", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Convert the skins string to an array if it's not already
        const updatedAccounts = response.data.map((account: ISmurfAccount) => {
          return {
            ...account,
            skins: account.skins.split(","), // Assuming skins are comma-separated
          };
        });

        setAccounts(updatedAccounts);
      } catch (error: any) {
        // Handle error for account smurfs
        console.error("Error fetching account smurfs:", error);
      }
    }

    async function fetchAvailableSmurfs() {
      try {
        const response = await api.get("/account-smurfs/fsdfererth/available", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAvailableSmurfs(response.data);
      } catch (error) {
        console.error("Error fetching available smurfs:", error);
      }
    }

    loadAccounts();
    fetchAvailableSmurfs();
  }, [token]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Smurf} alt="Smurf" />
          {t("table.smurfs")}
        </h2>
      </div>
      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto max-h-[550px]">
          {accounts.length === 0 ? (
            <p className="text-gray-100 font-semibold text-base text-center py-6">
              {t("table.nothingSmurf")}
            </p>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.server")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.level")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.be")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Skins
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      {data.server}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.level}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.blueessence}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.skins.toString().replace(/-/g, " | ")}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <button
                        className="p-2"
                        onClick={() => handleInfoModal(String(data.id))}
                      >
                        <img src={FileSearch} alt="FileSearch" />
                      </button>
                      <button className="p-2">
                        <img src={Delete} alt="Delete" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {showInfoModal && <PopupInfoSmurf id={idInfoAccount} />}
    </div>
  );
};

export default TableMySmurfBooster;
