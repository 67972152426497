
import { useEffect, useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import './style.css'
import { useCheckout } from "@context/CheckoutContextProvider"
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import { ICoupon } from "@utils/types";
import maniacoin from '@assets/checkout/maniacoin.png'
import CurrencyDisplay, { currencies } from "@hooks/Currency";

const Coupon: React.FC = () => {
    const { token, user } = useAuth()
    const { couponName, checkoutPrice, checkout, setCoupon } = useCheckout()
    const [cupom, setCupom] = useState<string>('');
    const [couponStatus, setCouponStatus] = useState<'not-found' | 'found' | 'applied' | null>(null);
    const [coupons, setCoupons] = useState<ICoupon[]>([]);
    const [couponsFetched, setCouponsFetched] = useState(false);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        const ApplyCoupon = async () => {
            if (couponName) {
                try {
                    setCupom(couponName.toUpperCase())
                    await setCoupon(couponName.toUpperCase(), 'add');
                    setCouponStatus('applied');
                } catch (error) {
                    console.error('Erro ao aplicar o cupom:', error);
                    setCouponStatus('not-found');
                }
            }
        };

        ApplyCoupon();
    }, [couponName]);

    const handleAplicarCupom = async () => {
        if (couponStatus && couponStatus === 'found') {
            try {
                await setCoupon(cupom.toUpperCase(), 'add');
                setCouponStatus('applied');
            } catch (error) {
                console.error('Erro ao aplicar o cupom:', error);
                setCouponStatus('not-found');
            }
        }
    };

    const handleCupon = (coupon: string) => {
        setCupom(coupon)

        if (coupons.length === 0) return;

        if (coupon.length === 0) {
            setCouponStatus(null);
            return;
        }

        const foundCoupon = coupons.find((couponItem: ICoupon) => couponItem.name === coupon.toUpperCase());
        if (!foundCoupon) {
            setCouponStatus('not-found');
            return;
        }
        else {
            setCouponStatus('found');
        }
    };

    const handleClearCupon = async () => {
        if (cupom || couponName) {
            setCupom('')
            setCouponStatus(null);
            await setCoupon('', 'remove');
        }
    };

    useEffect(() => {
        if (!token || !user) {
            return;
        }

        const fetchCoupons = async () => {
            try {
                const response = await api.get(`/coupons/list-all/${user.id ? user.id : 'none'}`);
                const { data } = response;
                setCoupons(data);
                setCouponsFetched(true);
            } catch (error) {
                console.error('Error fetching coupons:', error);
            }
        };

        if (!couponsFetched) {
            fetchCoupons();
        }
    }, [user, couponsFetched]);

    const getCouponStatusClass = () => {
        switch (couponStatus) {
            case 'found':
                return 'border-[#564FFF]';
            case 'not-found':
                return 'border-[#FF4661]';
            case 'applied':
                return 'border-[#564FFF]';
            default:
                return 'border-[#E3E8EF]';
        }
    };

    const getCouponStatusClassSvg = () => {
        switch (couponStatus) {
            case 'found':
                return '#7A5CFA';
            case 'not-found':
                return '#FF4661';
            case 'applied':
                return '#7A5CFA';
            default:
                return '#97A3B6';
        }
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className={`flex items-center p-[10px] rounded-[5px] border-[1.5px] border-slate-800 justify-between transition-all duration-700 bg-blue-20 ${getCouponStatusClass()} ${couponStatus === 'applied' ? 'filter drop-shadow-lg' : ''}`}>
                <div className={`flex items-center gap-[8px] w-full`} >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill=""
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            className="transition-all duration-700"
                            d="M17.7513 9.03896C18.0763 9.03896 18.3346 8.78062 18.3346 8.45562V7.68062C18.3346 4.26396 17.293 3.22229 13.8763 3.22229H6.1263C2.70964 3.22229 1.66797 4.26396 1.66797 7.68062V8.07229C1.66797 8.39729 1.9263 8.65562 2.2513 8.65562C3.0013 8.65562 3.60964 9.26396 3.60964 10.014C3.60964 10.764 3.0013 11.364 2.2513 11.364C1.9263 11.364 1.66797 11.6223 1.66797 11.9473V12.339C1.66797 15.7556 2.70964 16.7973 6.1263 16.7973H13.8763C17.293 16.7973 18.3346 15.7556 18.3346 12.339C18.3346 12.014 18.0763 11.7556 17.7513 11.7556C17.0013 11.7556 16.393 11.1473 16.393 10.3973C16.393 9.64729 17.0013 9.03896 17.7513 9.03896ZM7.5013 7.40562C7.95964 7.40562 8.33464 7.78062 8.33464 8.23896C8.33464 8.69729 7.96797 9.07229 7.5013 9.07229C7.04297 9.07229 6.66797 8.69729 6.66797 8.23896C6.66797 7.78062 7.03464 7.40562 7.5013 7.40562ZM12.5013 13.239C12.0346 13.239 11.6596 12.864 11.6596 12.4056C11.6596 11.9473 12.0346 11.5723 12.493 11.5723C12.9513 11.5723 13.3263 11.9473 13.3263 12.4056C13.3263 12.864 12.968 13.239 12.5013 13.239ZM13.2513 7.90562L7.64297 13.514C7.51797 13.639 7.35964 13.6973 7.2013 13.6973C7.04297 13.6973 6.88464 13.639 6.75964 13.514C6.51797 13.2723 6.51797 12.8723 6.75964 12.6306L12.368 7.02229C12.6096 6.78062 13.0096 6.78062 13.2513 7.02229C13.493 7.26396 13.493 7.66396 13.2513 7.90562Z"
                            fill={`${getCouponStatusClassSvg()}`}
                        />
                    </svg>
                    <input
                        type="text"
                        placeholder="Ex.: MANIA10"
                        disabled={couponStatus === 'applied'}
                        value={cupom}
                        onChange={(e) => handleCupon(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleAplicarCupom();
                            }
                          }}
                        className={`${couponStatus === 'applied' ? 'text-[#7A5CFA]' : 'text-slate-100 bg-blue-20'} bg-transparent font-poppins font-medium text-xs w-full outline-none border-none pr-4 uppercase `}
                    />
                </div>
                {couponStatus === 'applied'
    ? (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="relative"
        >
            {!hovered ? (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition-all duration-700"
                >
                    <path
                        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z"
                        fill="#7758F7"
                    />
                </svg>
            ) : (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer transition-all duration-700"
                    onClick={handleClearCupon}
                >
                    <path
                        d="M5 5L15 15M5 15L15 5L5 15Z"
                        stroke="#7758F7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </div>
    )
    : couponStatus === 'found' || couponStatus === null
        ? <div onClick={handleAplicarCupom} className="font-poppins font-medium text-xs text-[#BFBFBF] cursor-pointer select-none">Aplicar</div>
        : null
}
            </div>
            {couponStatus === 'applied'
                ? <span className="font-poppins font-semibold text-xs leading-18 text-[#7A5CFA] select-none">Parabéns, você ganhou {<CurrencyDisplay price={Number(checkoutPrice?.totalCouponDiscount)} reference={'BRL'} destination={checkout?.currency as keyof typeof currencies || 'BRL'} convert={false} />} de desconto!</span>
                : null
            }
        </div>
    );    
};

const OfferItem: React.FC<{
    title: string;
    tooltipContent: string;
    toggleId: string;
    extraText: string;
}> = ({ title, tooltipContent, toggleId, extraText }) => {
    const { toggleManiacoins, checkout } = useCheckout()


    return (
        <div className="flex flex-row items-end gap-5 justify-between w-full">
            <div className="flex flex-col justify-center items-start whitespace-nowrap gap-1">
                <div className="flex flex-row justify-center items-center gap-1">
                    <img src={maniacoin} alt="Mania" width={20} height={20} />
                    <div className="flex items-center text-sm font-semibold tracking-normal leading-5 text-white">
                        <div className="font-poppins font-semibold text-sm leading-20 text-[#A7A7C8]">{title}</div>
                    </div>

                </div>
                <div className="text-xs text-slate-300 font-medium text-opacity-50">
                    {extraText}
                </div>
            </div>
            <label className="bg-blue-50 switch" htmlFor={toggleId}>
                <input
                    className="switch__input"
                    type="checkbox"
                    role="switch"
                    id={toggleId}
                    checked={checkout && checkout.maniaStatus || false}
                    onChange={toggleManiacoins}
                />
                <svg className="switch__check bg-[#dfdfdf] rounded-full" viewBox="0 0 16 16" width="16px" height="16px">
                    <polyline className="switch__check-line" fill="none" stroke="hsl(var(--hue),10%,50%)" strokeDasharray="9 9" strokeDashoffset="3.01" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="5,8 11,8 11,11" />
                </svg>
                <span className="switch__sr">Power</span>
            </label>

        </div>
    );
};

const MoreOffers = () => {
    const { user } = useAuth();
    const { checkout, checkoutPrice } = useCheckout()

    if (!user && !checkout) {
        return null;
    }

    return (
        <div className="h-full flex flex-col justify-center w-full max-md:max-w-full gap-4">
            <div className="flex flex-col gap-2">
                <div className="text-slate-100 font-inter font-medium text-sm">
                    Possui um cupom? Utilize-o abaixo.
                </div>
                <Coupon />

            </div>
            <div className="flex flex-col gap-6">
                <OfferItem
                    title="Maniacoins"
                    tooltipContent="Você ganha maniacoins ao adquirir serviços em nosso site! Seus maniacoins serão usados para gerar ainda mais descontos em sua próxima compra!"
                    toggleId="maniacoin"
                    extraText={`Use seus maniacoins e receba até 20% de desconto`}
                />
                {/* 
                <OfferItem
                    title="Marcar como um presente"
                    tooltipContent="Dê um presente especial a alguém."
                    toggleId="prize"
                    extraText="Dê um presente especial a alguém especial."
                /> */}
            </div>
        </div>
    );
};

export default MoreOffers;
