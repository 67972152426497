import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { useAuth } from "@context/AuthContext";
import { ServiceRequests } from "@utils/types";
import { toast } from "react-toastify";
import api from "@services/client/api";

import Spin from "@assets/Spin.svg";
import Close from "@assets/Buy/Close.svg";
import Client from "@assets/Table/Client.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";

type Props = {
  orderId: string;
};

type FormFeedbackData = {
  avaliation: number;
  booster: string;
  type_service: string;
  whatsapp: string;
  invocador: string;
  mensagem: string;
};

const maskPhone = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value.substring(0, 15);
};

const PopupInfoFeedback = ({ orderId }: Props) => {
  const { token } = useAuth();

  const [showPopup, setShowPopup] = useState(true);

  const { register, handleSubmit, reset } = useForm<FormFeedbackData>({
    mode: "all",
    criteriaMode: "all",
  });

  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState<ServiceRequests>();
  const [values, setValues] = useState<FormFeedbackData>();

  const getOrder = async () => {
    try {
      const response = await api.patch(
        `/requests/search-by-id/6e1a87f751b12e4b310ecbeu2034018/${orderId}`,
        {
          toSecure: "66dthyt6grgrtfdsfsf",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data as ServiceRequests;
      setOrder(data);

      setValues({
        avaliation: data.feedback_points ?? 1,
        mensagem: data.feedback_text ?? "",
        invocador: data.is_feedback ? data.nick_game : "",
        booster: data.booster.champion_name,
        type_service: data.type_service,
        whatsapp: data.client.telephone || "",
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`Error: ${err?.response?.data?.message}`);
        return;
      }

      toast.error(`O pedido não pode ser carregado, tente novamente.`);
      setShowPopup(false);
      console.error(err);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  useEffect(() => {
    reset(values);
  }, [values]);

  const onSubmit = async (formData: FormFeedbackData) => {
    try {
      await api.patch(
        `requests/update-feedback/${orderId}`,
        {
          point: formData.avaliation,
          text: formData.mensagem,
          invoker: formData.invocador,
          whatsapp: formData.whatsapp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.info(`Obrigado por enviar o feedback! 💛`);
      setShowPopup(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`Error: ${err?.response?.data?.message}`);
      }

      toast.error(`Não foi possivel enviar o feedback, tente novamente.`);
      setShowPopup(false);
      console.error(err);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (!loaded || !order) {
    return (
      <>
        {showPopup && (
          <div className="bg-overlay">
            <div
              className={`fixed z-10 inset-0 flex justify-center items-center`}
            >
              <div className="bg-blue-50 w-5/6 h-5/6 mx-auto border border-secondary-500 rounded-3xl py-4 px-4">
                <div className="flex justify-center items-center bg-blue-20 w-full h-full p-4 rounded-3xl mb-4">
                  <img
                    src={Spin}
                    alt={Spin}
                    className="animate-spin select-none"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Client} alt={Client} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    Feedback:
                  </h2>
                    <h2 className="mr-2 text-gray-500 text-2xl font-normal">
                      {" "}
                      #{order.ecommerce_id}
                    </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="bg-info-feedback bg-center bg-cover flex flex-col items-center justify-center rounded-xl p-4 h-52">
                <div className="bg-secondary-500 w-24 h-24 rounded-xl">
                  <img
                    src={`https://app-elomania-v2-uploads.s3.amazonaws.com/${order.client.avatar}`}
                    alt="Perfil"
                    className="w-full h-full rounded-2xl"
                  />
                </div>
                <h3 className="text-gray-500 font-semibold text-base mt-2">
                  {" "}
                  {order.client.name}
                </h3>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                  <div className="w-full">
                    <h2> Jogo: </h2>
                    <input
                      type="text"
                      disabled={true}
                      value={order.game_service}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-full">
                    <h2> Avaliação: </h2>
                    <input
                      {...register("avaliation")}
                      type="number"
                      min={1}
                      max={5}
                      id="avaliacao"
                      disabled={order.is_feedback}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-full">
                    <h2> Booster: </h2>
                    <input
                      {...register("booster")}
                      type="text"
                      id="booster"
                      disabled={true}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-full">
                    <h2> Tipo de Serviço: </h2>
                    <input
                      {...register("type_service")}
                      type="text"
                      id="service"
                      disabled={true}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-full">
                    <h2> Whatsapp: </h2>
                    <input
                      {...register("whatsapp")}
                      type="text"
                      id="whatsapp"
                      disabled={order.is_feedback}
                      onChange={(e) =>
                        (e.target.value = maskPhone(e.target.value))
                      }
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-full">
                    <h2> Invocador: </h2>
                    <input
                      {...register("invocador")}
                      type="text"
                      id="invocador"
                      disabled={order.is_feedback}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="w-full mt-4">
                  <h2> Mensagem: </h2>
                  <input
                    {...register("mensagem")}
                    type="text"
                    id="message"
                    disabled={order.is_feedback}
                    className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                  />
                </div>
                <div className="w-full flex items-center justify-end gap-2 mt-4">
                  <button
                    className="bg-gray-100 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    type="button"
                    onClick={handleClosePopup}
                  >
                    Fechar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupInfoFeedback;
