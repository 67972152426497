import React, { useState, useEffect } from "react";
import Smurf from "@assets/Table/Client.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import Search from "@assets/Table/Search.svg";
import Delete from "@assets/Table/Delete.svg";
import IconEA from "@assets/Table/IconEA.png";
import Add from "@assets/Table/Add.svg";
import PopupNewSmurf from "./(modals)/create";
import PopupInfoSmurf from "./(modals)/info";
import PopupDeleteSmurf from "./(modals)/delete";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import Loading from "@pages/loading";
import { Stock } from "./stock";

import SearchBar from "./search";

import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export interface ISmurfAccount {
  accounts: {
    id: string;
    is_available: boolean;
    server: string;
    level: string;
    blueessence: string;
    client_name?: string;
    skins: Skin[];
  };
}

export interface Skin {
  name: string;
  rarity: string;
  skin_num: number;
  skin_url: string;
  champion: string;
}

const TableSmurfAdm = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [availableSmurfs, setAvailableSmurfs] = useState<ISmurfAccount[]>([]);
  const [soldSmurfs, setSoldSmurfs] = useState<ISmurfAccount[]>([]);

  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("skins");
  const [selectedServer, setSelectedServer] = useState<string | null>("BR");
  const { token } = useAuth();

  const { t } = useTranslation();

  const [popupState, setPopupState] = useState<{
    showPopupCreate: boolean;
    showPopupInfo: boolean;
    showPopupDelete: boolean;
    id: string;
  }>({
    showPopupCreate: false,
    showPopupInfo: false,
    showPopupDelete: false,
    id: "",
  });

  const handleButtonClick = () => {
    setPopupState((prevState) => ({
      ...prevState,
      showPopupCreate: !prevState.showPopupCreate,
      id: "",
    }));
  };

  const handleInfoModal = (id: string) => {
    setPopupState((prevState) => ({
      ...prevState,
      showPopupInfo: !prevState.showPopupInfo,
      id: id,
    }));
  };

  const handleButtonDelete = (id: string) => {
    setPopupState((prevState) => ({
      ...prevState,
      showPopupDelete: !prevState.showPopupDelete,
      id: id,
    }));
  };

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  useEffect(() => {
    const fetchAvailableSmurfs = async () => {
      try {
        const response = await api.get("/account-smurfs/available", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAvailableSmurfs(response.data);
      } catch (error) {
        console.error("Error fetching available smurfs:", error);
      }
    };

    const fetchSoldSmurfs = async () => {
      try {
        const response = await api.get("/account-smurfs/no-available", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSoldSmurfs(response.data);
      } catch (error) {
        console.error("Error fetching sold smurfs:", error);
      }
    };

    Promise.all([fetchAvailableSmurfs(), fetchSoldSmurfs()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [token]);

  const filterSmurfs = (smurfs: ISmurfAccount[]) => {
    return smurfs.filter((data) => {
      const skins = data.accounts.skins || [];
      const server = data.accounts.server
        ? data.accounts.server.toLowerCase()
        : "";
      const blueessence = data.accounts.blueessence
        ? data.accounts.blueessence.toLowerCase()
        : "";
      const client_name = data.accounts.client_name
        ? data.accounts.client_name.toLowerCase()
        : "";

      const isSkinMatch = skins.some((skin) =>
        skin.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      return (
        (searchQuery === "" ||
          isSkinMatch ||
          blueessence.includes(searchQuery.toLowerCase()) ||
          client_name.includes(searchQuery.toLowerCase())) &&
        (searchOption === "all" ||
          (searchOption === "skins" && isSkinMatch) ||
          (searchOption === "ea" &&
            blueessence.includes(searchQuery.toLowerCase())) ||
          (searchOption === "client" &&
            client_name.includes(searchQuery.toLowerCase()))) &&
        selectedServer &&
        server === selectedServer.toLowerCase()
      );
    });
  };

  const searchAvailableSmurfs = filterSmurfs(availableSmurfs);
  const searchSoldSmurfs = filterSmurfs(soldSmurfs);

  const Skin = (data: Skin[]) => {
    const maxVisibleSkins = 3;

    const visibleSkins = data.slice(0, maxVisibleSkins);
    const remainingSkinsCount = Math.max(0, data.length - maxVisibleSkins);

    return (
      <Tooltip
        content={
          <div className="min-w-xs p-4">
            <span className="font-bold mb-2">Skins</span>
            <ul className="list-disc list-inside w-full">
              {data.map((skin: Skin, index: number) => (
                <li
                  key={index}
                  className={`text-white rarity-${skin.rarity.toLowerCase()}`}
                >
                  <span
                    className={`font-medium whitespace-nowrap ${getRarityColor(
                      skin.rarity
                    )}`}
                  >
                    {skin.name} - {skin.rarity}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <div className="flex flex-row justify-center items-center">
          {visibleSkins.map((skin: Skin, index: number) => (
            <React.Fragment key={index}>
              <img
                src={`${skin.skin_url}`}
                alt={`${skin.name}`}
                className="w-8 h-8 rounded-full object-cover mt-1 border-2 border-gray-300"
                style={{ marginRight: `-5px` }}
              />
            </React.Fragment>
          ))}
          {remainingSkinsCount > 0 && (
            <div className="w-8 h-8 rounded-full bg-primary-500 flex items-center justify-center text-white">
              +{remainingSkinsCount}
            </div>
          )}
        </div>
      </Tooltip>
    );
  };

  const getRarityColor = (rarity: string): string => {
    switch (rarity.toLowerCase()) {
      case "épica":
        return "text-primary-500";
      case "lendária":
        return "text-amber-500";
      case "mítica":
        return "text-rose-600";
      case "ultimate":
        return "text-emerald-400";
      case "padrão":
        return "text-slate-400";
      case "desconhecida":
        return "text-gray-500";
      default:
        return "text-gray-500";
    }
  };

  const handleSearch = (option: string, query: string) => {
    setSearchOption(option);
    setSearchQuery(query);
  };

  const handleServerClick = (server: string) => {
    setSelectedServer(server);
  };

  const tableCellStyle =
    "transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4";

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastSmurf = currentPage * perPage;
  const indexOfFirstSmurf = indexOfLastSmurf - perPage;
  const currentSmurfData = searchQuery
    ? searchAvailableSmurfs.slice(indexOfFirstSmurf, indexOfLastSmurf)
    : searchAvailableSmurfs.slice(indexOfFirstSmurf, indexOfLastSmurf);

    const soldSmurfData = searchQuery
    ? searchSoldSmurfs.slice(indexOfFirstSmurf, indexOfLastSmurf)
    : searchSoldSmurfs.slice(indexOfFirstSmurf, indexOfLastSmurf);

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="grid sm:flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Smurf} alt="Smurf" />
          Smurfs
        </h2>
        <div className="grid sm:flex items-center gap-2">
          <SearchBar onSearch={handleSearch} />
          <button
            className="flex items-center gap-2 bg-blue-1001 rounded-lg py-3 px-6 text-sm font-semibold"
            onClick={handleButtonClick}
          >
            <img src={Add} alt="Export" />
            Smurf
          </button>
        </div>
      </div>
      <div className="mt-4">
        <div className="grid sm:flex items-center justify-between">
          <div className="flex items-center">
            <button
              className={`tab-button ${
                currentTab === 0
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-sm font-semibold border border-secondary-500 p-3 mr-4 rounded-lg`}
              onClick={() => handleTabChange(0)}
            >
              {t("admin.available")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 1
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-sm font-semibold border border-secondary-500 p-3 rounded-lg`}
              onClick={() => handleTabChange(1)}
            >
              {t("admin.sold")}
            </button>
          </div>

          <Stock onServerClick={handleServerClick} />
        </div>

        {loading && <Loading />}
        {!loading && currentTab === 0 && (
          <>
            <CardBody className="px-0 max-h-full overflow-auto min-h-[75vh] bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className={tableCellStyle}>{t("table.server")} </th>
                    <th className={tableCellStyle}>{t("table.level")} </th>
                    <th
                      className={`${tableCellStyle} flex justify-center items-center gap-2`}
                    >
                      {t("table.be")}
                      <img src={IconEA} alt="EA" />
                    </th>
                    <th className={tableCellStyle}>Skins</th>
                    <th className={tableCellStyle}>{t("table.action")} </th>
                  </tr>
                </thead>
                <tbody>
                  {currentSmurfData.map((data: ISmurfAccount) => (
                    <tr
                      key={data.accounts.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent transform hover:translate-y-[-10px] z-100`}
                      onClick={() => handleInfoModal(data.accounts.id)}
                    >
                      <td className={tableCellStyle}>{data.accounts.server}</td>
                      <td className={tableCellStyle}>{data.accounts.level}</td>
                      <td className={tableCellStyle}>
                        {data.accounts.blueessence}
                      </td>
                      <td className={tableCellStyle}>
                        {Skin(data.accounts.skins)}
                      </td>
                      <td className={`${tableCellStyle} rounded-e-2xl`}>
                        <button
                          className="p-2"
                          onClick={() => {
                            handleButtonDelete(data.accounts.id);
                          }}
                        >
                          <img src={Delete} alt="Delete" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>

            <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
              <Typography
                variant="small"
                color="white"
                className="font-normal text-blue-50 dark:text-gray-500"
              >
                Página {currentPage} de{" "}
                {Math.ceil(searchAvailableSmurfs.length / perPage)}
              </Typography>
              <div className="flex gap-2">
                <Button
                  variant="filled"
                  size="sm"
                  className="text-white bg-gray-600 fonte-semibold rounded-lg"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Anterior
                </Button>
                <Button
                  variant="filled"
                  size="sm"
                  className="text-white bg-primary-500 rounded-lg"
                  onClick={handleNextPage}
                  disabled={indexOfLastSmurf >= searchAvailableSmurfs.length}
                >
                  Seguinte
                </Button>
              </div>
            </CardFooter>
          </>
        )}
        {!loading && currentTab === 1 && (
          <>
            <CardBody className="px-0 max-h-full overflow-auto min-h-[75vh] bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className={tableCellStyle}>{t("table.server")} </th>
                    <th className={tableCellStyle}>{t("client.name")} </th>
                    <th className={`${tableCellStyle} flex items-center gap-2`}>
                      {t("table.be")}
                      <img src={IconEA} alt="EA" />
                    </th>
                    <th className={tableCellStyle}>Skins</th>
                    <th className={tableCellStyle}>{t("table.action")} </th>
                  </tr>
                </thead>
                <tbody>
                  {soldSmurfData.map((data: ISmurfAccount) => (
                    <tr
                      key={data.accounts.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent dark:bg-transparent  transform hover:translate-y-[-10px] z-100`}
                    >
                      <td className={tableCellStyle}>{data.accounts.server}</td>
                      <td className={tableCellStyle}>
                        {data.accounts.client_name}
                      </td>

                      <td className={tableCellStyle} id="my-anchor-element">
                        {data.accounts.blueessence}
                      </td>
                      <td className={tableCellStyle}>
                        {Skin(data.accounts.skins)}
                      </td>

                      <td className={`${tableCellStyle} rounded-e-2xl`}>
                        <button
                          className="p-2"
                          onClick={() => handleInfoModal(data.accounts.id)}
                        >
                          <img src={FileSearch} alt="FileSearch" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>

            <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
              <Typography
                variant="small"
                color="white"
                className="font-normal text-blue-50 dark:text-gray-500"
              >
                Página {currentPage} de{" "}
                {Math.ceil(searchSoldSmurfs.length / perPage)}
              </Typography>
              <div className="flex gap-2">
                <Button
                  variant="filled"
                  size="sm"
                  className="text-white bg-gray-600 fonte-semibold rounded-lg"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Anterior
                </Button>
                <Button
                  variant="filled"
                  size="sm"
                  className="text-white bg-primary-500 rounded-lg"
                  onClick={handleNextPage}
                  disabled={indexOfLastSmurf >= searchSoldSmurfs.length}
                >
                  Seguinte
                </Button>
              </div>
            </CardFooter>
          </>
        )}
      </div>
      {popupState.showPopupCreate && <PopupNewSmurf />}
      {popupState.showPopupInfo && <PopupInfoSmurf id={popupState.id} />}
      {popupState.showPopupDelete && <PopupDeleteSmurf id={popupState.id} />}
    </div>
  );
};

export default TableSmurfAdm;
