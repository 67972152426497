import React, { ChangeEvent, useState } from 'react';
import { CheckCircle, XCircle } from 'react-feather'

interface InputProps {
    index: number;
    label: string;
    name: string;
    type?: string;
    placeholder: string;
    value: string | number;
    onChange: (index: number, name: string, value: any, state: boolean) => void;
    formatValue?: (value: string) => string | any;
    formatType?: 'onBlur' | 'onChange';
    min?: number;
    max?: number;
}

const CustomInput: React.FC<InputProps> = ({
    index,
    label,
    name,
    type = "text",
    placeholder,
    value,
    onChange,
    formatValue,
    formatType = 'onBlur',
    min = 1,
    max,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [status, setStatus] = useState<'valid' | 'invalid' | 'pending'>('pending');

    const handleBlur = () => {
        let valueLength = Number(value.toString().length);
        let isMaxValid = max ? valueLength <= max : true;
        let isMinValid = min ? valueLength >= min : true;
        let overallValidity = isMaxValid && isMinValid;
        setIsFocused(!overallValidity)
        if (formatType === 'onBlur' && formatValue) {
            const formattedValue = formatValue(value.toString());
            onChange(index, name, formattedValue, true);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const valueLength = inputValue.length;

        const isMaxValid = max ? valueLength <= max : true;
        const isMinValid = min ? valueLength >= min : true;
        const overallValidity = isMaxValid && isMinValid;
        try {
            if (formatType === 'onChange' && formatValue) {
                const formattedValue = formatValue(inputValue);
                onChange(index, name, formattedValue, overallValidity);
            } else {
                onChange(index, name, inputValue, overallValidity);
            }
        } catch (error) {
            setStatus('invalid');
        }
        setStatus(overallValidity ? 'valid' : 'invalid');
    };

    const maxChars = Number(max) || 0;
    const valueLength = value ? value.toString().length : 0;
    const remainingChars = maxChars - valueLength;


    return (
        <div>
            <label className="block text-gray-200 text-sm font-bold mb-2" htmlFor={name}>
                {label}:
            </label>
            <div className="flex flex-row py-2 pr-3 items-center justify-center border-2 border-[#232630] rounded w-full focus:shadow-outline bg-blue-500">
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={typeof value === 'number' ? value.toString() : value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="appearance-none w-full px-3 text-gray-400 leading-tight focus:outline-none bg-transparent"
                    onFocus={(e) => setIsFocused(true)}
                    autoComplete="false"
                    autoCapitalize="off"
                    spellCheck="false"
                    min={min}
                    max={max}
                />
                <div className='flex flex-row items-center justify-center gap-2 transition-all duration-700'>
                    {isFocused && maxChars > 0 && valueLength >= 0.5 * maxChars && (
                        <p className={`text-sm ${remainingChars <= Number(maxChars * 0.2) ? 'text-red-500' : 'text-gray-400'}`}>
                            {remainingChars}
                        </p>

                    )}
                    {status === 'valid' && valueLength !== 0 ?
                        <CheckCircle size={16} color="#0CAF60" className="text-green-500 shadow-green" /> :
                        (status !== 'pending' && valueLength !== 0 && <XCircle size={16} color="#E53935" className="text-red-500 shadow-red" />)}
                </div>
            </div>
        </div>
    );
};

export default CustomInput;
