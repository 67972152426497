import { useCheckout } from "@context/CheckoutContextProvider";
import ButtonHold from "../button";
import CurrencyDisplay, { currencies } from "@hooks/Currency";
const PriceDetails = () => {
  const { checkoutPrice, checkout } = useCheckout();

  return (
    <div className="flex flex-col justify-center w-full px-4 gap-3">
      <div className="flex flex-col">
        <div className="flex gap-2 justify-between mt-3 max-md:flex-wrap max-md:max-w-full">
          <div className="font-medium text-sm text-[#A7A7C8]">Subtotal</div>
          <div className="font-medium text-sm text-[#A7A7C8]">
            <CurrencyDisplay price={Number(checkoutPrice?.subTotal)} reference={'BRL'} destination={checkout?.currency as keyof typeof currencies || 'BRL'} convert={false} />
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 max-md:flex-wrap max-md:max-w-full">
          <div className="font-medium text-sm text-[#A7A7C8]">Desconto</div>
          <div className="font-medium text-sm text-indigo-600">
            <CurrencyDisplay price={Number(checkoutPrice?.totalDiscount)} reference={'BRL'} destination={checkout?.currency as keyof typeof currencies || 'BRL'} convert={false} />
          </div>
        </div>
      </div>
      <div className="shrink-0 box-border flex flex-col items-start h-[0.5] w-full border-t border-b-0 border-[#293054]" />
      <div className="flex gap-2 justify-between only:font-semibold max-md:flex-wrap max-md:max-w-full">
        {" "}
        {/* Redução do espaço entre os elementos e do tamanho do texto */}
        <div className="font-semibold text-[#DBDBE9] text-lg">Total</div>
        <div className="font-semibold text-indigo-600 text-lg">
          <CurrencyDisplay price={Number(checkoutPrice?.total)} reference={'BRL'} destination={checkout?.currency as keyof typeof currencies || 'BRL'} convert={false} />
        </div>{" "}
        {/* Redução do tamanho do texto */}
      </div>
      <ButtonHold />
    </div>
  );
};

export default PriceDetails;
