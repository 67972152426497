import React from 'react';
import { useRouletteConfigContext } from '../../Context/RouletteConfigContext';
import './style.css';
import { Plus } from 'react-feather';

interface ScrollIndicatorProps {
    activeIndex: number;
    setActiveIndex: (index: number) => void;
    onAddItem: () => void;
}

const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({ activeIndex, setActiveIndex, onAddItem }) => {
    const { prizeData } = useRouletteConfigContext();

    if(!prizeData || prizeData.length === 0) return null;

    return (
        <div className="absolute top-1/2 left-2 transform -translate-y-1/2 flex flex-col gap-2 z-10 py-4 px-1 flex-shrink-0 rounded-full items-center">
            <div className='flex flex-col gap-2'>
                {prizeData.map((item, index) => (
                    <div
                        key={index}
                        className="flex items-center gap-2 cursor-pointer h-4"
                        onClick={() => setActiveIndex(index)}
                    >
                        <div
                            className={`w-3 h-3 rounded-full transition-all duration-500 ${index === activeIndex ? 'bg-primary-500 ring-2 ring-gray-700 ring-offset-[1.5px]' : 'bg-blue-500 bg-opacity-50'}`}
                        />
                        <span className={`text-sm ${index === activeIndex ? 'text-white' : 'text-gray-400'} truncate w-20`}>
                            {item.name}
                        </span>
                    </div>
                ))}
                <Plus size={12} color="white" onClick={onAddItem} className='cursor-pointer w-3 h-3 transition-transform duration-300 transform hover:rotate-45' />
            </div>
        </div>
    );
};

export default ScrollIndicator;
