import TableFeedback from "@components/Dashboard/mainDashboard/Adm/client/feedback"

type Props = {}

const FeedbackAdm = (props: Props) => {
  return (
    <>
      <TableFeedback />
    </>
  )
}

export default FeedbackAdm