import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Info from "@assets/Roleta/Info.png";
import { useRouletteContext } from "../../Context/RouletteProvider";
import mc from "@assets/maniacoin.png";
import { ManiacoinsConfig } from "@components/(Roulette)/manage/config/maniacoins";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { Tooltip } from "@material-tailwind/react";

const RouletteHeader = () => {
  const { t } = useTranslation();
  const { handleConvertMania, rouletteUserData } = useRouletteContext();
  const [maniaConfig, setManiaconfig] = useState<ManiacoinsConfig | null>(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await api.get("/roulette/config/props/maniacoins", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setManiaconfig(response.data.maniacoins);
      } catch (err) {
        console.log(err);
      }
    };

    fetchConfig();
  }, []);

  return (
    <div className="w-full flex items-center justify-between">
      <div className="w-full flex items-center gap-4">
        <img src={Info} alt="Roleta" className="w-10 h-10" />
        <span className={`text-white font-semibold text-base leading-[24px]`}>
          Tente a sorte em nossa Roleta Grátis e ganhe prêmios!
        </span>
      </div>

      {maniaConfig && maniaConfig?.enable ? (
        <Tooltip
        content={`Troque ${maniaConfig?.exchangeValue} MC por um giro extra na nossa roleta! 🚀`}
          placement="bottom"
          className="bg-primary-200 border-secondary-500 text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
        >
          <div
            className="inline-flex items-center gap-2 cursor-pointer px-8 py-3 font-semibold text-base rounded-full border-2 border-blue-1001 bg-blue-50 dark:bg-blue-1001 shadow-2xl"
            onClick={handleConvertMania}
          >
            <img src={mc} alt="Mania Coins" width={32} height={32} />
            <span className="whitespace-nowrap pr-8">
              +1 giro por {maniaConfig && maniaConfig?.exchangeValue} MC
            </span>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default RouletteHeader;
