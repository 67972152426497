import { IResponsePreferenceDTO } from '@components/Chat/types/IRequest';
import React from 'react';

interface PreferenceOption {
    value: string | any;
    label: string;
    image?: string;
}

interface RenderPreferencesProps {
    preferences: IResponsePreferenceDTO[];
    ignoredTypes?: string[];
}

const RenderPreferences: React.FC<RenderPreferencesProps> = ({ preferences, ignoredTypes = [] }) => {

    const renderPreferenceValue = (type: string, option: PreferenceOption) => {
        switch (type) {
            case 'spell':
            case 'queue':
                return (
                    <span>{option.label}</span>
                );
            case 'selected_champions_required':
                return (
                    <div className="flex flex-col gap-2">
                        <span>{option.label}</span>
                        <div className="grid grid-cols-4 gap-2 mt-1"> 
                            {option.value.map((champion: { image: string; name: string }, index: number) => (
                                <div key={index} className="w-8 h-8">
                                    <img
                                        src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${champion.image}`}
                                        alt={champion.name}
                                        className="w-8 h-8 rounded-full object-cover"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'primary_route':
            case 'secondary_route':
                return (
                    <div className="flex items-center gap-2">
                        <span>{option.label.toUpperCase()}</span>
                    </div>
                );
            default:
                return <span>{option.value}</span>;
        }
    };

    const filteredPreferences = preferences.filter((pref) => !ignoredTypes.includes(pref.type));

    return (
        <>
            {filteredPreferences.map((pref, index) => (
                <div key={index} className="flex flex-col gap-2 items-start justify-center">
                    <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                        {pref.label}
                    </h2>
                    <div className='flex items-center justify-center w-full flex-row'>
                        <span className="text-md">
                            {renderPreferenceValue(pref.type, pref.option)}
                        </span>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RenderPreferences;
