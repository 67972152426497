import { useRouletteContext } from '@components/(Roulette)/roulette/Context/RouletteProvider';
import { useAuth } from '@context/AuthContext';
import api from '@services/client/api';
import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';

export interface Subscription {
    email: string;
    password: string;
    type: string;
    client_id?: string;
    claimed: boolean;
    created_at?: string;
}

export interface SteamKey {
    key: string;
    claimed: boolean;
    created_at?: string;
    client_id?: string;
}

export interface Coupon {
    code: string;
    percentage: number;
    client_id: string;
    claimed: boolean;
    created_at: string;
}

export interface PropertiesConfig {
    subscriptions: Subscription[];
    steam_key: SteamKey[];
    coupons: Coupon[];
}

export interface Configurations {
    _id: string;
    name: string;
    value: string;
    properties: PropertiesConfig;
    description: string;
}

interface Prize {
    id?: string;
    image: any | string | null;
    name: string;
    description: string;
    probability: number;
    level_required: string; 
    enabled: boolean;
    visible: boolean;
    send_to_discord: boolean;
    show_popup: boolean;
    autoredeem: boolean;
    admin_confirmation: boolean;
    delivery_method: string;
    delivery_count: string;
}

interface DataValidate {
    [key: string]: boolean;
}

// Tipo do Contexto
interface ConfigContextType {
    prizeData: Prize[];
    setPrizeData: React.Dispatch<React.SetStateAction<Prize[]>>;
    dataValidate: DataValidate[];
    setDataValidate: React.Dispatch<React.SetStateAction<DataValidate[]>>;
    config: Configurations | null;
    loading: boolean;
    error: string | null;
    addPrize: () => void;
    handleSave: () => Promise<void>; 
    deletePrize: (id: string, index: number) => Promise<void>; 
    saveLoading: boolean;
}

// Criação do Contexto
const RouletteConfigContext = createContext<ConfigContextType>({
    prizeData: [],
    setPrizeData: () => {},
    dataValidate: [],
    setDataValidate: () => {},
    config: null,
    loading: true,
    error: null,
    addPrize: () => {},
    handleSave: async () => {},
    deletePrize: async (id: string, index: number) => {},
    saveLoading: false,
});

// Provedor do Contexto
const RouletteConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [config, setConfig] = useState<Configurations | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { token } = useAuth();
    const { roulettePrizes, setUpdateItems, updateItems } = useRouletteContext();

    const [saveLoading, setSavingLoading] = useState<boolean>(false);

    const [prizeData, setPrizeData] = useState<Prize[]>([]);
    const [dataValidate, setDataValidate] = useState<DataValidate[]>([]);

    useEffect(() => {
        if (roulettePrizes) {
            const formattedPrizes = roulettePrizes.map(prize => ({
                id: prize.id,
                image: prize.image,
                name: prize.name,
                description: prize.description,
                probability: prize.probability,
                level_required: prize.level_required,
                enabled: prize.enabled,
                visible: prize.visible,
                send_to_discord: prize.send_to_discord,
                show_popup: prize.show_popup,
                autoredeem: prize.autoredeem,
                admin_confirmation: prize.admin_confirmation,
                delivery_method: prize.delivery_method,
                delivery_count: prize.delivery_count,
            }));
            setPrizeData(formattedPrizes);

            const initialDataValidate = formattedPrizes.map(() => ({
                image: true,
                name: true,
                description: true,
                probability: true,
                level_required: true,
                enabled: true,
                visible: true,
                send_to_discord: true,
                show_popup: true,
                autoredeem: true,
                admin_confirmation: true,
                delivery_method: true,
                delivery_count: true,
            }));
            setDataValidate(initialDataValidate);
        }
    }, [roulettePrizes]);

    const deletePrize = async (id: string, index: number) => {
        try {
          await api.delete(`/roulette/prizes/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setPrizeData((prevPrizeData) => prevPrizeData.filter((_, i) => i !== index));
          setDataValidate((prevDataValidate) => prevDataValidate.filter((_, i) => i !== index));
        } catch (error: any) {
          console.error('Erro ao excluir o item:', error);
          alert(error.response.data.message);
        }
      };
      

    const addPrize = () => {
        setPrizeData([...prizeData, {
            image: null,
            name: '',
            description: '',
            probability: 0,
            level_required: '',
            enabled: true,
            visible: true,
            send_to_discord: true,
            show_popup: true,
            autoredeem: false,
            admin_confirmation: false,
            delivery_method: '',
            delivery_count: '',
        }]);
        setDataValidate(prevDataValidate => [
            ...prevDataValidate,
            {
                image: true,
                name: false,
                description: false,
                probability: false,
                level_required: false,
                delivery_method: false,
                delivery_count: true,
                enabled: true,
                visible: true,
                send_to_discord: true,
                show_popup: true,
                autoredeem: true,
                admin_confirmation: true,
            }
        ]);
    };

    const handleSave = async () => {
        try {
            setSavingLoading(true);
    
            await Promise.all(prizeData.map(async (prize) => {
                const prizePayload = {
                    id: prize.id || '',
                    name: prize.name,
                    description: prize.description,
                    probability: prize.probability,
                    level_required: prize.level_required,
                    enabled: prize.enabled,
                    visible: prize.visible,
                    send_to_discord: prize.send_to_discord,
                    show_popup: prize.show_popup,
                    autoredeem: prize.autoredeem,
                    admin_confirmation: prize.admin_confirmation,
                    delivery_method: prize.delivery_method,
                    delivery_count: prize.delivery_count,
                };
    

                  let createdPrize = await api.post('/roulette/prizes', prizePayload, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                
    
                if (createdPrize && prize.image && typeof prize.image !== 'string') {
                    const formData = new FormData();
                    formData.append('file', prize.image);
    
                    let response = await api.put(`/roulette/prizes/${createdPrize.data.prize.id}`, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }
            }));
    
            setUpdateItems(!updateItems);
        } catch (error) {
            console.error(error);
            alert('Erro ao salvar os itens. Verifique o console para mais detalhes.');
        } finally {
            setSavingLoading(false);
        }
    };

    return (
        <RouletteConfigContext.Provider value={{ config, deletePrize, loading, error, prizeData, saveLoading, setPrizeData, dataValidate, setDataValidate, addPrize, handleSave }}>
            {children}
        </RouletteConfigContext.Provider>
    );
};

// Hook para Usar o Contexto
export const useRouletteConfigContext = (): ConfigContextType => {
    const context = useContext(RouletteConfigContext);

    if (!context) {
        throw new Error('useRouletteConfigContext deve ser usado dentro de RouletteConfigProvider');
    }

    return context;
};

export { RouletteConfigProvider, RouletteConfigContext };
