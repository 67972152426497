import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import IconDelete from "@assets/Table/IconDelete.png";
import "@assets/Coockie.png";
import "@styles/popup.css";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";

type Props = {
  showPopupDelete: boolean;
  setShowPopupDelete: (showPopup: boolean) => void;
  boosterIdDelete: string;
};

const PopupDeleteBooster = ({
  boosterIdDelete,
  showPopupDelete,
  setShowPopupDelete,
}: Props) => {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(false); 

  const handleClosePopup = () => {
    setShowPopupDelete(false);
  };

  const deleteAccount = async (id: string | null) => {
    console.log("Initiating delete...");
    if (id === null) {
      console.error("accountId is null");
      setIsLoading(false);
      return;
    }
  
    setIsLoading(true);
    try {
      console.log("Trying to delete...");
      const response = await api.delete(`/boosters/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Deleted successfully", response);
      toast.success("Booster deletado com sucesso!");
      setShowPopupDelete(false);
      window.location.reload();
    } catch (error: any) {
      console.error("Error deleting account:", error);
      toast.error(error?.response.data.message);
      setIsLoading(false);
    } finally {
      console.log("Exiting delete...");
    }
  };  

  return (
    <>
      {showPopupDelete && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-0 flex justify-center items-center mt-24`}
          >
            <div className="bg-blue-50 w-3/5 mx-auto border border-secondary-500 rounded-3xl py-4 px-4">
              <div className="bg-blue-20 w-full p-4 rounded-3xl">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 flex items-center gap-2"></div>
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
                <div className="rounded-2xl p-4">
                  <div className="grid place-items-center gap-4">
                    <div className="w-full flex items-center justify-center">
                      <img src={IconDelete} alt="IconDelete" />
                    </div>
                    <h2 className="text-center font-semibold text-lg text-gray-500">
                      {" "}
                      Deseja deletar o booster selecionado?{" "}
                    </h2>
                    <p className="text-center font-semibold text-base text-gray-100">
                      {" "}
                      Cuidado! Ao deletar o booster, ele será excluído
                      imediatamente do banco de dados.{" "}
                    </p>
                  </div>
                  <div className="p-2 flex items-center justify-center gap-2 mt-2">
                    <button
                      className="bg-gray-100 rounded-xl px-4 py-2"
                      onClick={handleClosePopup}
                    >
                      Fechar
                    </button>
                    <button
                      className="bg-red-500 rounded-xl px-4 py-2"
                      onClick={() => deleteAccount(boosterIdDelete)} // Modified this line
                      disabled={isLoading}
                    >
                      {isLoading ? "Carregando..." : "Excluir"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupDeleteBooster;