import React, {
  useEffect,
  useState,
  useRef,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
} from "react";
import MoreOffers from "@components/(checkout)/(container)/more-offers";
import PriceDetails from "@components/(checkout)/(container)/price-details";
import OrderDetails from "@components/(checkout)/(container)/order-details";
import "./style.scss";
import { useCheckout } from "@context/CheckoutContextProvider";
import useMediaQueryCheckout from "@hooks/useMediaQueryCheckout";

const CarrinhoModal = () => {
  const [widthClass, setWidth] = useState<string>("w-1/3");
  const { width } = useMediaQueryCheckout();
  const { setOpenCheckout, openCheckout, checkout, errorMsg } = useCheckout();
  const modalRef = useRef<HTMLDivElement>(null);
  let doc = document.getElementById("tidio-chat-iframe");

  useEffect(() => {
    if (!width) return;
    const divisionResult = width / 1500;

    if (divisionResult < 0.6) {
      setWidth("w-full");
    } else if (divisionResult > 0.6 && divisionResult < 0.9) {
      setWidth("w-2/3");
    } else if (divisionResult > 0.9) {
      setWidth("w-1/3");
    }
  }, [width]);

  useEffect(() => {
    if (!doc || !modalRef) return;
    if (openCheckout) {
      doc.style.transform = `translateX(-${modalRef?.current?.offsetWidth}px)`;
    }
  }, [modalRef, openCheckout]);


  const handleCloseModal: MouseEventHandler<HTMLDivElement> = (
    event: ReactMouseEvent<HTMLDivElement>
  ) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setOpenCheckout(false);
      if (!doc) return;
      doc.style.transform = 'translateX(0%)';
    }
  };

  return (
    <div
      className={`${openCheckout ? "checkout-open" : ""
        } z-[99999999] bg-blue-20 bg-opacity-25 fixed w-full h-screen backdrop-blur-sm`}
      onMouseDown={handleCloseModal}
    >
      <div
        ref={modalRef}
        className={`fixed top-0 right-0 ${widthClass} h-screen bg-blue-50 slide-in-left`}
        onMouseDown={(e: ReactMouseEvent<HTMLDivElement>) =>
          e.stopPropagation()
        }
      >
        {checkout &&
          checkout?.checkoutItems &&
          checkout.checkoutItems.length === 0 ? (
          <div className="w-full h-full flex flex-col items-center justify-center gap-1.5">
            <h1 className="font-semibold text-center text-md">
              Que pena! Seu carrinho está vazio.
            </h1>
            <span className="text-xs font-semibold text-center text-slate-600 w-3/3">
              Adicione itens ao seu carrinho para continuar com sua compra.
            </span>
          </div>
        ) : (
          <div className="flex flex-col h-screen">
            <div className="text-lg font-medium text-slate-500 p-4 pb-2">
              Detalhes do pedido:
            </div>
            <div className="overflow-y-auto overflow-edit flex-grow">
              <OrderDetails />
            </div>
            <div className="px-4 py-3 pb-24">
            {errorMsg ? <div className="bg-red-500/20 border-l-[2.5px] border-red-500 w-full h-10 rounded-sm select-none mb-2 flex items-center justify-center pl-2 text-sm font-medium text-red-500">{errorMsg}</div> : null}
              <div className="w-full p-4 bg-[#1E2242] rounded-lg">
                <MoreOffers />
              </div>
              <PriceDetails />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarrinhoModal;
