import React, { useState, ChangeEvent } from 'react';
import EA from "@assets/Table/ea.webp";
import { Smurf } from "..";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";

interface CreatePoliceProps {
    smurf?: Smurf;
    operation: 'create' | 'edit';
}

interface Prices {
    eaInicial: string;
    eaFinal: string;
    random?: number;
    epic?: number;
    mythic?: number;
    legendary?: number;
    ultimate?: number;
}

export const CreatePolice: React.FC<CreatePoliceProps> = ({ smurf, operation }) => {
    const { token } = useAuth();
    const [selectedServer, setSelectedServer] = useState<string>(smurf?.server || 'BR');

    const regex = /(\d+)k-(\d+)k/;
    
    const match = smurf?.bluesessence.match(regex);

    const [prices, setPrices] = useState<Prices>({
        eaInicial: (match && match[1]) ? match[1].toString() : "", 
        eaFinal: (match && match[2]) ? match[2].toString() : "",
        random: smurf?.random,
        epic: smurf?.epic,
        mythic: smurf?.mythic,
        legendary: smurf?.legendary,
        ultimate: smurf?.ultimate,
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, field: keyof Prices) => {
        setPrices({
            ...prices,
            [field]: e.target.value,
        });
    };

    const handleServerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedServer(e.target.value);
    };

    const savePrices = async () => {
        
        
        try {
            const bluesessence = `${prices.eaInicial}k-${prices.eaFinal}k`;;
            
            let response;

            if (operation === 'create') {
                response = await api.post(
                    "/prices-smurfs",
                    {
                        bluesessence,
                        server: selectedServer,
                        isRandom: false,
                        random: prices.random,
                        epic: prices.epic,
                        mythic: prices.mythic,
                        legendary: prices.legendary,
                        ultimate: prices.ultimate,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } else if (operation === 'edit' && smurf?.id) {
                response = await api.put(
                    `/prices-smurfs/${smurf.id}`,
                    {
                        bluesessence,
                        server: selectedServer,
                        isRandom: false,
                        random: prices.random,
                        epic: prices.epic,
                        mythic: prices.mythic,
                        legendary: prices.legendary,
                        ultimate: prices.ultimate,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                
            } else {
                toast.error("Operação desconhecida ou ID de smurf ausente para a operação 'edit'.");
                return;
            }

            if (response && response.data) {
                toast.success('Política de preço criada/editada com sucesso.');
                window.location.reload();
            } else {
                console.error("Response or response data is undefined.");
            }
        } catch (error: any) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Ocorreu um erro na chamada da API.");
            }
        }
        
    };


    return (
        <>
            <div className="mt-4">
                <div className="bg-blue-50 rounded-xl p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10">
                    <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                            <div className="bg-secondary-500 rounded-xl w-24 h-auto flex justify-center items-center">
                                <img src={EA} alt="EA" className="w-full h-full" />
                            </div>
                            <h3 className="text-gray-100 text-base font-semibold mb-2">
                                EA Inicial:
                            </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">

                            <input
                                type="number"
                                placeholder="0.00"
                                value={prices.eaInicial !== undefined ? prices.eaInicial.toString() : ''}
                                onChange={(e) => handleInputChange(e, 'eaInicial')}
                                className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="w-full flex items-center justify-between gap-2">
                            <div className="bg-secondary-500 rounded-xl w-24 h-auto flex justify-center items-center">
                                <img src={EA} alt="EA" className="w-full h-full" />
                            </div>
                            <h3 className="text-gray-100 text-base font-semibold mb-2">
                                EA Final:
                            </h3>
                        </div>
                        <div className="w-full flex justify-center items-center">

                            <input
                                type="number"
                                placeholder="0.00"
                                value={prices.eaFinal !== undefined ? prices.eaFinal.toString() : ''}
                                onChange={(e) => handleInputChange(e, 'eaFinal')}
                                className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                            />

                        </div>
                    </div>
                    <div className="flex items-start gap-4">
                        <div className="w-full flex items-center gap-2">
                            <h3 className="text-gray-100 text-base font-semibold mb-2">
                                Servidor
                            </h3>
                        </div>
                        <div className="bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full grid gap-6">
                            <div className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    id="brServer"
                                    name="server"
                                    value="BR"
                                    checked={selectedServer === "BR"}
                                    onChange={handleServerChange}
                                    className="w-5 h-5 cursor-pointer"
                                />
                                <label
                                    htmlFor="brServer"
                                    className="text-gray-100 text-base font-semibold"
                                >
                                    BR
                                </label>
                            </div>
                            <div className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    id="naServer"
                                    name="server"
                                    value="NA"
                                    checked={selectedServer === "NA"}
                                    onChange={handleServerChange}
                                    className="w-5 h-5 cursor-pointer"
                                />
                                <label
                                    htmlFor="naServer"
                                    className="text-gray-100 text-base font-semibold"
                                >
                                    NA
                                </label>
                            </div>
                            <div className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    id="euwServer"
                                    name="server"
                                    value="EUW"
                                    checked={selectedServer === "EUW"}
                                    onChange={handleServerChange}
                                    className="w-5 h-5 cursor-pointer"
                                />
                                <label
                                    htmlFor="euwServer"
                                    className="text-gray-100 text-base font-semibold"
                                >
                                    EUW
                                </label>
                            </div>
                            <div className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    id="lasServer"
                                    name="server"
                                    value="LAS"
                                    checked={selectedServer === "LAS"}
                                    onChange={handleServerChange}
                                    className="w-5 h-5 cursor-pointer"
                                />
                                <label
                                    htmlFor="lasServer"
                                    className="text-gray-100 text-base font-semibold"
                                >
                                    LAS
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="gap-4">
                        <div className="flex items-center gap-4 mb-4">
                            <div className="w-full flex items-center justify-between gap-2">
                                <h3 className="text-gray-100 text-base font-semibold mb-2">
                                    Aleatória
                                </h3>
                            </div>
                            <div className="w-full flex justify-center items-center">

                                <input
                                    type="number"
                                    placeholder="0.00"
                                    value={prices.random !== undefined ? prices.random.toString() : ''}
                                    onChange={(e) => handleInputChange(e, 'random')}
                                    className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                                />

                            </div>
                        </div>
                        <div className="flex items-center gap-4 mb-4">
                            <div className="w-full flex items-center justify-between gap-2">
                                <h3 className="text-gray-100 text-base font-semibold mb-2">
                                    Épica
                                </h3>
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <input
                                    type="number"
                                    placeholder="0.00"
                                    value={prices.epic !== undefined ? prices.epic.toString() : ''}
                                    onChange={(e) => handleInputChange(e, 'epic')}
                                    className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-4 mb-4">
                            <div className="w-full flex items-center justify-between gap-2">
                                <h3 className="text-gray-100 text-base font-semibold mb-2">
                                    Mítica
                                </h3>
                            </div>
                            <div className="w-full flex justify-center items-center">

                                <input
                                    type="number"
                                    placeholder="0.00"
                                    value={prices.mythic !== undefined ? prices.mythic.toString() : ''}
                                    onChange={(e) => handleInputChange(e, 'mythic')}
                                    className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-4 mb-4">
                            <div className="w-full flex items-center justify-between gap-2">
                                <h3 className="text-gray-100 text-base font-semibold mb-2">
                                    Lendária
                                </h3>
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <input
                                    type="number"
                                    placeholder="0.00"
                                    value={prices.legendary !== undefined ? prices.legendary.toString() : ''}
                                    onChange={(e) => handleInputChange(e, 'legendary')}
                                    className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                                />


                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="w-full flex items-center justify-between gap-2">
                                <h3 className="text-gray-100 text-base font-semibold mb-2">
                                    Ultimate
                                </h3>
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <input
                                    type="number"
                                    placeholder="0.00"
                                    value={prices.ultimate !== undefined ? prices.ultimate.toString() : ''}
                                    onChange={(e) => handleInputChange(e, 'ultimate')}
                                    className="text-gray-500 text-right font-normal text-sm bg-transparent border-2 border-gray-500/10 rounded-xl outline-none p-3 w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    onClick={savePrices}
                    className="bg-primary-500 w-full text-gray-500 font-semibold text-sm rounded-full py-2 px-5 text-center my-4"
                >
                   {operation === 'create' ? 'Criar Política' : 'Editar Política'} 
                </button>
            </div>
        </>
    )
}