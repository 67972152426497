import React, { useEffect, useState } from 'react';
import { useAuth } from '@context/AuthContext';
import api from '@services/client/api';
import moment from 'moment';
import { IRequest } from '@utils/types';

import IconLol from '@assets/Table/IconLol.svg';
import IconWR from '@assets/Table/IconWR.svg';
import IconCS from '@assets/Table/IconCS.svg';
import IconTFT from '@assets/Table/IconTFT.svg';
import IconValorant from '@assets/Table/IconValorant.svg';

const getFullAvatarUrl = (avatar: any) => {
  return avatar && (avatar.startsWith('http://') || avatar.startsWith('https://'))
    ? avatar
    : avatar
      ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`
      : '';
};

interface Pedido {
  id: string;
  client_id: string;
  checkout_id: string;
  item_id: string;
  type: string;
  name: string;
  isPromotional: boolean;
  price: number;
  tags: string[];
  subtitle: string;
  status: string;
  image?: string | null;
  description?: string | null;
  created_at: Date;
  updated_at: Date;
  expires_at: Date;
  total_paid: number;
  maniacoins_applied: number;
}

const Awaiting = () => {
  const { token, user, signOut } = useAuth();
  const [orders, setOrders] = useState<Pedido[]>([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.patch(`/checkout-item/list/${user && user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (typeof response.data === 'object' && !Array.isArray(response.data)) {
          const ordersArray = Object.keys(response.data).map(key => response.data[key]);
          setOrders(ordersArray);
        } else {
          setOrders(response.data); 
        }
      } catch (error) {
        console.error('Erro ao buscar os pedidos:', error);
      }
    };
  
    fetchOrders();
  }, []);
  

  const handleLogout = () => {
    signOut();
  };


  return (
    <div>
      <video className="absolute w-screen h-screen object-cover" autoPlay muted loop>
        <source
          src="https://app-elomania-v2-uploads.s3.amazonaws.com/video/jinx.mp4"
          type="video/mp4"
        />
        Seu navegador não suporta vídeo HTML5.
      </video>

      <div className="flex flex-col absolute z-10 w-full h-full bg-black/80 items-center justify-start">
        <div className="absolute top-0 left-0 w-full bg-primary-500 text-white p-4 text-center">
          Para acessar o <strong>dashboard</strong> um dos seus pagamentos precisa ser aprovado.
        </div>
        <div className='flex items-center justify-center flex-col w-full h-full' >
        <div className="sm:flex items-center justify-between w-[80%] px-6 select-none mt-[5%]">
          <div className="flex flex-row gap-4 items-center justify-center">
            <img
              src={getFullAvatarUrl(user && user.avatar)}
              alt="Imagem do Perfil"
              className="border-2 border-primary-500 w-20 h-20 rounded-full"
            />
            <div className="flex flex-col gap-1">
              <span className="text-2xl font-bold uppercase">{user && user.name}</span>
              <span className="text-md text-gray-100">
                Membro desde{' '}
                <span className="text-white font-medium">
                  {moment(user && user.created_at).format('DD MMM YYYY')}
                </span>
              </span>
            </div>
          </div>
          <div className="flex flex-col text-center gap-4">
            <a
              href="https://elomania.com"
              className="bg-blue-1001/25 hover:bg-blue-1001/60 border-2 border-blue-1001 rounded-lg text-xl font-semibold p-4"
            >
              Voltar ao Site
            </a>
            <button
              onClick={handleLogout}
              className="text-blue-1001 hover:text-gray-500 text-xl font-semibold"
            >
              {' '}
              Sair da Conta{' '}
            </button>
          </div>
        </div>

        <div className="w-full mt-2 px-8 flex flex-col items-center justify-center gap-3">
          <div className="flex flex-row gap-2 items-center w-[80%] select-none">
            <h2 className="text-lg text-gray-100">Meus Pedidos</h2>
            <span className="bg-[#171620] rounded-md px-1.5 py-0.5">
              {orders && Object.keys(orders).length.toString().padStart(2, '0')}
            </span>
          </div>
          <div className="flex flex-col w-full max-h-[350px] overflow-y-auto gap-2 items-center justify-center">
            {orders &&
              orders.map((order) => (
                <div
                  key={order.id}
                  className="flex flex-row justify-star items-center bg-[#171620]/80 w-[80%] h-[150px] rounded-md"
                >
                  <div className="flex items-center justify-center bg-[#242333] w-[100px] h-full">
                    {order.image && (
                      <img
                        src={order.image}
                        alt={order.name}
                      />
                    )}
                  </div>
                  <div className="flex flex-row items-center gap-16 ml-4 w-full px-4">
                    <div className="flex flex-col items-center gap-1">
                      <span className="text-gray-100">Pedido</span>
                      <span className="font-semibold">#{order.id.slice(0, 4)}</span>
                    </div>
                    <div className="flex flex-col items-start gap-1">
                      <span className="text-gray-100">Pedido</span>
                      <span className="font-semibold">
                        {order.type === 'order' ? 'Serviço' : 'Smurf'}
                      </span>
                    </div>
                    <div className="flex flex-col items-start gap-1">
                      <span className="text-gray-100">Contratação</span>
                      <span className="font-semibold">
                        {moment(order.created_at).format('DD MMM YYYY')}
                      </span>
                    </div>
                    <div
                      className={`flex flex-col items-start gap-1 ${order.status === 'paid'
                          ? 'text-green-500'
                          : order.status === 'available'
                            ? 'text-gray-100'
                            : 'text-red-500'
                        }`}
                    >
                      <span className="text-gray-100">Status</span>
                      <span className="font-semibold">
                        {order.status === 'paid'
                          ? 'Pagamento aprovado'
                          : order.status === 'available'
                            ? 'Aguardando pagamento'
                            : 'Pagamento recusado'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            {Object.keys(orders).length === 0 && (
              <h1 className="mt-12 h-[150px] text-gray-100 font-medium select-none">
                Você ainda não fez nenhum pedido 😥
              </h1>
            )}
          </div>
        </div>

        </div>
      </div>
    </div>
  );
};

export default Awaiting;