import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { IUser } from "@utils/types";
import Profile from "@assets/Topbar/Profile.svg";
import useNotificationHook from "@hooks/Notification";
import InfoMonth from "./InfoMonth";
import { useTranslation } from "react-i18next";
import GraphGame from "./GraphGame";
import "@styles/hover.css";

type Props = {
  user: IUser;
  token: string;
};

interface NotifyLink {
  message: string;
  avatar: string;
  booster?: string;
  cliente?: string;
}

interface NotificationProps {
  index: string | number;
  avatar: string;
  name: string | undefined;
  type: string;
  content: string;
  description: string;
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const NotificationComponent = ({
  index,
  avatar,
  name,
  type,
  content,
  description,
}: NotificationProps) => {
  return (
    <div
      key={index}
      className="w-full flex items-center justify-between py-7 border-b border-b-gray-100/10"
    >
      <div className="flex items-center gap-4">
        <div className="border border-gray-100/60 transition-colors duration-1000 bg-white-100 dark:bg-secondary-500 min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full overflow-hidden">
          <img
            src={avatar}
            alt="Perfil"
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="grid gap-2">
          <div className="flex items-center gap-2">
            <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">
              {name
                ? name.split(" ").filter((word) => word !== "")[0] +
                  (name.split(" ").filter((word) => word !== "").length > 2
                    ? "..."
                    : "")
                : ""}
            </h2>
            <h3 className="transition-colors duration-1000 bg-blue-50 text-gray-500 text-sm font-medium py-1 px-2 rounded-3xl">
              {" "}
              {type}{" "}
            </h3>
          </div>
          <p className="text-blue-1001 font-semibold text-base"> {content} </p>
        </div>
      </div>
      <p className="text-gray-100 font-semibold text-sm"> {description} </p>
    </div>
  );
};

const ResumeAdm = ({ user, token }: Props) => {
  const { t } = useTranslation();
  const { notifications, isLoaded } = useNotificationHook({ user, token });

  return (
    <div className="w-full">
      <div className="bg-transparent grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/* Capa e Perfil */}
        <div className="bg-blue-50 relative rounded-3xl w-full h-[400px]">
          <div className="capa w-full h-full rounded-3xl">
            <img
              src={
                user.banner
                  ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                  : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
              }
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
          <div
            className="absolute left-0 top-0 rounded-3xl w-full h-full"
            style={{
              background:
                "linear-gradient(0.75deg, #02061B -3.4%, rgba(34, 16, 20, 0) 115.43%)",
            }}
          ></div>
          <div className="absolute z-10 left-4 top-4 items-center justify-center w-full rounded-3xl">
            <div className="bg-gray-500/20 rounded-3xl w-16 py-2 text-center">
              <p className="text-gray-500 font-normal text-xs">Admin</p>
            </div>
            <div className="w-full grid place-items-center mt-14 gap-2">
              <div className="bg-secondary-500 w-20 h-20 rounded-3xl">
                <img
                  src={`${user.getAvatarUrl}`}
                  alt="Perfil"
                  className="w-full h-full rounded-3xl"
                />
              </div>
              <h3 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-lg">
                {user.name}
              </h3>
            </div>
          </div>
        </div>

        {/* Atividades Recentes */}
        <div className="transition-colors h-[400px] overflow-hidden duration-1000 bg-gradient-to-b py-6 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl p-4 border border-gray-100 dark:border-secondary-500">
          <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base">
            {t("table.activities")}
          </h2>
          <div className="h-full overflow-y-auto scrollbar-transparent">
            {notifications.length > 0 ? (
              <div>
                {notifications.slice(0, 10).map((notification, index) => {
                  const link = isJsonString(notification.link)
                    ? JSON.parse(notification.link)
                    : "";

                  return (
                    <div
                      key={index}
                      className={
                        index === notifications.length - 1
                          ? ""
                          : "border-b border-b-secondary-500"
                      }
                    >
                      {isJsonString(notification.link) ? (
                        <NotificationComponent
                          index={index}
                          avatar={link.avatar}
                          name={!link.booster ? link.cliente : link.booster}
                          type={!link.booster ? "Cliente" : "Booster"}
                          content={link.message}
                          description={notification.description}
                        />
                      ) : (
                        <NotificationComponent
                          index={index}
                          avatar={Profile}
                          name={`Old`}
                          type={`Notification`}
                          content={notification.content}
                          description={notification.description}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-4">
                <p className="text-gray-100 text-sm font-semibold">
                  {t("table.noneactivities")}
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Info */}
        <InfoMonth />
      </div>
      <GraphGame />
    </div>
  );
};

export default ResumeAdm;
