import React, { useEffect, useState } from "react";
import Feedback from "@assets/Table/Feedback.svg";
import Delete from "@assets/Table/Delete.svg";
import Message from "@assets/Table/Message.svg";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import IconCS from "@assets/Table/IconCS.svg";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import api from "@services/client/api";
import Spin from "@assets/Spin.svg";
import Reload from "@assets/Table/Reload.svg";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useAuth } from "@context/AuthContext";
import { useTranslation } from "react-i18next";

type Props = {};

interface Candidate {
  id: string;
  name: string;
  message: string;
  game_type: string;
  current_elo: string;
  whatsapp: string;
  discord: string;
}

const TableCandidates = (props: Props) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const getCandidates = async () => {
    try {
      const response = await api.get("/candidates/all-candidates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = response;
      setCandidates(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err?.response?.data?.message);
        return;
      }

      toast.error(
        `Não foi possivel recuperar os dados dos candidatos do banco de dados. Tente novamente mais tarde.`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCandidates();
  }, []);

  const [popupMessage, setPopupMessage] = useState<{
    id: string;
    message: string;
  } | null>(null);

  const handleReload = () => {
    setLoading(true);
    getCandidates();
  };

  const handleDeleteCandidate = async (id: string) => {
    try {
      await api.delete(`/candidates/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.info(`O candidato ${id} foi deletado.`);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err?.response?.data?.message);
        return;
      }

      toast.error(
        `Não foi possivel deletar o candidato ${id}. Tente novamente mais tarde.`
      );
    } finally {
      getCandidates();
    }
  };

  const getGameIcon = (game: string) => {
    switch (game) {
      case "League Of Legends":
        return <img src={IconLol} alt="League of Legends" />;
      case "Wild Rift":
        return <img src={IconWR} alt="Wild Rift" />;
      case "Teamfight Tactics":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS2" />;
      case "CS:GO":
        return <img src={IconCS} alt="CS2" />;
      default:
        return null;
    }
  };

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastCoupon = currentPage * perPage;
  const indexOfFirstCoupon = indexOfLastCoupon - perPage;

  const displayedCandidates = candidates.slice(
    indexOfFirstCoupon,
    indexOfLastCoupon
  );

  return (
    <>
      <Card className="h-full w-full p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">
              {t('sidebar.candidates')} 
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
              {t('admin.titlecandidates')} 
              </Typography>
            </div>
            <div className="flex flex-row w-full shrink-0 gap-2 md:w-max">
              <div className="bg-blue-20 rounded min-w-[50px] text-center p-3">
                <p className="text-gray-500 font-semibold text-base select-none">
                  {candidates.length}
                </p>
              </div>
              <Button
                className="bg-blue-20 flex items-center justify-center rounded"
                size="md"
                onClick={handleReload}
              >
                <img
                  className={`w-[24px] h-[24px] ${
                    loading === true ? "animate-spin" : ""
                  }`}
                  src={loading === true ? Spin : Reload}
                  alt=""
                />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="px-0 overflow-auto bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  {t('admin.name')} 
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  {t('client.current')} 
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  Whatsapp
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  Discord
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  {t('table.game')} 
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  {t('admin.message')} 
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                {t('table.action')} 
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedCandidates.map((data) => (
                <tr
                  key={data.id}
                  className="transition-colors duration-1000 even:bg-white-20 dark:even:bg-blue-20"
                >
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                    {data.name}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                    {data.current_elo}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                    {data.whatsapp}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                    {data.discord}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                    <span className="flex items-center justify-center">
                      {getGameIcon(data.game_type)}
                    </span>
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                    <Tooltip
                      placement="top"
                      content={data.message ? data.message : "O candidato não enviou nenhuma observação!"}
                      className="p-10 min-w-[200px] max-w-[70%]"
                    >
                      <button className="p-2 rounded border border-blue-1001 relative">
                        <img src={Message} alt={Message} />
                      </button>
                    </Tooltip>
                  </td>

                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                    <button
                      className="p-2"
                      onClick={() => handleDeleteCandidate(data.id)}
                    >
                      <img src={Delete} alt={Delete} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
          <Typography variant="small" color="white" className="font-normal text-blue-50 dark:text-gray-500">
            Página {currentPage} de {Math.ceil(candidates.length / perPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-gray-600 font-semibold rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-primary-500 rounded-lg"
              onClick={handleNextPage}
              disabled={indexOfLastCoupon >= candidates.length}
            >
              Seguinte
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default TableCandidates;
