import TableAdmins from "@components/Dashboard/mainDashboard/Adm/team/admins"

type Props = {}

const AdminsAdm = (props: Props) => {
  return (
    <>
      <TableAdmins />
    </>
  )
}

export default AdminsAdm