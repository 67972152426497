import React, { useEffect, useState, useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Info from "@assets/Roleta/Info.png";
import { useRouletteContext } from "../../Context/RouletteProvider";
import mc from "@assets/maniacoin.png";
import { ManiacoinsConfig } from "@components/(Roulette)/manage/config/maniacoins";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";

const ProgressTimer = () => {
  const { t } = useTranslation();
  const { rouletteUserData, setSpinAgain } = useRouletteContext();
  const [timeUntilNextRoulette, setTimeUntilNextRoulette] =
    useState<string>("");

  const calculateTimeUntilNextRoulette = () => {
    if (!rouletteUserData) {
      return "Carregando...";
    }
  
    const { remainingRoulettes, nextRouletteDate } = rouletteUserData;
  
    const currentDate = moment().utc();
    const nextRoulette = moment.utc(nextRouletteDate); 
  
    if (!nextRoulette.isValid()) {
      return "Carregando...";
    }
  
    const additionalTime = 2 * 60 * 1000;
    const timeUntilNextRoulette = nextRoulette.diff(currentDate)

    if (timeUntilNextRoulette <= 0) {
      setSpinAgain(true);
      return "00:00:00";
    }
  
    const duration = moment.duration(timeUntilNextRoulette);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
  
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeUntilNextRoulette(calculateTimeUntilNextRoulette());
    }, 1000);

    return () => clearInterval(timer);
  }, [rouletteUserData]);

  const remainingRoulettesText = useMemo(() => {
    if (!rouletteUserData) {
      return "Carregando...";
    }

    return rouletteUserData.remainingRoulettes > 0
      ? `${rouletteUserData.remainingRoulettes} ${t("roulette.round")}`
      : timeUntilNextRoulette ? `Gire novamente em ${timeUntilNextRoulette}` : 'Carregando...';
  }, [rouletteUserData, t, timeUntilNextRoulette]);

  return <span>{remainingRoulettesText}</span>;
};

export default ProgressTimer;
