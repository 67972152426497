import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ptTranslate from './locales/pt/translation.json';
import enTranslate from './locales/en/translation.json';
import esTranslate from './locales/es/translation.json';

i18n.use(initReactI18next).init({
    fallbackLng: 'pt',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        "pt": ptTranslate,
        "es": esTranslate,
        "en": enTranslate
    }
});

export default i18n;
