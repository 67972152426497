import React, { ChangeEvent } from 'react';

const bgGray700 = 'dark:bg-gray-700';
const bgBlue300 = 'peer-focus:ring-blue-300';
const bgBlue800 = 'dark:peer-focus:ring-blue-800';
const borderGray300 = 'after:border-gray-300';
const borderGray600 = 'dark:border-gray-600';
const bgWhite = 'after:bg-white';
const bgBlue600 = 'peer-checked:bg-primary-500';
const textGray300 = 'dark:text-slate-400';

interface CustomCheckProps {
    index: number;
    label: string;
    name: string;
    checked: boolean;  // Defina se o checkbox deve estar marcado ou não
    onChange: (index: number, name: string, value: boolean, state: boolean) => void;
}

const CustomCheck: React.FC<CustomCheckProps> = ({ index, onChange, name, label, checked }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        onChange(index, name, checked, true); 
    };

    return (
        <label className="inline-flex items-center cursor-pointer">
            <input 
                type="checkbox" 
                checked={checked} // Define o estado inicial do checkbox
                className="sr-only peer" 
                onChange={handleChange} 
            />
            <div className={`relative w-11 h-6 rounded-full peer peer-focus:ring-4 ${bgBlue300} ${bgBlue800} ${bgGray700} peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] ${bgWhite} ${borderGray300} after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${borderGray600} ${bgBlue600}`}></div>
            <span className={`ms-3 text-sm font-medium ${textGray300}`}>{label}</span>
        </label>
    );
};

export default CustomCheck;
