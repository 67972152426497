import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Close from "@assets/Buy/Close.svg";
import Coupon from "@assets/Table/Coupon.svg";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { useTranslation } from "react-i18next";

type Props = {};

const PopupNewDuoBooster = (props: Props) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [nivel, setNivel] = useState("");
  const { token } = useAuth();

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSaveAccount = async () => {
    setLoading(true);

    try {
      const response = await api.post(
        "/accountDuoboost",
        {
          user,
          nick: login,
          password,
          current_level: nivel,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Conta criada com sucesso!", { autoClose: 3000 });
      handleClosePopup();
    } catch (error) {
      toast.error("Erro ao criar a conta. Tente novamente.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
        <div
          className={`fixed z-10 inset-6 flex justify-center items-center`}
        >
          <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Coupon} alt={Coupon} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("admin.new")}
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="bg-blue-50 max-h-[450px] overflow-y-auto gap-4">
                <div className="flex justify-between items-center gap-4">
                  <div className="w-[50%]">
                    <h2> {t("table.user")}: </h2>
                    <input
                      type="text"
                      id="user"
                      placeholder="Digite o usuário"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[50%]">
                    <h2> Login: </h2>
                    <input
                      type="text"
                      id="login"
                      placeholder="Digite o login"
                      value={login}
                      onChange={(e) => setLogin(e.target.value)}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center gap-4">
                  <div className="w-[50%]">
                    <h2> {t("table.password")}: </h2>
                    <input
                      type="password"
                      id="password"
                      placeholder="Digite a senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                  <div className="w-[50%]">
                    <h2> {t("table.level")}: </h2>
                    <input
                      type="text"
                      id="nivel"
                      placeholder="Ex.: Esmeralda"
                      value={nivel}
                      onChange={(e) => setNivel(e.target.value)}
                      className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end gap-2 mt-4">
                  <button
                    className="bg-gray-100 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    onClick={handleClosePopup}
                  >
                    {t("tools.close")}
                  </button>
                  <button
                    className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    onClick={handleSaveAccount}
                    disabled={loading}
                  >
                    {loading ? "Salvando..." : t("tools.save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNewDuoBooster;
