import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import api from '@services/client/api';
import { IUser } from '@utils/types';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';

interface AuthContextData {
  user: IUser | null;
  token: string | null;
  loaded: boolean;
  isLoginPopupOpen: boolean;
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  updateUser: (user: IUser) => void;
  signUp: (credentials: SignUpCredentials) => Promise<void>;
  toggleLoginPopup: () => void;
}

interface SignInCredentials {
  authtype: 'common' | 'google' | 'discord';
  email?: string;
  password?: string;
  code?: string;
  credentials?: GoogleCredentials;
}

interface GoogleCredentials {
  credential: string;
}

interface SignUpCredentials {
  name: string;
  nick_name: string;
  email: string;
  password: string;
  telephone: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const tokenCookieName = '@Elomania:token';
  const userDataCookieName = '@Elomania:user';
  const cookieLocal = typeof window !== 'undefined' ? localStorage.getItem(tokenCookieName) : null;
  const userLocal = typeof window !== 'undefined' ? localStorage.getItem(userDataCookieName) : null;

  function parseJwt(token: string) {
    if (!token) return;

    const base64Url = token.split('.')[1];
    if (!base64Url) {
      throw new Error('Formato de token inválido');
    }

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    const tokenToUse = urlToken || Cookies.get(tokenCookieName);

    try {
      const tokenCookie = tokenToUse;
      if (urlToken) {
        urlParams.delete('token');
        const newUrl = `${window.location.pathname}`;
        window.history.replaceState({}, document.title, newUrl);
      }
      else if(cookieLocal && userLocal && tokenCookie === localStorage.getItem(tokenCookieName)) {
        setUser(JSON.parse(userLocal));
        setToken(tokenCookie || '');
        setLoaded(true);
        return;
      }

      const decoded = parseJwt(tokenCookie || '') as any;
      if (decoded) {
        const payload = decoded.payload.user as IUser;
        setUser(payload);
        setToken(tokenCookie || '');
        saveUserTokenToStorageAndCookies(payload, tokenCookie || '');
      }
    } catch (error) {
      console.error('Erro ao processar o token:', error);
    } finally {
      setLoaded(true);
    }
  }, []);

  // useEffect(() => {
  //   const getUserInfo = async () => {
  //     try {
  //       const userAgent = navigator.userAgent;
  //       console.log("User Agent:", userAgent);

  //       // Exemplo de obtenção do endereço IP do usuário utilizando um serviço de terceiros
  //       const response = await fetch("https://api.ipify.org?format=json");
  //       const data = await response.json();
  //       const ipAddress = data.ip;
  //       console.log("IP Address:", ipAddress);

  //       // Verificar se é o primeiro acesso do usuário
  //       const isFirstVisit = !Cookies.get("visitedBefore");
  //       console.log("Is first visit:", isFirstVisit);

  //       // Salvar cookie indicando que o usuário já visitou o site antes
  //       Cookies.set("visitedBefore", "true", { expires: 365 });

  //       // Obter horário do acesso utilizando Moment.js
  //       const accessTime = moment().format("YYYY-MM-DD HH:mm:ss");
  //       console.log("Access Time:", accessTime);
  //     } catch (error) {
  //       console.error("Error getting user info:", error);
  //     }
  //   };

  //   getUserInfo();
  // }, []);

  const hostName = new URL(process.env.REACT_APP_URL || '').hostname;
  const useSecureCookies = hostName.startsWith('https://');

  const saveUserTokenToStorageAndCookies = (userData: IUser, userToken: string) => {
    localStorage.setItem(userDataCookieName, JSON.stringify(userData));
    localStorage.setItem(tokenCookieName, userToken);
    Cookies.set(tokenCookieName, userToken, {
      domain: hostName == 'localhost' ? hostName : '.' + hostName,
      expires: 7,
      secure: useSecureCookies,
      httpOnly: useSecureCookies,
      sameSite: 'lax',
    });
  };

  const clearUserTokenFromStorageAndCookies = () => {
    localStorage.removeItem(userDataCookieName);
    localStorage.removeItem(tokenCookieName);
    Cookies.remove(tokenCookieName, {
      domain: hostName == 'localhost' ? hostName : '.' + hostName,
      expires: 7,
      secure: useSecureCookies,
      httpOnly: useSecureCookies,
      sameSite: 'lax',
    });
  };

  const signIn = async (credentials: SignInCredentials): Promise<void> => {
    try {
      let response = null;

      if (credentials.authtype === 'common') {
        response = await signInWithEmail(credentials);
      } else if (credentials.authtype === 'google') {
        response = await signInWithGoogle(credentials);
      } else if (credentials.authtype === 'discord') {
        response = await signInWithDiscord(credentials);
      }

      if (!response || !response.data || !response.data.token || !response.data.user) {
        throw new Error('Falha ao autenticar. Dados de usuário ou token ausentes na resposta.');
      }

      const { token, user } = response.data;

      setUser(user);
      setToken(token);

      saveUserTokenToStorageAndCookies(user, token);
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      throw new Error('Falha ao fazer login. Por favor, tente novamente.');
    }
  };

  const signInWithEmail = async (credentials: SignInCredentials): Promise<AxiosResponse<any>> => {
    const { email, password } = credentials;
    return await api.post('/sessions/sign-in', { email, password });
  };

  const signInWithGoogle = async (credentials: SignInCredentials): Promise<AxiosResponse<any>> => {
    const { credential } = credentials.credentials as GoogleCredentials;
    return await api.post('/sessions/google', { credentials: { credential } });
  };

  const signInWithDiscord = async (credentials: SignInCredentials): Promise<AxiosResponse<any>> => {
    const { code } = credentials;
    return await api.post('/sessions/discord', { code });
  };

  const signOut = (): void => {
    setUser(null);
    setToken(null);

    clearUserTokenFromStorageAndCookies();
  };

  const updateUser = (updatedUser: IUser): void => {
    setUser(updatedUser);
    saveUserTokenToStorageAndCookies(updatedUser, token || '');
  };

  const signUp = async (credentials: SignUpCredentials): Promise<void> => {
    try {
      const response = await api.post('/sessions/sign-up', credentials);
      const { token, user } = response.data;

      setUser(user);
      setToken(token);

      saveUserTokenToStorageAndCookies(user, token);
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw new Error('Falha ao cadastrar usuário. Por favor, tente novamente.');
    }
  };

  const toggleLoginPopup = (): void => {
    setLoginPopupOpen((prev) => !prev);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loaded,
        token,
        isLoginPopupOpen,
        signIn,
        signOut,
        updateUser,
        signUp,
        toggleLoginPopup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
