import React, { useState, useEffect } from "react";
import Coupon from "@assets/Table/Coupon.svg";
import Add from "@assets/Table/Add.svg";
import Delete from "@assets/Table/Delete.svg";
import PopupNewCoupon from "./PopupNewCoupon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ICoupon } from "@utils/types";
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

type Props = {};

interface TableData {
  id: string;
  codigo: string;
  descricao: string;
  desconto: number;
  jogos: string[];
}

const TableCoupons = (props: Props) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const { token } = useAuth();
  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const handleButtonClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  const getGameIcon = (game: string) => {
    switch (game) {
      case "all_site":
        return <p> Todo o Site </p>;
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const loadCoupons = async () => {
    try {
      const response = await api.get(`/coupons/list-all/${user && user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCoupons(response.data);
    } catch (error: any) {
      toast.warn(error.response.data.message);
    }
  };

  useEffect(() => {
    loadCoupons();
  }, [showDeleteConfirmationModal, token]);

  const handleDeleteCoupon = async (id: string) => {
    try {
      await api.delete(`/coupons/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Cupom excluído com sucesso!");
      loadCoupons();
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao excluir o cupom. Por favor, tente novamente."
      );
    }
  };

  const tableDataCoupons: TableData[] = coupons.map((account) => ({
    id: account.id,
    codigo: account.name,
    descricao: account.description,
    desconto: account.percentage,
    jogos: account.services.split(", "),
  }));

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  /* Footer Table */

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastCoupon = currentPage * perPage;
  const indexOfFirstCoupon = indexOfLastCoupon - perPage;

  const visibleCoupons = tableDataCoupons.slice(
    indexOfFirstCoupon,
    indexOfLastCoupon
  );

  return (
    <>
      <Card className="h-full w-full p-4 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent"
        >
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" className="text-primary-500">
                {t("sidebar.cupons")}
              </Typography>
              <Typography className="mt-1 font-normal text-gray-100">
                {t("sidebar.textcupons")}
              </Typography>
            </div>
            <div className="flex flex-row w-full shrink-0 gap-2 md:w-max">
              <Button
                className="flex items-center gap-3 bg-primary-500"
                size="md"
                onClick={handleButtonClick}
              >
                <img src={Add} alt={Add} /> {t("admin.new")}
              </Button>
            </div>
          </div>
        </CardHeader>

        <CardBody className="px-0 overflow-auto bg-white-100 dark:bg-blue-50 rounded-2xl p-4">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("client.code")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
{t("table.description")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("client.discount")} %
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.game")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
                <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                  <Typography
                    variant="small"
                    color="white"
                    className="flex items-center justify-between gap-2 transition-colors duration-1000 text-gray-100 dark:text-gray-500 font-normal leading-none opacity-70"
                  >
                    {t("table.action")}
                    <div className="cursor-pointer">
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    </div>
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleCoupons.map((data) => (
                <tr
                  key={data.id}
                  className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                    isHovered ? "hovered-row" : ""
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-s-2xl">
                    {data.codigo}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.descricao || "Não Informado"}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.desconto}%
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4">
                    {data.jogos.map((jogo) => (
                      <span
                        key={jogo}
                        className="flex items-center"
                      >
                        {getGameIcon(jogo)}
                      </span>
                    ))}
                  </td>
                  <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal px-4 py-4 rounded-e-2xl">
                    <button
                      className="p-2"
                      onClick={() => handleDeleteCoupon(data.id)}
                    >
                      <img src={Delete} alt={Delete} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="flex items-center justify-between border-t border-gray-100 p-4">
          <Typography variant="small" color="white" className="font-normal text-blue-50 dark:text-gray-500">
            Página {currentPage} de {Math.ceil(coupons.length / perPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-gray-600 fonte-semibold rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              variant="filled"
              size="sm"
              className="text-white bg-primary-500 rounded-lg"
              onClick={handleNextPage}
              disabled={
                indexOfLastCoupon >= coupons.length ||
                currentPage >= Math.ceil(coupons.length / perPage)
              }
            >
              Seguinte
            </Button>
          </div>
        </CardFooter>
      </Card>
      {showPopup && <PopupNewCoupon />}
    </>
  );
};

export default TableCoupons;
