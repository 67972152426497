import TableMySalesBooster from "@components/Dashboard/Booster/historic"
import TableMyShoppingBooster from "@components/Dashboard/Booster/historic/myShopping"
import api from '@services/client/api'
import { useAuth } from '@context/AuthContext'
import { useEffect, useState } from 'react'

type Props = {}

const HistoricBooster = (props: Props) => {
  const { user, token, signOut } = useAuth();

  const [data, setData] = useState({
    availableRequests: [],
    currentRequests: [],
    finalizedRequests: [],
    gameBooster: '',
  });

  useEffect(() => {
    async function loadServices() {
      try {
        const response = await api.patch("/requests/booster/9q2a62f751b62e00b31we2ceb2za0998/all", {
          toSecure: '66dthyt6grgrtfdsfsf'
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const { data } = response;
        setData(data);

      } catch (error: any) {
        if (error?.response?.status === 401) {
          signOut();
        }
      }
     
    }
    loadServices();
  }, [token, signOut])

  return (
    <>
      {/* <TableMyShoppingBooster /> */}
      <TableMySalesBooster finalizedRequests={data.finalizedRequests} />
    </>
  )
}

export default HistoricBooster