import React, { useState, useEffect } from 'react';
import api from '@services/client/api';
import CustomCheck from '@components/custom/checkbox';
import CustomInput from '@components/custom/input';
import { useAuth } from '@context/AuthContext';

export interface ManiacoinsConfig {
    enable: boolean;
    exchangeValue: number;
}

const ManiacoinsCard: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [maniaConfig, setManiaconfig] = useState<ManiacoinsConfig | null>(null);

    const [enable, setEnable] = useState<boolean>(false);
    const [exchangeValue, setExchangeValue] = useState<number>(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const { token } = useAuth();

    useEffect(() => {
        const fetchConfig = async () => {
            setLoading(true);
            try {
                const response = await api.get('/roulette/config/props/maniacoins', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setManiaconfig(response.data.maniacoins);
            } catch (err) {
                setError('Erro ao buscar status da roleta.');
            } finally {
                setLoading(false);
            }
        };

        fetchConfig();
    }, []);

    useEffect(() => {
        if (maniaConfig) {
            setEnable(maniaConfig.enable ?? false);
            setExchangeValue(maniaConfig.exchangeValue ?? 0);
        }
    }, [maniaConfig]);

    const handleUpdate = async () => {
        if (!maniaConfig) return;
        setIsUpdating(true);
        try {
            await api.post(`/roulette/config/props/maniacoins`,
                {
                    value: {
                        enable,
                        exchangeValue,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSuccessMessage('Atualizado com sucesso!');
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
        } catch (err) {
            console.error('Erro ao atualizar configurações do Maniacoins:', err);
        } finally {
            setIsUpdating(false);
        }
    };


    if (loading) return <div className="text-gray-400">Carregando...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="px-6 py-8 h-2/4 w-1/3 bg-blue-500 rounded-md shadow-md space-y-4">
        <h2 className="w-full text-xl font-semibold text-white">Configurações de Maniacoins</h2>
            <div className="flex flex-col space-y-4">
                <div>
                    <CustomCheck
                        index={0}
                        label="Habilitar Maniacoins"
                        name="enable"
                        checked={enable}
                        onChange={(index, name, value, state) => setEnable(value)}
                    />
                </div>
                <div>
                    <CustomInput
                        index={1}
                        label="Valor de Troca"
                        name="exchangeValue"
                        type="number"
                        placeholder="Digite o valor"
                        value={exchangeValue}
                        onChange={(index, name, value, state) => setExchangeValue(Number(value))}
                        formatValue={(value) => value.replace(/[^0-9]/g, '')}
                        formatType="onChange"
                        min={1}
                        max={10}
                    />
                </div>
            </div>
            <button
                onClick={handleUpdate}
                disabled={isUpdating}
                className="bg-indigo-600 text-white p-2 w-full rounded hover:bg-indigo-700 disabled:opacity-50 transition-colors duration-300"
            >
                {isUpdating ? 'Atualizando...' : successMessage || 'Atualizar'}
            </button>
        </div>
    );
};

export default ManiacoinsCard;
