import TableCandidates from "@components/Dashboard/mainDashboard/Adm/team/Candidates"

type Props = {}

const CandidatesAdm = (props: Props) => {
  return (
    <>
      <TableCandidates />
    </>
  )
}

export default CandidatesAdm