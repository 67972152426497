import React, { useState, useEffect, ChangeEvent } from 'react';
import api from '@services/client/api';

import './switch.css'
import { AxiosRequestConfig } from 'axios';
import { useAuth } from '@context/AuthContext';
import { useTranslation } from 'react-i18next';

interface Config {
    _id: string;
    name: string;
    value: string;
    description: string;
    properties: {
        home: {
            enabled: boolean;
        };
        dashboard: {
            enabled: boolean;
        };
        page: {
            route: string;
            enabled: boolean;
        };
    };
    author: string;
}

const Maintenance: React.FC = () => {
    const { t } = useTranslation();

    const [config, setConfig] = useState<Config | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { token } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get<Config>('/config/maintenance', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setConfig(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching config:', error);
            }
        };

        fetchData();
    }, []);

  
    const handleChange = (key: string, value: boolean) => {
        if (config) {
            setConfig((prevConfig: Config | null) => {
                if (!prevConfig) return prevConfig;
                const updatedConfig = { ...prevConfig };
                updatedConfig.properties[key as keyof Config['properties']].enabled = value;
                return updatedConfig;
            });
        }
    };

    const handleRouteChange = (value: string) => {
        if (config) {
            setConfig(prevConfig => {
                if (!prevConfig) return prevConfig;
                return {
                    ...prevConfig,
                    properties: {
                        ...prevConfig.properties,
                        page: {
                            ...prevConfig.properties.page,
                            route: value
                        }
                    }
                };
            });
        }
    };

    const handleSave = async () => {
        try {
            const configAxios: AxiosRequestConfig = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
    
            if (config) {
                const { _id, ...configWithoutId } = config;
                let response = await api.put(`/config/${config._id}`, configWithoutId, configAxios);
                alert('Configuração salva com sucesso!');
            }
        } catch (error) {
            console.error('Error saving config:', error);
            alert('Erro ao salvar configuração');
        }
    };
    

    if (loading || !config) {
        return <p>Loading...</p>;
    }

    return (
        <div className="p-4 bg-blue-50 rounded-md text-white">
            <div className='flex flex-col'>
                <div className='flex flex-col gap-1.5 mb-4'>
                    <h2 className="text-xl font-bold text-primary-500">{config.name} </h2>
                    <p className="text-xs text-gray-400">Autor: {config.author}</p>
                </div>
                <p className="mb-4">{config.description}</p>
            </div>
            <div className='flex flex-row w-full h-[10em] items-center justify-between gap-2'>
                <div className="flex items-center justify-center flex-col w-full h-full bg-blue-20 rounded-md p-4 gap-3">
                    <h3 className="font-semibold text-slate-500 text-md mr-4">Home</h3>
                    <div className="switch w-full flex items-center justify-center mr-4">
                        <div className="switch__2">
                            <input id="switch-1" type="checkbox" checked={config.properties.home.enabled} onChange={(e) => handleChange('home', e.target.checked)} />
                            <label htmlFor="switch-1"></label>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center w-full h-full bg-blue-20 rounded-md p-4 gap-3">
                    <h3 className="font-semibold text-slate-500 text-md  mr-4">Dashboard</h3>
                    <div className="switch w-full flex items-center justify-center mr-5">
                        <div className="switch__2">
                            <input id="switch-2" type="checkbox" checked={config.properties.dashboard.enabled} onChange={(e) => handleChange('dashboard', e.target.checked)} />
                            <label htmlFor="switch-2"></label>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center w-full bg-blue-20 h-full p-4 gap-3">
                    <h3 className="font-semibold text-slate-500 text-md mb-2">Página específica</h3>
                    <div className='flex flex-col items-center justify-center gap-4'>
                    <div className="switch w-full flex items-center justify-center mr-3">
                            <div className="switch__2">
                                <input id="switch-3" type="checkbox" checked={config.properties.page.enabled} onChange={(e) => handleChange('page', e.target.checked)} />
                                <label htmlFor="switch-3"></label>
                            </div>
                        </div>

                        <input
                            type="text"
                            className="py-3 px-2 text-center text-md w-full border rounded-md text-slate-600 bg-blue-50 border-none outline-none"
                            value={config.properties.page.route}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleRouteChange(e.target.value)}
                            placeholder="Ex.: /smurfs"
                        />
                    </div>
                </div>
            </div>
            <button className="bg-blue-500 text-white mt-4 px-4 py-2 rounded" onClick={handleSave}>{t("tools.save")}</button>
        </div>
    );
};

export default Maintenance;
