import mc from "@assets/maniacoin.png";
import alarm from "@assets/Alarm.svg";
import api from "@services/client/api";
import { IUser } from "@utils/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Typography } from "@material-tailwind/react";
import moment from "moment";
import News from "../news";
import { Link } from "react-router-dom";

type ILevelData = {
  currentLevel: number;
  levels: number;
  progress: number;
  class: string;
  next_class: string;
  spent_next_level: number;
  spent_next_class: number;
};

type Props = {
  user: IUser;
  token: string;
};

const ResumeClient = ({ user, token }: Props) => {
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [popupMessageProgress, setPopupMessageProgress] = useState<
    string | null
  >(null);
  const [apiData, setApiData] = useState<IUser | null>(null);
  const { t } = useTranslation();
  const [expireDateInfo, setExpireDateInfo] = useState({
    formatted: "",
    relative: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setApiData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleMouseEnterProgress = (mensagem: string) => {
    setPopupMessageProgress(mensagem);
  };

  const handleMouseLeaveProgress = () => {
    setPopupMessageProgress(null);
  };

  useEffect(() => {
    const calculateExpireDate = () => {
      if (apiData && apiData.maniacoin && apiData.maniacoin.expireDate) {
        const expireDate = new Date(apiData.maniacoin.expireDate);
        const diffMilliseconds = expireDate.getTime() - Date.now();
        const diffDuration = moment.duration(diffMilliseconds);
        const formattedDate = `${Math.floor(
          diffDuration.asDays()
        )}d ${diffDuration.hours()}h ${diffDuration.minutes()}min`;
        const relativeDate = moment(expireDate).fromNow();
        setExpireDateInfo({ formatted: formattedDate, relative: relativeDate });
      }
    };

    calculateExpireDate();

    const interval = setInterval(calculateExpireDate, 60000);
    return () => clearInterval(interval);
  }, [apiData]);

  const getFullAvatarUrl = (avatar: string | null) => {
    if (
      avatar &&
      (avatar.startsWith("http://") || avatar.startsWith("https://"))
    ) {
      return avatar;
    }
    return avatar
      ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`
      : "";
  };

  return (
    <div className="w-full">
      <div className="bg-transparent grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
        {/* Capa e Perfil */}
        <div className="bg-blue-20 relative rounded-3xl w-full h-[400px] border-2 border-primary-100">
          <div className="capa w-full h-full rounded-3xl overflow-hidden">
            {/* Sobreposição escura */}
            <img
              src={
                user.banner
                  ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                  : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
              }
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
          <div className="absolute z-10 rounded-3xl py-3 top-0 flex flex-col justify-between items-center w-full h-full">
            <div className="flex items-center justify-between px-4 w-full">
              <div className="flex items-center gap-4">
                {user.subscribed && (
                  <div className="bg-blue-100 rounded-3xl w-14 py-2 text-center mb-4">
                    <p className="text-gray-500 font-normal text-xs">
                      Elomania Premium
                    </p>
                  </div>
                )}
                <div className="bg-gray-500/10 rounded-3xl w-16 py-2 flex items-center justify-center mb-4">
                  <p className="text-gray-500 font-normal text-xs">
                    {t("client.name")}
                  </p>
                </div>
              </div>
            </div>
            <div className="view-profile w-full h-full flex justify-center items-center">
              <Link
                to="/dashboard/profile"
                className="flex flex-col items-center justify-center text-center py-4 gap-8"
              >
                <img
                  src={getFullAvatarUrl(user && user.avatar)}
                  alt="IconProfile"
                  className="w-20 h-20 rounded-lg"
                />
                <p className="text-gray-500 text-base font-semibold">
                  {t("sidebar.welcome")}{" "}
                  {user && user.name ? user.name.split(" ")[0] : ""}!
                </p>
              </Link>
            </div>
            <div
              className="w-full mt-14 px-4 cursor-pointer"
              onMouseEnter={() => handleMouseEnterProgress(t("client.hover"))}
              onMouseLeave={handleMouseLeaveProgress}
            >
              <div className="flex items-center justify-between mt-8">
                <p className="text-sm text-gray-500">
                  {apiData?.levelData?.currentLevel}
                </p>
                <p className="text-sm text-gray-500">
                  {apiData?.levelData?.levels}
                </p>
              </div>
              <div className="h-2 bg-gray-500 rounded-full">
                <div
                  className="h-full bg-gradient-to-l from-[#FF8730] to-[#FFE62E]  shadow-[2px_2px_16px_#FF8730] rounded-full"
                  style={{ width: `${apiData?.levelData?.progress || 0}%` }}
                ></div>
              </div>
              <div className="flex items-center justify-between mt-2">
                <p className="text-gray-100 font-semibold text-base">
                  {t("client.current")}: &nbsp;
                  <span className="text-gray-500">
                    {apiData?.levelData?.class || "N/A"}
                  </span>
                </p>
                <p className="text-gray-100 font-semibold text-base">
                  {t("client.next")}: &nbsp;
                  <span className="text-gray-500">
                    {apiData?.levelData?.next_class || "N/A"}
                  </span>
                </p>
              </div>
              {popupMessageProgress && (
                <div
                  className="absolute left-2 z-20 mt-4 bg-blue-50 border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                  onMouseEnter={() =>
                    handleMouseEnterProgress(t("client.hover"))
                  }
                  onMouseLeave={handleMouseLeaveProgress}
                >
                  {popupMessageProgress}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col h-full">
          <div className="flex items-center justify-center select-none gap-4 transition-colors duration-1000 bg-gradient-to-b py-10 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl p-4 border border-gray-100 dark:border-secondary-500">
            <img src={mc} alt="Mania Coins" width={32} height={32} />
            <span className="text-blue-50 dark:text-gray-500 font-semibold text-base transition-colors duration-1000">
              ManiaCoins
            </span>
            <div className="px-5 py-1 min-w-[55px] font-semibold text-lg rounded-full border-2 border-blue-1001 bg-blue-50 dark:bg-blue-1001/10 shadow-2xl">
              {apiData && apiData.maniacoin && apiData.maniacoin.totalAmount
                ? `${apiData.maniacoin.totalAmount.toLocaleString("pt-BR")}`
                : "0"}
            </div>
          </div>
          <div className="flex flex-col text-center justify-between items-center gap-4 h-full transition-colors duration-1000 bg-gradient-to-b p-2 pt-6 pb-4 from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-3xl border border-gray-100 dark:border-secondary-500 mt-4">
            <h3 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-lg">
              {t("client.title")}
            </h3>
            <span className=" text-center text-gray-100 text-sm font-medium w-full">
              {t("client.mc")}
            </span>
            <div className="flex items-center justify-center gap-1 text-sm text-[#FFE143] font-medium transition duration-500">
              <img src={alarm} alt="Timer" width={25} height={25} />
              {apiData && apiData.maniacoin.totalAmount > 0 ? (
                <span className="whitespace-nowrap text-blue-50 dark:text-gray-100 text-xs font-semibold">
                  {t("client.timeLeft")}: {expireDateInfo.formatted}
                </span>
              ) : (
                <span className="whitespace-nowrap text-blue-50 dark:text-gray-100 text-xs font-semibold">
                 {t("client.nomc")}
                </span>
              )}
            </div>
          </div>
        </div>
        <News />
      </div>
    </div>
  );
};

export default ResumeClient;